import store from "../../../../store";

import {
    resetFTReportAllFilters,
    setFTReportCRMIdValue,
    setFTReportFromDateValue,
    setFTReportLimitCountValue,
    setFTReportPageValue,
    setFTReportRowsPerPageValue,
    setFTReportSearchKeywordValue,
    setFTReportSkipCountValue,
    setFTReportStatusValue,
    setFTReportToDateValue,
    setFTReportTxnSubTypeValue,
    setFTReportTxnTypeValue
} from "./FTReportReducer";

export const setFTReportKeywordValue = (data) => {
    store.dispatch(setFTReportSearchKeywordValue(data));
}

export const setFTReportSkipCount = (data) => {
    store.dispatch(setFTReportSkipCountValue(data));
}

export const setFTReportLimitCount = (data) => {
    store.dispatch(setFTReportLimitCountValue(data));
}

export const setFTReportPageCount = (data) => {
    store.dispatch(setFTReportPageValue(data));
}

export const setFTReportRowsPerPage = (data) => {
    store.dispatch(setFTReportRowsPerPageValue(data));
}

export const resetFTReportFilters = (data) => {
    store.dispatch(resetFTReportAllFilters(data));
}

export const setFTReportCRMId= (data) => {
    store.dispatch(setFTReportCRMIdValue(data));
}

export const setFTReportTxnType= (data) => {
    store.dispatch(setFTReportTxnTypeValue(data));
}

export const setFTReportTxnSubType= (data) => {
    store.dispatch(setFTReportTxnSubTypeValue(data));
}

export const setFTReportStatus= (data) => {
    store.dispatch(setFTReportStatusValue(data));
}

export const setFTReportFromDate= (data) => {
    store.dispatch(setFTReportFromDateValue(data));
}

export const setFTReportToDate= (data) => {
    store.dispatch(setFTReportToDateValue(data));
}