import React from 'react';

import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import appTheme from "../../../../../../../assets/appTheme";

import SummaryByClient from "./components/summaryByClient/SummaryByClient";
import SummaryByFinancial from "./components/summaryByFinancialCategory/SummaryByFinancialCategory";
import SummaryByFund from "./components/summaryByFundHouse/SummaryByFundHouse";

import DLText from "../../../../../../../core/components/DLText/DLText";

const AnalyticsView = (props) => {
    const {isXSView, isMobileView, isSMView, isMDView} = props;

    const renderButtons = () => {
        const {getAnalyticsData, isSelected} = props;

        const getButtonStyle = (isSelected, selectedValue) => ({
            textTransform: 'none',
            borderRight: '1px solid #B2B2B2',
            backgroundColor: isSelected === selectedValue ? appTheme.appColor.darkBlue : appTheme.appColor.white,
            color: isSelected === selectedValue ? appTheme.appColor.white : '',
            minWidth: '48px',
            borderColor: '#B2B2B2',
            fontSize: '14px',
            fontFamily: "'Poppins', sans-serif",
        });

        return (
            <ButtonGroup variant="outlined"
                         aria-label="outlined button group"
                         style={{height: '32px'}}>
                <Button className={'rounded-start'}
                        style={getButtonStyle(isSelected, 'TODAY')}
                        onClick={() => {
                            getAnalyticsData('TODAY')
                        }}
                >D</Button>
                <Button style={getButtonStyle(isSelected, 'THIS_WEEK')}
                        onClick={() => {
                            getAnalyticsData('THIS_WEEK')
                        }}
                >W</Button>
                <Button style={getButtonStyle(isSelected, 'THIS_MONTH')}
                        onClick={() => {
                            getAnalyticsData('THIS_MONTH')
                        }}
                >M</Button>
                <Button
                    style={getButtonStyle(isSelected, 'THIS_QUARTER')}
                    onClick={() => {
                        getAnalyticsData('THIS_QUARTER')
                    }}
                >Q</Button>
                <Button className={'rounded-end'}
                        style={getButtonStyle(isSelected, 'THIS_FINANCIAL_YEAR')}
                        onClick={() => {
                            getAnalyticsData('THIS_FINANCIAL_YEAR')
                        }}
                >FY</Button>
            </ButtonGroup>
        )
    };

    const renderWidgetsTabUI = () => {
        return (
            <div className={`page-container ${isMDView ? 'pb-4 mb-4' : 'mb-3'}`} style={{minHeight: 310}}>
                <div className={'row py-3'}>
                    <div className={'col-12'}>
                        <SummaryByClient
                            {...props}
                        />
                    </div>
                </div>
                <div className={'row pb-3'}>
                    <div className={'col-6'}>
                        <SummaryByFund
                            {...props}
                        />
                    </div>
                    <div className={'col-6'}>
                        <SummaryByFinancial
                            {...props}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderWidgetsUI = () => {
        return (
            <div className={isXSView ? '' : 'px-3 py-3 d-flex'} style={{minHeight: 310}}>
                <div style={{width: isXSView ? '100%' : '40%'}}>
                    <SummaryByClient
                        {...props}
                    />
                </div>
                {isXSView && <div className='bg-light' style={{height: '7px'}}/>}
                <div className={isXSView ? '' : 'px-3'}
                     style={{width: isXSView ? '100%' : '30%'}}>
                    <SummaryByFund
                        {...props}
                    />
                </div>
                {isXSView && <div className='bg-light' style={{height: '7px'}}/>}
                <div className={isXSView ? 'pb-4' : ''} style={{width: isXSView ? '100%' : '30%'}}>
                    <SummaryByFinancial
                        {...props}
                    />
                </div>
            </div>
        );
    };

    const renderUI = () => {
        return (
            <div className={isMobileView ? `bg-white ${isMDView ? 'pb-1' : 'pb-3'} `  : 'border-rounded panel p-0'}>
                <div
                    className={"border-bottom d-flex justify-content-between align-items-center rounded-top px-3 py-2"}>
                    <div>
                        <DLText id={'title-name'}
                                isInline={true}
                                fontSize={isXSView ? 'md' : 'lg'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={"Analytics"}/>
                    </div>
                    {renderButtons()}
                </div>
                {(isSMView || isMDView) ? renderWidgetsTabUI() : renderWidgetsUI()}
            </div>
        );
    };

    return (
        <div>
            {renderUI()}
        </div>
    )
};

export default AnalyticsView;