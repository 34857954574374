export const headingFTData = ['Account', 'Ticket Number', 'Type of Request', 'Status', 'Created Date'];

export const headingFTDataForManagement = ['Account', 'Ticket Number', 'Type of Transaction', 'Status', 'Created Date', 'CRM & Location'];

export const headingFTReportData = ['Account Holders', 'IIN', 'Account Type', 'Transaction Type', 'Transaction Date', 'Schemes', 'Amount', 'Transaction Status', 'Sub Transaction Type', 'Target Product', 'Registrar ID', 'Folio No.', 'Payment Mode' , 'RTA Status', 'UMRN No.', 'Category', 'Action'];

export const headingFTReportDataForManagement = ['Account Holders', 'IIN', 'Account Type', 'Transaction Type', 'Transaction Date', 'Schemes', 'Amount', 'Transaction Status', 'Sub Transaction Type', 'Target Product', 'Registrar ID', 'Folio No.', 'Payment Mode' , 'RTA Status', 'UMRN No.', 'Category', 'CRM', 'CRM Location', 'Action'];

export const purchaseTransactionHeadingData = ['Schemes', 'Folio No.', 'Allocation', 'Amount', 'Action'];

export const purchaseTransactionCellConfig = [
    {
        textAlign: 'left',
        width: '35%',
        minWidth: '35%'
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'right',
        width: '100px',
        minWidth: '100px',
        paddingRight: 100
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'center',
        width: '15%',
        minWidth: '15%'
    }
];

export const FTReportCellConfig = [
    {
        minWidth: "300px",
        maxWidth: '300px',
        width: '300px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "425px",
        maxWidth: '425px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "120px",
        maxWidth: '120px',
        textAlign: "right",
    },
    {
        minWidth: "240px",
        maxWidth: '240px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "425px",
        maxWidth: '425px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "250px",
        maxWidth: '250px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "100px",
        maxWidth: '100px',
        textAlign: "center",
    },
];

export const FTReportCellConfigForManagement = [
    {
        minWidth: "300px",
        maxWidth: '300px',
        width: '300px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "425px",
        maxWidth: '425px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "120px",
        maxWidth: '120px',
        textAlign: "right",
    },
    {
        minWidth: "240px",
        maxWidth: '240px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "425px",
        maxWidth: '425px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "150px",
        maxWidth: '150px',
        textAlign: "left",
    },
    {
        minWidth: "250px",
        maxWidth: '250px',
        textAlign: "left",
    },
    {
        minWidth: "170px",
        maxWidth: '170px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
    },
    {
        minWidth: "200px",
        maxWidth: '200px',
        textAlign: "left",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    {
        minWidth: "100px",
        maxWidth: '100px',
        textAlign: "center",
    },
];

export const purchaseSIPTransactionHeadingData = ['Schemes', 'Folio No.','SIP Start Date & End Date','SIP Date', 'Allocation', 'Amount', 'Action'];

export const purchaseSIPTransactionCellConfig = [
    {
        textAlign: 'left',
        width: '30%',
        minWidth: '30%',
        maxWidth:'30%'
    },
    {
        textAlign: 'left',
        width: '150px',
        minWidth: '150px'
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'left',
        width: '72px',
        minWidth: '72px'
    },
    {
        textAlign: 'right',
        width: '100px',
        minWidth: '100px',
    },
    {
        textAlign: 'left',
        width: '15%',
        minWidth: '15%'
    },
    {
        textAlign: 'center',
        width: '15%',
        minWidth: '15%'
    }
];



