import {connect} from 'react-redux';
import {isEmpty} from "../../../core/components/DLComponentHelper";
import store from "../../../store";
import {
    clientsListing,
    raiseRequest,
} from '../../ClientRequestsActions';
import {getCategoryOptions, getClientAccountTypeOptions as getAccountTypeOptions} from "../../../AppComponents/MetadataActions";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import {showToastMessage} from "../../../AppComponents/AppActions";
import {remark} from "../../../onboarding/components/formsAndReview/FormsAndReviewActions";
import RaiseRequestView from "./RaiseRequestView";
import React, {useEffect} from "react";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const RaiseRequest = (props) => {

        const [CreateRequest, setCreateRequest] = React.useState({
            isOptionSelected: false,
            IINNumber: '',
            keyword: '',
            clients: [],
            accountHolders: [],
            accountType: '',
            accountId: '',
            category: '',
            companyName: '',
            remark: '',
            modalErrorMessage: '',
            scrollToFieldId: '',
            isSubmitApiInProgress:false,
            isApiInProgress: false,
            handleErrorInComponent: true,
            UIState: '',
            isShowInlineLoader: '',
            errors: {
                IINNumber: '',
                keyword: '',
                remark: '',
            },
            dropdownValues: {
                categoryOptions: [],
                accountTypeOptions: [],
            }
        });

        useEffect(() => {
            getCategoryValues();
        }, []);

        const capitalizeFirstLetter = (sentence) => {
            return sentence
                .split(' ')
                .map(word => word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : '')
                .join(' ');
        }

        const setValuesForDropdown = (data, labelToken, valueToken) => {
            if (isEmpty(data)) {
                return;
            }

            return data.map((item) => {
                return {label: item?.[labelToken], value: item?.[valueToken]}
            });
        };

        const getCategoryValues = () => {

            setCreateRequest(prevState => ({
                ...prevState,
                isLoaderVisible: true
            }))

            getCategoryOptions()
                .then(res => {
                    if (res?.success) {
                        getAccountTypeOptions()
                            .then(accountTypeRes => {
                                if (accountTypeRes?.success) {
                                    setCreateRequest(prevState => ({
                                        ...prevState,
                                        dropdownValues: {
                                            ...prevState.dropdownValues,
                                            categoryOptions: setValuesForDropdown(res.data?.categoryType, "name", "id"),
                                            accountTypeOptions: setValuesForDropdown(accountTypeRes.data?.accountType, "name", "id")
                                        },
                                        UIState: APP_CONST.CONTENT_AVAILABLE
                                    }))
                                } else {
                                    setCreateRequest(prevState => ({
                                        ...prevState,
                                        isLoaderVisible: false,
                                        UIState: APP_CONST.CONTENT_UNAVAILABLE
                                    }));
                                }
                            })
                    } else {
                        setCreateRequest(prevState => ({
                            ...prevState,
                            isLoaderVisible: false,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE
                        }));
                    }
                })
        }

        const handleSearch = (keyword) => {

            setCreateRequest(prevStep => ({
                ...prevStep,
                keyword: keyword,
                isOptionSelected: false,
                isShowInlineLoader: true,
            }));

            const payload = {
                keyword: keyword,
            };

            if (isEmpty(keyword)) {
                return;
            }

            clientsListing(payload)
                .then((res) => {
                    if (res.success) {
                        setCreateRequest((prevStep) => ({
                            ...prevStep,
                            clients: res?.data,
                            isShowInlineLoader: false,
                        }))
                    } else {
                        setCreateRequest((prevStep) => ({
                            ...prevStep,
                            clients: [],
                            isShowInlineLoader: false,
                        }))
                    }
                })
        }

        const getAccountType = (value) => {
            const {dropdownValues} = CreateRequest;
            const {accountTypeOptions} = dropdownValues;
            let accountType = accountTypeOptions.find((item) => {
                return item?.value === value;
            })
            return accountType?.label + " Account";
        }

        const getMappedData = (response) => {
            const {errors} = CreateRequest;
            if (isEmpty(response) || response?.length === 0) {
                return {...CreateRequest}
            }

            return {
                category: isEmpty(response?.category) ? '' : response?.category,
                accountHolders: isEmpty(response?.accountHolders) ? '' : response?.accountHolders,
                companyName: isEmpty(response?.corporate?.name) ? '' : response?.corporate?.name,
                accountType: isEmpty(response?.accountType) ? '' : response?.accountType,
                accountId: isEmpty(response?.accountId) ? '' : response?.accountId,
                IINNumber: isEmpty(response?.IINNumber) ? '' : response?.IINNumber,

                errors: {
                    ...errors,
                    category: !isEmpty(response?.category) ? '' : errors?.category,
                    IINNumber: !isEmpty(response?.IINNumber) ? '' : errors?.IINNumber
                },
            };
        };

        const setCategoryValues = (value) => {
            const {dropdownValues} = CreateRequest;
            const {categoryOptions} = dropdownValues;
            let option = categoryOptions.find((item) => {
                return item.value === value;
            })
            return option?.label;
        }

        const handleClick = (id) => {
            const {clients} = CreateRequest;
            let data = clients.find(item => (item.accountId === id) ? item : '');
            let mappedState = getMappedData(data);
            setCreateRequest(prevStep => ({
                ...prevStep,
                ...mappedState,
                keyword: '',
                isOptionSelected: true,
                errors: {
                    ...prevStep.errors,
                    IINNumber: ''
                }
            }));
        }

        const handleChangeClient = () => {
            setCreateRequest(prevState => ({
                ...prevState,
                isOptionSelected: false,
                IINNumber: '',
                clients: [],
                accountHolders: [],
                accountType: '',
                category: '',
                accountId: '',
            }))
        }

        const validateChanges = (title, compState) => {
            const {remark, IINNumber} = compState;
            let errorObj = {...CreateRequest.errors};

            if (title === "remark" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(remark)) {
                    errorObj.remark = "Please enter remark";
                } else if (remark.length > 500) {
                    errorObj.remark = "Maximum 500 characters are allowed";
                } else {
                    errorObj.remark = "";
                }

                if (title === 'remark') {
                    return errorObj;
                }
            }

            if (title === "IINNumber" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(IINNumber)) {
                    errorObj.IINNumber = "Please select IIN Number";
                } else {
                    errorObj.IINNumber = "";
                }

                if (title === 'IINNumber') {
                    return errorObj;
                }
            }

            return errorObj;
        };

        function removeErrorWord(input) {
            return input.replace('Error', '');
        }

        const getFirstErrorFieldId = (errors) => {
            for (const section in errors) {
                const error = errors[section];
                if (typeof error === 'string' && error) {
                    return (removeErrorWord(section)) + '-field';
                }
            }
            return null;
        };

        const checkIfValidateObj = (validateObj) => {
            const {remark, IINNumber} = validateObj;
            if (remark || IINNumber) {
                // If any mandatory error is present (non-empty), return false
                return false;
            }
            // If no mandatory errors are present, return true
            return true;
        };

        const clearData = () => {
            setCreateRequest(prevState => ({
                ...prevState,
                isOptionSelected: false,
                IINNumber: '',
                clients: [],
                accountHolders: [],
                accountType: '',
                category: '',
                accountId: '',
            }))
        };

        const handleSubmit = () => {

            const {
                remark, handleErrorInComponent, accountId
            } = CreateRequest;

            const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, CreateRequest);

            setCreateRequest((prevStep) => ({
                ...prevStep,
                isSubmitApiInProgress:true,
                scrollToFieldId: getFirstErrorFieldId(validateObj)
            }));

            if (!checkIfValidateObj(validateObj)) {
                setCreateRequest(prevStep => ({
                    ...prevStep,
                    isApiInProgress: false,
                    isSubmitApiInProgress:false,
                    errors: {
                        ...prevStep.errors,
                        ...validateObj
                    }
                }));
                return;
            }

            const payload = {
                type: "FINANCIAL",
                comment: remark,
                accountId: accountId
            };

            setCreateRequest(prevStep => ({
                ...prevStep,
                isApiInProgress: true
            }));

            raiseRequest(payload, handleErrorInComponent)
                .then((res) => {
                        if (res.success) {
                            clearData();
                            store.dispatch(showToastMessage('success', 'A request is raised'))
                            setCreateRequest((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false
                            }));
                            props.history.push('/client-requests');
                        } else {
                            setCreateRequest((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false,
                                modalErrorMessage: res?.__error
                            }));
                        }
                    }
                );
        };

        const onBlurInputText = (name) => {
            const validateObject = validateChanges(name, CreateRequest);

            setCreateRequest(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObject
                },
            }));
        };

        const onChangeFormData = (name, value) => {
            if (name === 'scrollToFieldId') {
                setCreateRequest(prevStep => ({
                    ...prevStep,
                    scrollToFieldId: value
                }));
                return
            }
            let updatedState = {...CreateRequest};
            updatedState[name] = value;
            updatedState[name + 'Error'] = '';

            const validateObject = validateChanges(name, updatedState);

            setCreateRequest(prevStep => ({
                ...prevStep,
                ...updatedState,
                modalErrorMessage: '',
                errors: {
                    ...prevStep.errors,
                    ...validateObject
                },
            }));
        };

        if (isEmpty(CreateRequest.UIState)) {
            return (<DLLoader type={"screen"} isVisible={true}/>);
        }

        return (
            <RaiseRequestView
                {...props}
                {...CreateRequest}
                handleSearch={handleSearch}
                getAccountType={getAccountType}
                handleClick={handleClick}
                setCategoryValues={setCategoryValues}
                handleChangeClient={handleChangeClient}
                onBlurInputText={onBlurInputText}
                handleSubmit={handleSubmit}
                onChangeFormData={onChangeFormData}
                capitalizeFirstLetter={capitalizeFirstLetter}
            />
        );
    }
;

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(RaiseRequest);
