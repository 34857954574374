import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import DLLoader from "../../../core/components/DLLoader/DLLoader";
import {
    setSearchKeyword, setFromDate, getLeadsListing,
    setToDate, setStatus, resetLeadFilters,
    setSkipCount, setLimitCount, setPageCount, setLeadsRowPerPageCount, setCRM, resetSelectedLeadFilter
} from "../../LeadsActions";
import {getLeadSources, getCRMs} from "../../../AppComponents/MetadataActions";

import ManagementLeadListView from "./ManagementLeadsListView";


const ManagementLeadList = (props) => {
    const [leadListState, setLeadListState] = useState({
        leads: [],
        listingCount: 0,
        isShowFilterMobile: false,
        isCreateModalOpen: false,
        isAssignCRMModalOpen: false,
        isHistoryModalOpen: false,

        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        filters: {
            keyword: '',
            fromDate: '',
            toDate: '',
            status: [],
            CRMId: 'ALL'
        },
        sourceNames: [],
        CRMs: [],
        advisoryUsers : [],  //todo remove after component modification
        selectedIds: [],

        selectedLeadId : "",
        selectedLeadName: ""
    });

    const {page, rowsPerPage, skipCount, limitCount} = props.leadsFilter;

    useEffect(() => {
        getCRMData();
        getSourceNameList();
        getLeads();
    }, []);

    useEffect(() => {
        getLeads();
    }, [props.leadsFilter]);

    const getCRMData = () =>{
        setLeadListState((prevStep) => ({
            ...prevStep,
            isShowLoader: true
        }));
        getCRMs().then((CRMRes) => {
            if (CRMRes?.success) {
                setLeadListState((prevStep) => ({
                    ...prevStep,
                    CRMs: setValuesForDropdown(CRMRes.data?.advisoryUsers, "firstName", "id"),
                    advisoryUsers: CRMRes.data?.advisoryUsers,
                    isShowLoader: false
            }));

            } else {
                setLeadListState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false
                }));
            }
        })
    }

    const getSourceNameList = () => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isShowLoader: true
        }));
        getLeadSources().then((res) => {
            if (res?.success) {
                setLeadListState((prevStep) => ({
                    ...prevStep,
                    sourceNames: res.data?.sources,
                    isShowLoader: false
                }));
            } else {
                setLeadListState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false
                }));
            }
        })
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: 'ALL'},{label: "Unassigned", value: 'UNASSIGNED'}]
        if (isEmpty(data)) {
            return;
        }

        data.map((item) => {
            list.push({label: item?.[labelToken]+" "+item?.lastName, value: item?.[valueToken]})
        });

        return list;
    };

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.toDate)) {
            filterObject.toDate = filter.toDate;
        }
        if (!isEmpty(filter.fromDate)) {
            filterObject.fromDate = filter.fromDate;
        }
        if (!isEmpty(filter.status)) {
            if (filter.status[0] === "ALL") {
                delete filterObject.status
            } else {
                filterObject.status = filter.status;
            }
        }
        if (!isEmpty(filter.CRMId) && filter.CRMId !== "ALL") {
            filterObject.CRMId = filter.CRMId;
        }
        return filterObject;
    };

    const getLeads = () => {
        const {leadsFilter} = props;

        setLeadListState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            skipCount: leadsFilter.skipCount,
            limitCount: leadsFilter.limitCount,
            filters: generateFilter(leadsFilter)
        };

        getLeadsListing(payload)
            .then(res => {
                if (res?.success) {
                    setLeadListState(prevState => ({
                        ...prevState,
                        leads: res.data?.leadRequests,
                        listingCount: res.data?.count,
                        skipCount: leadsFilter.skipCount,
                        limitCount: leadsFilter.limitCount,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setLeadListState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const applyFilterBadge = () => {
        const {leadsFilter} = props;
        let badgeObj = {
            title : '',
            data : ''
        };
        if (!isEmpty(leadsFilter.toDate) || !isEmpty(leadsFilter.fromDate)) {
            badgeObj.title= 'Not Contacted: ';
            badgeObj.data= leadsFilter.dateKey;
        }
        if (!isEmpty(leadsFilter.CRMId) && leadsFilter.CRMId !== "ALL" && leadsFilter.CRMId !== "UNASSIGNED") {
            badgeObj.title= '';
            badgeObj.data= '';
        }
        return badgeObj;
    };

    // Function to determine whether to enable/disable the pagination control buttons
    const getPaginationNextButtonStatus = () => {
        const {listingCount} = leadListState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setSearchKeyword(value);
            return;
        }

        if (name === 'fromDate') {
            let fromDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    fromDate: fromDate
                }
            }));
            setFromDate(fromDate);
            return;
        }

        if (name === 'toDate') {
            let toDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    toDate: toDate,
                }
            }));
            setToDate(toDate);
            return;
        }

        if (name === 'status') {
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    status: [value]
                }
            }));
            setStatus([value]);
        }
        if (name === 'CRMId') {
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    CRMId: value,
                }
            }));
            setCRM(value);
        }
        if (name === 'fromtoDate') {
            let fromDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            let toDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    fromDate: fromDate,
                    toDate: toDate,
                }
            }));
            resetSelectedLeadFilter('Date');
        }
    };

    const resetPagination = () => {
        setLeadsRowPerPageCount(50);
        setLimitCount(50);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setLeadListState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setLeadsRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let tempLimitCount = limitCount;
        let tempSkipCount = skipCount;

        const isForward = newPage > page;
        if (isForward) {
            tempSkipCount = tempSkipCount + rowsPerPage;
            tempLimitCount = rowsPerPage
        } else {
            tempSkipCount = tempSkipCount - rowsPerPage;
            tempLimitCount = rowsPerPage
        }

        setSkipCount(tempSkipCount);
        setLimitCount(tempLimitCount);
        setPageCount(newPage);
    };

    const handleToggleMobileFilter = (value) => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };



    const handleOpenAssignCRMModal = () => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isAssignCRMModalOpen: true
        }));
    };

    const handleCloseAssignCRMModal = () => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isAssignCRMModalOpen: false
        }));
    };

    const resetLeadListing = () => {
        resetLeadFilters();
    };

    const onSaveSuccess = () => {
        setLeadListState(prevState => ({
            ...prevState,
            selectedIds: []
        }));
        getLeads();
    };

    const getSourceName = (id) => {

        const {sourceNames} = leadListState;
        if (id === 'NONE') {
            return 'None'
        }
        const selectedSource = sourceNames.find(source => source.id === id);
        return selectedSource ? selectedSource.name : "None";
    };

    const handleCheckBox = (id) =>{
        const{selectedIds}= leadListState
        let array = selectedIds;
        if(!array.includes(id)){
            array.push(id);
        }else{
            array.forEach((item, index)=>{
                if(item === id){
                    array.splice(index, 1);
                }
            })
        }
        setLeadListState((prevState)=>({
            ...prevState,
            selectedIds : array
        }))
    }

    const getCRMName = (id) =>{
        const{CRMs} = leadListState;
        let CRMName = ""
        if(isEmpty(id)){
           return "";
        }
        CRMs.forEach((item)=>{
            if(item?.value === id){
                CRMName = item?.label;
            }
        })
        return isEmpty(CRMName)? '-' : CRMName;
    }

    const checkIsChecked = (id) =>{
        const{selectedIds}= leadListState;
        return selectedIds.includes(id);
    }

    const selectedOption = (value, id, leadName) =>{
        if(value ===  "Delete"){
            handleOpenDeleteLeadModal(id, leadName)
        }
        if(value ===  "History"){
            handleOpenHistoryModal(id, leadName)
        }
    }

    const handleOpenDeleteLeadModal = (id, leadName) => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isDeleteLeadModalOpen: true,
            selectedLeadId : id,
            selectedLeadName: leadName
        }));
    };

    const handleCloseDeleteLeadModal = () => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isDeleteLeadModalOpen: false,
            selectedLeadId : "",
            selectedLeadName : ""
        }));
    };

    const handleOpenHistoryModal = (id, leadName) => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isHistoryModalOpen: true,
            selectedLeadId : id,
            selectedLeadName: leadName
        }));
    };

    const handleCloseHistoryModal = () => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isHistoryModalOpen: false,
            selectedLeadId : "",
            selectedLeadName : ""
        }));
    };

    const isFiltersApplied = (filters) => {

        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (key === 'status' && filters[key][0] === 'NOT_CONTACTED') {
                        return false
                    }
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '') {
                    return true;
                }
            }
        }
        return false;
    };

    const handleRefreshButton=()=>{
        getLeads();
    };

    const redirectToDetailsScreen = (leadId) => {
        props.history.push('/leads/' + leadId)
    };

    if (isEmpty(leadListState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <ManagementLeadListView
            {...props}
            {...leadListState}
            {...props.leadsFilter}
            handleRefreshButton={handleRefreshButton}
            handleToggleMobileFilter={handleToggleMobileFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            getLeads={getLeads}
            onChangeFilter={onChangeFilter}
            onSaveSuccess={onSaveSuccess}
            resetLeadListing={resetLeadListing}
            redirectToDetailsScreen={redirectToDetailsScreen}
            getSourceName={getSourceName}
            handleOpenAssignCRMModal={handleOpenAssignCRMModal}
            handleCloseAssignCRMModal={handleCloseAssignCRMModal}
            handleCheckBox={handleCheckBox}
            checkIsChecked={checkIsChecked}
            getCRMName={getCRMName}
            applyFilterBadge={applyFilterBadge}
            handleOpenDeleteLeadModal={handleOpenDeleteLeadModal}
            handleCloseDeleteLeadModal={handleCloseDeleteLeadModal}
            handleOpenHistoryModal={handleOpenHistoryModal}
            handleCloseHistoryModal={handleCloseHistoryModal}
            selectedOption={selectedOption}
            isFiltersApplied={isFiltersApplied}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    leadsFilter: state.leadState.leadsFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(ManagementLeadList);
