import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import moment from "moment";
import {isEmpty} from "../../../../../../../core/components/DLComponentHelper";
import {getCRMSummary} from "../../../../../../HomePageActions";
import {
    setOverviewInterval
} from "../../../../../managementDashboard/components/transactions/components/overview/OverviewAction";

import OverviewsView from "./OverviewsView";
import {intervalFilterOptions} from "./OverviewConst";

const Overview = (props) => {
    const [OverviewState, setOverviewState] = useState({
        interval: 'THIS_MONTH',
        intervalOptions: [],
        xAxis: [],
        yAxis: [],
        businessAmount: 0,
        purchaseAmount: 0,
        sipAmount: 0,
        redemptionAmount: 0,
        isShowLoader: false,
        isApiInProgress: false,
        UIState: '',
        isChartClickModalOpen: false,
        graphData: [],
        selectedDataIndex: ''
    });

    useEffect(() => {
        getChartData(OverviewState.interval);
    }, [OverviewState.interval]);

    const getChartData = (interval) => {

        setOverviewState((prevState) => ({
            ...prevState,
            isShowLoader: true,
            intervalOptions: setValuesForDropdown(intervalFilterOptions, 'label', 'value'),
        }));

        getCRMSummary(interval).then((res) => {
            if (res?.success) {
                setOverviewState((prevState) => ({
                    ...prevState,
                    isApiInProgress: true,
                    intervalOptions: setValuesForDropdown(intervalFilterOptions, 'label', 'value', null),
                    businessAmount: isEmpty(res?.data?.totalBusiness) ? 0 : res?.data?.totalBusiness,
                    purchaseAmount: isEmpty(res?.data?.totalPurchase) ? 0 : res?.data?.totalPurchase,
                    sipAmount: isEmpty(res?.data?.totalSip) ? 0 : res?.data?.totalSip,
                    redemptionAmount: isEmpty(res?.data?.totalRedemption) ? 0 : res?.data?.totalRedemption,
                    isShowLoader: false,
                    graphData: res?.data
                }));
                setChartData(res?.data, interval);
            } else {
                setOverviewState((prevState) => ({
                    ...prevState,
                    isApiInProgress: false,
                    isShowLoader: false,
                }))
            }
        })
    };

    const setChartData = (data, interval) => {
        let xAxis = [];
        let yAxis = [];
        if (isEmpty(data)) {
            setOverviewState((prevState) => ({
                ...prevState,
                businessAmount: 0,
                purchaseAmount: 0,
                sipAmount: 0,
                redemptionAmount: 0,
            }));
            return;
        }
        data?.items?.map((item) => {
            if(interval === 'THIS_WEEK') {
                xAxis.push(moment(item?.date).format("ddd"));
            }
            else if(interval === 'THIS_MONTH') {
                xAxis.push(moment(item?.date).format("DD-MMM"));
            }
            else {
                xAxis.push(moment(item?.date).format("MMM"));
            }

            yAxis.push(item?.totalamount);
        });
        setOverviewState(prevState => ({
            ...prevState,
            xAxis: xAxis,
            yAxis: yAxis,
        }));
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [];
        if (isEmpty(data)) {
            return;
        }

        data.map((item) => {
            list.push({
                label: item?.[labelToken],
                value: item?.[valueToken]
            })
        });

        return list;
    };

    const handleChangeFilter = (interval) => {
        setOverviewState((prevState) => ({
            ...prevState,
            interval: interval
        }));

        setOverviewInterval(interval);
    };

    const handleChartClick = (params) => {
        const{graphData} = OverviewState;
        let legendAttribute = getLegendAttribute(params?.dataIndex, graphData?.items);

        setOverviewState((prev) => ({
            ...prev,
            selectedDataIndex: params?.dataIndex,
            isChartClickModalOpen: true,
            legendInterval: OverviewState.interval,
            legendAttribute: legendAttribute
        }));
    };

    const handleCloseChartClickModal = () => {

        setOverviewState((prev) => ({
            ...prev,
            selectedDataIndex: '',
            isChartClickModalOpen: false,
            legendInterval: '',
            legendAttribute: ''
        }));
    };

    const getLegendAttribute = (dataIndex, graphData) => {
        let dataObj = [];
        if(isEmpty(dataIndex) || isEmpty(graphData)){
            return "";
        }
        dataObj = graphData.find((obj, index)=>{
            return dataIndex === index;
        });

        return isEmpty(dataObj)? "" : dataObj?.date;

    };

    return (
        <OverviewsView
            {...props}
            {...OverviewState}
            getChartData={getChartData}
            handleChangeFilter={handleChangeFilter}
            handleCloseChartClickModal={handleCloseChartClickModal}
            handleChartClick={handleChartClick}
        />
    )
};


const mapStateToProps = (state) => ({
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(Overview);