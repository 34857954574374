import React from 'react'
import FTReportListForManagement from "./FTReportListForManagement/FTReportListForManagement";
import FTReportListForCRM from "./FTReportListForCRM/FTReportListForCRM";

const FTReportListView = (props) => {
    const {isSetUserType, isManagementUser} = props;

    if (!isSetUserType) {
        return ''
    }
    if(isManagementUser){
        return (
            <div className='w-100'>
                <FTReportListForManagement {...props} />
            </div>
        );
    }else{
        return (
            <div className='w-100'>
                <FTReportListForCRM {...props} />
            </div>
        );
    }
}

export default FTReportListView;
