import React from 'react';
import TransactionsView from "./TransactionsView";

const Transaction = (props) => {
    return (
        <TransactionsView
            {...props}
        />
    );
};

export default Transaction;