import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {getCategoryOptions, getCountryData} from "../../../AppComponents/MetadataActions";
import {getLeadSources} from "../../../AppComponents/MetadataActions";
import {isAlphabetic, isIncludeSpecialCharacters, isValidEmail, trimValues} from "../../../utils/helper";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import LeadCreateView from "./LeadsCreateView";
import {showToastMessage} from "../../../AppComponents/AppActions";
import store from "../../../store";
import {saveLeadData} from "../../LeadsActions";

const LeadsCreate = (props) => {

        const [leadCreateState, setLeadCreateState] = useState({
                name: '',
                phone: '',
                email: '',
                referredBy: '',
                source: '',
                city: '',
                residentialStatus: '',
                entityType: '',
                category: '',
                country: '',
                remark: '',
                sources: [],
                isApiInProgress: false,
                handleErrorInComponent: true,
                modalErrorMessage: '',
                scrollToFieldId: '',

                errors: {
                    nameError: '',
                    phoneError: '',
                    emailError: '',
                    cityError: '',
                    countryError: '',
                    categoryError: '',
                    residentialStatusError: '',
                    entityTypeError: '',
                    sourceError: '',
                    referredByError: '',
                    remarkError: '',
                },
                dropdownValues: {
                    countryOptions: [],
                    categoryOptions: [],
                    residentialStatusOptions: [
                        {
                            label: 'Residential',
                            value: 'RESIDENTIAL'

                        },
                        {
                            label: 'Non Residential',
                            value: 'NON_RESIDENTIAL'
                        }],
                    entityTypeStatusOptions: [
                        {
                            label: 'Individual',
                            value: 'INDIVIDUAL'

                        },
                        {
                            label: 'Corporate',
                            value: 'CORPORATE'
                        }]
                },
            }
            )
        ;

        useEffect(() => {
            getLeadSourceValues();
        }, []);

        const getLeadSourceValues = () => {
            getLeadSources().then((res) => {
                if (res?.success) {
                    setLeadCreateState((prevStep) => ({
                        ...prevStep,
                        sources: res.data.sources
                    }));

                    getCountryData()
                        .then(res => {
                            let country = res.data.country;
                            if (res?.success) {
                                getCategoryOptions()
                                    .then(res => {
                                        if (res?.success) {
                                            setLeadCreateState(prevState => ({
                                                ...prevState,
                                                dropdownValues: {
                                                    ...prevState.dropdownValues,
                                                    countryOptions: setValuesForDropdown(country, "description", "code"),
                                                    categoryOptions: setValuesForDropdown(res.data?.categoryType, "name", "id"),
                                                },
                                            }));
                                        } else {
                                            setLeadCreateState(prevState => ({
                                                ...prevState,
                                                isLoaderVisible: false
                                            }));
                                        }
                                    })
                            } else {
                                setLeadCreateState(prevState => ({
                                    ...prevState,
                                    isLoaderVisible: false
                                }));
                            }
                        });
                } else {
                    setLeadCreateState((prevStep) => ({
                        ...prevStep,
                        modalErrorMessage: res.__error
                    }));

                }
            })
        };

        const setValuesForDropdown = (data, labelToken, valueToken) => {
            if (isEmpty(data)) {
                return;
            }
            let obj = [];

            obj = data.map((item) => {
                return {label: item?.[labelToken], value: item?.[valueToken]}
            });

            return obj
        };

        const getMappedSourceValues = () => {
            const {sources} = leadCreateState;
            let updatedArr;

            if (isEmpty(sources)) {
                return [];
            }

            updatedArr = sources.map(item => ({
                value: item.id,
                label: item.name
            }));

            return updatedArr;
        };

        const validateChanges = (title, compState) => {
            const {name, phone, email, source, referredBy, residentialStatus, entityType, remark, category, city, country} = compState;
            let errorObj = {...leadCreateState.errors};

            if (title === "name" || title === APP_CONST.FORM_SUBMISSION) {

                if (isEmpty(name)) {
                    errorObj.nameError = "Please enter name";
                } else if (isIncludeSpecialCharacters(name) || isAlphabetic(name)) {
                    errorObj.nameError = "Please enter alphabets only";
                } else if (name.length > 30) {
                    errorObj.nameError = "Maximum 30 characters are allowed";
                } else {
                    errorObj.nameError = "";
                }

                if (title === 'name') {
                    return errorObj;
                }
            }

            if (title === "phone" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(phone)) {
                    errorObj.phoneError = "Please enter phone number";
                } else if (phone.length !== 10) {
                    errorObj.phoneError = "Enter valid phone number";
                } else {
                    errorObj.phoneError = "";
                }

                if (title === 'phone') {
                    return errorObj;
                }
            }

            if (title === "email" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(email)) {
                    errorObj.emailError = "Please enter email";
                } else if (!isValidEmail(email, true)) {
                    errorObj.emailError = "Please enter valid email address";
                } else if (email.length > 50) {
                    errorObj.emailError = "Maximum 50 characters are allowed";
                } else {
                    errorObj.emailError = "";
                }
                if (title === 'email') {
                    return errorObj;
                }
            }

            if (title === "source" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(source)) {
                    errorObj.sourceError = "Please select source";
                } else {
                    errorObj.sourceError = "";
                }

                if (title === 'source') {
                    if(source === 'NONE' || source === 'COLD_CALL' || source === 'WALK-IN'|| source === 'SOCIAL_MEDIA' || source === 'SEMINAR/WEBINAR'){
                        errorObj.referredByError = ""
                    }
                    return errorObj;
                }
            }

            if (title === "referredBy" || title === APP_CONST.FORM_SUBMISSION) {

                if (referredBy?.length > 30) {
                    errorObj.referredByError = "Maximum 30 characters are allowed";
                }else if (source !== 'NONE' && (source === 'CLIENT_REFERRAL' || source === 'EMPLOYEE_REFERRAL' || source === 'EXTERNAL_REFERRAL' || source === 'DIRECTOR_REFERRAL') && isEmpty(referredBy)) {
                    errorObj.referredByError = "Please enter referred by";
                }else if (isIncludeSpecialCharacters(referredBy) || isAlphabetic(referredBy)) {
                    errorObj.referredByError = "Please enter alphabets only";
                } else {
                    errorObj.referredByError = "";
                }

                if (title === 'referredBy') {
                    return errorObj;
                }
            }

            if (title === "city" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(city)) {
                    errorObj.cityError = "Please enter city";
                } else {
                    errorObj.cityError = "";
                }

                if (title === 'city') {
                    return errorObj;
                }
            }

            if (title === "country" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(country)) {
                    errorObj.countryError = "Please select country";
                } else {
                    errorObj.countryError = "";
                }

                if (title === 'country') {
                    return errorObj;
                }
            }

            if (title === "category" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(category)) {
                    errorObj.categoryError = "Please select category";
                } else {
                    errorObj.categoryError = "";
                }

                if (title === 'category') {
                    return errorObj;
                }
            }

            if (title === "residentialStatus" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(residentialStatus)) {
                    errorObj.residentialStatusError = "Please select residential status";
                } else {
                    errorObj.residentialStatusError = "";
                }

                if (title === 'residentialStatus') {
                    return errorObj;
                }
            }

            if (title === "entityType" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(entityType)) {
                    errorObj.entityTypeError = "Please select entity type";
                } else {
                    errorObj.entityTypeError = "";
                }

                if (title === 'entityType') {
                    return errorObj;
                }
            }

            if (title === "remark" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(remark)) {
                    errorObj.remarkError = "Please enter remark";
                } else if (remark.length > 500) {
                    errorObj.remarkError = "Maximum 500 characters allowed"
                } else {
                    errorObj.remarkError = "";
                }

                if (name === 'remark') {
                    return errorObj;
                }
            }
            return errorObj;
        };

        const clearData = () => {
            setLeadCreateState((prevStep) => ({
                ...prevStep,
                name: '',
                email: '',
                phone: '',
                referredBy: '',
                source: 'NONE',
                firstNameError: '',
                lastNameError: '',
                emailError: '',
                categoryError: '',
                countryError: '',
                phoneError: '',
                referredByError: '',
                residentialStatusError: '',
                entityTypeError: '',
            }));
        };

        const onChangeFormData = (name, value) => {
            if(name === 'scrollToFieldId'){
                setLeadCreateState(prevStep => ({
                    ...prevStep,
                    scrollToFieldId: value
                }));
                return
            }
            let updatedState = {...leadCreateState};
            updatedState[name] = value;
            updatedState[name + 'Error'] = '';

            const validateObject = validateChanges(name, updatedState);

            setLeadCreateState(prevStep => ({
                ...prevStep,
                ...updatedState,
                modalErrorMessage: '',
                errors: {
                    ...prevStep.errors,
                    ...validateObject
                },
            }));
        };

        const onBlurInputText = (name) => {
            const validateObject = validateChanges(name, leadCreateState);

            setLeadCreateState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObject
                },
            }));
        };

        const checkIfValidateObj = (validateObj) => {
            const {nameError, emailError, phoneError, sourceError, residentialStatus, cityError, countryError, categoryError, remarkError,referredByError} = validateObj;
            if (nameError || emailError || phoneError || sourceError || residentialStatus || cityError || countryError || categoryError || remarkError || referredByError) {
                // If any mandatory error is present (non-empty), return false
                return false;
            }
            // If no mandatory errors are present, return true
            return true;
        };

        const handleCreate = () => {
            const {
                name, email, phone, referredBy, source, city, country, category, entityType, remark,
                handleErrorInComponent, residentialStatus
            } = leadCreateState;

            const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, leadCreateState);

            setLeadCreateState((prevStep) => ({
                ...prevStep,
                scrollToFieldId: getFirstErrorFieldId(validateObj),
                isApiInProgress: true,
            }));

            if (!checkIfValidateObj(validateObj) ) {
                setLeadCreateState(prevStep => ({
                    ...prevStep,
                    isApiInProgress: false,
                    errors: {
                        ...prevStep.errors,
                        ...validateObj
                    }
                }));
                return;
            }

            const payload = {
                fullName: name,
                email: email,
                phoneNumber: phone,
                sourceName: referredBy,
                sourceType: source,
                city: city,
                countryCode: country.value,
                category: category.value,
                accountType: entityType,
                remark: remark,
                residentialStatus: residentialStatus

            };

            setLeadCreateState(prevStep => ({
                ...prevStep,
                isApiInProgress: true
            }));

            saveLeadData(payload, handleErrorInComponent)
                .then((res) => {
                        if (res.success) {
                            clearData();
                            store.dispatch(showToastMessage('success', 'A new lead, ' + name + ', has been created'))
                            setLeadCreateState((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false
                            }));
                            props.history.push('/leads');
                        } else {
                            setLeadCreateState((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false,
                                modalErrorMessage: res.__error
                            }));
                        }
                    }
                );
        };
        function removeErrorWord(input) {
            return input.replace('Error', '');
        }

        const getFirstErrorFieldId = (errors) => {
            for (const section in errors) {
                const error = errors[section];
                if (typeof error === 'string' && error) {
                    return (removeErrorWord(section)) + '-field'; // return the field id (section) where the first error exists
                }
            }
            return null; // return null if no errors found
        };

        const onEnterKeyPress = (event) => {
            if (event?.key === 'Enter') {
                handleCreate();
            }
        };

        return (
            <LeadCreateView
                {...props}
                {...leadCreateState}
                handleCreate={handleCreate}
                onChangeFormData={onChangeFormData}
                getMappedSourceValues={getMappedSourceValues}
                onBlurInputText={onBlurInputText}
                onEnterKeyPress={onEnterKeyPress}
            />
        );
    }
;

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(LeadsCreate);
