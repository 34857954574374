import React from 'react';

import {isEmpty} from "../../../../../../utils/validations";
import {purchaseTransactionCellConfig, purchaseTransactionHeadingData} from "../../../../FTModal";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLTable from "../../../../../../core/components/DLTable/DLTable";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLDropDown from "../../../../../../core/components/DLDropdown/DLDropDown";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import appTheme from "../../../../../../assets/appTheme";
import {numberWithCommas} from "../../../../../../utils/helper";
import DLCheckbox from "../../../../../../core/components/DLCheckbox/DLCheckbox";
import DLSearchDropdown from "../../../../../../core/components/DLSearchDropdown/DLSearchDropdown";
import ModelPortfolio from "../modelPortfolio/ModelPortfolio";

const PurchaseTransactionView = (props) => {

    const {
        funds, investmentAmount, changeAmount, changeFolioNumber, redirectScreen, isShowLoader, searchKeyword,
        products, addProduct,isMobileView
    } = props;

    const getFormattedData = (data) => {
        return data?.map(item => ({
            value: item.id,
            label: item.description
        }));
    }

    const renderSearchBar = () => {
        const {searchFundError, searchProduct, isShowInlineLoader, productModalError} = props;
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <div className={"w-100"}>
                    <DLText id={""}
                            text={"Scheme Name"}
                            fontSize={"xs"}
                            fontColor={searchFundError !== '' ? "danger" : "black"}
                            marginBottom={"xs"}
                    />
                    <div className={"w-100"}>
                        <DLSearchDropdown
                            placeholder={"Search..."}
                            isShowInlineLoader={isShowInlineLoader}
                            data={getFormattedData(products)}
                            onChangeData={(e) => searchProduct(e)}
                            value={searchKeyword}
                            onClickItem={(id) => addProduct(id)}
                            helperMessage={'Please select scheme'}
                            error={searchFundError !== ''}
                            disableHelperMessageHeight={true}
                            dropdownHeight={"calc(100vh - " + (productModalError ? "545px" : "490px") + ")"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderHeader = () => {
        if (isMobileView) {
            return (
                <div
                    className={'d-flex align-items-center align-content-center w-100 justify-content-between border-bottom px-3'}>
                    <DLText id={"purchase-transaction-field"}
                            text={"Purchase Transaction"}
                            fontSize={"md"}
                            fontWeight={'semi-bold'}
                            marginBottom={"none"}
                    />
                    <div>
                        {renderActionButtons()}
                    </div>
                </div>
            )
        }

        return (
            <div className='d-flex justify-content-between w-100'>
                <div className='page-header p-0 m-0'>
                    Purchase Transaction
                </div>
                <div>
                    {renderActionButtons()}
                </div>
            </div>
        )
    };

    const renderModalError = () => {
        const {productModalError} = props;

        if (isEmpty(productModalError)) {
            return '';
        }
        return (
            <div className={'py-2'}>
                {!isEmpty(productModalError) && <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {productModalError}
                    </div>
                </div>}
            </div>
        );
    };

    const renderAddFundUI = () => {
        const {
            addedFund, reinvestProduct, isReinvested, addedFolioNumber, addedProductAmountError,
            onchangeValue, addedProductAmount, productMinValue, productMaxValue, productMultipleValue, isCheckBoxDisabled,
            isReinvestChecked, selectedScheme
        } = props;
        return (
            <div>
                <DLText id={'selected-fund'}
                        text={addedFund}
                />
                <div className='d-flex align-items-center justify-content-between'>
                    <DLCheckbox
                        id={'mark-as-reviewed-dayCheck'}
                        fontSize={"sm"}
                        label={'Reinvest'}
                        disableHelperMessageHeight={true}
                        onChangeValue={(e) => {
                            reinvestProduct(e)
                        }}
                        labelPlacement={'end'}
                        marginBottom={'none'}
                        checkboxcolor={isCheckBoxDisabled(selectedScheme)? appTheme.appColor.gray : appTheme.appColor.black}
                        isDisabled={isCheckBoxDisabled(selectedScheme)}
                        labelColor={isCheckBoxDisabled(selectedScheme)? appTheme.appColor.gray : appTheme.appColor.black}
                        isChecked={isReinvested}
                    />
                    <DLText id={''}
                            text={'Change Scheme'}
                            isClickable={true}
                            onClick={() => {
                                addProduct('')
                            }}
                    />
                </div>
                <div className='pt-3 mt-2'>
                    <div>
                        <DLText id={'folio-number'}
                                text={'Folio No.'}
                                fontSize={"xs"}
                                fontColor={"grayDark"}
                                isInline={true}
                        />
                        <DLText id={'selected-fund'}
                                text={' Leave the field blank to create new folio'}
                                fontSize={"xxs"}
                                fontColor={"disable"}
                                isInline={true}
                        />
                    </div>
                    <DLInputTextField id={'folio-number'}
                                      onChangeValue={(e) => {
                                          onchangeValue('addedFolioNumber', e)
                                      }}
                                      isClearable={false}
                                      value={addedFolioNumber}
                                      placeholder={''}
                                      isSearchable={true}
                                      disableHelperMessageHeight={true}
                    />
                </div>
                <div>
                    <div>
                        <DLText id={'selected-fund'}
                                text={'Amount'}
                                fontSize={"xs"}
                                fontColor={isEmpty(addedProductAmountError) ? "grayDark" : "danger"}
                                isInline={true}
                        />
                        <DLText id={'selected-fund'}
                                text={' Min. ₹ ' + numberWithCommas(productMinValue)}
                                fontSize={"xxs"}
                                fontColor={"disable"}
                                isInline={true}
                        />
                        <DLText id={'selected-fund'}
                                text={' & Max. ₹ ' + numberWithCommas(productMaxValue)}
                                fontSize={"xxs"}
                                fontColor={"disable"}
                                isInline={true}
                        />
                        <DLText id={'selected-fund'}
                                text={' ( multiple of ₹ ' + productMultipleValue + ' )'}
                                fontSize={"xxs"}
                                fontColor={"disable"}
                                isInline={true}
                        />
                    </div>

                    <DLInputTextField id={'leads-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      onChangeValue={(e) => {
                                          onchangeValue('addedProductAmount', e)
                                      }}
                                      isClearable={false}
                                      value={numberWithCommas(addedProductAmount)}
                                      error={!isEmpty(addedProductAmountError)}
                                      helperMessage={addedProductAmountError}
                                      placeholder={''}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"number"}
                                      disableHelperMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    const renderAddSchemeModal = () => {
        const {closeAddSchemeModal, isOpenAddSchemeModal, addedFund, addScheme} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddSchemeModal}
                open={isOpenAddSchemeModal}
                id={'add-scheme-modal'}>
                <DLModalTitle
                    id={'add-scheme-title'}
                    onClose={() => closeAddSchemeModal()}
                    title={'Add Item'}/>
                <DLModalContainer
                    id={'add-scheme-container'}>
                    <div style={{
                        minHeight: '320px'
                    }}>
                        {renderModalError()}
                        {(isEmpty(addedFund)) ? renderSearchBar() : renderAddFundUI()}
                    </div>
                </DLModalContainer>
                <DLModalActions id={'add-scheme-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'add-scheme-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddSchemeModal()}/>
                        <DLButton
                            id={'add-scheme-assignBtn'}
                            history={props.history}
                            label={'Add'}
                            buttonSize={"sm"}
                            isDisabled={!isEmpty(props?.productModalError)}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                addScheme()
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        )
    };

    const renderDiscardModal = () => {
        const {closeAddDiscardModal, isOpenDiscardModal, redirectScreen} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddDiscardModal}
                open={isOpenDiscardModal}
                id={'discard-modal'}>
                <DLModalTitle
                    id={'discard-title'}
                    onClose={() => closeAddDiscardModal()}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'discard-container'}>
                    <div className='mb-2 pb-1 text-center'>
                        <DLText id={''}
                                text={"Are you sure you want to discard this transaction?"}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={"This action cannot be undone."}
                                fontSize={"sm"}
                                fontColor={"danger"}
                                marginBottom={"none"}
                        />
                    </div>
                </DLModalContainer>
                <DLModalActions id={'discard-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'discard-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddDiscardModal()}/>
                        <DLButton
                            id={'discard-btn'}
                            history={props.history}
                            styleType={"contained"}
                            type={"danger"}
                            label={'Discard'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                redirectScreen('DISCARD')
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        )
    };

    const renderActionButtons = () => {

        const {clearSelectedPortfolio, openAddSchemeModal, openSelectFolioModal} = props;

        if (isMobileView) {
            return (
                <div
                    className={'d-flex align-items-center align-content-center w-100 py-2 ' + (isEmpty(funds) ? 'column-gap-2' : 'column-gap-3')}>
                    {(isEmpty(funds)) ?
                        <DLText id={'Clear-field'}
                                text={'Select Portfolio'}
                                fontSize={"sm"}
                                fontWeight={'semi-bold'}
                                marginBottom={'none'}
                                isClickable={true}
                                onClick={() => {
                                    openSelectFolioModal()
                                }}
                        />
                        :

                        <DLText id={'Clear-field'}
                                text={"Clear All"}
                                fontSize={"sm"}
                                fontWeight={'semi-bold'}
                                marginBottom={'none'}
                                isClickable={true}
                                onClick={() => {
                                    clearSelectedPortfolio()
                                }}
                        />
                    }
                    <DLButton
                        id={'add-btn'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => openAddSchemeModal()}
                        startIcon={<i className="fa-solid fa-plus fa-xl"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center'}>
                {(isEmpty(funds)) ?
                    <DLButton
                        id={'assign-crm-cancelBtn'}
                        history={props.history}
                        label={'Select Portfolio'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            openSelectFolioModal();
                        }}/>
                    :
                    <DLButton
                        id={'assign-crm-cancelBtn'}
                        history={props.history}
                        label={'Clear All'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            clearSelectedPortfolio()
                        }}/>}
                <DLButton
                    id={'assign-crm-cancelBtn'}
                    history={props.history}
                    label={'Add Scheme'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        openAddSchemeModal()
                    }}/>
            </div>
        );
    };

    const renderBottomActionButtons = () => {
        const {submitTransactionDetails, openDiscardModal} = props;

        return (
            <div
                className={(isMobileView ? 'mb3 bg-white fixed-bottom d-flex justify-content-center' : 'mb3 sticky-bottom-buttons ') + ' pb-3 pt-3'}>
                <div className={isMobileView ? 'me-3' : ''}>
                    <DLButton
                        id={'save-and-close-button'}
                        type={"danger"}
                        styleType={"outlined"}
                        sideMargin={'none'}
                        onClick={() => {
                            openDiscardModal()
                        }}
                        label={"Discard"}
                    />
                </div>
                <div>
                    <DLButton id={'next-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  submitTransactionDetails()
                              }}
                              label={"Done"}/>
                </div>
            </div>
        );
    };

    const renderDeleteUI = (selectedTransaction) => {
        const {deleteFund} = props;
        return (
            <div className='h-100 py-2'
                 style={{verticalAlign: 'Top'}}>
                <div className='d-flex justify-content-center align-items-center' style={{height: '36px'}}>
                    <i className='fa-regular fa-trash-can default-icon cursor'
                       onClick={() => {
                           deleteFund(selectedTransaction?.productId)
                       }}
                    />
                </div>
            </div>
        )
    };

    const renderAmountInput = (selectedFund) => {
        let amount = isEmpty(selectedFund?.amount)? selectedFund?.amount : parseInt(selectedFund?.amount).toFixed(0);
        let productId = selectedFund?.productId;

        return (
            <div className={isMobileView? 'py-2' : 'h-100 py-2'}
                 style={{maxWidth: 120, verticalAlign: "top"}}
                 onBlur={() => {
                     // changeFundDetails()
                 }}>
                <DLInputTextField id={'leads-searchBox'}
                                  marginBottom={"none"}
                                  rows={1}
                                  fontSize={"xs"}
                                  onChangeValue={(e) => {
                                      changeAmount(e, productId)
                                  }}
                                  isClearable={false}
                                  value={isEmpty(amount)? amount: numberWithCommas(amount)}
                                  onBlur={() => {
                                      console.log('in blury')
                                  }}
                                  isSearchable={true}
                                  size="small"
                                  inputHeight={'sm'}
                                  inputType={"number"}
                                  disableHelperMessageHeight={true}
                />
            </div>
        )
    };

    const renderMobileLastRowUI = (totalAmount, totalAllocation) => {
        return (
            <div
                className={'row p-0 m-0'}
                style={{bottom: '60px'}}>
                <div className={'col-5 ps-0'}>

                </div>
                <div className={'col-4'}>
                    <DLText id={'total-amount'}
                            text={numberWithCommas(totalAmount)}
                            fontWeight={"semi-bold"}
                            isInline={true}
                            customWrapperStyle={{paddingLeft: '14px'}}
                    />
                </div>

                <div className={'col-3'}>
                    <DLText id={'total-allocation'}
                            text={isEmpty(totalAllocation) ? '' : ((totalAllocation).toFixed(2) + '%')}
                            fontWeight={"semi-bold"}
                            isInline={true}
                            customWrapperStyle={{paddingRight: '4px'}}
                    />
                </div>

            </div>
        )
    }

    const renderLastRow = (tableData) => {
        const {funds} = props;
        let totalAllocation = 0;
        let totalAmount = 0;
        if (isEmpty(funds)) {
            return tableData
        }
        funds.map((fund) => {
            if (!isEmpty(fund?.amount)) {
                totalAmount += Number(fund?.amount)
            }
        });

        totalAllocation = ((totalAmount/ investmentAmount) * 100);

        if (!isMobileView) {
            return [
                {
                    isCustomUI: true,
                    customUI: renderInfoNote()
                },
                "",
                {
                    isCustomUI: true,
                    customUI: <DLText id={'total-allocation'}
                                      text={isEmpty(totalAllocation) ? '' : ((totalAllocation).toFixed(2) + '%')}
                                      fontWeight={"semi-bold"}
                    />
                },
                {
                    isCustomUI: true,
                    customUI: <div style={{paddingLeft: 14}}>
                        <DLText id={'total-amount'}
                                text={numberWithCommas(totalAmount)}
                                fontWeight={"semi-bold"}
                        />
                    </div>
                },
                ""
            ];
        } else {
            return [
                {
                    isCustomUI: true,
                    customUI: renderMobileLastRowUI(totalAmount, totalAllocation)
                }
            ]
        }
    };

    const renderInfoNote = () => {
        return (
            <div id='financial-categorization-note'>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-panel ms-0' style={{backgroundColor: appTheme.appColor.info}}>
                        <DLText id={'info'}
                                text={
                                    <>
                                        <DLText id={'info-icon'}
                                                text={<i className="fa-solid fa-circle-info pe-1"
                                                         style={{color: appTheme.appColor.primary}}/>}
                                                marginBottom={"none"}
                                                isInline={true}
                                                fontSize={'xs'}
                                        />
                                        <DLText id={'info-description'}
                                                text={'Leave the folio no. field blank to create new folio'}
                                                marginBottom={"none"}
                                                isInline={true}
                                                fontSize={'xs'}
                                        />
                                    </>
                                }
                                marginBottom={"none"}
                                fontSize={'xs'}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderErrorMessages = (errors) =>{
        const{isDoneClicked, filterErrorMessages} = props;
        if(isEmpty(errors)){
            return '';
        }
        errors = isDoneClicked? errors: filterErrorMessages(errors);

        return(
            errors?.map((error, index) => {
                return <div className='pb-2 d-flex align-items-center' key={"error-" + index}>
                    <i className="fa-solid fa-circle-info error-icon pe-1"/>
                    <DLText id={''}
                            text={error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            marginBottom={'none'}
                    />
                </div>
            })
        )
    }

    const renderProductDetails = (product) => {
        let productName = product?.productName;
        let errors = product?.errors;
        const errorUI = renderErrorMessages(errors);

        return (
            <div className='h-100'
            style={{verticalAlign: 'Top'}}>
                <div className='py-2'>
                    <DLText id={''}
                            text={productName}
                            fontSize={"xs"}
                            marginBottom={'none'}
                    />
                </div>
                {!isEmpty(errorUI) && <div className='pt-2'>
                    {errorUI}
                </div>}
            </div>
        )
    };

    const renderFolioNumberUI = (selectedFund) => {
        let folioNumber = selectedFund?.folioNumber;
        let productId = selectedFund?.productId;

        return (
            <div className={isMobileView? 'py-2' : 'h-100 py-2'}
                 style={{verticalAlign: 'Top'}}>
                <DLInputTextField id={'folio-number'}
                                  onChangeValue={(e) => {
                                      changeFolioNumber(e, productId)
                                  }}
                                  isClearable={false}
                                  value={folioNumber}
                                  placeholder={''}
                                  isSearchable={true}
                                  marginBottom={"none"}
                                  disableHelperMessageHeight={true}
                />
            </div>
        )
    };

    const renderMobileUI = (selectedFund, folioNumbers, allocation) => {
        const {deleteFund} = props;
        let errors = selectedFund?.errors;
        const errorUI = renderErrorMessages(errors);
        return (
            <div>
                <div className='d-flex justify-content-between mb-3 pb-1 mt-2 pt-1'>
                    <div className='w-75 px-0 mx-0'>
                        <DLText id={'scheme-title'}
                                text={selectedFund?.productName}
                                fontWeight={'semi-bold'}
                                fontSize={"xs"}
                        />
                    </div>
                    <div className='h-100 px-2'>
                        <i className='fa-regular fa-trash-can default-icon cursor'
                           onClick={() => {
                               deleteFund(selectedFund?.productId)
                           }}
                        />
                    </div>
                </div>
                <div
                    className={'row p-0 m-0'}>
                    <div className={'col-5 ps-0'}>
                        <DLText id={""}
                                text={'Folio Number'}
                                fontSize={'xs'}
                                marginBottom={"none"}
                                fontColor={"grayDark"}
                        />
                        {renderFolioNumberUI(selectedFund)}
                    </div>

                    <div className={'col-4'}>
                        <DLText id={""}
                                text={'Amount'}
                                fontSize={'xs'}
                                marginBottom={"none"}
                                fontColor={"grayDark"}
                        />
                        {renderAmountInput(selectedFund)}
                    </div>
                    <div className={'col-3'}>
                        <DLText id={'allocation-title'}
                                text={'Allocation'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                customWrapperStyle={{'margin': '0px'}}
                        />
                        <DLText id={'allocation'}
                                text={allocation}
                                fontColor={'black'}
                                fontSize={'xs'}
                                fontWeight={'semi-bold'}
                                customWrapperStyle={{'margin': '23px 0 0 0'}}
                        />
                    </div>
                </div>
                {!isEmpty(errorUI) && <div className='pb-2'>
                    {errorUI}
                </div>}
            </div>
        )
    }

    const getFormattedTableData = (transactionData) => {
        let tableData = [];

        if (isEmpty(transactionData)) {
            return tableData
        }
        transactionData.map((selectedTransaction) => {
            let transactionRecords = [];
            let transactionDetails = {};
            if (!isMobileView) {
                transactionRecords.push(renderProductDetails(selectedTransaction));
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderFolioNumberUI(selectedTransaction)
                });
                transactionRecords.push(
                    {
                        isCustomUI: true,
                        customUI: <div className='h-100 py-2' style={{verticalAlign: 'Top'}}>
                            <div className='d-flex justify-content-end align-items-center' style={{height: '36px'}}>
                                {selectedTransaction?.allocation}
                            </div>
                        </div>
                    });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAmountInput(selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderDeleteUI(selectedTransaction)
                });
            } else {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderMobileUI(selectedTransaction, selectedTransaction?.folioNumbers, selectedTransaction?.allocation)
                })
            }
            transactionDetails.id = selectedTransaction?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });
        return tableData

    };

    const getTableHeight = () => {
        const {responseErrors, warningMessage} = props;
        if(!isEmpty(responseErrors) && !isEmpty(warningMessage)){
            return 'calc(100dvh - 370px)'
        }
        if(!isEmpty(responseErrors) || !isEmpty(warningMessage)){
            return 'calc(100dvh - 330px)'
        }
        return 'calc(100dvh - 280px)'
    };

    const renderTransactionTable = () => {
        let tableData = getFormattedTableData(funds);
        if(!isMobileView) {
            return (
                <div className="w-100">
                    <DLTable
                        id={''}
                        tableMaxHeight={getTableHeight()}
                        tableMinHeight={'auto   '}
                        isRowClickable={false}
                        cellConfig={purchaseTransactionCellConfig}
                        headerData={purchaseTransactionHeadingData}
                        tableData={tableData}
                        isShowTotalCount ={true}
                        totalCountRow={renderLastRow()}
                        isShowPagination={false}
                    />
                </div>
            )
        }

        return (
            <div className="w-100">
                <DLTable
                    id={'portfolio-table'}
                    tableMaxHeight={'calc(100dvh - 240px)'}
                    tableMinHeight={'auto   '}
                    isRowClickable={false}
                    headerData={[]}
                    tableData={tableData}
                    isShowTotalCount={true}
                    totalCountRow={renderLastRow()}
                    isShowPagination={false}
                />
            </div>
        )

    };

    const renderEmptyUI = () => {
        return (
            <div className='d-flex align-items-center justify-content-center border border-rounded bg-red'
                 style={{
                     maxHeight: 'calc(100dvh - 276px)',
                     minHeight: 'calc(100dvh - 276px)'
                 }}>
                No schemes have been added for the purchase.
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, warningMessage} = props;

        if (isEmpty(responseErrors) && isEmpty(warningMessage)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={'mt-3'}>
                {!isEmpty(responseErrors) && <div className='error-container'>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {!isEmpty(responseErrors) ? responseErrors : warningMessage}
                    </div>
                </div>}
                {!isEmpty(warningMessage) && <div className='error-container'>
                    <div className={!isEmpty(responseErrors) ? 'error-vertical-line' : 'warning-vertical-line'}/>
                    <div className={!isEmpty(responseErrors) ? 'error-panel' : 'warning-panel'}>
                        {!isEmpty(responseErrors) ? responseErrors : warningMessage}
                    </div>
                </div>}
            </div>
        );
    };

    const renderUi = () => {
        return (
            <div style={{minHeight: 'calc(100dvh - 175px)'}}>
                {renderHeader()}
                <div className={isMobileView ? 'px-3 py-3 black-border-bottom black-border-top' : 'py-3'}>
                    <DLText id={''}
                            text={'Investment Amount: ' + numberWithCommas(investmentAmount)}
                            fontSize={"md"}
                            marginBottom={"none"}
                            fontWeight={isMobileView ? "normal" : "semi-bold"}
                    />
                    {renderErrorUI()}
                </div>

                <div className=''>
                    {(isEmpty(funds)) ? renderEmptyUI() : renderTransactionTable()}
                </div>
                {renderBottomActionButtons()}
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderSelectFolioModal = () => {
        const {isOpenSelectFolioModal, closeSelectFolioModal, getSelectedPortfolioData} = props;

        return (
            <DLModal
                history={props.history}
                maxWidth={"lg"}
                onClose={closeSelectFolioModal}
                open={isOpenSelectFolioModal}
                id={'select-portfolio-modal'}>
                <DLModalTitle
                    id={'select-portfolio-title'}
                    onClose={() => closeSelectFolioModal()}
                    title={'Select Model Portfolio'}/>
                <DLModalContainer
                    id={'select-portfolio-container'}
                    padding={"none"}>
                   <div style={{minHeight: 'calc(100vh - 145px)'}}>
                       <ModelPortfolio
                           {...props}
                           closeSelectFolioModal={closeSelectFolioModal}
                           getSelectedPortfolioData={(folioNo) => {getSelectedPortfolioData(folioNo)}}
                       />
                   </div>
                </DLModalContainer>
            </DLModal>
        )
    };

    return (
        <div className={isMobileView ? '' : 'bg-light p-3'}>
            <div className={'bg-white  ' + (isMobileView ? '' : 'border border-rounded m-1 p-3')}>
                {renderUi()}
            </div>
            {renderAddSchemeModal()}
            {renderDiscardModal()}
            {renderSelectFolioModal()}
            {renderLoader()}

        </div>
    );
};

export default PurchaseTransactionView;