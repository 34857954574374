import store from "../../../../../../../store";
import {
    resetOverviewAllFilters,
    setOverviewCRMIdValue,
    setOverviewIntervalValue,
    setOverviewLocationValue
} from "./OverviewReducer";

export const setOverviewCRMId = (data) => {
    store.dispatch(setOverviewCRMIdValue(data))
};

export const setOverviewLocation = (data) => {
    store.dispatch(setOverviewLocationValue(data))
};

export const setOverviewInterval = (data) => {
    store.dispatch(setOverviewIntervalValue(data))
};

export const resetOverviewFilter = (data) => {
    store.dispatch(resetOverviewAllFilters(data))
}