import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {PieChart} from 'echarts/charts';
import {GridComponent, LegendComponent, TitleComponent, TooltipComponent} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import {convertAmountInLakhAndCrore, numberWithCommas} from "../../utils/helper";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);

function EChartPieChart(props) {

    const {data} = props;

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                return `<span style="font-size: 16px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif">${params.name}</span> 
                        <br/>
                        <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(convertAmountInLakhAndCrore(params.value))}</span> 
                        <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Amount </span>
                        <br/><span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${params.percent}% 
                         </span><span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> of Total </span> 
                         <br/>
<!--                         <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(params.data.clientsCount)}</span> -->
<!--                         <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Clients </span>-->
               `
            },
        },
        legend: {
            show: false,
            orient: 'horizontal',
            bottom: 10,
            left: 'center',
            itemGap: 15,
            textStyle: {
                fontSize: 10,
                fontWeight: 'normal',
                fontFamily: 'Poppins, sans-serif',
                align: 'center',
            },
            itemWidth: 8,
            itemHeight: 8,
            icon: 'circle',
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: '90%',
                data: data,
                label: {
                    show: false,
                    position: 'inside',
                    formatter: function (params) {
                        let trimmedName = params.name.length > 10 ? params.name.substring(0, 10) + '...' : params.name;
                        return `${trimmedName}\n${params.percent}%`;
                    },
                    fontSize: 12,
                    fontWeight: 'normal',
                    color: '#fff',
                },
                labelLine: {
                    show: false
                },
                color: ['#1C64F2', '#16BDCA', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#CC7575']
            },
        ]
    };

    return (
        <div id="main">
            <ReactEChartsCore
                echarts={echarts}
                option={option}
                lazyUpdate={true}
                style={{width: '200px', height: '204px'}}
            />
        </div>
    );
}

export default EChartPieChart;

