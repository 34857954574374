import React from 'react';
import {connect} from "react-redux";

import SystematicInvestmentsView from "./SystematicInvestmentsView";

const SystematicInvestments = (props) => {

    return (
        <SystematicInvestmentsView
            {...props}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isMDView: state.appState.deviceInfo.isMDView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(SystematicInvestments);