import React from 'react';
import DLText from "../../core/components/DLText/DLText";
import logo from "../../assets/img/simplicity_logo.jpg";
import DLButton from "../../core/components/DLButton/DLButton";

const PreLoginRedirectionView = (props) => {
    const {isMobileView, logoutAndRedirect} = props;

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-center bg-light'
             style={{height: 'calc(100dvh - 50px)'}}>
            <div style={{
                padding: "34px 27px 34px 27px"
            }}>
                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                    <div className="text-center mb-3 pb-4">
                        <span className="p-0">
                            <div className='d-flex align-items-center'>
                              <img
                                  src={logo}
                                  alt={'img'}
                                  className={'img-fluid'}
                                  width={68}
                                  height={68}/>
                            </div>
                        </span>
                    </div>
                    <div className="text-center" style={{maxWidth: '525px'}}>
                        <DLText id={'enter-security-code'}
                                alignText={"center"}
                                text={'You are currently logged in, so you cannot view this transaction.'}
                                fontSize={isMobileView ? "lg" : "xl"}
                                fontWeight={"bold"}
                                marginBottom={'none'}/>
                        <div className='mb-4 mt-3 pt-3'>
                            <DLText id={'eventItem-clone-text1'}
                                    type={'normal'}
                                    fontSize={isMobileView ? "sm" : "md"}
                                    marginBottom={'none'}
                                    text={'Click the below button to proceed'}
                            />
                        </div>
                        <DLButton id={"shortlist-see-all-link"}
                                  type={"danger"}
                                  padding={"xl"}
                                  reduceWidth={false}
                                  label={'Log out & Continue '}
                                  onClick={()=>{
                                      logoutAndRedirect()
                                  }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreLoginRedirectionView;