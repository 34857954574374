import React from "react";

import {convertAmountInLakhAndCrore, numberWithCommas} from "../../../utils/helper";
import analytics from "../../../assets/img/appIcons/analytics.png"
import DLText from "../../../core/components/DLText/DLText";


const OverviewTotalBusinessView = (props) => {
    const {businessAmount, isXSView, isSMView, isMDView, isLGView} = props;

    const renderClassnameProperties = () => {
        if(isMDView) {
            return 'd-flex w-50 pt-2 border rounded'
        }
        else if((isXSView || isSMView)) {
            return 'd-flex col-12 py-2 my-1 border-bottom'
        } else {
            return 'col-6 py-4 border rounded'
        }
    };

    return (
        <div
            className={renderClassnameProperties()}>
            {!(isXSView || isSMView) && (
                <div className={isLGView ? 'mx-3' : (isXSView || isSMView) ? 'mx-2' : isMDView ? 'mx-4' : 'mx-4 mb-4'}>
                    <img src={analytics} alt={''} height={49} width={49}/>
                </div>
            )}

            <div className={(isLGView ? 'mx-3' : isMDView ? 'mx-1' : (isXSView || isSMView) ? 'mx-2 ' : 'mx-4') + ' h-100'}>
                <DLText id={'amount'}
                        text={'₹ ' + numberWithCommas(convertAmountInLakhAndCrore(businessAmount))}
                        fontSize={(isXSView || isSMView) ? 'lg' : 'xxl'}
                        fontWeight={'bold'}
                        marginBottom={'none'}
                />
                <DLText id={'total-overviewTotalBusiness-title'}
                        text={'Total Business'}
                        fontSize={(isXSView || isSMView) ? 'xs' : 'sm'}
                />
            </div>
        </div>
    )
};

export default OverviewTotalBusinessView;