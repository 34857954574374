import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";

import {
    FTReportDownload,
    getTxnReportDetails,
} from "../../../FTAction";

import {
    getCategoryOptions,
    getClientAccountTypeOptions as getClientAccountTypes
} from "../../../../../AppComponents/MetadataActions";
import {
    resetFTReportFilters, setFTReportFromDate,
    setFTReportKeywordValue,
    setFTReportLimitCount,
    setFTReportPageCount,
    setFTReportRowsPerPage,
    setFTReportSkipCount, setFTReportStatus, setFTReportToDate, setFTReportTxnSubType, setFTReportTxnType
} from "../FTReportAction";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

import FTReportListForCRMView from "./FTReportListForCRMView";
import {transactionStatuses, transactionSubTypes, transactionTypes} from "../FTReportConst";
import {convertUTCToLocalTime} from "../../../../../utils/helper";

const FTReportListForCRM = (props) => {
    const {FTReportFilter} = props;

    const [FTReportListState, setFTReportListState] = useState({
        FTReportListDetails: [],
        listingCount: 0,
        pageCount: 0,
        UIState: '',
        isActionClicked: false,
        isStatusModalOpen: false,
        isShowLoader: false,
        isShowLoaderMaster: false,
        isShowFilterMobile: false,
        selectedId: '',
        statusOptions: [],
        txnTypeOptions: [],
        txnSubTypeOptions: [],
        categoryOptions: [],
        accountTypeOptions: [],
        accountCategories: [],
        actionBtnOptions: [{
            label: "Status",
            value: "STATUS"
        }],
    });


    useEffect(() => {
        getFTReportDetails()

    }, [FTReportFilter]);

    useEffect(() => {
        getCategoryDetails();
    }, []);

    const {page, rowsPerPage, skipCount, limitCount} = props.FTReportFilter;

    const getCategoryDetails = () => {

        setFTReportListState((prevState) => ({
            ...prevState,
            isShowLoaderMaster: true
        }));

        getCategoryOptions().then(CategoryOptionsRes => {
            if (CategoryOptionsRes?.success) {

                getClientAccountTypes().then((accountTypeRes) => {
                    if (accountTypeRes?.success) {

                        setFTReportListState((prevState) => ({
                            ...prevState,
                            categoryOptions: setValuesForDropdown(CategoryOptionsRes?.data?.categoryType, "name", "id"),
                            accountTypeOptions: setValuesForDropdown(accountTypeRes?.data?.accountType, "name", "id"),
                            accountCategories: CategoryOptionsRes?.data?.categoryType,
                            statusOptions: setValuesForDropdown(transactionStatuses, "label", "value"),
                            txnTypeOptions: setValuesForDropdown(transactionTypes, "label", "value"),
                            txnSubTypeOptions: setValuesForDropdown(transactionSubTypes, "label", "value"),
                            isShowLoaderMaster: false,
                        }));

                    } else {
                        setFTReportListState((prevState) => ({
                            ...prevState,
                            isShowLoaderMaster: false,
                        }))
                    }
                })
            }
        })

    };

    const getFTReportDetails = () => {

        let payload = {
            "skipCount": FTReportFilter.skipCount,
            "limitCount": FTReportFilter.limitCount,
            "filters": generateFilter(FTReportFilter),
        };

        setFTReportListState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        getTxnReportDetails(payload, true).then((res) => {
            if (res?.success) {

                setFTReportListState((prevState) => ({
                    ...prevState,
                    listingCount: res?.data?.count,
                    FTReportListDetails: res?.data,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    isShowLoader: false,
                }));

            } else {
                setFTReportListState((prevState) => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }))
            }
        })
    };

    const generateFilter = (filter) => {

        let filterObject = {};

        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }

        if (!isEmpty(filter.status)) {
            if (filter.status[0] === "ALL") {
                delete filterObject.status
            } else {
                filterObject.trxnStatus = [filter.status[0]];
            }
        }

        if (!isEmpty(filter.txnType)) {
            if (filter.txnType[0] === "ALL") {
                delete filterObject.txnType
            } else {
                filterObject.trxnType = [filter.txnType[0]];
            }
        }

        if (!isEmpty(filter.txnSubType)) {
            if (filter.txnSubType[0] === "ALL") {
                delete filterObject.txnSubType
            } else {
                filterObject.subTrxnType = [filter.txnSubType[0]];
            }
        }

        if (!isEmpty(filter.fromDate)) {
            filterObject.fromDate = convertUTCToLocalTime(filter.fromDate, 'YYYY-MM-DD');
        }

        if (!isEmpty(filter.toDate)) {
            filterObject.toDate = convertUTCToLocalTime(filter.toDate, 'YYYY-MM-DD');
        }

        return filterObject;
    };

    const handleActionClicked = (isActionClicked, id) => {
        setFTReportListState((prevState) => ({
            ...prevState,
            isActionClicked: isActionClicked,
            selectedId: id
        }))
    };

    const handleChangePage = (event, newPage) => {
        let tempLimitCount = limitCount;
        let tempSkipCount = skipCount;

        const isForward = newPage > page;
        if (isForward) {
            tempSkipCount = tempSkipCount + rowsPerPage;
            tempLimitCount = rowsPerPage
        } else {
            tempSkipCount = tempSkipCount - rowsPerPage;
            tempLimitCount = rowsPerPage
        }

        setFTReportSkipCount(tempSkipCount);
        setFTReportLimitCount(tempLimitCount);

        setFTReportPageCount(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setFTReportListState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setFTReportRowsPerPage(parseInt(event.target.value, 10));
        setFTReportLimitCount(parseInt(event.target.value, 10));
        setFTReportPageCount(0);
        setFTReportSkipCount(0);
    };

    const handleStatusModalOpen = () => {
        setFTReportListState((prevState) => ({
            ...prevState,
            isStatusModalOpen: true
        }))
    };

    const handleStatusModalClose = () => {
        setFTReportListState((prevState) => ({
            ...prevState,
            isStatusModalOpen: false,
            selectedId: '',
        }))
    };

    const handleToggleMobileFilter = (value) => {
        setFTReportListState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const handleDownloadBtnClick = () => {
        setFTReportListState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            "skipCount": FTReportFilter.skipCount,
            "limitCount": FTReportFilter.limitCount,
            "filters": generateFilter(FTReportFilter),
        };

        FTReportDownload(payload);
        setTimeout(() => {
            setFTReportListState((preState) => ({
                    ...preState,
                    isShowLoader: false,
                }
            ));
        }, 500)
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setFTReportKeywordValue(value);
        }

        if (name === 'status') {
            setFTReportStatus([value]);
        }

        if (name === 'txnType') {
            setFTReportTxnType([value]);
        }

        if (name === 'txnSubType') {
            setFTReportTxnSubType([value]);
        }

        if (name === 'fromDate') {
            setFTReportFromDate(value);
        }

        if (name === 'toDate') {
            setFTReportToDate(value);
        }
    };

    const getCategory = (selectedStatus, accountCategories) => {

        if (selectedStatus === 'CORPORATE') {
            return 'Null'
        }
        if (isEmpty(selectedStatus) || isEmpty(accountCategories)) {
            return ''
        }
        if (selectedStatus === 'UNASSIGNED') {
            return 'Unassigned';
        }
        const status = accountCategories.find(status => status.id === selectedStatus);
        return status ? status.name : '';
    };

    const getAccountType = (value) => {
        const {accountTypeOptions} = FTReportListState;
        const accountType = accountTypeOptions.find(item => item?.value === value);
        return accountType?.label || '';
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const redirectToDetailsScreen = (accountId) => {
        props.history.push('/clients/' + accountId, {screenName: "FTReport"});
    };

    const resetPagination = () => {

        setFTReportRowsPerPage(50);
        setFTReportLimitCount(50);
        setFTReportPageCount(0);
        setFTReportSkipCount(0);

    };

    const resetFTReportListing = () => {
        resetFTReportFilters();
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount} = FTReportListState;

        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    return (
        <FTReportListForCRMView
            {...props}
            {...FTReportListState}
            {...props.FTReportFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onChangeFilter={onChangeFilter}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            handleActionClicked={handleActionClicked}
            handleStatusModalOpen={handleStatusModalOpen}
            handleStatusModalClose={handleStatusModalClose}
            resetFTReportListing={resetFTReportListing}
            getCategory={getCategory}
            getAccountType={getAccountType}
            getFTReportDetails={getFTReportDetails}
            handleToggleMobileFilter={handleToggleMobileFilter}
            handleDownloadBtnClick={handleDownloadBtnClick}
            redirectToDetailsScreen={redirectToDetailsScreen}
        />
    )
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    profile: state.userState.profile,
    FTReportFilter: state.FTReportState.FTReportFilter,
});

export default connect(mapStateToProps, {})(FTReportListForCRM);