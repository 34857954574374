import React from 'react';

import {isEmpty} from "../utils/validations";

import ClientsApproval from "./clientsApproval/ClientsApproval";
import LinkExpired from "./linkExpired/LinkExpired";

const ClientsTransactionView = (props) => {

    const {isUserVerified, responseError} = props;

    if (!isEmpty(responseError)) {
        return (
            <div className='h-100'>
                <LinkExpired {...props} />
            </div>
        );
    }

    if (isUserVerified) {
        return (
            <div>
                <ClientsApproval {...props}/>
            </div>
        );
    }
};

export default ClientsTransactionView;