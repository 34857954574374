import React from 'react';

import {isEmpty} from "../../../../../../utils/validations";
import {purchaseSIPTransactionCellConfig, purchaseSIPTransactionHeadingData} from "../../../../FTModal";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLTable from "../../../../../../core/components/DLTable/DLTable";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLDropDown from "../../../../../../core/components/DLDropdown/DLDropDown";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import appTheme from "../../../../../../assets/appTheme";
import {convertAmountInLakhAndCrore, convertUTCToLocalTime, numberWithCommas} from "../../../../../../utils/helper";
import DLCheckbox from "../../../../../../core/components/DLCheckbox/DLCheckbox";
import DLSearchDropdown from "../../../../../../core/components/DLSearchDropdown/DLSearchDropdown";
import ModelPortfolio from "../modelPortfolio/ModelPortfolio";
import moment from "moment";
import DLDatePicker from "../../../../../../core/components/DLDatePicker/DLDatePicker";

const PurchaseSIPTransactionView = (props) => {

    const {
        funds,
        investmentAmount,
        changeAmount,
        redirectScreen,
        isShowLoader,
        searchKeyword,
        products,
        addProduct,
        isMobileView,
        frequency,
        endDate,
        startDate,
        isForMaxPeriod,
        changeFolioNumber,
        changeSIPDate,
        handleDate,
        handleChangeDate,
        handleCheckBox,
        handleSelecetdDate,
        errors,
        isMaxPeriodSelected
    } = props;

    const getFormattedData = (data) => {
        return data?.map(item => ({
            value: item.id,
            label: item.description
        }));
    }

    const renderSearchBar = () => {
        const {searchFundError, searchProduct, isShowInlineLoader, productModalError} = props;
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <div className={"w-100"}>
                    <DLText id={""}
                            text={"Scheme Name"}
                            fontSize={"xs"}
                            fontColor={searchFundError !== '' ? "danger" : "black"}
                            marginBottom={"xs"}
                    />
                    <div className={"w-100"}>
                        <DLSearchDropdown
                            placeholder={"Search..."}
                            isShowInlineLoader={isShowInlineLoader}
                            data={getFormattedData(products)}
                            onChangeData={(e) => searchProduct(e)}
                            value={searchKeyword}
                            onClickItem={(id) => addProduct(id)}
                            helperMessage={'Please select product'}
                            error={searchFundError !== ''}
                            disableHelperMessageHeight={true}
                            dropdownHeight={"calc(100vh - " + (productModalError ? "545px" : "490px") + ")"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderHeader = () => {
        if (isMobileView) {
            return (
                <div
                    className={'d-flex align-items-center align-content-center w-100 justify-content-between border-bottom px-3'}>
                    <DLText id={"purchase-transaction-field"}
                            text={"SIP Transaction: Edit"}
                            fontSize={"md"}
                            fontWeight={'semi-bold'}
                            marginBottom={"none"}
                    />
                    <div>
                        {renderActionButtons()}
                    </div>
                </div>
            )
        }

        return (
            <div className='d-flex justify-content-between w-100'>
                <div className='page-header p-0 m-0'>
                    SIP Transaction: Edit
                </div>
                <div>
                    {renderActionButtons()}
                </div>
            </div>
        )
    };

    const renderModalError = () => {
        const {productModalError} = props;

        if (isEmpty(productModalError)) {
            return '';
        }
        return (
            <div className={'py-2'}>
                {!isEmpty(productModalError) && <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        {productModalError}
                    </div>
                </div>}
            </div>
        );
    };

    const renderAddFundUI = () => {
        const {
            addedFund,
            addedFolioNumber,
            reinvestProduct,
            isReinvested,
            addedProductAmountError,
            addedProductDateError,
            onchangeValue,
            addedProductAmount,
            productMinValue,
            productMaxValue,
            productMultipleValue,
            isCheckBoxDisabled,
            selectedScheme,
            selectedProductDropDown,
            changeSelectedSIPDate,
            selectedProductDate,
            convertToCrores
        } = props;
        return (
            <div>
                <DLText id={'selected-fund'}
                        text={addedFund}
                />
                <div className='d-flex align-items-center justify-content-between'>
                    <DLCheckbox
                        id={'mark-as-reviewed-dayCheck'}
                        fontSize={"sm"}
                        label={'Reinvest'}
                        disableHelperMessageHeight={true}
                        onChangeValue={(e) => {
                            reinvestProduct(e)
                        }}
                        labelPlacement={'end'}
                        marginBottom={'none'}
                        checkboxcolor={isCheckBoxDisabled(selectedScheme) ? appTheme.appColor.gray : appTheme.appColor.black}
                        isDisabled={isCheckBoxDisabled(selectedScheme)}
                        labelColor={isCheckBoxDisabled(selectedScheme) ? appTheme.appColor.gray : appTheme.appColor.black}
                        isChecked={isReinvested}
                    />
                    <DLText id={''}
                            text={'Change Scheme'}
                            isClickable={true}
                            onClick={() => {
                                addProduct('')
                            }}
                    />
                </div>
                <div className='pt-3 mt-2 mb-2'>
                    <div>
                        <DLText id={'folio-number'}
                                text={'Folio No.'}
                                fontSize={"xs"}
                                fontColor={"grayDark"}
                                isInline={true}
                        />
                        <DLText id={'selected-fund'}
                                text={' Leave the field blank to create new folio'}
                                fontSize={"xxs"}
                                fontColor={"disable"}
                                isInline={true}
                        />
                    </div>
                    <DLInputTextField id={'folio-number'}
                                      onChangeValue={(e) => {
                                          onchangeValue("addedFolioNumber", e)
                                      }}
                                      isClearable={false}
                                      value={addedFolioNumber}
                                      placeholder={''}
                                      marginBottom={'none'}
                                      isSearchable={true}
                                      disableHelperMessageHeight={true}
                    />
                </div>
                <div
                    className='sip-transaction-date-piker d-flex flex-row align-items-start gap-3 position-relative pt-1 w-100'>
                    <div className={'w-50'}>
                        <div>
                            <DLText id={'Start-Date'}
                                    text={'Start Date'}
                                    fontSize={"xs"}
                                    fontColor={isEmpty(errors.startDateError) ? "grayDark" : "danger"}
                                    isInline={true}
                            />
                        </div>
                        <DLDatePicker id={'start-date'}
                                      value={startDate}
                                      minimumDate={moment()}
                                      isClearable={true}
                                      isRequired={true}
                                      onChangeValue={(value) => {
                                          handleSelecetdDate('startDate', value);
                                      }}
                                      disableHelperMessageHeight={true}
                                      error={errors.startDateError !== ''}
                                      helperMessage={errors.startDateError}
                        />
                    </div>
                    <div className={'w-50'}>
                        <div>
                            <DLText id={'End-Date'}
                                    text={'End Date'}
                                    fontSize={"xs"}
                                    fontColor={isEmpty(errors.endDateError) ? "grayDark" : "danger"}
                                    isInline={true}
                            />
                        </div>
                        <DLDatePicker id={'end-date'}
                                      value={endDate}
                                      minimumDate={moment().add(12, 'months').format('YYYY-MM-DD')}
                                      maximumDate={moment().add(30, 'years').format('YYYY-MM-DD')}
                                      isClearable={true}
                                      isRequired={true}
                                      onChangeValue={(value) => {
                                          handleSelecetdDate('endDate', value);
                                      }}
                                      disableHelperMessageHeight={true}
                                      error={errors.endDateError !== ''}
                                      helperMessage={errors.endDateError}
                                      isDisabled={isMaxPeriodSelected}
                        />
                    </div>
                </div>
                <div className={'d-flex align-items-start pt-2 gap-2'}>
                    <DLCheckbox id={'maximum-period'}
                                isChecked={isMaxPeriodSelected}
                                label={'Maximum Period'}
                                labelPlacement={'end'}
                                fontSize={'sm'}
                                labelColor={'black'}
                                onChangeValue={() => {
                                    handleCheckBox('selectedFund','')
                                }}

                    />
                </div>
                <div>
                    <div className='h-100 pb-2'
                         style={{verticalAlign: 'Top', maxHeight: '100px'}}>
                        <div>
                            <DLText id={'folio-number'}
                                    text={'SIP Date'}
                                    fontSize={"xs"}
                                    fontColor={isEmpty(addedProductDateError) ? "grayDark" : "danger"}
                                    isInline={true}
                            />
                        </div>
                        <DLDropDown
                            id={"status-filter"}
                            fontSize={"xs"}
                            labelPlacement={'top'}
                            value={isEmpty(selectedProductDate) ? '':selectedProductDate}
                            options={selectedProductDropDown}
                            isRequired={true}
                            onSelect={(e) => {
                                changeSelectedSIPDate(e)
                            }}
                            error={!isEmpty(addedProductDateError)}
                            helperMessage={addedProductDateError}
                            marginBottom={"none"}
                            disableHelperMessageHeight={true}
                            minWidth={'100%'}
                        />
                    </div>
                    <div>
                        <div>
                            <DLText id={'selected-fund'}
                                    text={'Amount'}
                                    fontSize={"xs"}
                                    fontColor={isEmpty(addedProductAmountError) ? "grayDark" : "danger"}
                                    isInline={true}
                            />
                            <DLText id={'selected-fund'}
                                    text={' Min. ₹ ' + numberWithCommas(convertAmountInLakhAndCrore(productMinValue))}
                                    fontSize={"xxs"}
                                    fontColor={"disable"}
                                    isInline={true}
                            />
                            <DLText id={'selected-fund'}
                                    text={' & Max. ₹ ' + numberWithCommas(convertToCrores(productMaxValue)) + ' cr'}
                                    fontSize={"xxs"}
                                    fontColor={"disable"}
                                    isInline={true}
                            />
                            <DLText id={'selected-fund'}
                                    text={' ( multiple of ₹ ' + productMultipleValue + ' )'}
                                    fontSize={"xxs"}
                                    fontColor={"disable"}
                                    isInline={true}
                            />
                        </div>

                        <DLInputTextField id={'leads-searchBox'}
                                          marginBottom={"none"}
                                          rows={1}
                                          onChangeValue={(e) => {
                                              onchangeValue('addedProductAmount', e)
                                          }}
                                          isClearable={false}
                                          value={numberWithCommas(addedProductAmount)}
                                          error={!isEmpty(addedProductAmountError)}
                                          helperMessage={addedProductAmountError}
                                          placeholder={''}
                                          isSearchable={true}
                                          size="small"
                                          inputHeight={'sm'}
                                          inputType={"number"}
                                          disableHelperMessageHeight={true}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderAddSchemeModal = () => {
        const {closeAddSchemeModal, isOpenAddSchemeModal, addedFund, addScheme} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                isFullScreen={isMobileView}
                onClose={closeAddSchemeModal}
                open={isOpenAddSchemeModal}
                id={'add-scheme-modal'}>
                <DLModalTitle
                    id={'add-scheme-title'}
                    onClose={() => closeAddSchemeModal()}
                    title={'Add Item'}/>
                <DLModalContainer
                    id={'add-scheme-container'}>
                    <div style={{
                        minHeight: '320px'
                    }}>
                        {renderModalError()}
                        {(isEmpty(addedFund)) ? renderSearchBar() : renderAddFundUI()}
                    </div>
                </DLModalContainer>
                <DLModalActions id={'add-scheme-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'add-scheme-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddSchemeModal()}/>
                        <DLButton
                            id={'add-scheme-assignBtn'}
                            history={props.history}
                            label={'Add'}
                            buttonSize={"sm"}
                            isDisabled={!isEmpty(props?.productModalError)}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                addScheme()
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        )
    };

    const renderDiscardModal = () => {
        const {closeAddDiscardModal, isOpenDiscardModal, redirectScreen} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={closeAddDiscardModal}
                open={isOpenDiscardModal}
                id={'discard-modal'}>
                <DLModalTitle
                    id={'discard-title'}
                    onClose={() => closeAddDiscardModal()}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'discard-container'}>
                    <div className='mb-2 pb-1 text-center'>
                        <DLText id={''}
                                text={"Are you sure you want to discard this transaction?"}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                marginBottom={"none"}
                        />
                        <DLText id={''}
                                text={"This action cannot be undone."}
                                fontSize={"sm"}
                                fontColor={"danger"}
                                marginBottom={"none"}
                        />
                    </div>
                </DLModalContainer>
                <DLModalActions id={'discard-action'}>
                    <div className='mb-4' style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'discard-cancelBtn'}
                            history={props.history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => closeAddDiscardModal()}/>
                        <DLButton
                            id={'discard-btn'}
                            history={props.history}
                            styleType={"contained"}
                            type={"danger"}
                            label={'Discard'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            onClick={() => {
                                redirectScreen('DISCARD')
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        )
    };

    const renderActionButtons = () => {

        const {clearSelectedPortfolio, openAddSchemeModal, openSelectFolioModal} = props;

        if (isMobileView) {
            return (
                <div
                    className={'d-flex align-items-center align-content-center w-100 py-2 ' + (isEmpty(funds) ? 'column-gap-2' : 'column-gap-3')}>
                    {(isEmpty(funds)) ?
                        <DLText id={'Clear-field'}
                                text={'Select Portfolio'}
                                fontSize={"sm"}
                                fontWeight={'semi-bold'}
                                marginBottom={'none'}
                                isClickable={true}
                                onClick={() => {
                                    openSelectFolioModal()
                                }}
                        />
                        :

                        <DLText id={'Clear-field'}
                                text={"Clear All"}
                                fontSize={"sm"}
                                fontWeight={'semi-bold'}
                                marginBottom={'none'}
                                isClickable={true}
                                onClick={() => {
                                    clearSelectedPortfolio()
                                }}
                        />
                    }
                    <DLButton
                        id={'add-btn'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => openAddSchemeModal()}
                        startIcon={<i className="fa-solid fa-plus fa-xl"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center'}>
                {(isEmpty(funds)) ?
                    <DLButton
                        id={'assign-crm-cancelBtn'}
                        history={props.history}
                        label={'Select Portfolio'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            openSelectFolioModal();
                        }}/>
                    :
                    <DLButton
                        id={'assign-crm-cancelBtn'}
                        history={props.history}
                        label={'Clear All'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            clearSelectedPortfolio()
                        }}/>}
                <DLButton
                    id={'assign-crm-cancelBtn'}
                    history={props.history}
                    label={'Add Scheme'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        openAddSchemeModal()
                    }}/>
            </div>
        );
    };

    const renderBottomActionButtons = () => {
        const {submitTransactionDetails, validateTransaction, openDiscardModal} = props;
        let isAllErrorClear = validateTransaction();

        return (
            <div
                className={(isMobileView ? 'bg-white fixed-bottom d-flex justify-content-center' : 'sticky-bottom-buttons ') + ' pb-3 pt-3'}>
                <div className={isMobileView ? 'me-3' : ''}>
                    <DLButton
                        id={'save-and-close-button'}
                        type={"danger"}
                        styleType={"outlined"}
                        sideMargin={'none'}
                        onClick={() => {
                            openDiscardModal()
                        }}
                        label={"Discard"}
                    />
                </div>
                <div>
                    <DLButton id={'next-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              type={"darkBlue"}
                              styleType={'contained'}
                              sideMargin={'none'}
                              onClick={() => {
                                  submitTransactionDetails()
                              }}
                              label={"Done"}/>
                </div>
            </div>
        );
    };

    const renderDeleteUI = (selectedTransaction) => {
        const {deleteFund} = props;
        return (
            <div className='h-100 py-2'
                 style={{verticalAlign: 'Top'}}>
                <i className='fa-regular fa-trash-can default-icon cursor'
                   onClick={() => {
                       deleteFund(selectedTransaction?.productId)
                   }}
                />
            </div>
        )
    };

    const renderAmountInput = (selectedFund) => {
        let amount = selectedFund?.amount;
        let productId = selectedFund?.productId;

        return (
            <div className='h-100 py-2'
                 style={{maxWidth: 120, verticalAlign: "top"}}>
                <DLInputTextField id={'leads-searchBox'}
                                  marginBottom={"none"}
                                  rows={1}
                                  fontSize={"xs"}
                                  onChangeValue={(e) => {
                                      changeAmount(e, productId)
                                  }}
                                  isClearable={false}
                                  value={isEmpty(amount) ? amount : numberWithCommas(amount)}
                                  placeholder={'Search'}
                                  isSearchable={true}
                                  size="small"
                                  inputHeight={'sm'}
                                  inputType={"number"}
                                  disableHelperMessageHeight={true}
                />
            </div>
        )
    };

    const renderMobileLastRowUI = (totalAmount, totalAllocation) => {
        return (
            <div
                className={'bg-light w-100 border-top border-bottom fixed-bottom px-3 py-2 row'}
                style={{right: '20px', bottom: '70px', width: '100%'}}>
                <div className={'py-2 col-6'}>
                    <DLText id={'total-amount'}
                            text={numberWithCommas(totalAmount)}
                            fontWeight={"semi-bold"}
                            isInline={true}
                    />
                </div>

                <div className={'py-2 ps-4 col-6'}>
                    <DLText id={'total-allocation'}
                            text={isEmpty(totalAllocation) ? '' : ((totalAllocation).toFixed(2) + '%')}
                            fontWeight={"semi-bold"}
                            isInline={true}
                    />
                </div>

            </div>
        )
    }

    const renderInfoNote = () => {
        return (
            <div id='financial-categorization-note'>
                <div
                    className={isMobileView ? 'align-items-start justify-content-start d-flex flex-column message-block gap-1 pt-1 px-2' : 'align-items-start justify-content-start d-flex flex-column message-block gap-1 pt-1'}>
                    <div className={isMobileView ? 'error-panel ms-0 w-auto' : 'error-panel ms-0 '}
                         style={{backgroundColor: appTheme.appColor.info}}>
                        <DLText id={'info'}
                                text={
                                    <>
                                        <DLText id={'info-icon'}
                                                text={<i className="fa-solid fa-circle-info pe-1"
                                                         style={{color: appTheme.appColor.primary}}/>}
                                                marginBottom={"none"}
                                                isInline={true}
                                                fontSize={'xs'}
                                        />
                                        <DLText id={'info-description'}
                                                text={'Leave the folio no. field blank to create new folio'}
                                                marginBottom={"none"}
                                                isInline={true}
                                                fontSize={'xs'}
                                        />
                                    </>
                                }
                                marginBottom={"none"}
                                fontSize={'xs'}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderLastRow = (tableData) => {
        const {funds} = props;
        let totalAllocation = 0;
        let totalAmount = 0;
        if (isEmpty(funds)) {
            return tableData
        }
        funds.map((fund) => {
            if (!isEmpty(fund?.amount)) {
                totalAmount += Number(fund?.amount)
            }
            if (!isEmpty(fund?.allocationAmount)) {
                totalAllocation += Number(fund?.allocationAmount)
            }

        });
        if (!isMobileView) {
            return [
                {
                    isCustomUI: true,
                    customUI: renderInfoNote()
                },
                "",
                "",
                "",
                {
                    isCustomUI: true,
                    customUI: <DLText id={'total-allocation'}
                                      text={isEmpty(totalAllocation) ? '' : ((totalAllocation).toFixed(2) + '%')}
                                      fontWeight={"semi-bold"}
                    />
                },
                {
                    isCustomUI: true,
                    customUI: <div style={{paddingLeft: 14}}>
                        <DLText id={'total-amount'}
                                text={numberWithCommas(totalAmount)}
                                fontWeight={"semi-bold"}
                        />
                    </div>
                },
                ""
            ];
        } else {
            return [
                {
                    isCustomUI: true,
                    customUI: renderMobileLastRowUI(totalAmount, totalAllocation)
                }
            ]
        }
    };

    const renderProductDetails = (product) => {
        let productName = product?.productName;
        let errors = product?.errors;
        const errorUI = renderErrorMessages(errors);

        return (
            <div className='h-100'
                 style={{verticalAlign: 'Top'}}>
                <div className='py-2'>
                    <DLText id={''}
                            text={productName}
                            fontSize={"xs"}
                            marginBottom={'none'}
                    />
                </div>
                {!isEmpty(errorUI) && <div className='pt-2'>
                    {errorUI}
                </div>}
            </div>
        )
    };

    const renderErrorMessages = (errors) => {
        const {isDoneClicked, filterErrorMessages} = props;
        if (isEmpty(errors)) {
            return '';
        }
        errors = isDoneClicked ? errors : filterErrorMessages(errors);

        return (
            errors?.map((error, index) => {
                return <div className='pb-2 d-flex align-items-top' key={"error-" + index}>
                    <i className="fa-solid fa-circle-info error-icon pe-1"
                       style={{paddingTop:'2px'}}
                    />
                    <DLText id={''}
                            text={error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            marginBottom={'none'}
                    />
                </div>
            })
        )
    }

    const renderFolioNumberUI = (selectedFund) => {
        let folioNumber = selectedFund?.folioNumber;
        let productId = selectedFund?.productId;

        return (
            <div className='h-100 py-2'
                 style={{verticalAlign: 'Top'}}>
                <DLInputTextField id={'folio-number'}
                                  onChangeValue={(e) => {
                                      changeFolioNumber(e, productId)
                                  }}
                                  isClearable={false}
                                  value={folioNumber || ''}
                                  placeholder={''}
                                  isSearchable={true}
                                  disableHelperMessageHeight={true}
                />
            </div>
        )
    };

    const renderSIPDatesDropdown = (SIPDatesDropdown, selectedTransaction) => {
        if (isEmpty(selectedTransaction?.sipDate)) {
            return (<div className='h-100 py-2'>-</div>);
        }
        return (
            <div className='h-100 py-2'
                 style={{verticalAlign: 'Top'}}>
                <DLDropDown
                    id={"status-filter"}
                    label={''}
                    labelFontColor={'black'}
                    fontSize={"xs"}
                    labelPlacement={'top'}
                    value={isEmpty(selectedTransaction?.selectedDate)?'':selectedTransaction?.selectedDate}
                    options={selectedTransaction?.sipDate}
                    onSelect={(e) => {
                        changeSIPDate(e, selectedTransaction?.productId)
                    }}
                    // error={!selectedTransaction?.isDateSelected}
                    marginBottom={"none"}
                    disableHelperMessageHeight={true}
                    minWidth={'80px'}
                />
            </div>
        )
    };

    const renderMobileErrorMessages = (errors) => {
        const {isDoneClicked, filterErrorMessages} = props;
        if (isEmpty(errors)) {
            return '';
        }
        errors = isDoneClicked ? errors : filterErrorMessages(errors);

        return (
            errors?.map((error, index) => {
                return <div className='pb-2 d-flex align-items-top' key={"error-" + index}>
                    <i className="fa-solid fa-circle-info error-icon pe-1"
                       style={{paddingTop:'2px'}}
                    />
                    <DLText id={''}
                            text={error}
                            fontSize={"xs"}
                            fontColor={"danger"}
                            marginBottom={'none'}
                    />
                </div>
            })
        )
    }

    const renderColumnErrors = (product) => {
        let errors = product?.errors;
        const errorUI = renderMobileErrorMessages(errors);
        return (
            <div className='h-100'>
                {!isEmpty(errorUI) && <div className='pt-2'>
                    {errorUI}
                </div>}
            </div>
        )
    };

    const renderTotal = () => {
        const {funds} = props;
        let totalAllocation = 0;
        let totalAmount = 0;

        funds.map((fund) => {
            if (!isEmpty(fund?.amount)) {
                totalAmount += Number(fund?.amount)
            }
            if (!isEmpty(fund?.allocationAmount)) {
                totalAllocation += Number(fund?.allocationAmount)
            }

        });

        return (
            <div className='w-100 position-fixed'
                 style={{bottom: '75px'}}>
                <div className={'border-top-black border-bottom-black sticky-bottom '}>
                    <div
                        className={'w-100 py-3'}>
                        <div className='row'>
                            <div className={'col-6'}>
                                <DLText id={'total-amount'}
                                        text={numberWithCommas(totalAmount)}
                                        fontWeight={"semi-bold"}
                                        customWrapperStyle={{paddingLeft: '30px'}}
                                        marginBottom={'none'}
                                />
                            </div>

                            <div className={'col-6'}>
                                <DLText id={'total-allocation'}
                                        text={isEmpty(totalAllocation) ? '' : ((totalAllocation).toFixed(2) + '%')}
                                        fontWeight={"semi-bold"}
                                        marginBottom={'none'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={props?.isMDView ? 'pt-2 ps-2' : 'd-flex justify-content-center pt-2'}>
                    {renderInfoNote()}
                </div>
            </div>
        )


    };


    const renderMobileEditUI = (selectedFund, SIPDatesDropdown, allocation) => {
        const {deleteFund} = props;
        let folioNumber = selectedFund?.folioNumber;
        let productId = selectedFund?.productId;
        return (
            <div className='py-3'>
                <div className='d-flex justify-content-between mb-1 pb-1'>
                    <div className='w-100 ps-0 mx-0 pe-1'>
                        <DLText id={'scheme-title'}
                                text={selectedFund?.productName}
                                fontWeight={'semi-bold'}
                                fontSize={"sm"}
                        />
                    </div>
                    <div className='h-100 px-2'>
                        <i className='fa-regular fa-trash-can default-icon cursor'
                           onClick={() => {
                               deleteFund(selectedFund?.productId)
                           }}
                        />
                    </div>
                </div>
                <div className={'h-100 mb-3 row'}>
                    <div className='col-6'>
                        <DLInputTextField id={'folio-number'}
                                          label={'Folio No.'}
                                          onChangeValue={(e) => {
                                              changeFolioNumber(e, productId)
                                          }}
                                          isClearable={false}
                                          value={folioNumber || ''}
                                          placeholder={''}
                                          marginBottom={'none'}
                                          isSearchable={true}
                                          disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='col-6'>
                        {isEmpty(selectedFund?.sipDate) ? <div>
                                <DLText id={'allocation-title'}
                                        text={'SIP Date'}
                                        fontColor={'grayDark'}
                                        fontSize={'xs'}
                                        customWrapperStyle={{'margin': '0px'}}
                                />
                                <DLText id={'allocation'}
                                        text={'-'}
                                        fontColor={'black'}
                                        fontSize={'xs'}
                                        fontWeight={'semi-bold'}
                                        customWrapperStyle={{'margin': '15px 0 0 0'}}
                                /></div> :
                            <DLDropDown
                                id={"status-filter"}
                                label={'SIP Date'}
                                labelFontColor={'grayDark'}
                                fontSize={"xs"}
                                labelPlacement={'top'}
                                value={isEmpty(selectedFund?.selectedDate)?'':selectedFund?.selectedDate}
                                options={selectedFund?.sipDate}
                                onSelect={(e) => {
                                    changeSIPDate(e, selectedFund?.productId)
                                }}
                                marginBottom={"none"}
                                disableHelperMessageHeight={true}
                                minWidth={'100%'}
                            />
                        }
                    </div>
                </div>
                <div className='h-100 mb-3'>
                    <div className='row'>
                        <div className='col-6'>
                            <DLDatePicker id={'start-date'}
                                          label={'SIP Start Date'}
                                          labelFontColor={'grayDark'}
                                          fontSize={"xs"}
                                          labelPlacement={'top'}
                                          value={selectedFund?.startDate}
                                          minimumDate={moment()}
                                          isClearable={true}
                                          isRequired={true}
                                          onChangeValue={(value) => {
                                              handleChangeDate('startDate',selectedFund?.productId, value);
                                          }}
                                          minWidth={'100%'}
                                          disableHelperMessageHeight={true}
                            />
                        </div>
                        <div className='col-6'>
                            <DLDatePicker id={'end-date'}
                                          label={'SIP End Date'}
                                          labelFontColor={'grayDark'}
                                          fontSize={"xs"}
                                          labelPlacement={'top'}
                                          value={selectedFund?.endDate}
                                          minimumDate={moment().add(12, 'months').format('YYYY-MM-DD')}
                                          maximumDate={moment().add(30, 'years').format('YYYY-MM-DD')}
                                          isClearable={true}
                                          isRequired={true}
                                          onChangeValue={(value) => {
                                              handleChangeDate('endDate',selectedFund?.productId, value);
                                          }}
                                          minWidth={'100%'}
                                          disableHelperMessageHeight={true}
                                          isDisabled={selectedFund?.isMaxPeriodSelected}
                            />
                            <div className='pt-1 d-flex align-items-center'>
                                <DLCheckbox
                                    id={'mark-as-reviewed-dayCheck'}
                                    fontSize={"sm"}
                                    disableHelperMessageHeight={true}
                                    onChangeValue={() => {
                                        handleCheckBox('fund',selectedFund?.productId)
                                    }}
                                    labelPlacement={'end'}
                                    marginBottom={'none'}
                                    checkboxcolor={'black'}
                                    labelColor={appTheme.appColor.black}
                                    isChecked={selectedFund?.isMaxPeriodSelected}
                                />
                                <div>
                                    <DLText id={''}
                                            text={'Maximum Period'}
                                            marginBottom={'none'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'h-100 row'}>
                    <div className='col-6'>
                        <DLInputTextField id={'leads-searchBox'}
                                          marginBottom={"none"}
                                          rows={1}
                                          fontSize={'xs'}
                                          label={'Amount'}
                                          labelFontColor={'grayDark'}
                                          onChangeValue={(e) => {
                                              changeAmount(e, selectedFund?.productId)
                                          }}
                                          isClearable={false}
                                          value={isEmpty(selectedFund?.amount) ? selectedFund?.amount : numberWithCommas(selectedFund?.amount)}
                                          placeholder={'Search'}
                                          isSearchable={true}
                                          inputHeight={'sm'}
                                          inputType={"number"}
                                          disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='col-6'>
                        <DLText id={'allocation-title'}
                                text={'Allocation'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                customWrapperStyle={{'margin': '0px'}}
                        />
                        <DLText id={'allocation'}
                                text={allocation}
                                fontColor={'black'}
                                fontSize={'xs'}
                                fontWeight={'semi-bold'}
                                customWrapperStyle={{'margin': '15px 0 0 0'}}
                        />
                    </div>
                </div>
                <div>
                    {renderColumnErrors(selectedFund)}
                </div>
            </div>
        )
    }

    const renderDate = (SIPDatesDropdown, selectedTransaction) => {

        return (
            <div className='h-100 py-2 d-flex flex-column '
                 style={{verticalAlign: 'Top'}}>
                <div className='d-flex align-items-center'>
                    <DLDatePicker id={'start-date'}
                                  value={selectedTransaction?.startDate}
                                  minimumDate={moment()}
                                  isClearable={true}
                                  isRequired={true}
                                  onChangeValue={(value) => {
                                      handleChangeDate('startDate',selectedTransaction?.productId, value);
                                  }}
                                  minWidth={'175px'}
                                  disableHelperMessageHeight={true}
                    />
                    <div className='px-1'>
                        <DLText id={''}
                                text={'&'}
                        />
                    </div>
                    <DLDatePicker id={'start-date'}
                                  value={selectedTransaction?.endDate}
                                  minimumDate={moment().add(12, 'months').format('YYYY-MM-DD')}
                                  maximumDate={moment().add(30, 'years').format('YYYY-MM-DD')}
                                  isClearable={true}
                                  isRequired={true}
                                  onChangeValue={(value) => {
                                      handleChangeDate('endDate',selectedTransaction?.productId, value);
                                  }}
                                  minWidth={'175px'}
                                  disableHelperMessageHeight={true}
                                  isDisabled={selectedTransaction?.isMaxPeriodSelected}
                    />
                </div>
                <div className='d-flex align-items-center justify-content-end pt-1'>
                    <DLCheckbox
                        id={'mark-as-reviewed-dayCheck'}
                        fontSize={"sm"}
                        disableHelperMessageHeight={true}
                        onChangeValue={() => {
                            handleCheckBox('fund',selectedTransaction?.productId)
                        }}
                        labelPlacement={'end'}
                        marginBottom={'none'}
                        checkboxcolor={'black'}
                        labelColor={appTheme.appColor.black}
                        isChecked={selectedTransaction?.isMaxPeriodSelected}
                    />
                    <div>
                        <DLText id={''}
                                text={'Maximum Period'}
                                marginBottom={'none'}
                        />
                    </div>
                </div>
            </div>
        )
    };


    const getFormattedTableData = (transactionData) => {
        let tableData = [];
        const {SIPDatesDropdown} = props
        if (isEmpty(transactionData)) {
            return tableData
        }
        transactionData.map((selectedTransaction) => {
            let transactionRecords = [];
            let transactionDetails = {};
            if (!isMobileView) {
                transactionRecords.push(renderProductDetails(selectedTransaction));
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderFolioNumberUI(selectedTransaction)
                });

                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderDate(SIPDatesDropdown, selectedTransaction)
                });


                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderSIPDatesDropdown(SIPDatesDropdown, selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: <div className='h-100 py-2'
                                   style={{verticalAlign: 'Top'}}>{selectedTransaction?.allocation}</div>
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderAmountInput(selectedTransaction)
                });
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderDeleteUI(selectedTransaction)
                });
            } else {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: renderMobileEditUI(selectedTransaction, SIPDatesDropdown, selectedTransaction?.allocation)
                })
            }
            transactionDetails.id = selectedTransaction?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });
        return tableData

    };

    const getTableHeight = () => {
        const {responseErrors, warningMessage} = props;
        if (!isEmpty(responseErrors) && !isEmpty(warningMessage)) {
            return 'calc(100dvh - 370px)'
        }
        if (!isEmpty(responseErrors) || !isEmpty(warningMessage)) {
            return 'calc(100dvh - 330px)'
        }
        return 'calc(100dvh - 280px)'
    };

    const mobileHeight = () => {
        const {responseErrors, warningMessage} = props;
        if (isEmpty(responseErrors) && isEmpty(warningMessage)) {
            if (props?.isMDView) {
                return 'calc(100dvh - 367px)';
            }
            return 'calc(100dvh - 325px)';
        }
        if (props?.isMDView) {
            return 'calc(100dvh - 406px)';
        }
        return 'calc(100dvh - 470px)';

    };

    const renderTransactionTable = () => {
        let tableData = getFormattedTableData(funds);
        if (!isMobileView) {
            return (
                <div className="w-100">
                    <DLTable
                        id={''}
                        tableMaxHeight={getTableHeight()}
                        tableMinHeight={'auto   '}
                        isRowClickable={false}
                        cellConfig={purchaseSIPTransactionCellConfig}
                        headerData={purchaseSIPTransactionHeadingData}
                        tableData={tableData}
                        isShowTotalCount={true}
                        totalCountRow={renderLastRow()}
                        isShowPagination={false}
                    />
                </div>
            )
        }

        return (
            <div className={isMobileView ? "" : "w-100 mb-5"}>
                <DLTable
                    id={'portfolio-table'}
                    tableMaxHeight={mobileHeight()}
                    tableMinHeight={'auto'}
                    isRowClickable={false}
                    headerData={[]}
                    tableData={tableData}
                    isShowTotalCount={false}
                    isShowPagination={false}
                />
            </div>
        )

    };

    const renderEmptyUI = () => {
        return (
            <div className='d-flex align-items-center justify-content-center border border-rounded bg-red'
                 style={{
                     maxHeight: 'calc(100dvh - 276px)',
                     minHeight: 'calc(100dvh - 276px)'
                 }}>
                No schemes have been added for the purchase.
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, warningMessage} = props;

        if (isEmpty(responseErrors) && isEmpty(warningMessage)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? 'mt-1' : 'mt-3'}>
                {!isEmpty(responseErrors) && <div className={isMobileView ? 'px-3 py-3 error-container' : 'pb-1 error-container'}>
                    <div className='error-vertical-line'/>
                      <div className={'error-panel'}>
                        {!isEmpty(responseErrors) ? responseErrors : warningMessage}
                      </div>
                    </div>}
                {!isEmpty(warningMessage) && isEmpty(responseErrors) && <div className={isMobileView ? 'px-3 py-3 error-container' : 'pb-1 error-container'}>
                    <div className={'warning-vertical-line'}/>
                      <div className={'warning-panel'}>
                          {warningMessage}
                      </div>
                    </div>}
            </div>
        );
    };

    const renderUi = () => {
        return (
            <div style={{minHeight: 'calc(100dvh - 175px)'}}>
                {renderHeader()}
                {renderErrorUI()}
                <div
                    className={isMobileView ? (props.isMDView ? 'py-1 d-flex align-item-center border-top-black border-bottom-black ' : 'py-1 border-top-black border-bottom-black border-top-black ') : ' d-flex align-items-center '}>
                    <div className={isMobileView ? 'px-3 py-1 d-flex ' : 'py-3 d-flex'}>
                        <DLText id={''}
                                text={'SIP Amount: '}
                                fontSize={"md"}
                                marginBottom={"none"}
                                fontWeight={"normal"}
                        />
                        <div className='ps-1'>
                            <DLText id={''}
                                    text={numberWithCommas(investmentAmount)}
                                    fontSize={"md"}
                                    marginBottom={"none"}
                                    fontWeight={"semi-bold"}
                            />
                        </div>
                    </div>
                    <div className={isMobileView ? 'px-3 py-1 d-flex' : 'ps-3 py-3 d-flex'}>
                        <DLText id={''}
                                text={'Frequency:'}
                                fontSize={"md"}
                                marginBottom={"none"}
                                fontWeight={"normal"}
                        />
                        <div className='ps-1'>
                            <DLText id={''}
                                    text={frequency}
                                    fontSize={"md"}
                                    marginBottom={"none"}
                                    fontWeight={"semi-bold"}
                            />
                        </div>
                    </div>
                </div>
                <div className=''>
                    {(isEmpty(funds)) ? renderEmptyUI() : renderTransactionTable()}
                </div>
                {renderBottomActionButtons()}
            </div>
        )
    };

    const renderMobileUi = () => {
        return (
            <div style={{minHeight: 'calc(100dvh - 175px)'}}>
                {renderHeader()}
                {renderErrorUI()}
                <div>
                    <div
                        className='d-flex justify-content-between align-items-center p-3 border-top-black border-bottom-black'>

                        <div className='d-flex column-gap-5 flex-wrap'>
                            <div>
                                <DLText
                                    id={'investment-amount-title'}
                                    type={'normal'}
                                    text={'SIP Amount: '}
                                    fontSize={'sm'}
                                    fontWeight={'normal'}
                                    fontColor={'black'}
                                    marginBottom={"none"}
                                    isInline={true}
                                />
                                <DLText id={'investment-amount-value'}
                                        type={'normal'}
                                        fontSize={'sm'}
                                        fontWeight={'bold'}
                                        text={numberWithCommas(investmentAmount)}
                                        fontColor={'black'}
                                        marginBottom={"none"}
                                        isInline={true}
                                />
                            </div>
                            <div>
                                <DLText
                                    id={'frequency-title'}
                                    type={'normal'}
                                    text={`Frequency: `}
                                    fontSize={'sm'}
                                    fontWeight={'normal'}
                                    fontColor={'black'}
                                    marginBottom={"none"}
                                    isInline={true}
                                />
                                <DLText
                                    id={'frequency-value'}
                                    type={'normal'}
                                    text={frequency}
                                    fontSize={'sm'}
                                    fontWeight={'bold'}
                                    fontColor={'black'}
                                    marginBottom={"none"}
                                    isInline={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className=''>
                    {(isEmpty(funds)) ? renderEmptyUI() : renderTransactionTable()}
                </div>
                {!isEmpty(funds) && renderTotal()}
                {renderBottomActionButtons()}
            </div>
        )
    };

    const renderSelectFolioModal = () => {
        const {isOpenSelectFolioModal, closeSelectFolioModal, getSelectedPortfolioData} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={"lg"}
                isFullScreen={isMobileView}
                onClose={closeSelectFolioModal}
                open={isOpenSelectFolioModal}
                id={'select-portfolio-modal'}>
                <DLModalTitle
                    id={'select-portfolio-title'}
                    onClose={() => closeSelectFolioModal()}
                    title={'Select Model Portfolio'}/>
                <DLModalContainer
                    id={'select-portfolio-container'}
                    padding={"none"}>
                    <div style={{minHeight: 'calc(100vh - 145px)'}}>
                        <ModelPortfolio
                            {...props}
                            closeSelectFolioModal={closeSelectFolioModal}
                            getSelectedPortfolioData={(folioNo) => {
                                getSelectedPortfolioData(folioNo)
                            }}
                        />
                    </div>
                </DLModalContainer>
            </DLModal>
        )
    };

    const renderLoader = () => {
        if (isShowLoader) {
            return (<DLLoader type={"screen"} isVisible={true}/>);
        }
    };

    return (
        <div className={isMobileView ? '' : 'bg-light p-3'}>
            <div className={'bg-white  ' + (isMobileView ? '' : 'border border-rounded m-1 p-3')}>
                {isMobileView ? renderMobileUi() : renderUi()}
            </div>
            {renderAddSchemeModal()}
            {renderDiscardModal()}
            {renderSelectFolioModal()}
            {renderLoader()}
        </div>
    );
};

export default PurchaseSIPTransactionView;