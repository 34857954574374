import React from 'react';

import {isCorporateUser, numberWithCommas} from "../../utils/helper";
import {isEmpty} from "../../core/components/DLComponentHelper";

import DLText from "../../core/components/DLText/DLText";
import DLButton from "../../core/components/DLButton/DLButton";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import ApproveTransaction from "../components/approveTransaction/ApproveTransaction";
import RejectTransaction from "../components/rejectTransaction/RejectTransaction";
import ApprovedOrRejected from "../approvedOrRejectedTransaction/ApprovedOrRejected";
import appTheme from "../../assets/appTheme";
import moment from "moment";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../assets/img/simplicity_logo.jpg";
import TxnTableLumpsum
    from "../../transactions/FT/components/FTDetails/sharedComponents/txnTableLumpsum/TxnTableLumpsum";
import TxnTableSIP from "../../transactions/FT/components/FTDetails/sharedComponents/txnTableSIP/TxnTableSIP";
const s3BaseURL = process.env.REACT_APP_S3_IMAGE_PUBLIC_BASE_URL;


const ClientsApprovalView = (props) => {
    const {
        isMobileView, IINNumber, accountType, txnRequestType, txnStatus, txnStage
        , UIState, paymentDetails, ticketNumber, txnData, handleOpenApproveTransactionModal,
        handleOpenRejectTransactionModal, transactionStatus, approvalTransactionStatus, isClientApproved
    } = props;

    const handleAmount = (amount) => {
        if (isEmpty(amount)) {
            return "-";
        }
        return numberWithCommas(amount)
    };

    const renderApproveTransactionModal = () => {
        const {isApproveTransactionModalOpen} = props;
        if (isApproveTransactionModalOpen) {
            return (
                <ApproveTransaction {...props}/>
            )
        }

    };

    const renderRejectTransactionModal = () => {
        const {isRejectTransactionModalOpen} = props;

        if (isRejectTransactionModalOpen) {
            return (
                <RejectTransaction {...props}/>
            )
        }

    };

    const renderAccountDetails = () => {
        const {accountDetails, details, corporateAccountDetails} = props;

        let allAccountHoldersNames = "";
        let accountType = isEmpty(details?.accountDetails) ? "" : details?.accountDetails?.accountType;
        if (!isEmpty(accountDetails)) {
            accountDetails.map((applicant) => {
                if (isEmpty(allAccountHoldersNames)) {
                    allAccountHoldersNames = applicant?.fullName;
                } else {
                    allAccountHoldersNames = allAccountHoldersNames + ', ' + applicant?.fullName;
                }
            });
        }

        if (isCorporateUser(accountType?.toUpperCase())) {
            allAccountHoldersNames = corporateAccountDetails?.name;
        }

        return (
            <div key={'accountDetails'}
                 className='d-flex align-items-center '>
                <div>
                    <DLText id={'account-holder-name'}
                            text={allAccountHoldersNames}
                            fontWeight={'bold'}
                            fontSize={'md'}
                            marginBottom={"none"}
                    />
                </div>
            </div>
        );
    };

    const renderHeader = () => {
        if (isMobileView) {
            return (
                <div className='w-100'>
                    <div className='ps-3 pb-3'>
                        <div>
                            <div>
                                {renderAccountDetails()}
                            </div>
                            <DLText id={'ac-details-name'}
                                    text={accountType + '-' + IINNumber}
                                    fontSize={"xs"}
                                    fontColor={"grayDark"}
                                    marginBottom={"none"}
                            />
                        </div>
                        <div className='pe-2 pt-2 d-flex align-items-center'>
                            {renderHeaderStatus(approvalTransactionStatus)}
                        </div>
                    </div>

                </div>
            )
        }
        return (
            <div className='w-100'>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black d-flex'>
                        {renderAccountDetails()}
                    </div>
                    <div className='px-2 border-end-black'>
                        <DLText id={'ac-details-name'}
                                fontWeight={'normal'}
                                fontSize={"md"}
                                fontColor={"grayDark"}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2'>
                        {renderHeaderStatus(approvalTransactionStatus)}
                    </div>
                </div>
            </div>
        )
    };

    const renderHeaderStatus = (statusType) => {
        if (statusType === 'Client Rejected'|| statusType === 'NSE Submission Failed') {
            return (<div className="d-flex align-items-center badge-red ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        } else if (statusType === "Client Approved") {
            return (<div className="d-flex align-items-center badge-green ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        } else {
            return (<div className="d-flex align-items-center badge-amber ms-0">
                <span style={{color: appTheme.appColor.white}}> {statusType} </span>
            </div>);
        }
    };

    const renderChequePaymentDetails = () => {
        if (isMobileView) {
            return (
                <div className="h-100 bg-white border-rounded">
                    <div className='d-flex justify-content-between p-3' style={{
                        borderTop: '1px solid',
                        borderBottom: '1px solid',
                        borderColor: appTheme.appColor.black
                    }}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={'lg'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className={'p-3'}>
                        <div className='row mb-3'>
                            <div className='col-6'>
                                <DLText id={'payment-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Payment Mode'}/>
                                <DLText id={'payment-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.paymentMechanism?.description}/>
                            </div>
                            <div className='col-6'>
                                <DLText id={'bank-name-&-account-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Bank Name & Account Number'}/>
                                {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-6'>
                                <DLText id={'cheque-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Cheque Number'}/>
                                <DLText id={'cheque-number-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.chequePayment?.chequeNo}/>
                            </div>
                            <div className='col-6'>
                                <DLText id={'micr-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'MICR Number'}/>
                                <DLText id={'micr-number-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.chequePayment?.micrNo}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <DLText id={'date'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Date on Cheque'}/>
                                <DLText id={'date-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={isEmpty(paymentDetails?.chequePayment?.chequeDate) ? "" : moment(paymentDetails?.chequePayment?.chequeDate).format("DD-MM-YYYY")}/>
                            </div>
                            <div className='col-6'>
                                <DLText id={'cheque-deposite-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Cheque Deposite Mode'}/>
                                <DLText id={'cheque-deposite-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.chequePayment?.checkDepositModeDescription}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 mt-3'}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                        <div className='col-9'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <DLText id={'cheque-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Number'}/>
                            <DLText id={'cheque-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.chequeNo}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'micr-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'MICR Number'}/>
                            <DLText id={'micr-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.micrNo}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'date'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Date on Cheque'}/>
                            <DLText id={'date-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={isEmpty(paymentDetails?.chequePayment?.chequeDate) ? "" : moment(paymentDetails?.chequePayment?.chequeDate).format("DD-MM-YYYY")}/>
                        </div>
                        <div className='col-3'>
                            <DLText id={'cheque-deposite-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Deposite Mode'}/>
                            <DLText id={'cheque-deposite-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.checkDepositModeDescription}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderPaymentDetails = () => {
        if (paymentDetails?.paymentMechanism?.code === "Q") {
            return renderChequePaymentDetails()
        }

        if (isMobileView) {
            return (
                <div className={"h-100 bg-white border-rounded "}>
                    <div className={'d-flex justify-content-between align-items-center p-3 '} style={{
                        borderTop: '1px solid',
                        borderBottom: '1px solid',
                        borderColor: appTheme.appColor.black
                    }}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={'md'}
                            fontWeight={'bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className={'pe-3 p-3'}>
                        <div className='row mb-3'>
                            <div className={"mb-3"}>
                                <DLText id={'bank-name-&-account-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Bank Name & Account Number'}/>
                                {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                            </div>
                            <div className={isMobileView ? "" : "col-3"}>
                                <DLText id={'payment-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Payment Mode'}/>
                                <DLText id={'payment-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.paymentMechanism?.description}/>
                            </div>
                        </div>
                        {paymentDetails?.paymentMechanism?.description !== "Bank Mandate"&& txnRequestType !== 'SIP' && <div className='row'>
                            <div>
                                <DLText id={'RTGS/IFSC-code'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'RTGS/IFSC Code'}/>
                                <DLText id={'RTGS/IFSC-code-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.bankAccount?.IFSCCode}/>
                            </div>
                        </div>}
                    </div>
                </div>
            )
        }

        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 mt-3'}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                        <div className='col-9'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    {paymentDetails?.paymentMechanism?.description !== "Bank Mandate" && txnRequestType !== 'SIP' &&<div className='row'>
                        <div className='col-3'>
                            <DLText id={'RTGS/IFSC-code'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'RTGS/IFSC Code'}/>
                            <DLText id={'RTGS/IFSC-code-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.bankAccount?.IFSCCode}/>
                        </div>
                    </div>}
                </div>
            </div>
        )
    };

    const renderBankNameAndAccountNumberUi = (bankAccountDetails) => {
        if (isEmpty(bankAccountDetails?.bankName) && isEmpty(bankAccountDetails?.accountNumber)) {
            return "-"
        }
        return (
            <div>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={bankAccountDetails?.BankMaster?.description}/>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={" - " + bankAccountDetails?.accountNumber}/>
            </div>
        )
    };

    const renderButtons = () => {
        if (approvalTransactionStatus !== "Client Approval Pending") {
            return;
        }
        if (isMobileView) {
            return (
                <div className="sticky-bottom-buttons gap-0" style={{background: appTheme.appColor.white}}>
                    <DLButton
                        id={'reject-Btn'}
                        history={props.history}
                        label={'Reject'}
                        buttonSize={"sm"}
                        type={"danger"}
                        fontSize={"sm"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleOpenRejectTransactionModal()
                        }}
                    />
                    <DLButton
                        id={'approve-Btn'}
                        history={history}
                        label={'Approve'}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => {
                            handleOpenApproveTransactionModal()
                        }}/>
                </div>
            )
        }
        return (
            <div className="sticky-bottom-buttons gap-0">
                {!isEmpty(txnData?.totalAllocationAmount) && txnRequestType !== 'SIP'
                &&
                <div className='d-flex justify-content-end align-items-center me-2 gap-1'>
                    <DLText id={''}
                            text={'Current payment amount'}
                            fontSize={"sm"}
                            fontWeight={"normal"}
                            fontColor={"grayDark"}
                            marginBottom={"none"}
                    />
                    <DLText id={''}
                            text={'Rs.' + handleAmount(txnData?.totalAllocationAmount)}
                            fontSize={"lg"}
                            fontWeight={"bold"}
                            marginBottom={"none"}
                    />
                </div>}
                <DLButton
                    id={'reject-Btn'}
                    history={props.history}
                    label={'Reject'}
                    buttonSize={"sm"}
                    type={"danger"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenRejectTransactionModal()
                    }}
                />
                <DLButton
                    id={'approve-Btn'}
                    history={history}
                    label={'Approve'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenApproveTransactionModal()
                    }}/>
            </div>
        )
    };

    const renderSIPPaymentDetails = () => {
        if (isMobileView) {
            return (
                <div className={"h-100 bg-white border-rounded "} style={{minHeight: '240px'}}>
                    <div
                        className={'d-flex justify-content-between align-items-center black-border-bottom black-border-top p-3 '}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={'md'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className={'pe-3 overflow-scroll overflow-x-hidden mt-3 px-3'}
                         style={{maxHeight: 'calc(100dvh - 480px)'}}>
                        <div className='row'>
                            <div className={"mb-3"}>
                                <DLText id={'bank-name-&-account-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Bank Name & Account Number'}/>
                                {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                            </div>
                            <div className={" mb-3"}>
                                <DLText id={'payment-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Payment Mode'}/>
                                <DLText id={'payment-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.paymentMechanism?.description}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 mt-3'}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                        <div className='col-9'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderDetails = () => {
        if (txnRequestType === 'SIP') {
            return (
                <div className='h-100'>
                    <div className='d-flex flex-column h-100'>
                        <div className='mb-2 flex-grow-1'>
                            <TxnTableSIP {...props}/>
                        </div>
                        <div className={isMobileView ? 'mb-5 pb-1' : 'mb-5'}>
                            {renderSIPPaymentDetails()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className='h-100'>
                <div className='d-flex flex-column h-100'>
                    <div className='mb-2 flex-grow-1'>
                        <TxnTableLumpsum {...props}/>
                    </div>
                    <div className={isMobileView ? 'mb-5 pb-1' : 'mb-5'}>
                        {renderPaymentDetails()}
                        {isMobileView && renderMobileTotalAmount()}
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileTotalAmount = () => {
        return (
            <div className="py-2 row px-1 m-0 bg-white"
                 style={{borderTop: '1px solid', borderBottom: '1px solid', borderColor: appTheme.appColor.black}}>
                <div className='data-card-label col-9'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                text={'Current payment amount: '}
                                fontSize={"sm"}
                                fontWeight={"normal"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
                <div className='data-card-label col-3'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                text={handleAmount(txnData?.totalAllocationAmount)}
                                fontSize={"md"}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderErrorUI = () => {
        const {txnStatus, txnStage, isClientApproved} = props;
        if (txnStatus === 'IN_PROGRESS' && txnStage === 'CLIENT_APPROVAL' && !isClientApproved) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? 'mt-1' : 'mt-3 mb-1 pb-2'}
                 style={{paddingInline: '30px'}}
            >
                {txnStatus === 'REJECTED' && txnStage === 'CLIENT_APPROVAL' && !isClientApproved &&
                <div className='pb-1 error-container'>
                    <div className='error-vertical-line'/>
                    <div className={'error-panel'}>
                        You have already rejected this transaction
                    </div>
                </div>
                }
                {isClientApproved && <div className='pb-1 error-container'>
                    <div className={'success-vertical-line'}/>
                    <div className={'success-panel'}>
                        You have already approved this transaction
                    </div>
                </div>}
            </div>
        )
    };

    const renderDesktopUI = () => {
            return (
                <div className='d-flex flex-column bg-light h-100' style={{minHeight: 'calc(100dvh)'}}>
                    {renderAppBar()}
                    <div style={{marginTop: '75px'}}>
                        {renderHeader()}
                        {renderErrorUI()}
                    </div>
                    <div className='page-container flex-grow-1 bg-light'>
                        {renderDetails()}
                    </div>
                    {txnStatus === 'IN_PROGRESS' && txnStage === 'CLIENT_APPROVAL' && !isClientApproved && renderButtons()}
                </div>
            );
        }
    ;

    const renderMobileUI = () => {
            return (
                <div className={isMobileView ? 'd-flex flex-column h-100':'d-flex flex-column h-100 bg-light'}>
                    {renderAppBar()}
                    <div className='bg-white' style={{paddingTop: '75px'}}>
                        {renderHeader()}
                    </div>
                    <div className='flex-grow-1'>
                        {renderDetails()}
                    </div>
                    <div>
                        {renderButtons()}
                    </div>
                </div>
            );
        }
    ;

    const renderAppBar = () => {
        return (
            <div className="">
                <AppBar drawerwidth={0}
                        style={{backgroundColor: appTheme.appColor.white, borderBottom: '2px solid lightGray'}}
                        position="fixed"
                        open={true}
                        className='shadow-none d-flex'>
                    <Toolbar className='' style={{
                        height: isMobileView ? '70px' : '60px',
                        minHeight: isMobileView ? '70px' : '60px '
                    }}>
                        {logoHeaderUi()}
                    </Toolbar>
                </AppBar>
            </div>
        )
    };

    const logoHeaderUi = () => {
            const {isXLView} = props;
            if (isMobileView) {
                return (
                    <div className='w-100'>
                        <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>
                            <div className='d-flex align-items-center w-100'>
                                <div style={{marginRight: isXLView ? 70 : 20}}>
                                    <div className='d-flex align-items-center'>
                                        <img className='rounded-2 me-1'
                                             src={logo}
                                             alt={'logo'}
                                             width={'30px'}
                                             height={'30px'}/>
                                        <div id={"logo"}>
                                            <DLText id={'logo-text'}
                                                    text={'Advisory'}
                                                    fontWeight={"bold"}
                                                    fontSize={"lg"}
                                                    marginBottom={"none"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex align-items-center flex-shrink-0'>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <div className={'text-end'}>
                                            <DLText id={"header"}
                                                    text={txnRequestType === 'SIP' ? "SIP" : "Lumpsum Purchase"}
                                                    fontSize={"sm"}
                                                    fontColor={"grayDark"}
                                                    marginBottom={"none"}
                                            />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='info-panel ms-0'>
                                                <DLText id={''}
                                                        text={'Ticket No: '}
                                                        fontSize={"xs"}
                                                        fontWeight={"normal"}
                                                        isInline={true}
                                                        fontColor={"grayDark"}
                                                />
                                                <DLText id={''}
                                                        text={ticketNumber}
                                                        fontSize={"xs"}
                                                        fontWeight={"semi-bold"}
                                                        isInline={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <div className='w-100'>
                    <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>
                        <div className='d-flex align-items-center w-100'>
                            <div style={{marginRight: isXLView ? 70 : 20}}>
                                <div className='d-flex align-items-center'>
                                    <img className='rounded-2 me-1'
                                         src={logo}
                                         alt={'logo'}
                                         width={'30px'}
                                         height={'30px'}/>
                                    <div id={"logo"}>
                                        <DLText id={'logo-text'}
                                                text={'Advisory'}
                                                fontWeight={"bold"}
                                                fontSize={"xl"}
                                                marginBottom={"none"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex align-items-center flex-shrink-0'>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <DLText id={"header"}
                                                text={txnRequestType === 'SIP' ? "Financial Transaction: SIP" : "Financial Transaction: Lumpsum Purchase"}
                                                fontSize={"sm"}
                                                fontColor={"grayDark"}
                                                marginBottom={"none"}
                                        />
                                    </div>
                                    <div className='d-flex align-items-center ms-2 ps-1'>
                                        <div className='info-panel ms-0'>
                                            <DLText id={''}
                                                    text={'Ticket No: '}
                                                    fontSize={"xs"}
                                                    fontWeight={"normal"}
                                                    isInline={true}
                                                    fontColor={"grayDark"}
                                            />
                                            <DLText id={''}
                                                    text={ticketNumber}
                                                    fontSize={"xs"}
                                                    fontWeight={"semi-bold"}
                                                    isInline={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        }
    ;

    const renderInProgress = () => {
        return (
            <div className='w-100 d-flex flex-column align-items-center justify-content-center bg-light'
                 style={{height: 'calc(100dvh)'}}>
                <div style={{
                    padding: "34px 27px 34px 27px"
                }}>
                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <div className="text-center mb-3">
                <span className="p-0">
                    <div className='d-flex align-items-center'>
                              <img
                                  src={s3BaseURL + 'assets/general/transactionInProgress.png'}
                                  alt={'img'}
                                  className={'img-fluid'}
                                  width={50}
                                  height={50}/>
                    </div>
                </span>
                        </div>
                        <div className="text-center">
                            <DLText id={'enter-security-code'}
                                    alignText={"center"}
                                    text={'Transaction Edit in Progress'}
                                    fontSize={isMobileView ? "lg" : "xl"}
                                    fontWeight={"bold"}
                                    marginBottom={'md'}/>
                            <div className='mb-5' style={{maxWidth: '600px'}}>
                                <DLText id={'eventItem-clone-text1'}
                                        type={'normal'}
                                        alignText={"center"}
                                        fontSize={isMobileView ? "sm" : "md"}
                                        marginBottom={'none'}
                                        text={'Your transaction is being edited by CRM. Please contact your CRM for further information.'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };


    const renderLoader = () => {
            return (<DLLoader type={"screen"} isVisible={isEmpty(UIState)}/>);
        }
    ;

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    if (!isEmpty(transactionStatus)) {
        return (
            <div className='h-100'>
                <ApprovedOrRejected {...props} type={transactionStatus}/>
            </div>
        )
    }

    return (
        <div>
            {( txnStage !== 'DRAFT' && txnStage !== 'MANAGEMENT_APPROVAL') ? (isMobileView ? renderMobileUI() : renderDesktopUI()): renderInProgress()}
            {renderApproveTransactionModal()}
            {renderRejectTransactionModal()}
        </div>
    );
};

export default ClientsApprovalView;