import React from 'react'

import {isEmpty} from "../../../../../../utils/validations";
import appTheme from "../../../../../../assets/appTheme";

import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLText from "../../../../../../core/components/DLText/DLText";

const FTReportStatusModalView = (props) => {
    const {isStatusModalOpen, handleStatusModalClose, FTReportDetails} = props;

    const renderStatus = (data, key) => {
        if (key === "transferredToAMC") {
            if (data === "Processed") {
                return <i className="fa-regular fa-circle-check bg-white NSE-status-icon"
                          style={{color: appTheme.appColor.success}}/>;
            } else {
                return (
                    <div className='NSE-status-progress-icon bg-white'
                         style={{
                             border: '2px solid ' + appTheme.appColor.secondary
                         }}>
                        <i className="fa-solid fa-exclamation "
                           style={{
                               fontSize: 10,
                               color: appTheme.appColor.secondary,
                               paddingTop: 2
                           }}/>
                    </div>
                )
            }
        }
        if (isEmpty(data)) {
            return (
                <div className='NSE-status-progress-icon bg-white'
                     style={{
                         border: '2px solid ' + appTheme.appColor.secondary
                     }}>
                    <i className="fa-solid fa-exclamation "
                       style={{
                           fontSize: 10,
                           color: appTheme.appColor.secondary,
                           paddingTop: 2
                       }}/>
                </div>
            );
        } else {
            return <i className="fa-regular fa-circle-check bg-white NSE-status-icon"
                      style={{color: appTheme.appColor.success}}/>;
        }
    }

    const getFormattedDate = (dateTime) => {
        const date = new Date(dateTime);

        const day = date.toLocaleString('en-GB', {day: '2-digit'});
        const month = date.toLocaleString('en-GB', {month: 'short'});

        const time = date.toLocaleString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return `${day} ${month} at ${time}`;
    }

    const renderFundsTransferedUI = () => {
        return (
            <div className='d-flex h-100 mt-3'>
                <div className='pt-1 px-0'>
                    {renderStatus(FTReportDetails?.fundTransDate)}

                    <div className='d-flex justify-content-center h-100'>
                        <div className='step-connector'/>
                    </div>
                </div>

                <div className='pb-2 pt-0'>
                    <DLText id={'fund-transferred-title'}
                            text={'Fund Transfered '}
                            fontWeight={'semi-bold'}
                            isInline={true}
                    />
                    {!isEmpty(FTReportDetails?.fundTransDate) && (
                        <DLText id={'fund-transferred-date'}
                                text={
                                    <div className='ps-1' style={{lineHeight: '15px', display: 'inline'}}>
                                    <span style={{
                                        fontSize: '10px',
                                        color: 'grayDark'
                                    }}>{getFormattedDate(FTReportDetails?.fundTransDate)}</span>
                                    </div>
                                }
                                fontWeight={"semi-bold"}
                                isInline={true}

                        />
                    )}

                </div>
            </div>
        )
    }

    const renderFundsClearedUI = () => {
        return (
            <div className='d-flex h-100'>
                <div className='pt-1'>
                    {renderStatus(FTReportDetails?.fundClearedDate)}
                    <div className='d-flex justify-content-center h-100 '>
                        <div className='step-connector'/>
                    </div>
                </div>

                <div className='pb-2'>
                    <DLText id={'fund-cleared-title'}
                            text={'Fund Cleared '}
                            fontWeight={'semi-bold'}
                            isInline={true}
                    />
                    {!isEmpty(FTReportDetails?.fundClearedDate) && (
                        <DLText id={'fund-cleared-date'}
                                text={<div className='ps-1' style={{lineHeight: '15px', display: 'inline'}}>
                            <span style={{
                                fontSize: '10px',
                                color: 'grayDark'
                            }}>{getFormattedDate(FTReportDetails?.fundClearedDate)}</span>
                                </div>}
                                fontWeight={"semi-bold"}
                                isInline={true}
                        />
                    )}


                    {!isEmpty(FTReportDetails?.rejectedReason) && (
                        <div>
                            <DLText id={'reason-title'}
                                    text={'Reason: '}
                                    fontWeight={'semi-bold'}
                                    fontSize={'xs'}
                                    isInline={true}
                            />
                            <DLText id={'reason-details'}
                                    text={FTReportDetails?.rejectedReason}
                                    fontWeight={'normal'}
                                    fontSize={'xs'}
                                    isInline={true}
                            />
                        </div>
                    )}

                </div>
            </div>
        )
    }

    const renderTransferredToAMCUI = () => {
        return (
            <div className='d-flex h-100'>
                <div className='pt-1'>
                    {renderStatus(FTReportDetails?.fundTransToAmc, "transferredToAMC")}
                    <div className='d-flex justify-content-center h-100'>
                        <div className='step-connector'/>
                    </div>
                </div>

                <div className='pb-2'>
                    <DLText id={'fund-transferred-to-AMC-title'}
                            text={'Fund transferred to AMC '}
                            fontWeight={'semi-bold'}
                    />
                    {!isEmpty(FTReportDetails?.fundTransToAmc) && (
                        <div>
                            <DLText id={'remark-title'}
                                    text={'Remark: '}
                                    fontWeight={'semi-bold'}
                                    fontSize={'xs'}
                                    isInline={true}
                            />
                            <DLText id={'remark'}
                                    text={FTReportDetails?.fundTransToAmc}
                                    fontWeight={'normal'}
                                    fontSize={'xs'}
                                    isInline={true}
                            />
                        </div>
                    )}

                </div>
            </div>
        )
    }

    const renderProceedByRTAUI = () => {
        return (
            <div className='d-flex '>
                <div className='pt-1'>
                    {renderStatus(FTReportDetails?.rtaFeedDate)}
                    <div
                        className='d-flex justify-content-center h-100'>
                        <div className='step-connector'/>
                    </div>
                </div>

                <div className='pb-2'>
                    <DLText id={'processed-by-rta-title'}
                            text={'Processed by RTA '}
                            fontWeight={'semi-bold'}
                            isInline={true}
                    />
                    {!isEmpty(FTReportDetails?.rtaFeedDate) && (
                        <DLText id={'rta-feed-date'}
                                text={
                                    <div className='ps-1' style={{lineHeight: '15px', display: 'inline'}}>
                                            <span style={{
                                                fontSize: '10px',
                                                color: 'grayDark'
                                            }}>{getFormattedDate(FTReportDetails?.rtaFeedDate)}</span>
                                    </div>}
                                fontWeight={"semi-bold"}
                                isInline={true}
                        />
                    )}

                    {FTReportDetails?.trxnStatus === 'Processed by RTA' && (
                        <div className='p-0 m-0'>
                            <DLText id={'proceed-title'}
                                    text={'Proceed: '}
                                    fontWeight={'semi-bold'}
                                    fontSize={'xs'}
                                    isInline={true}
                            />
                            <DLText id={'rta-status'}
                                    text={FTReportDetails?.rtaStatus}
                                    fontWeight={'normal'}
                                    fontSize={'xs'}
                                    isInline={true}
                            />
                        </div>
                    )}

                </div>
            </div>
        )
    }

    const renderAuthorizedUI = () => {
        return (
            <div className='d-flex mb-3'>
                <div className='pt-1'>
                    {renderStatus(FTReportDetails?.authorizedDateTime)}
                </div>
                <DLText id={'authorized-title'}
                        text={'Authorized '}
                        fontWeight={'semi-bold'}
                        isInline={true}
                />

                {!isEmpty(FTReportDetails?.authorizedDateTime) && (
                    <DLText id={'authorized-date'}
                            text={
                                <div className='ps-1' style={{lineHeight: '15px', display: 'inline'}}>
                                        <span style={{
                                            fontSize: '10px',
                                            color: 'grayDark'
                                        }}>{getFormattedDate(FTReportDetails?.authorizedDateTime)}</span>
                                </div>}
                            fontWeight={"semi-bold"}
                            isInline={true}
                    />
                )}
            </div>
        )
    }

    return (
        <div>
            <DLModal
                history={props.history}
                maxWidth={"xs"}
                onClose={() => handleStatusModalClose()}
                open={isStatusModalOpen}
                id={'ft-report-status-modal'}>
                <DLModalTitle
                    id={'status-title'}
                    onClose={() => handleStatusModalClose()}
                    title={'Status'}/>
                <DLModalContainer
                    padding={"sm"}
                    id={'complexity-container'}>
                    {renderFundsTransferedUI()}
                    {renderFundsClearedUI()}
                    {renderTransferredToAMCUI()}
                    {renderProceedByRTAUI()}
                    {renderAuthorizedUI()}
                </DLModalContainer>
            </DLModal>
        </div>
    )
}

export default FTReportStatusModalView;
