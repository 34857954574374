import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import store from "../../../store";
import {showToastMessage} from "../../../AppComponents/AppActions";

import {assignCRMToOnboarding, getOnboardingDetails} from "../../OnboardingActions";
import {getCRMs} from "../../../AppComponents/MetadataActions";
import ReassignCRMView from "./ReassignCRMView";


const ReassignCRM = (props) => {

    const{getApplicationId} = props;
    const [reassignCRMState, setReassignCRMState] = useState({
        selectedCRM: '',
        CRMOptions : [],
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:'',

        errors:{
            selectedCRMError:''
        }
    });

    useEffect(() => {
        getCRMData()
    }, []);

    const getCRMData = () =>{
        let applicationId = getApplicationId();
        setReassignCRMState((prevStep) => ({
            ...prevStep,
            isApiInProgress: true
        }));
        getCRMs().then((CRMRes) => {
            if (CRMRes?.success) {

                getOnboardingDetails(applicationId)
                    .then((res) => {
                        if (res?.success) {
                            setReassignCRMState((prevStep) => ({
                                ...prevStep,
                                CRMOptions : setValuesForDropdown(CRMRes.data?.advisoryUsers,"firstName","id"),
                                currentCRM : res.data?.advisoryUserId,
                                isApiInProgress: false
                            }));
                        } else {
                            setReassignCRMState((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false
                            }));
                        }
                    })
            } else {
                setReassignCRMState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false
                }));
            }
        })
    }

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = []
        if (isEmpty(data)) {
            return;
        }

        data.map((item) => {
            list.push({label: item?.[labelToken]+" "+item?.lastName, value: item?.[valueToken]})
        });

        return list;
    };

    const validateChanges = (name, compState) => {
        const {selectedCRM} = compState;
        let errorObj = {...reassignCRMState.errors};

        if (name === "selectedCRM" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(selectedCRM)) {
                errorObj.selectedCRMError = "Please select CRM";
            } else {
                errorObj.selectedCRMError = "";
            }

            if (name === 'selectedCRM') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const clearData = () => {
        const {handleCloseReassignCRMModal} = props;
        handleCloseReassignCRMModal();
        setReassignCRMState((prevStep) => ({
            ...prevStep,
            selectedCRM: [],
        }));
    };

    const onChangeFormData = (name, value) => {
        let updatedState = {...reassignCRMState};
        updatedState[name] = value;
        updatedState[name+'Error'] = '';

        const validateObject = validateChanges(name, updatedState);

        setReassignCRMState(prevStep => ({
            ...prevStep,
            ...updatedState,
            modalErrorMessage: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const getCRMName = (crmId) =>{
        const {CRMOptions} = reassignCRMState;

        if(isEmpty(crmId) || isEmpty(CRMOptions)){
            return '';
        }
        return CRMOptions.find((item)=>{
            return item?.value === crmId;
        })
    }

    const checkIfValidateObj=(validateObj)=>{
        const {selectedCRMError} = validateObj;
        if (selectedCRMError) {
            // If any mandatory error is present (non-empty), return false
            return false;
        }
        // If no mandatory errors are present, return true
        return true;
    };

    const assignCRM = () => {
        const {selectedCRM} = reassignCRMState;

        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, reassignCRMState);

        if (!checkIfValidateObj(validateObj)) {
            setReassignCRMState(prevStep => ({
                ...prevStep,
                isApiInProgress: false,
                errors:{
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }

        let payload = {
            onboardingApplicationIds: [getApplicationId()],
            CRMId: selectedCRM
        }
        let CRMName = getCRMName(selectedCRM)
        setReassignCRMState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        assignCRMToOnboarding(payload)
            .then((res) => {
                if (res.success) {
                    store.dispatch(showToastMessage('success', 'This onboarding is assigned to '+CRMName?.label))
                    clearData();
                    // onSaveSuccess();
                    setReassignCRMState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false
                    }));
                } else {
                    setReassignCRMState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        modalErrorMessage:res.__error
                    }));
                }
            }
        );
    };

    return (
        <ReassignCRMView
            {...props}
            {...reassignCRMState}
            assignCRM={assignCRM}
            onChangeFormData={onChangeFormData}
            getCRMName={getCRMName}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(ReassignCRM);
