import TxnTableLumpsumView from "./TxnTableLumpsumView";
import React from "react";

const TxnTableLumpsum = (props) => {
    return (
        <TxnTableLumpsumView
            {...props}
        />
    );
};

export default TxnTableLumpsum;
