import React from 'react';

import {headingFTDataForManagement} from "../../../FTModal";
import {handleKeyPress} from "../../../../NFT/NFTHelper";
import {convertUTCToLocalTime, isCorporateUser} from "../../../../../utils/helper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";

import DLText from "../../../../../core/components/DLText/DLText";
import DLTable from "../../../../../core/components/DLTable/DLTable";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLDropDown from "../../../../../core/components/DLDropdown/DLDropDown";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";

import emptyFilterFilled from "../../../../../assets/img/appIcons/emptyFilterFilled.png";
import emptyFilter from "../../../../../assets/img/appIcons/emptyFilter.png";
import filterDot from "../../../../../assets/img/appIcons/filterDot.png";

const FTRequestListForManagementView = (props) => {

    const {UIState, isMobileView, isXSView, onChangeFilter, FTFilter, isShowLoader, handleRedirect, keyword, status, CRMId} = props;

    const renderStatusUI = (txnRequestStage, txnRequestStatus) =>{

        const {allFTStatus} = props;

        let statusLabel = '';
        let statusType = '';
        if(isEmpty(txnRequestStage)){
            return '-'
        }
        if(isEmpty(allFTStatus)){
            return txnRequestStage
        }

        if(txnRequestStage === 'DRAFT'){
            statusLabel = 'Draft';
        } else {
            allFTStatus.map((statusObj) => {
                if (statusObj.mapping.stage === txnRequestStage && statusObj.mapping.status === txnRequestStatus) {
                    statusType = statusObj.mapping.status;
                    statusLabel = statusObj?.label
                }
            })
        }

        if(statusType === 'REJECTED'|| statusType === "FAILED"){
            return (<div className='d-flex'>
                <span className="status-dot my-1 me-2 danger" style={{minWidth: '8px'}}/>
                <span style={{fontSize: "12px"}}> {statusLabel} </span>
            </div>)
        }
        else if(statusType === "APPROVED"){
            return (<div className='d-flex'>
                <span className="status-dot my-1 me-2 success" style={{minWidth: '8px'}}/>
                <span style={{fontSize: "12px"}}> {statusLabel} </span>
            </div>)
        }else{
            return (<div className='d-flex'>
                <span className="status-dot my-1 me-2 warning" style={{minWidth: '8px'}}/>
                <span style={{fontSize: "12px"}}> {statusLabel} </span>
            </div>)
        }


    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between pb-1 pt-3'>
                <div className= 'page-header pt-0 pb-3'>
                    Financial Transactions
                </div>
            </div>
        )
    };

    const isFiltersApplied = (filters) => {

        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (key === 'status' && filters[key][0] === 'NOT_CONTACTED') {
                        return false
                    }
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '') {
                    return true;
                }
            }
        }
        return false;
    };

    const renderFilterIcon = () => {
        const {isShowFilterMobile, FTFilter} = props;
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(FTFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter, resetFTListing, FTStatusDropdown, CRMDropdownValues} = props;

        return (<div>
            <div className='d-flex align-items-start justify-content-between gap-3 px-3'>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'FT-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      onKeyPress={handleKeyPress}
                                      placeholder={'Search by Name'}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>

                    {isFiltersApplied(FTFilter) &&
                        <div>
                            <i className="fa-solid fa-xmark cursor ms-2 "
                               onClick={() => resetFTListing()}
                               style={{fontSize: 18}}/>
                        </div>
                    }
                </div>
            </div>
            {isShowFilterMobile &&
                <div className='bg-light mt-2 py-2 px-3'>
                    <div style={{flex: 1}}>
                        <DLDropDown
                            id={"status-filter"}
                            label={'Status'}
                            labelFontColor={'grayDark'}
                            labelPlacement={'top'}
                            value={status[0]}
                            options={FTStatusDropdown}
                            onSelect={(e) => {
                                onChangeFilter('status', e)
                            }}
                            disableHelperMessageHeight={true}
                            minWidth={'100%'}
                            marginBottom={'none'}
                        />
                    </div>
                    <div className={'pt-1'} style={{flex: 1}}>
                        <DLDropDown
                            id={"CRM-filter"}
                            label={'CRM'}
                            labelFontColor={'grayDark'}
                            value={CRMId[0]}
                            minWidth={"100%"}
                            options={CRMDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('CRMId', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                </div>
            }
        </div>)
    };

    const renderFilter = () => {

        const {CRMDropdownValues, FTStatusDropdown} = props;

        return (
            <div>
                <div className='d-flex'>
                    <div className='search-input me-3' style={{flex: 1.5}}>
                        <DLInputTextField
                            id={'lead-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={isEmpty(keyword) ? '' : keyword}
                            placeholder={'Search by Name'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='select-filter me-3' style={{minWidth: "300px"}}>
                        <DLDropDown
                            id={"application-status-filter"}
                            label={'Status'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={status[0]}
                            minWidth={"100%"}
                            options={FTStatusDropdown}
                            onSelect={(e) => {
                                onChangeFilter('status', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    <div className='select-filter mb-3 me-3'
                         style={{minWidth: "250px"}}>
                        <DLDropDown
                            id={"CRM-filter"}
                            label={'CRM'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={CRMId[0]}
                            minWidth={"100%"}
                            options={CRMDropdownValues}
                            onSelect={(e) => {
                                onChangeFilter('CRMId', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderCount = () => {
        const {isXSView, handleRefreshButton, listingCount} = props;

        if (isXSView) {
            return (
                <div className={isEmpty(listingCount) ? 'px-3 pt-3 pb-2 mb-1' : 'black-border-bottom px-3 pt-3 pb-2 mb-1'}>
                    {!isEmpty(listingCount) && listingCount !== 0 &&
                        <div className={'d-flex align-items-center '}>
                            <DLText id={''}
                                    isInline={true}
                                    fontColor={'grayDark'}
                                    fontSize={'xs'}
                                    marginBottom={'none'}
                                    fontWeight={'semi-bold'}
                                    text={'Showing ' + (listingCount) + ' records'}/>
                            <div className={'d-flex align-items-center mx-2'}
                                 onClick={() => {
                                     handleRefreshButton()
                                 }}>
                                <i className="fa-solid fa-rotate-right cursor"/>
                            </div>
                        </div>}
                </div>)
        }
        return (
            <div className={'d-flex align-items-center pb-3'} style={{height: '37px'}}>
                {!isEmpty(listingCount) && listingCount !== 0 &&
                    <div className={'d-flex align-items-center '}>
                        <DLText id={''}
                                isInline={true}
                                fontColor={'grayDark'}
                                fontSize={'sm'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + (listingCount) + ' records'}/>
                        <div className={'d-flex align-items-center mx-2'}
                             onClick={() => {
                                 handleRefreshButton()
                             }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                        </div>
                    </div>}
            </div>)

    };

    const renderAccountHolders = (accountHolders, accountType, corporateAccountDetails) => {
        let name = '';

        if (isCorporateUser(accountType)) {
            name = corporateAccountDetails?.fullName;
        } else if (isEmpty(accountHolders)) {
            return '-';
        } else {
            accountHolders.map((item, index) => {
                if (accountType !== 'MINOR') {
                    if (index <= (accountHolders?.length - 2)) {
                        name = name + item?.fullName + ', ';
                    } else {
                        name = name + item?.fullName;
                    }
                } else {
                    if (item?.holder_type === 'PRIMARY_HOLDER_MINOR') {
                        name = name + item?.fullName;
                    }
                }
            });
        }

        return (
            <DLText
                id={''}
                text={name}
                fontSize={"xs"}
                fontWeight={"semi-bold"}
            />
        );
    };

    const getAccountUI = (holderDetails) => {
        const {getAccountType, getValueFromList, categoryTypes} = props;

        let category = getValueFromList(holderDetails?.category, categoryTypes);

        return(
            <div className={isXSView ? '' : 'py-1'} style={{width: isXSView ? '' : '370px'}}>
                {renderAccountHolders(holderDetails?.accountHolders, holderDetails?.accountType, holderDetails?.corporateAccountDetails)}
                <DLText id={''}
                        text={getAccountType(holderDetails?.accountType) + ' - '}
                        fontWeight={"normal"}
                        isInline={true}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
                <DLText id={''}
                        text={holderDetails?.IINNumber}
                        fontWeight={"normal"}
                        isInline={true}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
                {!(isEmpty(category)) && <DLText id={''}
                                                 text={' - ' + category}
                                                 fontWeight={"normal"}
                                                 isInline={true}
                                                 fontSize={"xs"}
                                                 fontColor={"grayDark"}
                />}
            </div>
        )
    };

    const getCRMAndLocationUI = (holderDetails) =>{
        const {getValueFromList, CRMDropdownValues} = props;
        return(
            <div className='py-1'>
                <DLText id={''}
                        text={getValueFromList(holderDetails?.assignedTo, CRMDropdownValues, true)}
                        fontSize={"xs"}
                        fontWeight={"normal"}
                />
                <DLText id={''}
                        text={isEmpty(holderDetails?.location) ? ' - ' : holderDetails?.location}
                        fontWeight={"normal"}
                        isInline={true}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
            </div>
        )
    };

    const renderMobileUI = (fields) =>{
        const {getValueFromList, typesOfTransaction} = props;

        return(
            <div className='py-2 my-1'>
                {getAccountUI(fields)}
                <div className={'row pt-3'}>
                    <div className={'col-5'}>
                        <DLText id={'status'}
                                text={'Status'}
                                fontSize={"xxs"}
                                fontColor={"grayDark"}
                                marginBottom={'none'}
                        />
                        <DLText id={''}
                                text={renderStatusUI(fields?.txnRequestStage, fields?.txnRequestStatus)}
                                fontWeight={"normal"}
                                isInline={true}
                                fontSize={"xs"}
                                marginBottom={'none'}
                        />
                    </div>
                    <div className={'col-4'}>
                        <DLText id={''}
                                text={'Transaction Type'}
                                fontSize={"xxs"}
                                fontColor={'grayDark'}
                                marginBottom={'none'}
                        />
                        <DLText id={''}
                                text={getValueFromList(fields?.txnRequestType, typesOfTransaction)}
                                isInline={true}
                                fontSize={"xs"}
                                marginBottom={'none'}
                        />
                    </div>
                    <div className={'col-3'}>
                        <DLText id={''}
                                text={'Created Date'}
                                fontSize={"xxs"}
                                marginBottom={'none'}
                                fontColor={'grayDark'}
                        />
                        <DLText id={''}
                                text={convertUTCToLocalTime(fields?.createdAt, 'DD-MM-YYYY')}
                                isInline={true}
                                fontSize={"xs"}
                                marginBottom={'none'}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const getFormattedTableData = (FTList) => {

        const {typesOfTransaction, getValueFromList} = props;

        let tableData = [];
        if (isEmpty(FTList)) {
            return tableData
        }

        FTList.map((fields) => {
            let FTFields = [];
            let FTHolderDetails = {};
            if(isMobileView){
                FTFields.push({
                    isCustomUI: true,
                    customUI: renderMobileUI(fields)
                });
            }else{
                FTFields.push({
                    isCustomUI: true,
                    customUI: getAccountUI(fields)
                });
                FTFields.push(isEmpty(fields?.ticketNumber) ? '-': fields?.ticketNumber);
                FTFields.push(isEmpty(fields?.txnRequestType) ? '-': getValueFromList(fields?.txnRequestType, typesOfTransaction));
                FTFields.push(renderStatusUI(fields?.txnRequestStage, fields?.txnRequestStatus));
                FTFields.push(convertUTCToLocalTime(fields?.createdAt, 'DD-MM-YYYY'));
                FTFields.push({
                    isCustomUI: true,
                    customUI: getCRMAndLocationUI(fields)
                });
            }



            FTHolderDetails.id = {clientRequestId : fields?.clientRequestId, txnRequestId : fields?.id};
            FTHolderDetails.data = FTFields;
            tableData.push(FTHolderDetails);
        });

        return tableData

    };

    const renderTableUI = () => {
        const {isShowFilterMobile, FTList, listingCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage,
            getPaginationNextButtonStatus, skipCount} = props;

        let tableData = getFormattedTableData(FTList);

        if (isEmpty(FTList)) {
            return (
                <div className={'w-100 d-flex align-items-center justify-content-center' + (isXSView ? '' : ' panel')}
                     style={{minHeight: 'calc(100dvh - 277px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className={isXSView ? "black-border-bottom" : "w-100"}>
                <DLTable
                    id={''}
                    tableMaxHeight={isXSView ? isShowFilterMobile ? 'calc(100dvh - 471px)' : 'calc(100dvh - 313px)' : 'calc(100dvh - 340px)'}
                    tableMinHeight={isXSView ? isShowFilterMobile ? 'calc(100dvh - 471px)' : 'calc(100dvh - 313px)' : 'calc(100dvh - 340px)'}
                    isRowClickable={true}
                    onRowClick={(rowData) => {
                        handleRedirect(rowData?.clientRequestId, rowData?.txnRequestId)
                    }}
                    headerData={isMobileView ? [] : headingFTDataForManagement}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }
                />
            </div>
        )
    };

    const renderFTTable = () => {
        return (
            <div className={'w-100'}>
                {renderCount()}
                {renderTableUI()}
            </div>
        )
    };

    if (isEmpty(UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderMobileHeader = () => {
        return (
            <div className={'page-header-mobile'}>
                Financial Transactions
            </div>
        )
    }

    const renderUi = () => {
        return (
            <div className={'h-100 d-flex justify-content-center' + (isMobileView ? '' : ' bg-light ')}
                 style={{minHeight: isXSView ? 'calc(100dvh - 58px)' : 'calc(100dvh - 52px)'}}>
                <div className={isXSView ? 'w-100' : 'w-100 bg-light'}>
                    {isXSView ? renderMobileHeader() : renderHeader()}
                    {(isMobileView) ?
                        renderFilterMobile() :
                        <div className={'page-container'}>
                            {renderFilter()}
                        </div>
                    }
                    <div className={isXSView ? '' : 'page-container bg-light'}>
                        {renderFTTable()}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderUi()}
            {renderLoader()}
        </div>
    );

};

export default FTRequestListForManagementView;