import React, {useState} from 'react';
import {connect} from "react-redux";

import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";
import {copyTransaction} from "../../../../FTAction";

import CopyTransactionView from "./CopyTransactionView";

const CopyTransaction = (props) => {

    const {txnRequestId, handleCloseCopyTransactionModal, onSaveSuccess} = props;
    const [copyTransactionState, setCopyTransactionState] = useState({
        isApiInProgress: false,
        handleErrorInComponent: true,
        modalErrorMessage: ''
    });

    const handleCopyTransaction = () => {
        setCopyTransactionState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));
        let payload = {
            txnRequestId : txnRequestId
        }

        copyTransaction(payload)
            .then((res) => {
                    if (res.success) {
                        store.dispatch(showToastMessage('success', 'Transaction copied'))
                        setCopyTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        onSaveSuccess('isCopyTransactionModalOpen', false);
                    } else {
                        setCopyTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res.__error
                        }));
                    }
                }
            );
    };

    return (
        <CopyTransactionView
            {...props}
            {...copyTransactionState}
            handleCopyTransaction={handleCopyTransaction}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(CopyTransaction);
