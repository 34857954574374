import React, {useEffect, useState} from 'react';
import PersonalProfileView from "./PersonalProfileView";
import {connect} from "react-redux";
import {isEmpty} from "../../../utils/validations";
import {
    getPersonalProfileData,
    updatePersonalProfileData
} from "../../OnboardingActions";
import {isAlphabetic, isIncludeSpecialCharacters, isValidEmail, validateAge} from "../../../utils/helper";
import moment from "moment";
import store from "../../../store";
import {showToastMessage} from "../../../AppComponents/AppActions";
import {getStateList, getOccupationList, getIndustryTypeList, getPoliticallyExposedOptions,
    getApplicableIncomeOptions, getPhoneEmailBelongsToList} from "../../../AppComponents/MetadataActions";

const PersonalProfile = (props) => {

    const [personalProfileState, setPersonalProfileState] = useState({
        selfInformation: {
            type: 'self information',
            title: "",
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirth: "",
            birthPlace: "",
            maritalStatus: ""
        },
        fathersDetails: {
            parentType: "FATHER",
            firstName: "",
            middleName: "",
            lastName: ""
        },
        mothersDetails: {
            parentType: "MOTHER",
            firstName: "",
            middleName: "",
            lastName: ""
        },
        address: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            stateCode: "",
            zipCode: "",
            district: ""
        },
        contact: {
            primaryEmail: "",
            primaryEmailRelation: "",
            primaryPhoneNumber: "",
            primaryPhoneNumberRelation: "",
            secondaryEmail: "",
            secondaryEmailRelation: "",
            secondaryPhoneNumber: "",
            secondaryPhoneNumberRelation: "",
            officePhone: "",
            residentialPhone: "",
        },
        profession: {
            occupationCode: "",
            companyName: "",
            industryType: "",
            annualIncomeCode: "",
            liabilities: ""
        },
        politicallyExposed: {
            PEPCode: "N",
        },
        errors: {
            selfInformation: {
                title: "",
                firstName: "",
                middleName: "",
                lastName: "",
                dateOfBirth: "",
                birthPlace: "",
                maritalStatus: ""
            },
            mothersDetails: {
                parentType: "MOTHER",
                firstName: "",
                middleName: "",
                lastName: ""
            },
            fathersDetails: {
                parentType: "FATHER",
                firstName: "",
                middleName: "",
                lastName: ""
            },
            address: {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                stateCode: "",
                zipCode: "",
                district:""
            },
            contact: {
                primaryEmail: "",
                primaryEmailRelation: "",
                primaryPhoneNumber: "",
                primaryPhoneNumberRelation: "",
                secondaryEmail: "",
                secondaryEmailRelation: "",
                secondaryPhoneNumber: "",
                secondaryPhoneNumberRelation: "",
                officePhone: "",
                residentialPhone: "",
            },
            profession: {
                occupationCode: "",
                industryType: "",
                companyName: "",
                annualIncomeCode: "",
                liabilities: ""
            },
            politicallyExposed: {
                PEPCode: '',
            }
        },
        dropdownValues: {
            states: [],
            occupations: [],
            industries: [],
            politicallyExposedValues: [],
            emailPhoneBelongsTo: [],
            annualIncomes: [],
            maritalStatusOptions: [
                {
                    label: 'Married',
                    value: 'MARRIED'

                },
                {
                    label: 'Unmarried',
                    value: 'UNMARRIED'
                },
                {
                    label: 'Others',
                    value: 'OTHERS'

                }]
        },
        responseErrors: [],
        totalErrors: 0,
        scrollToFieldId: '',
        isLoaderVisible: false,
        isMultipleApplicant: false,
    });

    useEffect(() => {
        getMasterData();
    }, [props.selectedScreenObj]);

    const getMasterData = () => {
        const {selectedScreenObj, totalApplicants, onboardingApplicationId} = props;

        setPersonalProfileState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        getStateList()
            .then(stateRes => {
                if (stateRes?.success) {

                    const stateData = stateRes?.data?.state;
                    getOccupationList()
                        .then(occupationRes => {
                            if (occupationRes?.success) {

                                const occupationData = occupationRes?.data?.occupation;
                                getIndustryTypeList()
                                    .then(industryRes => {
                                        if (industryRes?.success) {

                                            const industryData = industryRes?.data?.industryType;
                                            getPoliticallyExposedOptions()
                                                .then(pepRes => {
                                                    if (pepRes?.success) {

                                                        getApplicableIncomeOptions()
                                                            .then(applicableIncomeRes => {
                                                                if (applicableIncomeRes?.success) {
                                                                    let applicableIncomeData = applicableIncomeRes?.data?.applicableIncome;

                                                                    applicableIncomeData = [...applicableIncomeData].sort((a, b) => a.code - b.code);

                                                                    const politicallyExposedData = pepRes?.data?.pep;
                                                                    getPhoneEmailBelongsToList()
                                                                        .then(belongsToRes => {
                                                                            if (belongsToRes?.success) {
                                                                                const belongsToData = belongsToRes?.data?.relationship;
                                                                                const dropdownVals = {
                                                                                    ...personalProfileState.dropdownValues,
                                                                                    occupations: setValuesForDropdown(occupationData, "description", "code"),
                                                                                    states: setValuesForDropdown(stateData, "description", "code"),
                                                                                    industries: setValuesForDropdown(industryData, "name", "id"),
                                                                                    politicallyExposedValues: setValuesForDropdown(politicallyExposedData, "description", "code"),
                                                                                    emailPhoneBelongsTo: setValuesForDropdown(belongsToData, "description", "code"),
                                                                                    annualIncomes: setValuesForDropdown(applicableIncomeData, "description", "code")
                                                                                };

                                                                                getPersonalProfileData(selectedScreenObj?.personId, onboardingApplicationId)
                                                                                // getPersonalProfileData(accountId)
                                                                                    .then(ppResp => {
                                                                                        if (ppResp?.success) {
                                                                                            const updatedPPData = getMappedData(ppResp.data, dropdownVals);

                                                                                            setPersonalProfileState(prevState => ({
                                                                                                ...prevState,
                                                                                                ...updatedPPData,
                                                                                                isLoaderVisible: false,
                                                                                                dropdownValues: {
                                                                                                    ...prevState.dropdownValues,
                                                                                                    occupations: setValuesForDropdown(occupationData, "description", "code"),
                                                                                                    states: setValuesForDropdown(stateData, "description", "code"),
                                                                                                    industries: setValuesForDropdown(industryData, "name", "id"),
                                                                                                    politicallyExposedValues: setValuesForDropdown(politicallyExposedData, "description", "code"),
                                                                                                    emailPhoneBelongsTo: setValuesForDropdown(belongsToData, "description", "code"),
                                                                                                    annualIncomes: setValuesForDropdown(applicableIncomeData, "description", "code")
                                                                                                },
                                                                                                isMultipleApplicant: (totalApplicants?.length > 2),
                                                                                                applicantName: selectedScreenObj?.applicantName
                                                                                            }));
                                                                                        } else {
                                                                                            setPersonalProfileState(prevState => ({
                                                                                                ...prevState,
                                                                                                isLoaderVisible: false
                                                                                            }));
                                                                                        }
                                                                                    });
                                                                            }
                                                                        })

                                                                }
                                                            })
                                                    }
                                                })
                                        }
                                    })
                            }
                        })
                }
            });
    };

    const getParentDetails = (type, parents) => {

        let selectedParentDetails = parents.find(obj => obj.parentType === type);
        if(!isEmpty(selectedParentDetails)){
            return {
                parentType: type,
                firstName: isEmpty(selectedParentDetails.firstName) ? '' : selectedParentDetails.firstName,
                middleName: isEmpty(selectedParentDetails.middleName) ? '' : selectedParentDetails.middleName,
                lastName: isEmpty(selectedParentDetails.lastName) ? '' : selectedParentDetails.lastName,
            }
        }else{
            return {
                parentType: type,
                firstName: "",
                middleName: "",
                lastName: ""
            }
        }
    };

    const getPersonDetailsById = (personId, response, dropdownVals) => {

        if(isEmpty(personId) || isEmpty(response)){
            return {
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                stateCode: '',
                zipCode: '',
                district:''
            }
        }else{
            let selectedPersonDetails = response.find(obj => obj.personId === personId);
            return {
                addressLine1: isEmpty(selectedPersonDetails.addressLine1) ? '' : selectedPersonDetails.addressLine1,
                addressLine2: isEmpty(selectedPersonDetails.addressLine2) ? '' : selectedPersonDetails.addressLine2,
                addressLine3: isEmpty(selectedPersonDetails.addressLine3) ? '' : selectedPersonDetails.addressLine3,
                city: isEmpty(selectedPersonDetails.city) ? '' : selectedPersonDetails.city,
                stateCode: getDropdownSelectedObj('states', selectedPersonDetails.stateCode, dropdownVals),
                zipCode: isEmpty(selectedPersonDetails.zipCode) ? '' : selectedPersonDetails.zipCode,
                district: isEmpty(selectedPersonDetails.district) ? '' : selectedPersonDetails.district,
            }
        }
    };

    //GET CALL MAPPING
    const getMappedData=(response, dropdownVals)=>{
        const {fathersDetails, mothersDetails} = personalProfileState;
        const {isFromOnboardingFlow} = props;



        const {selectedScreenObj} = props;
        if(isEmpty(response)){
            return {}
        }
        let fathersDetailsObj = fathersDetails;
        let mothersDetailsObj = mothersDetails;

        if(!isEmpty(response?.parents)){

            fathersDetailsObj = getParentDetails('FATHER',response?.parents);
            mothersDetailsObj = getParentDetails('MOTHER',response?.parents);
        }
        let selfInformation = {
            type: 'self information',
            title: getDropdownSelectedObj('title',response.title,dropdownVals),
            firstName: isEmpty(response?.firstName) ? '' : response.firstName,
            middleName: isEmpty(response?.middleName) ? '' : response.middleName,
            lastName: isEmpty(response?.lastName) ? '' : response.lastName,
            dateOfBirth: isEmpty(response?.dateOfBirth) ? '' : response.dateOfBirth,
            birthPlace: isEmpty(response?.birthPlace) ? '' : response.birthPlace,
            maritalStatus: getDropdownSelectedObj('maritalStatusOptions',response.maritalStatus,dropdownVals),
        };

        let contact = {
            primaryEmail: isEmpty(response?.primaryEmail) ? '' : response.primaryEmail,
            primaryEmailRelation: getDropdownSelectedObj('emailPhoneBelongsTo',response.primaryEmailRelation,dropdownVals),
            primaryPhoneNumber: isEmpty(response?.primaryPhoneNumber) ? '' : response.primaryPhoneNumber,
            primaryPhoneNumberRelation:getDropdownSelectedObj('emailPhoneBelongsTo',response.primaryPhoneNumberRelation,dropdownVals),
            secondaryEmail: isEmpty(response?.secondaryEmail) ? '' : response.secondaryEmail,
            secondaryEmailRelation: getDropdownSelectedObj('emailPhoneBelongsTo',response.secondaryEmailRelation,dropdownVals),
            secondaryPhoneNumber: isEmpty(response?.secondaryPhoneNumber) ? '' : response.secondaryPhoneNumber,
            secondaryPhoneNumberRelation: getDropdownSelectedObj('emailPhoneBelongsTo',response.secondaryPhoneNumberRelation,dropdownVals),
            officePhone: isEmpty(response?.officePhone) ? '' : response.officePhone,
            residentialPhone: isEmpty(response?.residentialPhone) ? '' : response.residentialPhone,
        };

        let profession = {
            occupationCode: isEmpty(response?.profession?.occupationCode) ? '' : getDropdownSelectedObj('occupations',response.profession.occupationCode,dropdownVals),
            companyName: isEmpty(response?.profession?.companyName) ? '' : response.profession.companyName,
            industryType:  isEmpty(response?.profession?.industryType) ? '' : getDropdownSelectedObj('industries',response.profession.industryType,dropdownVals),
            annualIncomeCode:  isEmpty(response?.personFinancial?.annualIncomeCode) ? '' : getDropdownSelectedObj('annualIncomes',response.personFinancial?.annualIncomeCode,dropdownVals),
            liabilities:  isEmpty(response?.personFinancial?.liabilities) ? '' : response?.personFinancial?.liabilities
        };

        let updatedState = {
            selfInformation: {...selfInformation},
            fathersDetails: fathersDetailsObj,
            mothersDetails: mothersDetailsObj,
            address: isEmpty(response?.personAddresses) ? {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                stateCode: "",
                zipCode: "",
                district: ""
            } : getPersonDetailsById(selectedScreenObj?.personId , response?.personAddresses, dropdownVals),
            contact: {...contact},
            profession: {...profession},
            politicallyExposed: {
                PEPCode:  isEmpty(response?.PEPCode) ? 'N' : getDropdownSelectedObj('politicallyExposedValues',response.PEPCode,dropdownVals)
            },
            errors : {
                selfInformation: {
                    title: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    dateOfBirth: "",
                    birthPlace: "",
                    maritalStatus: ""
                },
                mothersDetails: {
                    parentType: "MOTHER",
                    firstName: "",
                    middleName: "",
                    lastName: ""
                },
                fathersDetails: {
                    parentType: "FATHER",
                    firstName: "",
                    middleName: "",
                    lastName: ""
                },
                address: {
                    addressLine1: "",
                    addressLine2: "",
                    addressLine3: "",
                    city: "",
                    stateCode: "",
                    zipCode: "",
                    district: ""
                },
                contact: {
                    primaryEmail: "",
                    primaryEmailRelation: "",
                    primaryPhoneNumber: "",
                    primaryPhoneNumberRelation: "",
                    secondaryEmail: "",
                    secondaryEmailRelation: "",
                    secondaryPhoneNumber: "",
                    secondaryPhoneNumberRelation: "",
                    officePhone: "",
                    residentialPhone: "",
                },
                profession: {
                    occupationCode: "",
                    industryType: "",
                    companyName: "",
                    annualIncomeCode: "",
                    liabilities: ""
                },
                politicallyExposed: {
                    PEPCode: '',
                }
            }
        };

        if(!isFromOnboardingFlow){
            let validatedCurrentState= validateChanges(updatedState, 'SUBMIT')
            return {
                ...updatedState,
                errors: {...validatedCurrentState},
                scrollToFieldId: getFirstErrorFieldId(validatedCurrentState),
            };
        }else{
            return {
                ...updatedState,
                scrollToFieldId: getFirstErrorFieldId(''),
            };
        }
    };

    const getDropdownSelectedObj=(type,selectedValue, dropDownValues)=>{

        if(isEmpty(selectedValue)){
            if(type === 'politicallyExposedValues'){
                return 'N'
            }
            return ''
        }
        if(type === 'title'){
            return getTitleValues()?.find(dropdownVal=> dropdownVal.value === selectedValue)
        }
        if(type === 'maritalStatusOptions' || type === 'politicallyExposedValues'){
            let selectedObj = dropDownValues[type]?.find(dropdownVal=> dropdownVal.value === selectedValue)
            return selectedObj?.value
        }
        if(type === 'annualIncomes'){
            let selectedObj = dropDownValues[type]?.find(dropdownVal=> dropdownVal.value === selectedValue);
            return selectedObj?.value
        }
        return dropDownValues[type]?.find(dropdownVal=> dropdownVal.value === selectedValue)
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }
        let obj = [];

        obj = data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });

        return obj
    };

    // todo - replace with api
    const getTitleValues = () => {
        return [
            {
                label: 'Mr.',
                value: 'Mr.'
            },
            {
                label: 'Mrs.',
                value: 'Mrs.'
            },
            {
                label: 'Ms.',
                value: 'Ms.'
            },
            {
                label: 'M/s.',
                value: 'M/s.'
            }
        ];
    };

    const handleChange = (name, value, fieldType) => {
        let updatedCompState = {...personalProfileState};
        if(value === null){
            value = ''
        }
        setPersonalProfileState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        if (fieldType === "selfInformation") {
            if (name === 'dateOfBirth') {
                updatedCompState.selfInformation.dateOfBirth = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            } else {
                updatedCompState.selfInformation[name] = value;
            }

        } else if (fieldType === "fathersDetails") {
            updatedCompState.fathersDetails[name] = value;
        } else if (fieldType === "mothersDetails") {
            updatedCompState.mothersDetails[name] = value;
        } else if (fieldType === "address") {
            updatedCompState.address[name] = value
        } else if (fieldType === "contact") {
            updatedCompState.contact[name] = value;
        } else if (fieldType === "profession") {
            updatedCompState.profession[name] = value
        } else if (fieldType === "politicallyExposed") {
            updatedCompState.politicallyExposed[name] = value
        } else {
            updatedCompState[name] = value
        }

        setPersonalProfileState(prevStep => ({
            ...prevStep,
            ...updatedCompState,
        }));

        const validateObject = validateChanges(updatedCompState, name, fieldType);

        setPersonalProfileState(prevStep => ({
            ...prevStep,
            isLoaderVisible: false,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));

    };

    const validateSelfInformation = (componentState, name) => {
        const {selfInformation, errors} = componentState;
        const {title, dateOfBirth, birthPlace, firstName, middleName, lastName, maritalStatus} = selfInformation;

        let errorObj = {...errors.selfInformation};

        if (name === 'firstName' || name === 'SUBMIT') {
            if (isEmpty(firstName)) {
                errorObj.firstName = 'Please enter first name'
            } else if (isIncludeSpecialCharacters(firstName) || isAlphabetic(firstName)) {
                errorObj.firstName = 'Please enter alphabets only'
            } else if (firstName.length > 20) {
                errorObj.firstName = 'Please enter 20 alphabets only'
            } else {
                errorObj.firstName = ''
            }
            if (name === 'firstName') {
                return errorObj
            }
        }

        if (name === 'middleName' || name === 'SUBMIT') {
            if ((isIncludeSpecialCharacters(middleName) || isAlphabetic(middleName)) && !isEmpty(middleName)) {
                errorObj.middleName = 'Please enter alphabets only'
            } else if (middleName?.length > 20) {
                errorObj.middleName = 'Please enter 20 alphabets only'
            } else {
                errorObj.middleName = ''
            }
            if (name === 'middleName') {
                return errorObj
            }
        }

        if (name === 'lastName' || name === 'SUBMIT') {
            if ((isIncludeSpecialCharacters(lastName) || isAlphabetic(lastName)) && !isEmpty(lastName)) {
                errorObj.lastName = 'Please enter alphabets only'
            } else if (lastName?.length > 20) {
                errorObj.lastName = 'Please enter 20 alphabets only'
            } else {
                errorObj.lastName = ''
            }

            if (name === 'lastName') {
                return errorObj
            }
        }

        if (name === 'dateOfBirth' || name === 'SUBMIT') {
            let isMinorApplicant = validateAge(dateOfBirth, 18);
            if (isEmpty(dateOfBirth)) {
                errorObj.dateOfBirth = 'Please enter date of birth'
            } else if (isMinorApplicant) {
                errorObj.dateOfBirth = 'Applicant age must be above 18'

            } else {
                errorObj.dateOfBirth = ''
            }
            if (name === 'dateOfBirth') {
                return errorObj
            }
        }
        if (name === 'birthPlace' || name === 'SUBMIT') {
            if (isEmpty(birthPlace)) {
                errorObj.birthPlace = 'Please enter birthplace'
            } else if (isIncludeSpecialCharacters(birthPlace) || isAlphabetic(birthPlace)) {
                errorObj.birthPlace = 'Please enter alphabets only'
            } else if (birthPlace?.length > 30) {
                errorObj.birthPlace = 'Please enter 30 alphabets only'
            } else {
                errorObj.birthPlace = ''
            }

            if (name === 'birthPlace') {
                return errorObj
            }
        }
        if (name === 'maritalStatus' || name === 'SUBMIT') {
            if (isEmpty(maritalStatus)) {
                errorObj.maritalStatus = 'Please select marital status'
            } else {
                errorObj.maritalStatus = ''
            }

            if (name === 'maritalStatus') {
                return errorObj
            }
        }
        return errorObj
    };

    const validateFathersDetails = (componentState, name) => {
        const {fathersDetails, errors} = componentState;
        const {firstName, middleName, lastName} = fathersDetails;

        let errorObj = {...errors.fathersDetails};

        if (name === 'firstName' || name === 'SUBMIT') {

            if (isEmpty(firstName)) {
                errorObj.firstName = 'Please enter first name'
            } else if (isIncludeSpecialCharacters(firstName) || isAlphabetic(firstName)) {
                errorObj.firstName = 'Please enter alphabets only'
            } else if (firstName?.length > 20) {
                errorObj.firstName = 'Please enter 20 alphabets only'
            } else {
                errorObj.firstName = ''
            }

            if (name === 'firstName') {
                return errorObj
            }
        }

        if (name === 'middleName' || name === 'SUBMIT') {
            if ((isIncludeSpecialCharacters(middleName) || isAlphabetic(middleName)) && !isEmpty(middleName)) {
                errorObj.middleName = 'Please enter alphabets only'
            } else if (middleName?.length > 20) {
                errorObj.middleName = 'Please enter 20 alphabets only'
            } else {
                errorObj.middleName = ''
            }

            if (name === 'middleName') {
                return errorObj
            }
        }

        if (name === 'lastName' || name === 'SUBMIT') {
            if ((isIncludeSpecialCharacters(lastName) || isAlphabetic(lastName)) && !isEmpty(lastName)) {
                errorObj.lastName = 'Please enter alphabets only'
            } else if (lastName?.length > 20) {
                errorObj.lastName = 'Please enter 20 alphabets only'
            } else {
                errorObj.lastName = ''
            }

            if (name === 'lastName') {
                return errorObj
            }
        }
        return errorObj
    };

    const validateMothersDetails = (componentState, name) => {
        const {mothersDetails, errors} = componentState;
        const {firstName, middleName, lastName} = mothersDetails;

        let errorObj = {...errors.mothersDetails};

        if (name === 'firstName' || name === 'SUBMIT') {

            if (isEmpty(firstName)) {
                errorObj.firstName = 'Please enter first name'
            } else if (isIncludeSpecialCharacters(firstName) || isAlphabetic(firstName)) {
                errorObj.firstName = 'Please enter alphabets only'
            } else if (firstName?.length > 20) {
                errorObj.firstName = 'Please enter 20 alphabets only'
            } else {
                errorObj.firstName = ''
            }

            if (name === 'firstName') {
                return errorObj
            }
        }

        if (name === 'middleName' || name === 'SUBMIT') {
            if ((isIncludeSpecialCharacters(middleName) || isAlphabetic(middleName)) && !isEmpty(middleName)) {
                errorObj.middleName = 'Please enter alphabets only'
            } else if (middleName?.length > 20) {
                errorObj.middleName = 'Please enter 20 alphabets only'
            } else {
                errorObj.middleName = ''
            }

            if (name === 'middleName') {
                return errorObj
            }
        }

        if (name === 'lastName' || name === 'SUBMIT') {

            if ((isIncludeSpecialCharacters(lastName) || isAlphabetic(lastName)) && isEmpty(lastName)) {
                errorObj.lastName = 'Please enter alphabets only'
            } else if (lastName?.length > 20) {
                errorObj.lastName = 'Please enter 20 alphabets only'
            } else {
                errorObj.lastName = ''
            }

            if (name === 'lastName') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateAddress = (componentState, name) => {
        const {address, errors} = componentState;
        const {addressLine1, addressLine2, addressLine3, city, zipCode, stateCode, district} = address;

        let errorObj = {...errors.address};

        if ((name === 'addressLine1') || name === 'SUBMIT') {
            if (isEmpty(addressLine1)) {
                errorObj.addressLine1 = "Please enter address"
            } else if (addressLine1?.length > 50) {
                errorObj.addressLine1 = 'Please enter 50 characters only'
            } else {
                errorObj.addressLine1 = ""
            }
            if (name === 'addressLine1') {
                return errorObj
            }
        }

        if ((name === 'addressLine2') || name === 'SUBMIT') {
            if (addressLine2?.length > 50) {
                errorObj.addressLine2 = 'Please enter 50 characters only'
            } else {
                errorObj.addressLine2 = ""
            }
            if (name === 'addressLine2') {
                return errorObj
            }
        }
        if ((name === 'addressLine3') || name === 'SUBMIT') {
            if (addressLine3?.length > 50) {
                errorObj.addressLine3 = 'Please enter 50 characters only'
            } else {
                errorObj.addressLine3 = ""
            }
            if (name === 'addressLine3') {
                return errorObj
            }
        }

        if ((name === 'city') || name === 'SUBMIT') {
            if (isEmpty(city)) {
                errorObj.city = "Please enter city"
            }else if (city?.length > 30) {
                errorObj.city = 'Please enter 30 characters only'
            } else {
                errorObj.city = ""
            }
            if (name === 'city') {
                return errorObj
            }
        }

        if ((name === 'zipCode') || name === 'SUBMIT') {
            if (isEmpty(zipCode)) {
                errorObj.zipCode = " Please enter pincode"
            } else if (zipCode?.length > 6) {
                errorObj.zipCode = 'Please enter 6 character only'
            } else {
                errorObj.zipCode = ""
            }
            if (name === 'zipCode') {
                return errorObj
            }
        }

        if ((name === 'stateCode') || name === 'SUBMIT') {
            if (isEmpty(stateCode)) {
                errorObj.stateCode = "Please select state"
            } else {
                errorObj.stateCode = ""
            }
            if (name === 'stateCode') {
                return errorObj
            }
        }

        if ((name === 'district') || name === 'SUBMIT') {
            if (isEmpty(district)) {
                errorObj.district = " Please enter district"
            } else if (district?.length > 30) {
                errorObj.district = 'Please enter 30 character only'
            } else {
                errorObj.district = ""
            }
            if (name === 'district') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateContact = (componentState, name) => {
        const {contact, errors} = componentState;
        const {
            primaryEmail, primaryEmailRelation, primaryPhoneNumber, primaryPhoneNumberRelation, secondaryEmail, secondaryPhoneNumber,
            officePhone, residentialPhone, secondaryEmailRelation, secondaryPhoneNumberRelation
        } = contact;
        let errorObj = {...errors.contact};

        if (name === 'primaryEmail' || name === 'SUBMIT') {
            if (isEmpty(primaryEmail)) {
                errorObj.primaryEmail = "Please enter primary email";
                // handleChange('primaryEmailRelation', '', 'contact')
            } else if (primaryEmail?.length > 60) {
                errorObj.primaryEmail = 'Please enter 60 characters only'
            } else if (!isValidEmail(primaryEmail, true)) {
                errorObj.primaryEmail = 'Please enter valid email address'
            } else {
                errorObj.primaryEmail = ""
            }
            if (name === 'primaryEmail') {
                return errorObj
            }
        }

        if (name === 'primaryEmailRelation' || name === 'SUBMIT') {
            if (isEmpty(primaryEmailRelation)) {
                errorObj.primaryEmailRelation = "Please select email belongs to"
            } else {
                errorObj.primaryEmailRelation = ""
            }
            if (name === 'primaryEmailRelation') {
                return errorObj
            }
        }

        if ((name === 'secondaryEmail' || name === 'SUBMIT')) {
            if (secondaryEmail?.length > 60) {
                errorObj.secondaryEmail = 'Please enter 60 characters only';
            } else if ((!isValidEmail(secondaryEmail, true) && !isEmpty(secondaryEmail))) {
                errorObj.secondaryEmail = 'Please enter valid email address'
            } else {
                errorObj.secondaryEmail = "";
                if(isEmpty(secondaryEmail)){
                    errorObj.secondaryEmailRelation = ""
                }
            }
            if (name === 'secondaryEmail') {
                return errorObj
            }
        }

        if ((name === 'secondaryEmailRelation' || name === 'SUBMIT')) {
            if (!isEmpty(secondaryEmail) && isEmpty(secondaryEmailRelation)) {
                errorObj.secondaryEmailRelation = 'Please select email belongs to'
            } else {
                errorObj.secondaryEmailRelation = ""
            }
            if (name === 'secondaryEmailRelation') {
                return errorObj
            }
        }

        if (name === 'primaryPhoneNumber' || name === 'SUBMIT') {
            if (isEmpty(primaryPhoneNumber)) {
                errorObj.primaryPhoneNumber = "Please enter mobile";
                // handleChange('primaryPhoneNumberRelation', '', 'contact')
            } else if (primaryPhoneNumber?.length > 10) {
                errorObj.primaryPhoneNumber = 'Please enter 10 numbers only'
            } else if ((primaryPhoneNumber?.length < 10) && !isEmpty(primaryPhoneNumber)) {
                errorObj.primaryPhoneNumber = 'Enter valid mobile number'
            } else {
                errorObj.primaryPhoneNumber = ""
            }
            if (name === 'primaryPhoneNumber') {
                return errorObj
            }
        }

        if (name === 'primaryPhoneNumberRelation' || name === 'SUBMIT') {
            if (isEmpty(primaryPhoneNumberRelation)) {
                errorObj.primaryPhoneNumberRelation = "Please select mobile belongs to"
            } else {
                errorObj.primaryPhoneNumberRelation = ""
            }
            if (name === 'primaryPhoneNumberRelation') {
                return errorObj
            }
        }

        if (name === 'secondaryPhoneNumber' || name === 'SUBMIT') {
            if (secondaryPhoneNumber?.length > 10) {
                errorObj.secondaryPhoneNumber = 'Please enter 10 numbers only';
            } else if ((secondaryPhoneNumber?.length < 10) && (!isEmpty(secondaryPhoneNumber))) {
                errorObj.secondaryPhoneNumber = 'Enter valid mobile number'
            } else {
                errorObj.secondaryPhoneNumber = "";
                if(isEmpty(secondaryPhoneNumber)){
                    errorObj.secondaryPhoneNumberRelation = ""
                }
                errorObj.secondaryPhoneNumber = ""
            }
            if (name === 'secondaryPhoneNumber') {
                return errorObj
            }
        }

        if ((name === 'secondaryPhoneNumberRelation' || name === 'SUBMIT')) {
            if (!isEmpty(secondaryPhoneNumber) && isEmpty(secondaryPhoneNumberRelation)) {
                errorObj.secondaryPhoneNumberRelation = 'Please select mobile belongs to'
            } else {
                errorObj.secondaryPhoneNumberRelation = ""
            }
            if (name === 'secondaryPhoneNumberRelation') {
                return errorObj
            }
        }

        if (name === 'officePhone' || name === 'SUBMIT') {
            if (officePhone?.length > 20) {
                errorObj.officePhone = 'Please enter 20 numbers only'
            } else {
                errorObj.officePhone = ""
            }
            if (name === 'officePhone') {
                return errorObj
            }
        }

        if (name === 'residentialPhone' || name === 'SUBMIT') {
            if (residentialPhone?.length > 20) {
                errorObj.residentialPhone = 'Please enter 20 numbers only'
            } else {
                errorObj.residentialPhone = ""
            }
            if (name === 'residentialPhone') {
                return errorObj
            }
        }

        if (name === 'secondaryPhoneNumber' || name === 'SUBMIT') {
            if (secondaryPhoneNumber?.length > 10) {
                errorObj.secondaryPhoneNumber = 'Please enter 10 numbers only'
            } else if ((secondaryPhoneNumber?.length < 10) && (!isEmpty(secondaryPhoneNumber))) {
                errorObj.secondaryPhoneNumber = 'Enter valid mobile number'
            } else {
                errorObj.secondaryPhoneNumber = ""
            }
            if (name === 'secondaryPhoneNumber') {
                return errorObj
            }
        }
        return errorObj
    };

    const validateProfession = (componentState, name) => {
        const {profession, errors} = componentState;
        const {occupationCode, companyName, annualIncomeCode, liabilities} = profession;

        let errorObj = {...errors.profession};

        if ((name === 'occupationCode') || name === 'SUBMIT') {

            if (isEmpty(occupationCode)) {
                errorObj.occupationCode = 'Please select occupation'
            } else {
                errorObj.occupationCode = ''
            }

            if (name === 'occupationCode') {
                return errorObj
            }
        }
        if ((name === 'companyName' || name === 'SUBMIT')) {

            if (isIncludeSpecialCharacters(companyName)) {
                errorObj.companyName = 'Please enter alphabets only'
            } else if (companyName?.length > 30) {
                errorObj.companyName = 'Please enter 30 characters only'
            } else {
                errorObj.companyName = ''
            }

            if (name === 'occupation') {
                return errorObj
            }
        }

        if ((name === 'annualIncomeCode' || name === 'SUBMIT')) {

            if (isEmpty(annualIncomeCode)) {
                errorObj.annualIncomeCode = 'Please select annual income'
            } else {
                errorObj.annualIncomeCode = ''
            }

            if (name === 'annualIncomeCode') {
                return errorObj
            }
        }

        if (name === 'liabilities' || name === 'SUBMIT') {
            if (liabilities?.length > 51) {
                errorObj.liabilities = 'Please enter 50 characters only';
            } else {
                errorObj.liabilities = '';
            }
            if (name === 'liabilities') {
                return errorObj;
            }
        }

        return errorObj
    };

    const validateChanges = (componentState, name, fieldType) => {
        const {errors} = componentState;

        let errorObj = errors;
        if (fieldType === 'selfInformation' || isEmpty(fieldType)) {
            errorObj.selfInformation = validateSelfInformation(componentState, name);
        }

        if (fieldType === 'fathersDetails' || isEmpty(fieldType)) {
            errorObj.fathersDetails = validateFathersDetails(componentState, name);
        }

        if (fieldType === 'mothersDetails' || isEmpty(fieldType)) {
            errorObj.mothersDetails = validateMothersDetails(componentState, name);
        }
        if (fieldType === 'address' || isEmpty(fieldType)) {
            errorObj.address = validateAddress(componentState, name);
        }
        if (fieldType === 'contact' || isEmpty(fieldType)) {
            errorObj.contact = validateContact(componentState, name);
        }
        if (fieldType === 'profession' || isEmpty(fieldType)) {
            errorObj.profession = validateProfession(componentState, name);
        }

        return errorObj;
    };

    const getFirstErrorFieldId = (errors) => {
        let sectionOrderToSort =['selfInformation', 'fathersDetails', 'mothersDetails', 'address', 'contact', 'profession', 'politicallyExposed'];
        for (const section of sectionOrderToSort) {
            if (errors[section]) {
                for (const key in errors[section]) {

                    const error = errors[section][key];
                    if (error && typeof error === 'object' && 'label' in error && error.label !== 'type') {
                        return section + '-' + error.label + '-id';
                    }
                    if (typeof error === 'string' && error && key !== 'type' && key !== 'parentType') {
                        return section + '-' + key + '-id';
                    }
                }
            }
        }
        return 'self-info-block';
    };

    const assignResponseError = (response, stateErrors) => {
        const errorResponse = response?.validationErrors;

        let newStateErrors = {...stateErrors};
        let responseErrors = [];

        if (isEmpty(errorResponse)) { //handled state if response status is failed
            setPersonalProfileState(prevStep => ({
                ...prevStep,
                isLoaderVisible: false,
                errors: {
                    ...prevStep.errors,
                    ...newStateErrors,
                },
                scrollToFieldId: getFirstErrorFieldId(stateErrors),
                responseErrors: [],
                totalErrors: 0,
            }));
            return
        }

        errorResponse.forEach((error) => { //assign each field by section name
            const section = error.section;
            if (!newStateErrors[section]) {
                return;
            }
            if (isEmpty(error.fields)) { //validate if all fields of mother details are empty
                if (section === 'mothersDetails') {
                    newStateErrors = {
                        ...newStateErrors,
                        ...validateMothersDetails(personalProfileState)
                    }
                }
                if (section === 'fathersDetails') { //validate if all fields of father details are empty
                    newStateErrors = {
                        ...newStateErrors,
                        ...validateFathersDetails(personalProfileState)
                    }
                }
            } else if ((error.fields)?.length > 1) { //add page level validation error
                responseErrors.push(error.message)
            } else {
                //assign each field error to respected field and log if invalid field is found
                error.fields.forEach(field => {
                    newStateErrors[section][field] = error.message;
                });
            }
        });
        let errors = {
            ...personalProfileState.errors,
            ...newStateErrors,
        };
        setPersonalProfileState(prevStep => ({
            ...prevStep,
            errors: errors,
            responseErrors: responseErrors,
            totalErrors: responseErrors?.length,
            scrollToFieldId: isEmpty(responseErrors) ? getFirstErrorFieldId(errors) : 'page-level-errors'

        }));
    };

    const simplifyObjectValues = (input) => {
        // Create a copy of the input object to avoid mutating the original object
        const output = {...input};

        // Iterate through the keys of the object
        for (const key in output) {
            // If the property's value is an object and has a 'value' key, replace it
            if (typeof output[key] === 'object' && output[key] !== null && 'value' in output[key]) {
                output[key] = output[key].value;
            }
        }
        return output;
    };

    const onNextClick = () => {
        const {profile, handleClickNext, isProceedWithErrors, selectedScreenObj, accountId, isFromOnboardingFlow, onboardingApplicationId} = props;
        setPersonalProfileState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));


        let errorId = '';

        let updatedData = {...personalProfileState};
        delete updatedData.fathersDetails;
        delete updatedData.mothersDetails;
        delete updatedData.dropdownValues;
        delete updatedData.errors;

        let profileDataObj = {
            ...updatedData.contact,
            ...updatedData.politicallyExposed,
            ...updatedData.selfInformation,
        };
        profileDataObj = simplifyObjectValues(profileDataObj);
        profileDataObj.personAddress = simplifyObjectValues(updatedData.address);
        profileDataObj.personId = selectedScreenObj.personId;
        profileDataObj.accountId = accountId;
        profileDataObj.profession = {...simplifyObjectValues(updatedData.profession)};
        profileDataObj.parents = [
            {...simplifyObjectValues(personalProfileState.fathersDetails)},
            {...simplifyObjectValues(personalProfileState.mothersDetails)}
        ];
        profileDataObj.onboardingApplicationId = onboardingApplicationId;
        profileDataObj.financial = {
            annualIncomeCode: personalProfileState.profession.annualIncomeCode,
            liabilities: personalProfileState.profession.liabilities,
        };


        updatePersonalProfileData(profileDataObj, true)
            .then(res => {
                if (res.success) {
                    if(isProceedWithErrors){
                        handleClickNext(true, selectedScreenObj?.personId);
                        setPersonalProfileState(prevState => ({
                            ...prevState,
                            isLoaderVisible: false
                        }));
                        return;
                    }

                    if (isEmpty(res.data?.validationErrors)) {
                        setPersonalProfileState(prevState => ({
                            ...prevState,
                            isLoaderVisible: false
                        }));
                        handleClickNext(true, selectedScreenObj?.personId)
                    } else {
                        if(!isFromOnboardingFlow){
                            handleClickNext(true, selectedScreenObj?.personId);
                        }
                        const validateObject = validateChanges(personalProfileState, 'SUBMIT');
                        assignResponseError(res.data, validateObject)
                    }
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId= 'page-level-errors'
                    }
                    setPersonalProfileState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors?.length,
                        scrollToFieldId: errorId,
                        isLoaderVisible: false
                    }));
                }

            })
    };

    const isTabUI = () => {
        const {isMDView, isLGView} = props;
        return isMDView || isLGView
    };

    const onSaveAndCloseClick = () => {

        const {profile, handleClickSaveAndClose, selectedScreenObj, saveFailureErrorMessage, accountId, onboardingApplicationId} = props;
        setPersonalProfileState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));


        let errorId = '';

        let updatedData = {...personalProfileState};
        delete updatedData.fathersDetails;
        delete updatedData.mothersDetails;
        delete updatedData.dropdownValues;
        delete updatedData.errors;

        let profileDataObj = {
            ...updatedData.contact,
            ...updatedData.politicallyExposed,
            ...updatedData.selfInformation,
        };
        profileDataObj = simplifyObjectValues(profileDataObj);
        profileDataObj.personAddress = simplifyObjectValues(updatedData.address);
        profileDataObj.personId = selectedScreenObj.personId;
        profileDataObj.accountId = accountId;
        profileDataObj.profession = {...simplifyObjectValues(updatedData.profession)};
        profileDataObj.parents = [
            {...simplifyObjectValues(personalProfileState.fathersDetails)},
            {...simplifyObjectValues(personalProfileState.mothersDetails)}
        ];
        profileDataObj.financial = {
            annualIncomeCode: personalProfileState.profession.annualIncomeCode,
            liabilities: personalProfileState.profession.liabilities
        };
        profileDataObj.onboardingApplicationId = onboardingApplicationId;

        updatePersonalProfileData(profileDataObj, true)
            .then(res => {
                if (res.success) {
                    handleClickSaveAndClose()
                } else {
                    store.dispatch(showToastMessage('warning', saveFailureErrorMessage));
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId= 'page-level-errors'
                    }
                    setPersonalProfileState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors?.length,
                        scrollToFieldId: errorId,
                        isLoaderVisible: false
                    }));
                }

            })
    };


    return (
        <PersonalProfileView
            {...props}
            {...personalProfileState}
            getTitleValues={getTitleValues}
            handleChange={handleChange}
            onNextClick={onNextClick}
            isTabUI={isTabUI}
            onSaveAndCloseClick={onSaveAndCloseClick}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(PersonalProfile);
