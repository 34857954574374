import React from 'react';

import {
    headingData,
    cellConfig,
    statusFilterOptions, cellConfigForMobile
} from "../../../NFTModal";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {handleKeyPress, renderTransactionStatusDot} from "../../../NFTHelper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {convertUTCToLocalTime, isCorporateUser} from "../../../../../utils/helper";

import emptyLeads from '../../../../../assets/img/empty-leads.png';
import emptyFilter from '../../../../../assets/img/appIcons/emptyFilter.png';
import emptyFilterFilled from '../../../../../assets/img/appIcons/emptyFilterFilled.png';
import filterDot from '../../../../../assets/img/appIcons/filterDot.png';

import '../../../../../assets/css/table.css'
import '../../../../../assets/css/listing.css'

import DLTable from "../../../../../core/components/DLTable/DLTable";
import DLText from "../../../../../core/components/DLText/DLText";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";
import FilterBadgeView from "../../../../../leads/components/filterBadge/FilterBadgeView";
import MultiselectDropdown from "../../../../../clientRequest/components/MultiselectDropdown";
import appTheme from "../../../../../assets/appTheme";


const NFTListForCRMView = (props) => {
    const {
        isXSView, listingCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, transactions, NFTFilter,
        isShowLoader, getPaginationNextButtonStatus, skipCount, onChangeFilter, UIState,
        isMobileView, getCategory, dropdownValues, getAccountType, badgeData, isSMView, resetNFTListing, handleChange,
        selectedStatus, initialStatus
    } = props;

    const {keyword} = NFTFilter;
    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 my-2'}>
                    <DLButton
                        id={'create-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => {
                            props.history.push("/create-non-financial-transaction")
                        }}
                        startIcon={<i className="fa-solid fa-plus"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center pe-2 my-2' + (isMobileView ? ' page-container' : ' mx-4')}>
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => {
                        props.history.push("/create-non-financial-transaction")
                    }}
                    label={"Raise a Request"}/>
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div
                className={isXSView ? 'd-flex justify-content-between pb-3 pt-3' : 'd-flex justify-content-between pb-1 pt-3'}>
                <div
                    className={isXSView ? 'ps-3 page-header p-0' : 'page-header d-flex justify-content-between py-0'}>
                    <div className="d-flex align-items-top">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{
                               width: 10,
                               paddingTop: "19px"
                           }}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Non-Financial Transactions
                        </div>
                    </div>
                </div>
                <div>
                    {renderHeaderCreateButton()}
                </div>
            </div>
        )
    };
    const isFiltersApplied = (filters) => {

        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (key === 'status' && filters[key][0] === 'NOT_CONTACTED') {
                        return false
                    }
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '') {
                    return true;
                }
            }
        }
        return false;
    }

    const renderFilterIcon = () => {
        const {isShowFilterMobile, NFTFilter} = props;
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(NFTFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter} = props;

        return <div>
            <div className='d-flex align-items-center justify-content-between gap-3 page-container '>
                <div className='search-input' style={{flex: 1}}>
                    <DLInputTextField id={'NFT-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      onKeyPress={handleKeyPress}
                                      placeholder={'Search by Name or IIN'}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}
                    />
                </div>
                <div className="d-flex align-items-center" style={{marginBottom: '5px'}}>
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>
                    {isFiltersApplied(NFTFilter) &&
                        <div>
                            <i className="fa-solid fa-xmark cursor ms-2 "
                               onClick={() => resetNFTListing()}
                               style={{fontSize: 18}}/>
                        </div>
                    }
                </div>
            </div>
            {isShowFilterMobile &&
                <div className='bg-light mt-2 px-3' style={{paddingTop: 10, paddingBottom: 10}}>
                    <DLText
                        id={'status'}
                        text={'Status'}
                        fontColor={'grayDark'}
                    />
                    <MultiselectDropdown
                        statusFilterOptions={statusFilterOptions}
                        isXSView={isXSView}
                        onStatusChange={(selectedStatus) => onChangeFilter('status', selectedStatus)}
                        handleChange={handleChange}
                        selectedStatus={selectedStatus}
                        initialStatus={initialStatus}
                    />
                    {!isEmpty(badgeData.data) && <div className='d-inline-block pt-2 mt-1 mb-1'>
                        <FilterBadgeView
                            badgeData={badgeData}
                            onClose={() => {
                                if (badgeData.filterKey === 'ageing') {
                                    onChangeFilter('fromDate', '');
                                    onChangeFilter('toDate', '');
                                    onChangeFilter('ageing', 'ALL');
                                } else {
                                    onChangeFilter(badgeData.filterKey, (badgeData.filterKey === 'title' ? '' : 'ALL'))
                                }
                                onChangeFilter('title', '')
                            }}
                        />
                    </div>}
                </div>
            }
        </div>
    };


    const getStatusUI = (status) => {
        const {getLabel} = props;

        if (isEmpty(status)) {
            return '-'
        }

        return (
            <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                        {renderTransactionStatusDot(status)}
                    </div>
                </div>
                {getLabel(statusFilterOptions, status)}
            </div>
        );
    };

    const renderFilter = () => {
        return (
            <div>
                <div className='d-flex'>
                    <div className='search-input' style={{flex: 1.8}}>
                        <DLInputTextField
                            id={'NFT-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={isEmpty(keyword) ? '' : keyword}
                            placeholder={'Search by Name or IIN'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='select-filter ms-3' style={{flex: 1}}>
                        <DLText
                            id={'status'}
                            text={'Status'}
                            fontColor={'grayDark'}
                        />
                        <MultiselectDropdown
                            statusFilterOptions={statusFilterOptions}
                            isXSView={isXSView}
                            onStatusChange={(selectedStatus) => onChangeFilter('status', selectedStatus)}
                            handleChange={handleChange}
                            selectedStatus={selectedStatus}
                            initialStatus={initialStatus}
                        />
                    </div>
                    {!isEmpty(badgeData.data) && <div className='d-flex align-items-end ms-3 mb-1 pb-1'>
                        <FilterBadgeView
                            badgeData={badgeData}
                            onClose={() => {
                                if (badgeData.filterKey === 'ageing') {
                                    onChangeFilter('fromDate', '');
                                    onChangeFilter('toDate', '');
                                    onChangeFilter('ageing', 'ALL');
                                } else {
                                    onChangeFilter(badgeData.filterKey, (badgeData.filterKey === 'title' ? '' : 'ALL'))
                                }
                                onChangeFilter('title', '')
                            }}
                        />
                    </div>}
                </div>
            </div>
        );
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'}}>
            <div className='text-center'>
                <img src={emptyLeads} alt={''}/>
                <DLText id={'create-first-NFT'}
                        text={'Please create your first non financial transaction.'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => props.history.push("/create-non-financial-transaction")}
                    label={'Create Transaction'}/>
            </div>
        </div>
    };

    const renderMobileTable = () => {
        const {isShowFilterMobile, redirectToDetailsScreen} = props;
        let tableData = getFormattedTableData(transactions);

        if (isEmpty(transactions)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records bg-white'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100 pagination-container">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? !isEmpty(badgeData.data) ? 'calc(100dvh - 445px)' : 'calc(100dvh - 402px)' : 'calc(100dvh - 313px)'}
                    tableMinHeight={isShowFilterMobile ? !isEmpty(badgeData.data) ? 'calc(100dvh - 445px)' : 'calc(100dvh - 402px)' : 'calc(100dvh - 313px)'}
                    isRowClickable={true}
                    onRowClick={(id) => {
                        redirectToDetailsScreen(id)
                    }}
                    cellConfig={cellConfigForMobile}
                    headerData={[]}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const getNameUi = (accountHolders, corporate, accountType, IINNumber, category) => {
        const clientCategory = getCategory(category) !== '-' ? (' - ' + getCategory(category)) : '';
        const clientAccountType = isXSView ? getAccountType(accountType) + " A/c" : getAccountType(accountType) + " Account";
        return (
            <div className={isXSView ? '' : 'py-1'} style={{width: isSMView ? "250px" : "350px"}}>
                {(isCorporateUser(accountType)) ?
                    <DLText id={''}
                            text={corporate.name}
                            fontSize={"xs"}
                            fontWeight={"normal"}
                    />
                    :
                    renderAccountHolders(accountHolders, accountType)}
                <DLText id={''}
                        text={clientAccountType + ' - '}
                        fontWeight={"normal"}
                        isInline={true}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
                <DLText id={''}
                        text={IINNumber}
                        fontWeight={"normal"}
                        isInline={true}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
                {isXSView &&
                    <span
                        id={''}
                        style={{
                            fontWeight: "normal",
                            display: "inline",
                            fontSize: "12px",
                            color: appTheme.appColor.grayDark,
                        }}
                    >
                        {clientCategory}
                    </span>
                }
            </div>
        )
    }

    const renderAccountHolders = (accountHolders, accountType) => {
        let name = ''
        if (isEmpty(accountHolders)) {
            return '-';
        }
        accountHolders.map((item, index) => {
            if (accountType !== 'MINOR') {
                if (index <= (accountHolders?.length - 2)) {
                    name = name + item?.fullName + ', '
                } else {
                    name = name + item?.fullName
                }
            } else {
                if (item?.holder_type === 'PRIMARY_HOLDER_MINOR') {
                    name = name + item?.fullName
                }
            }
        })

        return (
            <DLText id={''}
                    text={name}
                    fontSize={"xs"}
                    fontWeight={"normal"}
            />
        );
    }

    const renderCount = () => {
        const {isXSView, handleRefreshButton} = props;

        if (isXSView) {
            return (
                <div className='page-container mt-3 border-bottom'>
                    {!isEmpty(listingCount) &&
                        <div className='d-flex align-items-center pb-3'>
                            <DLText id={''}
                                    isInline={true}
                                    fontColor={'grayDark'}
                                    fontSize={'sm'}
                                    marginBottom={'none'}
                                    fontWeight={'semi-bold'}
                                    text={'Showing ' + (listingCount) + ' records'}/>
                            <div className={'d-flex align-items-center mx-2'}
                                 onClick={() => {
                                     handleRefreshButton()
                                 }}>
                                <i className="fa-solid fa-rotate-right cursor"/>
                            </div>
                        </div>
                    }
                </div>)
        }
        return (
            <div className={isMobileView ? 'py-3' : 'pb-3'} style={{height: '37px'}}>
                {!isEmpty(listingCount) &&
                    <div className={'d-flex align-items-center '}>
                        <DLText id={''}
                                isInline={true}
                                fontColor={'grayDark'}
                                fontSize={'sm'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + (listingCount) + ' records'}/>
                        <div className={'d-flex align-items-center mx-2'}
                             onClick={() => {
                                 handleRefreshButton()
                             }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                        </div>
                    </div>}
            </div>
        )
    };

    const getNFTListingMobile = (selectedTransaction) => {
        const {getLabel} = props;
        return (
            <div style={{width: '100%'}} className="p-3 ">
                {getNameUi(selectedTransaction?.accountHolders, selectedTransaction?.corporate,
                    selectedTransaction?.accountType, selectedTransaction?.IINNumber, selectedTransaction?.category)}
                <div className="row mt-4">
                    <div className="col-6">
                        <DLText
                            id="ticket-no"
                            text="Ticket No."
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <DLText
                            id="ticket-number"
                            text={selectedTransaction?.ticketNumber}
                            fontSize="xs"
                            marginBottom="none"
                        />
                    </div>
                    <div className="col-6">
                        <DLText
                            id="type-of-request"
                            text="Type of Request"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <div>
                            {getLabel(dropdownValues?.typeOfRequestFilterOptions, selectedTransaction?.nftRequestType)}
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6">
                        <DLText
                            id="open-date"
                            text="Open Date"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <DLText
                            id="funnel-chart-label-right"
                            text={convertUTCToLocalTime(selectedTransaction?.openDate, 'DD-MM-YYYY')}
                            fontSize="xs"
                            marginBottom="none"
                        />
                    </div>
                    <div className="col-6">
                        <DLText
                            id="status"
                            text="Status"
                            fontColor="grayDark"
                            fontSize="xxs"
                            marginBottom="none"
                        />
                        <DLText
                            id="status"
                            text={getStatusUI(selectedTransaction?.status)}
                            fontSize="xs"
                            marginBottom="none"
                        />
                    </div>
                </div>
            </div>
        )
    }

    const getFormattedTableData = (transactionData) => {
        let tableData = [];
        const {isXSView, getLabel} = props;
        if (isEmpty(transactionData)) {
            return tableData
        }

        transactionData.map((selectedTransaction) => {
            let transactionRecords = [];
            let transactionDetails = {};
            if (!isXSView) {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: getNameUi(selectedTransaction?.accountHolders, selectedTransaction?.corporate,
                        selectedTransaction?.accountType, selectedTransaction?.IINNumber)
                });
                transactionRecords.push(getCategory(selectedTransaction?.category));
                transactionRecords.push(selectedTransaction?.ticketNumber);
                transactionRecords.push(getLabel(dropdownValues?.typeOfRequestFilterOptions, selectedTransaction?.nftRequestType));
                transactionRecords.push(convertUTCToLocalTime(selectedTransaction?.openDate, 'DD-MM-YYYY'));
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: getStatusUI(selectedTransaction?.status)
                });
            } else {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: getNFTListingMobile(selectedTransaction)
                });
            }

            transactionDetails.id = selectedTransaction?.clientRequestId;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });

        return tableData

    };

    const renderDesktopTable = () => {
        const {isShowFilterMobile, redirectToDetailsScreen} = props;
        let tableData = getFormattedTableData(transactions);


        if (isEmpty(transactions)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{minHeight: 'calc(100dvh - 278px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    isRowClickable={true}
                    onRowClick={(id) => {
                        redirectToDetailsScreen(id)
                    }}
                    cellConfig={cellConfig}
                    headerData={headingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderNFTTable = () => {
        return (
            <div className={'w-100 mt-3' + ((isMobileView && !isXSView) ? '  page-container' : '')}>
                {renderCount()}
                {isXSView ?
                    renderMobileTable()
                    :
                    <div>
                        {renderDesktopTable()}
                    </div>
                }
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100 bg-white'} style={{
                    background: 'rgba(0, 0, 0, 0.02)'
                }}>
                    {renderHeader()}
                    {renderFilterMobile()}
                    {renderNFTTable()}
                    {renderLoader()}
                </div>
            </div>
        );
    }

    return (
        <div className={'h-100 d-flex justify-content-center bg-light'}>
            <div className={'w-100'}>
                {renderHeader()}
                <div className='page-container pt-1'>
                    {renderFilter()}
                </div>
                <div className='page-container'
                     style={{minHeight: 'calc(100dvh - 205px)'}}>
                    {renderNFTTable()}
                </div>
                {renderLoader()}
            </div>
        </div>
    );
};

export default NFTListForCRMView;