import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLDatePickerView from './DLDatePickerView';

class DLDatePicker extends Component {
  render() {

    if(this.props.hidden){
      return null;
    }

    return (
        <>
          <DLDatePickerView
              {...this.props}
          />
        </>
    );
  }
}

DLDatePicker.propTypes = {
  id: PropTypes.string.isRequired,  // for testing purpose
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isClearable: PropTypes.bool,
  value: PropTypes.any, // should be the object of moment component
  onChangeValue: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperMessage: PropTypes.string,
  disableHelperMessageHeight: PropTypes.bool,
  showCalenderIcon: PropTypes.bool,
  minimumDate: PropTypes.any,
  maximumDate: PropTypes.object,
  isDisabled: PropTypes.bool,
  hidden: PropTypes.bool,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  minWidth: PropTypes.string,
  labelFontSize: PropTypes.string,
  labelFontColor: PropTypes.oneOf([
    'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
  ]),
  bgColor: PropTypes.oneOf([
    'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
  ]),
};

DLDatePicker.defaultProps = {
  isRequired: false,
  showLabelOnTop: false,
  label: '',
  isClearable: true,
  error: false,
  helperMessage: '',
  isDisabled: false,
  hidden: false,
  value: null,
  disableHelperMessageHeight: false,
  format: 'dd-MM-yyyy',
  labelFontSize:'xs',
  labelFontColor: 'grayDark',
  bgColor: 'white'
};


export default DLDatePicker;
