import React, {useEffect} from 'react';

import DLText from "../../../core/components/DLText/DLText";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import {isEmpty} from "../../../utils/validations";
import {debounce, getRandomId} from "../../../core/components/DLComponentHelper";
import DLButton from "../../../core/components/DLButton/DLButton";
import {isCorporateUser} from "../../../utils/helper";
import DLSearchDropdown from "../../../core/components/DLSearchDropdown/DLSearchDropdown";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const RaiseRequestView = (props) => {
    const {
        isXSView,
        isOptionSelected,
        errors,
        handleSearch,
        clients,
        getAccountType,
        handleClick,
        accountHolders,
        accountType,
        IINNumber,
        category,
        setCategoryValues,
        handleChangeClient,
        handleSubmit,
        capitalizeFirstLetter,
        companyName,
        modalErrorMessage,
        scrollToFieldId,
        onChangeFormData,
        onBlurInputText,
        isShowInlineLoader,
        isSubmitApiInProgress
    } = props;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [props.scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        props.onChangeFormData('scrollToFieldId', '');
    }, 500);

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div
                    className={isXSView ? 'ps-3 page-header p-0 bg-white' : 'page-header d-flex justify-content-between'}>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Raise a Request
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const renderAccountHolders = (accountHolders) => {
        if (isEmpty(accountHolders)) {
            return '-';
        }
        return (
            <div className='d-flex'>
                {
                    accountHolders.map((item, index) => {
                        if (item?.accountHolderType === "GUARDIAN") {
                            return <></>;
                        }
                        return (
                            <div className='d-flex' key={getRandomId()}>
                                <div>
                                    <DLText id={''}
                                            text={capitalizeFirstLetter(item?.fullName)}
                                            fontWeight={"bold"}
                                            isInline={true}
                                            fontSize={"xs"}
                                            fontColor={"grayDark"}
                                    />
                                </div>
                                {index <= (accountHolders?.length - 2) && item?.accountHolderType !== 'PRIMARY_HOLDER_MINOR' &&
                                <div className='me-1'>
                                    <DLText id={''}
                                            text={','}
                                            isInline={true}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                            fontColor={"grayDark"}
                                    />
                                </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const renderCompanyName = (companyName) => {
        if (isEmpty(companyName)) {
            return '-';
        }
        return (
            <div>
                <DLText id={''}
                        text={companyName}
                        fontWeight={"bold"}
                        isInline={true}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
            </div>
        )

    }

    const getUi = (id) => {
        const item = clients.find(element => element?.accountId === id);
        if (!item) {
            return null;
        }
        return (
            <div>
                {isCorporateUser(item?.accountType)
                    ? renderCompanyName(item?.corporate?.name)
                    : renderAccountHolders(item?.accountHolders)}
                <div>
                    <DLText
                        id={""}
                        text={getAccountType(item?.accountType) + ' - '}
                        fontWeight="normal"
                        fontSize="xs"
                        isInline={true}
                        marginBottom="none"
                    />
                    <DLText
                        id={""}
                        text={item?.IINNumber || ""}
                        fontWeight="normal"
                        fontSize="xs"
                        isInline={true}
                        marginBottom="none"
                    />
                </div>
            </div>
        );
    };


    const getId = (data) => {
        return data?.map(item => ({
            value: item?.accountId,
        }));
    }

    const renderSearchBar = () => {
        return (
            <div className='w-100'>
                <DLSearchDropdown
                    placeholder={"Search by Name or IIN"}
                    isShowInlineLoader={isShowInlineLoader}
                    onChangeData={e => handleSearch(e)}
                    helperMessage={'Please select client'}
                    error={errors.IINNumber !== ''}
                    disableHelperMessageHeight={true}
                    data={getId(clients)}
                    renderCustomItemUI={(id) => {
                        return getUi(id.value)
                    }}
                    onClickItem={(id) => handleClick(id)}
                    noMatchMessage={"No clients found."}
                />
            </div>
        )
    };

    const renderAccounts = () => {
        return (
            <div>
                <div className='row'>
                    <div className='col-12 mb-4' id={'name-field'}>
                        <div className='mb-4'>
                            <div className={'d-flex'}>
                                <div className='section-title-vertical-bar'/>
                                <div className='ps-3'>
                                    <div className='d-flex'>
                                        <DLText id={''}
                                                text={getAccountType(accountType) + '- '}
                                                fontSize={"md"}
                                                fontWeight={"semi-bold"}
                                                isInline={true}
                                        />
                                        <DLText id={''}
                                                text={IINNumber}
                                                fontSize={"md"}
                                                fontWeight={"semi-bold"}
                                                isInline={true}
                                        />
                                        <div className='ps-3 d-flex align-items-center justify-content-center'>
                                            <DLText id={'change-client'}
                                                    isClickable={true}
                                                    text={"Change Client"}
                                                    fontColor={"lightBlue"}
                                                    onClick={() => {
                                                        handleChangeClient()
                                                    }}
                                            />
                                        </div>
                                    </div>
                                    <DLText id={''}
                                            text={setCategoryValues(category)}
                                            fontSize={"sm"}
                                            fontWeight={"semi-bold"}
                                            fontColor={"grayDark"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </div>
                        </div>
                        {getAccountHoldersUi(accountHolders)}
                    </div>
                </div>
            </div>
        )
    }

    const getAccountHoldersUi = (accountHolders) =>{

        if (!isEmpty(companyName)) {
            return (
                <div className='mb-2 ps-3 ms-2'>
                    <DLText id={''}
                            text={companyName}
                            fontSize={"sm"}
                            isInline={true}
                    />
                </div>
            )
        }

        if(isEmpty(accountHolders)){
            return;
        }

        return(
            accountHolders.map((item, index) => {
                if (item?.accountHolderType === "GUARDIAN") {
                    return <></>;
                }
                return (
                    <div className='mb-2 ps-3 ms-2' key={index}>
                        {(accountType === "JOINT") ?
                            <DLText id={''}
                                    text={'Account Holder ' + (index + 1) + ' - '}
                                    fontSize={"sm"}
                                    fontWeight={"semi-bold"}
                                    isInline={true}
                            /> :
                            <DLText id={''}
                                    text={'Account Holder ' + ' - '}
                                    fontSize={"sm"}
                                    fontWeight={"semi-bold"}
                                    isInline={true}
                            />
                        }
                        <DLText id={''}
                                text={item?.fullName}
                                fontSize={"sm"}
                                isInline={true}
                        />
                    </div>
                )
            })
        )
    }

    const renderButton = () => {
        return (
            <div className="sticky-bottom-buttons gap-0">
                <DLButton
                    id={'raise-request-cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => props.history.push("/client-requests")}/>
                <DLButton
                    id={'create-lead-saveBtn'}
                    history={props.history}
                    label={'Submit'}
                    isDisabled={isSubmitApiInProgress}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleSubmit()}
                />
            </div>
        )
    };

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderRaiseRequestForm = () => {
        return (
            <div className="h-100">
                <div className="bg-white row border-rounded p-3 m-0 "
                     style={{maxHeight: 'calc(100dvh - 154px)', minHeight: 'calc(100dvh - 154px)', overflow: "auto"}}>
                    <div className="col-lg-6">
                        {renderModalError()}
                        <div className='row'
                             id={'name-field'}>
                            {!isOptionSelected && <div className='mb-3'>
                                <div className='col-12' id={'search-field'}>
                                    <DLText id={''}
                                            text={'Client'}
                                            fontColor={errors.IINNumber !== "" ? "danger" : "grayDark"}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                    />
                                    {renderSearchBar()}
                                </div>
                            </div>}
                            {isOptionSelected &&
                                <div>
                                    {renderAccounts()}
                                </div>}
                            <div>
                                <div className='col-sm-12 p-0 pt-2'
                                     id={'remark-field'}>
                                    <DLInputTextField
                                        id={'remark'}
                                        label={'Remark'}
                                        value={''}
                                        marginBottom={"none"}
                                        inputType={"text"}
                                        isRequired={true}
                                        isMulti={true}
                                        rows={5}
                                        maxRows={5}
                                        disableHelperMessageHeight={true}
                                        error={errors.remark !== ''}
                                        helperMessage={errors.remark !== '' && errors.remark}
                                        hideCharLength={true}
                                        isClearable={false}
                                        maxCharLength={501}
                                        onChangeValue={(value) => {
                                            onChangeFormData('remark', value)
                                        }}
                                        onBlur={() => {
                                            onBlurInputText('remark')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (isEmpty(isSubmitApiInProgress)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    const renderDesktopUI = () => {
        return (
            <div className={isXSView ? '' : 'lead-details-container '}>
                {renderDesktopHeader()}
                <div className={isXSView ? 'p-0' : 'lead-page-container page-container bg-light mx-3 '}>
                    {renderRaiseRequestForm()}
                </div>
                {renderButton()}
            </div>
        )
    }

    return (
        <div>
            {renderDesktopUI()}
        </div>
    );
};

export default RaiseRequestView;