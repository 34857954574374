import {isEmpty} from "../../../../utils/validations";
import React from "react";
import DLText from "../../../../core/components/DLText/DLText";

export const renderTransactionStatusDot = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'Processed by RTA' :
            return <span className="status-dot success"/>;

        case 'Rejected / Reversal':
            return <span className="status-dot danger"/>;

        default:
            return <span className="status-dot warning"/>;
    }
};

export const getTransactionStatus = (selectedStatus, transactionStatuses, isDesktopView, isXSView) => {
    if (isEmpty(selectedStatus) || isEmpty(transactionStatuses)) {
        return ''
    }
    const status = transactionStatuses.find(status => status.label === selectedStatus);
    if (status) {
        return (
            <div className={"d-flex"}>
                <div>
                    {renderTransactionStatusDot(status?.value)}
                </div>
                {isDesktopView && <div>
                    <DLText id={'transaction-status'}
                            isInline={true}
                            fontSize={isXSView ? 'xxs' : 'xs'}
                            fontWeight={'normal'}
                            text={status.label}/>
                </div>}
            </div>
        )
    } else {
        return '';
    }
}