import React from "react";

import {getRoundedvalue, numberWithCommas} from "../../../../../../utils/helper";
import {isEmpty} from "../../../../../../utils/validations";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../../core/components/DLButton/DLButton";

const TxnTableLumpsumView = (props) => {

    const {
        isMobileView,
        investmentDetails,
        txnStage,
        txnData,
        handleAddOrEditTransactionDetails,
        isManagementUser
    } = props;

    const handleAmount = (amount) => {
        if (isEmpty(amount)) {
            return "-";
        }
        return numberWithCommas(amount)
    }

    const renderEmptyUi = () => {
        return (
            <div>
                <DLText id={''}
                        text={'No schemes have been added.'}
                        fontWeight={'normal'}
                        fontSize={"sm"}
                />
                {!isManagementUser && <div className='d-flex'>
                    <DLButton id={'add-button'}
                              onClick={() => handleAddOrEditTransactionDetails("ADD")}
                              hidden={txnStage !== "DRAFT"}
                              label={'Add Scheme'}/>
                    <DLButton id={'select-button'}
                              onClick={() => handleAddOrEditTransactionDetails("EDIT")}
                              hidden={txnStage !== "DRAFT"}
                              label={'Select Portfolio'}/>
                </div>}
            </div>
        )
    }

    const renderInvestmentAmount = () => {
        return (
            <DLText
                id={'investment-amount-title'}
                type={'normal'}
                text={'Investment Amount : ' + handleAmount(txnData?.investmentAmount)}
                fontSize={isMobileView? 'md' : 'lg'}
                fontWeight={isMobileView? 'bold' : 'semi-bold'}
                fontColor={'black'}
                marginBottom={"none"}
            />
        )
    }

    const renderMobileInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded h-100">
                    <div className='p-3  black-border-bottom black-border-top'>
                        <div className='d-flex justify-content-between align-items-center'>
                            {renderInvestmentAmount()}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center black-border-bottom'>
                        <div className='text-center my-5'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-light border-rounded">
                <div>
                    <div
                        className='d-flex justify-content-between bg-white align-items-center p-3  black-border-bottom black-border-top'>
                        {renderInvestmentAmount()}
                        <DLButton
                            id={'edit-transaction'}
                            label={'Edit'}
                            hidden={txnStage !== "DRAFT" || isManagementUser}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                    </div>
                </div>
                <div>
                    <div className='p-0 w-100 h-100 bg-white'>
                        <div className="h-100 c">
                            {renderMobileData()}
                            {renderMobileTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderInvestmentDetails = () => {
        if (isEmpty(investmentDetails)) {
            return (
                <div className="h-100 bg-white border-rounded p-3 h-100 pb-5">
                    <div className='d-flex justify-content-between'>
                        <div>
                            {renderInvestmentAmount()}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center h-100 panel rounded mt-2 mb-4 border'>
                        <div className='text-center'>
                            {renderEmptyUi()}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3 ">
                <div className='d-flex justify-content-between'>
                    {renderInvestmentAmount()}
                    <DLButton
                        id={'edit-transaction'}
                        label={'Edit'}
                        hidden={txnStage !== "DRAFT" || isManagementUser}
                        buttonSize={"sm"}
                        fontSize={"sm"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => handleAddOrEditTransactionDetails("EDIT")}/>
                </div>
                <div className='pt-3'>
                    <div className='p-0 w-100 border-rounded panel h-100'>
                        <div className="p-2 h-100 c">
                            {renderHeadings()}
                            {renderData()}
                            {renderTotalCount()}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderHeadings = () => {
        return (
            <div className={"py-2 border-bottom row  px-0 m-0"}>
                <div className='data-card-label col-5'>
                    <DLText id={'schemes'}
                            text={"Schemes"}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-3'>
                    <DLText id={'folio-no'}
                            text={"Folio No"}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                            fontSize={"sm"}
                    />
                </div>
                <div className='data-card-label col-3'>
                    <div className={'text-end'} style={{maxWidth: "150px"}}>
                        <DLText id={'allocation'}
                                text={"Allocation"}
                                fontWeight={"semi-bold"}
                                marginBottom={"none"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
                <div className='data-card-label col-1 text-end'>
                    <DLText id={'amount'}
                            text={"Amount"}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderTotalCount = () => {
        return (
            <div className={"py-2 border-top row px-0 m-0"}>
                <div className='data-card-label col-5'/>
                <div className='data-card-label col-3'/>
                <div className='data-card-label col-3'>
                    <div className={'text-end'} style={{maxWidth: "150px"}}>
                        <DLText id={'total-percentage'}
                                text={isEmpty(txnData?.totalAllocationPercentage) ? "-" : getRoundedvalue(txnData?.totalAllocationPercentage) + "%"}
                                fontWeight={"semi-bold"}
                                marginBottom={"none"}
                                fontSize={"sm"}
                        />
                    </div>
                </div>
                <div className='data-card-label col-1 text-end'>
                    <DLText id={'total-allocation'}
                            text={isEmpty(txnData?.totalAllocationAmount) ? "-" : handleAmount(txnData?.totalAllocationAmount)}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                            fontSize={"sm"}
                    />
                </div>
            </div>
        )
    };

    const renderData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"py-2 row px-0 m-0"}
                                 key={index}>
                                <div className='data-card-label col-5'>
                                    <DLText id={''}
                                            text={item?.productName}
                                            fontWeight={"semi-bold"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                <div className='data-card-label col-3'>
                                    <div className='d-flex align-items-center h-100'>
                                        <DLText id={''}
                                                text={isEmpty(item?.folioNumber) ? "Create new folio" : item?.folioNumber}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                                <div className='data-card-label col-3'>
                                    <div className={'text-end h-100'} style={{maxWidth: "150px"}}>
                                        <div className='d-flex align-items-center justify-content-end h-100'>
                                            <DLText id={''}
                                                    text={item?.allocation + "%"}
                                                    fontWeight={"normal"}
                                                    fontSize={"xs"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='data-card-label col-1'>
                                    <div className='d-flex align-items-center justify-content-end h-100'>
                                        <DLText id={''}
                                                text={handleAmount(item?.amount)}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderMobileData = () => {
        return (
            <div>
                {investmentDetails.map((item, index) => {
                    return (
                        <div key={index} className={investmentDetails.length - 1 === index ? "" : "border-bottom"}>
                            <div className={"p-3 m-0"}
                                 key={index}>
                                <div className='data-card-label pb-2'>
                                    <DLText id={''}
                                            text={item?.productName}
                                            fontColor={'black'}
                                            fontWeight={"semi-bold"}
                                            fontSize={"sm"}
                                    />
                                </div>
                                <div className='row'>
                                    <div className='data-card-label col-5'>
                                        <div className='d-flex align-items-center h-100'>
                                            <div>
                                                <DLText id={''}
                                                        text={'Folio No'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        isInline={false}
                                                        marginBottom={"none"}
                                                />
                                                <DLText id={''}
                                                        text={isEmpty(item?.folioNumber) ? "Create new folio" : item?.folioNumber}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        isInline={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-4'>
                                        <div className='d-flex align-items-center justify-content-start h-100'>
                                            <div>
                                                <DLText id={''}
                                                        text={'Amount'}
                                                        fontColor={'grayDark'}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                        marginBottom={"none"}
                                                        isInline={false}
                                                />
                                                <DLText id={''}
                                                        text={handleAmount(item?.amount)}
                                                        fontWeight={"normal"}
                                                        fontSize={"xs"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data-card-label col-3'>
                                        <div className={'text-start'} style={{maxWidth: "150px"}}>
                                            <div className='d-flex align-items-center justify-content-start h-100'>
                                                <div>
                                                    <DLText id={''}
                                                            text={'Allocation'}
                                                            fontColor={'grayDark'}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                            marginBottom={"none"}
                                                            isInline={false}
                                                    />
                                                    <DLText id={''}
                                                            text={item?.allocation + "%"}
                                                            fontWeight={"normal"}
                                                            fontSize={"xs"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    };

    const renderMobileTotalCount = () => {
        return (
            <div className={"py-3 black-border-bottom row px-0 m-0 bg-white black-border-top"}>
                <div className='data-card-label col-5'/>
                <div className='data-card-label col-4 d-flex align-items-center justify-content-start'>
                    <div className={'text-start'}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                text={isEmpty(txnData?.totalAllocationAmount) ? "-" : handleAmount(txnData?.totalAllocationAmount)}
                        />
                    </div>
                </div>
                <div className='data-card-label col-3 d-flex align-items-center justify-content-start'>
                    <div className={'text-start'} style={{maxWidth: "150px"}}>
                        <DLText id={''}
                                fontWeight={"bold"}
                                text={isEmpty(txnData?.totalAllocationPercentage) ? "-" : getRoundedvalue(txnData?.totalAllocationPercentage) + "%"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className={'h-100'}>
            {isMobileView ? renderMobileInvestmentDetails() : renderInvestmentDetails()}
        </div>
    );
};


export default TxnTableLumpsumView;
