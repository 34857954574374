import React, {useState} from 'react';
import {connect} from "react-redux";

import {sendToManagement} from "../../../../FTAction";

import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";

import SendToManagementView from "./SendToManagementView";


const SendToManagement = (props) => {

    const {txnRequestId, handleCloseSendToManagementModal, onSaveSuccess, ticketNumber} = props;
    const [sendToManagementState, setSendToManagementState] = useState({
        isApiInProgress: false,
        handleErrorInComponent: true,
        modalErrorMessage: ''
    });

    const handleSendToManagement = () => {

        let payload = {
            txnRequestId: txnRequestId
        }

        setSendToManagementState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        sendToManagement(payload)
            .then((res) => {
                    if (res.success) {
                        store.dispatch(showToastMessage('success', 'Financial Transaction: '+ ticketNumber +' is  sent to management for approval.'))
                        setSendToManagementState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        onSaveSuccess('isSendToManagementModalOpen',false);
                    } else {
                        setSendToManagementState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res.__error
                        }));
                    }
                }
            );
    };

    return (
        <SendToManagementView
            {...props}
            {...sendToManagementState}
            handleSendToManagement={handleSendToManagement}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(SendToManagement);
