import React from "react";

import TransactionVerticalBarChart from "../OverviewBarChart";

import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const OverviewBarChartUIView = (props) => {
    const {isXSView, isSMView, isMDView, xAxis, yAxis, checkIfChartDataAvailable, isShowLoader, isMobileView} = props;

    const getBarChartValues = () => {
        let data = {
            name: xAxis,
            data: yAxis,
            emphasis: {
                focus: 'series'
            },
            barGap: 0.2,
            barWidth: '12px',
            itemStyle: {
                color: '#0073FF',
            },
            type: 'bar',
        };
        return data;
    };

    return (
        <div className={(isMDView ? 'border rounded' : (isXSView || isSMView) ? '' : 'border rounded me-3') + ' h-100'}>
            <div
                className={"px-3 d-flex justify-content-between rounded-top " + ((isXSView || isSMView) ? "py-3" : "py-2 border-bottom")}>
                <div className='d-flex align-items-center'>
                    <DLText
                        id={'title-name'}
                        text={"Total Business"}
                        isInline={true}
                        fontSize={'md'}
                        marginBottom={'none'}
                        fontWeight={'bold'}
                    />
                </div>
            </div>
            {isShowLoader ?
                <div className={(isMobileView? '' : 'pb-5') + ' h-100 '}>
                    <div className='d-flex align-items-center justify-content-center h-100'
                         style={{minHeight: isMDView? 'calc(100dvh - 450px)' : isXSView ? 'calc(100dvh - 484px)' : isSMView ? 'calc(100dvh - 408px)' : ''}}>
                        <DLLoader type={"inline"} isVisible={isShowLoader}/>
                    </div>
                </div>
                : !checkIfChartDataAvailable() ?
                    (
                        <div className={(isMobileView ? '' : 'pb-5') + ' h-100 '}>
                            <div
                                className='d-flex justify-content-center align-items-center h-100'
                                style={{minHeight: isMDView? 'calc(100dvh - 450px)' : isXSView ? 'calc(100dvh - 484px)' : isSMView ? 'calc(100dvh - 408px)' : '' }}>
                                No data found.
                            </div>
                        </div>
                    ) :
                    <div className='h-100 pb-5'>
                        < TransactionVerticalBarChart
                            {...props}
                            id={"total-overviewTotalBusiness-line-overviewChart"}
                            series={getBarChartValues(yAxis)}
                            xAxislabels={xAxis}
                            showDownloadIcon={false}
                            yAxisPosition={"left"}
                            isBarChartClickable={true}
                        />
                    </div>
            }
        </div>

    )
};

export default OverviewBarChartUIView;
