import React from 'react';
import {connect} from "react-redux";

import OverviewTotalBusinessView from "./OverviewTotalBusinessView";

const OverviewTotalBusiness = (props) => {
    return (
        <OverviewTotalBusinessView {...props} />
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(OverviewTotalBusiness);