import React from 'react';

import ReactECharts from 'echarts-for-react' ;
import appTheme from "../../assets/appTheme";
import {isEmpty} from "../../utils/validations";
import {connect} from "react-redux";
import {convertAmountInLakhAndCroreForChart} from "../../utils/helper";

function OverviewBarChart(props) {
    const {
        series,
        xAxislabels,
        isXSView,
        id,
        yAxisPosition,
        isMDView,
        isLGView,
        isSMView
    } = props;

    const {OverviewFilter, isChartClickable, handleChartClick} = props;
    const {OverviewInterval} = OverviewFilter;

    const getBaseLog = (x, y) => {
        return Math.log(y) / Math.log(x);
    };

    const getRoundedStepSize = (value) => {
        if (!value || value <= 0) return 1;
        let log = getBaseLog(10, value);
        log = Math.floor(log);

        if (log >= 5) {
            return Math.ceil(value / 100000) * 100000;
        } else if (log === 4) {
            return Math.ceil(value / 10000) * 10000;
        } else if (log === 3) {
            return Math.ceil(value / 1000) * 1000;
        } else if (log === 2) {
            return Math.ceil(value / 100) * 100;
        } else if (log === 1) {
            return Math.ceil(value / 10) * 10;
        } else {
            return Math.ceil(value);
        }
    };

    const getMaxRoundedNumber = (value) => {
        if (!value || value <= 0) return 0;
        let log = getBaseLog(10, value);
        log = Math.floor(log);

        if (log >= 6) {
            return Math.ceil(value / 100000) * 100000;
        } else if (log === 5) {
            return Math.ceil(value / 10000) * 10000;
        } else if (log === 4) {
            return Math.ceil(value / 1000) * 1000;
        } else if (log === 3) {
            return Math.ceil(value / 100) * 100;
        } else if (log === 2) {
            return Math.ceil(value / 10) * 10;
        } else {
            return Math.ceil(value);
        }
    };

    const numericNumbers = series?.data.map(Number);
    let maxValue = Math.max(...numericNumbers);
    let minValue = Math.min(...numericNumbers);
    let stepSize;
    let steps = 5;

    if (minValue >= 0 && maxValue > 0) {
        minValue = 0;
        if (maxValue < 10) {
            maxValue = 10;
        } else {
            maxValue = getMaxRoundedNumber(Math.trunc(maxValue));
        }

        stepSize = maxValue / steps;
        stepSize = getRoundedStepSize(stepSize);

        maxValue = stepSize * steps;
    }

    if (minValue < 0 && maxValue > 0) {
        const adjustedMaxValue = getMaxRoundedNumber(maxValue);
        const adjustedMinValue = Math.floor(minValue / adjustedMaxValue) * adjustedMaxValue;

        stepSize = (adjustedMaxValue - adjustedMinValue) / steps;
        stepSize = getRoundedStepSize(stepSize);

        maxValue = Math.ceil(maxValue / stepSize) * stepSize;
        minValue = Math.floor(minValue / stepSize) * stepSize;
    }

    if (minValue < 0 && maxValue <= 0) {
        let newMaxValue = Math.abs(minValue);
        if (newMaxValue < 10) {
            newMaxValue = 10;
        } else {
            newMaxValue = getMaxRoundedNumber(newMaxValue);
        }

        stepSize = newMaxValue / steps;
        stepSize = getRoundedStepSize(stepSize);

        maxValue = 0;
        minValue = Math.floor(minValue / stepSize) * stepSize;
    }

    if (!stepSize || stepSize < 1) {
        stepSize = 1;
    }

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' },
            formatter: (params) => {
                let label = params[0]?.name || '';
                const value = params[0]?.value?.toLocaleString() || '0';
                return `${label}: ${convertAmountInLakhAndCroreForChart(params[0].value)}`;
            }
        },
        legend: {
            show: false
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            right: 0,
            top: 0
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: xAxislabels,
                name: OverviewInterval === 'THIS_MONTH' ? 'Dates' : OverviewInterval === 'THIS_WEEK' ? 'Days' : 'Months',
                nameLocation: 'middle',
                nameGap: 50,
                nameTextStyle: {
                    color: appTheme.appColor.black,
                    fontFamily: appTheme.default.fontFamily
                },
                axisLabel: {
                    interval: (isXSView || isSMView && !isMDView)  && OverviewInterval === 'THIS_MONTH' ? 6 : 0,
                    rotate: 45,
                    fontSize: isXSView || isSMView ? 10 : 12
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                min: minValue,
                max: maxValue,
                name: 'Amount',
                nameLocation: 'middle',
                nameGap: 50,
                nameTextStyle: {
                    color: appTheme.appColor.black,
                    fontFamily: appTheme.default.fontFamily
                },
                splitNumber: steps,
                boundaryGap: false,
                interval: stepSize,
                position: isEmpty(yAxisPosition) ? 'left' : yAxisPosition,
                axisLabel: {
                    formatter: (value) => convertAmountInLakhAndCroreForChart(value),
                    textStyle: {
                        fontSize: 10
                    }
                }
            }
        ],
        grid: {
            top: 30,
            left: 80,
            right: (isXSView || isSMView)  ? 10 : 20,
            bottom: isLGView ? '60' : isMDView ? 100 : (isXSView || isSMView)  ? 100 : 100,
        },
        series: [
            {
                type: 'bar',
                data: series.data,
                barWidth: 7,
                itemStyle: {
                    color: (params) => (params.value < 0 ? '#FF9494' : '#0073FF') // Red for negative values, blue for positive
                }
            }
        ],
        roam: false,
        zoom: null
    };

    const onChartClick = (params) => {
        if(isChartClickable){
            handleChartClick(params);
        }
    };

    const onEvents = {
        click: onChartClick,
    };

    const getHeight = () => props.height || ((isXSView || isSMView)  ? '245px' : isMDView ? "280px" : "159px");

    return (
        <div id={id} className='' style={{width: '100%', height: getHeight()}}>
            <ReactECharts option={option}
                          style={{height: isLGView ? '220px' : (isXSView || isSMView) ? '320px' : isMDView ? '355px' : '253px'}}
                          onEvents={onEvents}
            />
        </div>
    );
}


const mapStateToProps = (state) => ({
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    OverviewFilter: state.OverviewState.OverviewFilter
});

export default connect(mapStateToProps, {})(OverviewBarChart);