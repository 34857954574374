import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../AppComponents/AppConstants";
import store from "../../../../store";

import {showToastMessage} from "../../../../AppComponents/AppActions";
import {
    clientsListing,
    raiseNFTRequest
} from "../../NFTAction";
import {getCategoryOptions, getClientAccountTypeOptions as getClientAccountTypes, getTypeOfRequestFilterOptions} from "../../../../AppComponents/MetadataActions";

import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import NFTCreateView from "./NFTCreateView";

const NFTCreate = (props) => {

        const [NFTCreateState, setNFTCreateState] = useState({
                requestType: '',
                name: '',
                phoneNumber: '',
                email: '',
                category: '',
                accountHolders: [],
                accountType: '',
                IINNumber: '',
                comment: '',
                clients: [],
                keyword: '',
                isOptionSelected: false,
                isApiInProgress: false,
                handleErrorInComponent: true,
                modalErrorMessage: '',
                scrollToFieldId: '',
                UIState: '',
                categoryType : '',
                companyName:'',
                clientId: '',
                isShowInlineLoader: false,
                errors: {
                    name: '',
                    phoneNumber: '',
                    email: '',
                    category: '',
                    comment: '',
                    requestType: '',
                    IINNumber: '',
                },
                dropdownValues: {
                    categoryOptions: [],
                    NFTRequestOptions: [],
                    accountTypeOptions: []
                },
                isOpenCancelConfirmModal : false
            }
        );

        useEffect(() => {
            getCategoryValues();
        }, []);

        const handleSearch = (keyword) => {

            const payload = {
                keyword: keyword,
            };

            setNFTCreateState(prevStep => ({
                ...prevStep,
                keyword: keyword,
                isOptionSelected: false,
                isShowInlineLoader: true,
            }));

            if (isEmpty(keyword)) {
                return;
            }

            clientsListing(payload)
                .then((res) => {
                        if (res.success) {
                            setNFTCreateState((prevStep) => ({
                                ...prevStep,
                                clients: res?.data,
                                isShowInlineLoader: false
                            }));
                        } else {
                            setNFTCreateState((prevStep) => ({
                                ...prevStep,
                                clients: [],
                                isShowInlineLoader: false
                            }));
                        }
                    }
                );
        };

        const handleClick = (id) => {
            const {clients} = NFTCreateState;
            let data = clients.find(item => (item.accountId === id) ? item : '')
            let mappedState = getMappedData(data)
            setNFTCreateState(prevStep => ({
                ...prevStep,
                ...mappedState,
                keyword: '',
                isOptionSelected: true,
                categoryType:data?.accountType,
                clientId: data?.accountId
            }));

        };

        const getMappedData = (response) => {
            const {errors} = NFTCreateState;
            if (isEmpty(response) || response?.length === 0) {
                return {...NFTCreateState}
            }
            return {
                name: isEmpty(response?.fullName) ? '' : response?.fullName,
                phoneNumber: isEmpty(response?.phoneNumber) ? '' : response?.phoneNumber,
                email: isEmpty(response?.email) ? '' : response?.email,
                category: isEmpty(response?.category) ? '' : response?.category,
                accountHolders: isEmpty(response?.accountHolders) ? '' : response?.accountHolders,
                companyName: isEmpty(response?.corporate?.name) ? '' : response?.corporate?.name,
                accountType: isEmpty(response?.accountType) ? '' : response?.accountType,
                IINNumber: isEmpty(response?.IINNumber) ? '' : response?.IINNumber,

                errors: {
                    ...errors,
                    name: !isEmpty(response?.fullName) ? '' : errors?.fullName,
                    phoneNumber: !isEmpty(response?.phoneNumber) ? '' : errors?.phoneNumber,
                    email: !isEmpty(response?.email) ? '' : errors?.email,
                    category: !isEmpty(response?.category) ? '' : errors?.category,
                    IINNumber: !isEmpty(response?.IINNumber) ? '' : errors?.IINNumber
                },
            };
        };

        const setCategoryValues = (value) => {
            const {dropdownValues} = NFTCreateState;
            const {categoryOptions} = dropdownValues;
            const option = categoryOptions.find((item) => item.value === value);
            return option?.label;
        }

        const getCategoryValues = () => {

            setNFTCreateState(prevState => ({
                ...prevState,
                isLoaderVisible: true
            }));

            getCategoryOptions()
                .then(res => {
                    if (res?.success) {

                        getClientAccountTypes()
                            .then(accountTypeRes => {

                                if (accountTypeRes?.success) {
                                    getTypeOfRequestFilterOptions()
                                        .then(reqTypeRes => {
                                            if (reqTypeRes?.success) {
                                                setNFTCreateState(prevState => ({
                                                    ...prevState,
                                                    dropdownValues: {
                                                        ...prevState.dropdownValues,
                                                        categoryOptions: setValuesForDropdown(res.data?.categoryType, "name", "id"),
                                                        NFTRequestOptions: setValuesForDropdown(reqTypeRes.data?.nftRequestTypes, "name", "id"),
                                                        accountTypeOptions: setValuesForDropdown(accountTypeRes.data?.accountType, "name", "id")
                                                    },
                                                    UIState: APP_CONST.CONTENT_AVAILABLE
                                                }));
                                            } else {
                                                setNFTCreateState(prevState => ({
                                                    ...prevState,
                                                    isLoaderVisible: false,
                                                    UIState: APP_CONST.CONTENT_UNAVAILABLE
                                                }));
                                            }

                                        })
                                }
                            })
                    } else {
                        setNFTCreateState(prevState => ({
                            ...prevState,
                            isLoaderVisible: false,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE
                        }));
                    }
                })
        };

        const getAccountType = (value) => {
            const {dropdownValues} = NFTCreateState;
            const {accountTypeOptions} = dropdownValues;
            const accountType = accountTypeOptions.find((item) => item?.value === value);
            return accountType?.label + " Account";
        };

        const setValuesForDropdown = (data, labelToken, valueToken) => {
            if (isEmpty(data)) {
                return;
            }

            return data.map((item) => {
                return {label: item?.[labelToken], value: item?.[valueToken]}
            });
        };

        const validateChanges = (title, compState) => {
            const {comment, category, requestType, IINNumber} = compState;
            let errorObj = {...NFTCreateState.errors};

            if (title === "requestType" || title === APP_CONST.FORM_SUBMISSION) {

                if (isEmpty(requestType)) {
                    errorObj.requestType = "Please select request type";
                } else {
                    errorObj.requestType = "";
                }

                if (title === 'requestType') {
                    return errorObj;
                }
            }

            if (title === "comment" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(comment)) {
                    errorObj.comment = "Please enter comment";
                } else {
                    errorObj.comment = "";
                }

                if (title === 'comment') {
                    return errorObj;
                }
            }

            if (title === "category" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(category)) {
                    errorObj.category = "Please select category";
                } else {
                    errorObj.category = "";
                }

                if (title === 'category') {
                    return errorObj;
                }
            }
            if (title === "IINNumber" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(IINNumber)) {
                    errorObj.IINNumber = "Please select IIN Number";
                } else {
                    errorObj.IINNumber = "";
                }

                if (title === 'IINNumber') {
                    return errorObj;
                }
            }

            return errorObj;
        };

        const clearData = () => {
            setNFTCreateState((prevStep) => ({
                ...prevStep,
                requestType: '',
                name: '',
                email: '',
                phoneNumber: '',
                category: '',
                comment: '',
                accountHolders: [],
                accountType: '',
                IINNumber: '',
                clientId: '',
                isOptionSelected: false
            }));
        };

        const onChangeFormData = (name, value) => {
            if (name === 'scrollToFieldId') {
                setNFTCreateState(prevStep => ({
                    ...prevStep,
                    scrollToFieldId: value
                }));
                return
            }
            let updatedState = {...NFTCreateState};
            updatedState[name] = value;
            updatedState[name + 'Error'] = '';

            const validateObject = validateChanges(name, updatedState);

            setNFTCreateState(prevStep => ({
                ...prevStep,
                ...updatedState,
                modalErrorMessage: '',
                errors: {
                    ...prevStep.errors,
                    ...validateObject
                },
            }));
        };

        const onBlurInputText = (name) => {
            const validateObject = validateChanges(name, NFTCreateState);

            setNFTCreateState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObject
                },
            }));
        };

        const checkIfValidateObj = (validateObj) => {
            const {comment, requestType, IINNumber} = validateObj;

            if (IINNumber || comment || requestType) {
                // If any mandatory error is present (non-empty), return false
                return false;
            }
            // If no mandatory errors are present, return true
            return true;
        };


        const handleCreate = () => {
            const {
                comment, requestType, handleErrorInComponent, clientId
            } = NFTCreateState;

            const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, NFTCreateState);
            setNFTCreateState((prevStep) => ({
                ...prevStep,
                scrollToFieldId: getFirstErrorFieldId(validateObj)
            }));

            if (!checkIfValidateObj(validateObj)) {
                setNFTCreateState(prevStep => ({
                    ...prevStep,
                    isApiInProgress: false,
                    errors: {
                        ...prevStep.errors,
                        ...validateObj
                    }
                }));
                return;
            }


            const payload = {
                type: "NON_FINANCIAL",
                requestType: requestType,
                comment: comment,
                accountId: clientId
            };

            setNFTCreateState(prevStep => ({
                ...prevStep,
                isApiInProgress: true
            }));

            raiseNFTRequest(payload, handleErrorInComponent)
                .then((res) => {
                        if (res.success) {
                            clearData();
                            store.dispatch(showToastMessage('success', 'A new request is raised'))
                            setNFTCreateState((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false
                            }));
                            props.history.goBack();
                        } else {
                            setNFTCreateState((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false,
                                modalErrorMessage: res?.__error
                            }));
                        }
                    }
                );
        };

        function removeErrorWord(input) {
            return input.replace('Error', '');
        }

        const getFirstErrorFieldId = (errors) => {
            for (const section in errors) {
                const error = errors[section];
                if (typeof error === 'string' && error) {
                    return (removeErrorWord(section)) + '-field';
                }
            }
            return null; // return null if no errors found
        };

        const onClickCancel = (value) =>{
            const {
                comment, requestType, clientId
            } = NFTCreateState;
            if(!isEmpty(comment) || !isEmpty(requestType) || !isEmpty(clientId)){
                setNFTCreateState(prevStep => ({
                    ...prevStep,
                    isOpenCancelConfirmModal: value
                }));
            }else{
                redirectToListing()
            }
        };

        const redirectToListing = () =>{
            props.history.goBack();
        };

        if (isEmpty(NFTCreateState.UIState)) {
            return (<DLLoader type={"screen"} isVisible={true}/>);
        }

        return (
            <NFTCreateView
                {...props}
                {...NFTCreateState}
                redirectToListing={redirectToListing}
                handleCreate={handleCreate}
                onChangeFormData={onChangeFormData}
                onBlurInputText={onBlurInputText}
                handleSearch={handleSearch}
                handleClick={handleClick}
                setCategoryValues={setCategoryValues}
                getAccountType={getAccountType}
                clearData={clearData}
                onClickCancel={onClickCancel}
            />
        );
    }
;

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(NFTCreate);
