import React from 'react';
import moment from "moment";

import appTheme from "../../../assets/appTheme";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import {
    managementCellConfig,
    managementHeadingData,
    managementHeadingMobileData,
    managementMobileCellConfig
} from "../../LeadsModal";
import {handleKeyPress, renderStatusBlock, renderStatusDotWithDays} from "../../LeadsHelper";
import {isEmpty} from "../../../core/components/DLComponentHelper";

import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLDropDown from "../../../core/components/DLDropdown/DLDropDown";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLTable from "../../../core/components/DLTable/DLTable";
import DLCheckbox from "../../../core/components/DLCheckbox/DLCheckbox";

import AssignCRM from "../assignCRM/AssignCRM";
import DeleteLead from "../deleteLead/DeleteLead";

import emptyLeads from '../../../assets/img/empty-leads.png';
import emptyFilter from '../../../assets/img/appIcons/emptyFilter.png';
import emptyFilterFilled from '../../../assets/img/appIcons/emptyFilterFilled.png';
import filterDot from '../../../assets/img/appIcons/filterDot.png';

import '../../../assets/css/table.css'
import '../../../assets/css/listing.css'
import LeadHistory from "../leadHistory/LeadHistory";
import FilterBadgeView from "../filterBadge/FilterBadgeView";


const ManagementLeadListView = (props) => {
    const {
        isXSView, listingCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, leads, leadsFilter,
        isShowLoader, getPaginationNextButtonStatus, skipCount, onChangeFilter, UIState,
        isMobileView, CRMs, handleCheckBox
    } = props;

    const {keyword, status, CRMId} = leadsFilter;

    const statusFilterOptions = [
        {label: 'All', value: 'ALL'},
        {label: 'Contacted', value: 'CONTACTED'},
        {label: 'Lost', value: 'LOST'},
        {label: 'Not Contacted', value: 'NOT_CONTACTED'},
        {label: 'On Hold', value: 'ON_HOLD'},
        {label: 'Onboarding Completed', value: 'ONBOARDING_COMPLETED'},
        {label: 'Onboarding Initiated', value: 'ONBOARDING_INITIATED'}
    ];

    const renderAssignButton = () => {
        const {isMobileView, selectedIds, handleOpenAssignCRMModal} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 my-2'}>
                    <DLButton
                        id={'assign-button'}
                        buttonSize={'sm'}
                        reduceWidth={isMobileView}
                        isDisabled={isEmpty(selectedIds)}
                        fontSize={'sm'}
                        sideMargin={'md'}
                        backgroundColor={"grey"}
                        styleType={"outlined"}
                        onClick={() => handleOpenAssignCRMModal()}
                        label={"Assign to CRM"}/>
                </div>
            );
        }

        return (
            <div className={'d-flex justify-content-center my-2' + (isMobileView ? ' page-container' : '')}>
                <DLButton
                    id={'assign-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    isDisabled={isEmpty(selectedIds)}
                    fontSize={'sm'}
                    sideMargin={'md'}
                    backgroundColor={"grey"}
                    styleType={"outlined"}
                    onClick={() => handleOpenAssignCRMModal()}
                    label={"Assign to CRM"}/>
            </div>
        )

    };

    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 my-2'}>
                    <DLButton
                        id={'create-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => props.history.push("/createLeads/new")}
                        startIcon={<i className="fa-solid fa-plus"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center my-2 me-4' + (isMobileView ? ' page-container' : '')}>
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    sideMargin={'md'}
                    backgroundColor={"grey"}
                    styleType={"contained"}
                    onClick={() => props.history.push("/createLeads/new")}
                    label={"Create Lead"}/>
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between pb-1 pt-3'>
                <div className='page-header pt-0'>
                    Leads
                </div>
                <div className={"d-flex"}>
                    <div>
                        {renderAssignButton()}
                    </div>
                    <div>
                        {renderHeaderCreateButton()}
                    </div>
                </div>
            </div>
        )
    };

    const renderFilterIcon = () => {
        const {isShowFilterMobile, leadsFilter, isFiltersApplied} = props;
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(leadsFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter, resetLeadListing, isFiltersApplied, applyFilterBadge} = props;
        const badgeData = applyFilterBadge();

        return <div>
            <div className='d-flex align-items-center justify-content-between gap-3 page-container '>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'leads-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      placeholder={'Search by Name'}
                                      onKeyPress={handleKeyPress}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}

                    />
                </div>
                <div className="d-flex align-items-center">
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>

                    {isFiltersApplied(leadsFilter) &&
                    <div>
                        <i className="fa-solid fa-xmark cursor ms-2 "
                           onClick={() => resetLeadListing()}
                           style={{fontSize: 18}}/>
                    </div>
                    }
                </div>
            </div>
            {isShowFilterMobile &&
            <div className='bg-light mt-2' style={{paddingTop: 10, paddingBottom: 10}}>
                <div className='page-container'>
                    <div className='d-flex align-items-start justify-content-between flex-wrap gap-2 gap-wrap-3'>
                        <div style={{flex:1}}>
                            <DLDropDown
                                id={"status-filter"}
                                label={'Status'}
                                labelFontColor={'black'}
                                labelPlacement={'top'}
                                value={status[0]}
                                options={statusFilterOptions}
                                onSelect={(e) => {
                                    onChangeFilter('status', e)
                                }}
                                marginBottom={"none"}
                                disableHelperMessageHeight={true}
                                minWidth={'100%'}
                                // minWidth={'92dvw'}
                            />
                        </div>
                        <div style={{flex: 1}}>
                            <DLDropDown
                                id={"status-filter"}
                                label={'CRM'}
                                labelFontColor={'grayDark'}
                                labelFontSize={'sm'}
                                value={CRMId}
                                minWidth={"100%"}
                                options={CRMs}
                                onSelect={(e) => {
                                    onChangeFilter('CRMId', e)
                                }}
                                disableHelperMessageHeight={true}
                                marginBottom={"none"}
                                labelPlacement={'top'}
                            />
                        </div>
                    </div>
                    <div>
                        {badgeData.data !== 'UNASSIGNED' && <div className='d-flex align-items-end mb-1 pt-1 mt-2 pb-1'>
                            <FilterBadgeView
                                badgeData={badgeData}
                                onClose={() => {
                                    (badgeData?.title !== 'CRM: ') ?
                                        onChangeFilter('fromtoDate', '')
                                        :
                                        onChangeFilter('CRMId', 'ALL')
                                }}
                            />
                        </div>}
                    </div>
                </div>
            </div>
            }
        </div>
    };

    const renderFilter = () => {
        const {applyFilterBadge} = props;
        const badgeData = applyFilterBadge();
        return (
            <div>
                <div className='d-flex page-container'>
                    <div className='search-input' style={{flex: 1.8}}>
                        <DLInputTextField
                            id={'lead-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={isEmpty(keyword) ? '' : keyword}
                            placeholder={'Search by Name'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='select-filter ms-3' style={{flex: 1}}>
                        <DLDropDown
                            id={"status-filter"}
                            label={'Status'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={status[0]}
                            minWidth={"100%"}
                            options={statusFilterOptions}
                            onSelect={(e) => {
                                onChangeFilter('status', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    <div className='select-filter ms-3' style={{flex: 1}}>
                        <DLDropDown
                            id={"status-filter"}
                            label={'CRM'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={CRMId}
                            minWidth={"100%"}
                            options={CRMs}
                            onSelect={(e) => {
                                onChangeFilter('CRMId', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>

                    {badgeData.data !== 'UNASSIGNED' && <div className='d-flex align-items-end mb-1 pb-1 ms-3'>
                        <FilterBadgeView
                            badgeData={badgeData}
                            onClose={() => {
                                (badgeData?.title !== 'CRM: ') ?
                                    onChangeFilter('fromtoDate', '')
                                    :
                                    onChangeFilter('CRMId', 'ALL')
                            }}
                        />
                    </div>}
                </div>
            </div>
        );
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{
                        minHeight: 'calc(100dvh - 132px)',
                        overflow: 'hidden',
                        fontSize: '16px'
                    }}>
            <div className='text-center'>
                <img src={emptyLeads} alt={''}/>
                <DLText id={'create-first-lead'}
                        text={'Please create your first lead.'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => props.history.push("/createLeads/new")}
                    label={'Create Lead'}/>
            </div>
        </div>
    };

    const renderMobileTable = () => {
        const {isShowFilterMobile} = props;
        let tableData = getFormattedTableData(leads);

        if (isEmpty(leads)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100 pagination-container">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 345px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 345px)'}
                    isRowClickable={false}
                    onRowClick={() => {
                    }}
                    cellConfig={managementMobileCellConfig}
                    headerData={managementHeadingMobileData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderCount = () => {
        const {handleRefreshButton} = props;
        return (

            <div className={isXSView ? 'page-container my-3': isMobileView ? 'py-3' : 'pb-3'} style={{minHeight: '37px'}}>
                {!isEmpty(listingCount) &&
                <div className={'d-flex align-items-center m-0 p-0'}>
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Showing ' + (listingCount) + ' records'}/>

                    <div className={'d-flex align-items-center m-0 p-0 mx-2'}
                         onClick={() => {
                        handleRefreshButton()
                    }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                    </div>
                </div>
                }
            </div>)

    };

    const renderCheckBox = (id) => {
        const {checkIsChecked} = props;
        return (
            <DLCheckbox
                id={'mark-as-reviewed-dayCheck'}
                fontSize={"sm"}
                disableHelperMessageHeight={true}
                onChangeValue={() => {
                    handleCheckBox(id)
                }}
                labelPlacement={'end'}
                marginBottom={'none'}
                checkboxcolor={'black'}
                labelColor={appTheme.appColor.black}
                isChecked={checkIsChecked(id)}
            />
        )
    };

    const renderDaysStatus = (date) => {
        if (isEmpty(date)) {
            return '-'
        }
        return (
            <div className="">
                {renderStatusDotWithDays(date)}
            </div>);
    };

    const renderTextUi = (name, label, subLabel, leadId) => {
        const {getCRMName, redirectToDetailsScreen} = props;

        if (name === "nameAndEmail") {
            return (
                <div className="py-1">
                    {!isEmpty(label) && <DLText id={''}
                                                text={isEmpty(label) ? '-' : label}
                                                isClickable={true}
                                                marginBottom={isXSView? "none" : "xs"}
                                                onClick={() => {
                                                    redirectToDetailsScreen(leadId)
                                                }}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={subLabel}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            );
        }
        if (name === "sourceAndReferral") {
            return (
                <div className="">
                    {!isEmpty(label) && <DLText id={''}
                                                text={isEmpty(label) ? '-' : label}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={subLabel}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            );
        }
        if (name === "createdDate") {
            return (
                <div className="">
                    {!isEmpty(label) && <DLText id={''}
                                                text={moment(label).format("DD-MM-YYYY")}
                                                fontSize={"xs"}
                                                fontColor={"black"}
                    />}
                    {!isEmpty(label) && <div>
                        {renderDaysStatus(label)}
                    </div>}
                </div>
            );
        }
        if (name === "CRMAndAssignedDate") {
            return (
                <div className="">
                    {!isEmpty(label) ?
                        <DLText id={'CRM-name'}
                                text={getCRMName(label)}
                                fontSize={"xs"}
                                fontColor={"black"}
                        />
                        :
                        <DLText id={'CRM-name'}
                                text={'-'}
                                fontSize={"xs"}
                                fontColor={"black"}
                        />
                    }
                    {!isEmpty(subLabel) && <DLText id={''}
                                                   text={isEmpty(subLabel) ? "" : moment(subLabel).format("DD-MM-YYYY")}
                                                   fontSize={"xs"}
                                                   fontColor={"grayDark"}
                    />}
                </div>
            );
        }
    };

    const getFormattedTableData = (leads) => {
        let tableData = [];
        const {isXSView, getSourceName} = props;
        if (isEmpty(leads)) {
            return tableData
        }

        leads.map((selectedLead) => {
            let leadRecords = [];
            let leadDetails = {};
            if (!isXSView) {
                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderCheckBox(selectedLead.id)
                });
                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("nameAndEmail", selectedLead?.lead?.fullName, selectedLead?.lead?.email, selectedLead.id)
                });
                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("sourceAndReferral", getSourceName(selectedLead.referer?.sourceType), selectedLead.referer?.sourceName)
                });

                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("createdDate", isEmpty(selectedLead?.createdAt) ? "" : selectedLead.createdAt)
                });

                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("CRMAndAssignedDate", selectedLead?.assignedTo, isEmpty(selectedLead?.assignedTS) ? "" : selectedLead?.assignedTS)
                });
                // Rendering custom ui here for the below column
                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderStatusBlock(selectedLead?.status)
                });
                // leadRecords.push({
                //     isCustomUI: true,
                //     customUI: renderOptionMenu(selectedLead.id, selectedLead?.lead?.fullName)
                // });
            } else {
                // Rendering custom ui here for the below column
                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderCheckBox(selectedLead.id)
                });
                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("nameAndEmail", selectedLead?.lead?.fullName, '', selectedLead.id)
                });
                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("createdDate", isEmpty(selectedLead?.createdAt) ? "" : selectedLead.createdAt)
                });

                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderTextUi("CRMAndAssignedDate", selectedLead?.assignedTo, isEmpty(selectedLead?.assignedTS) ? "" : selectedLead?.assignedTS)
                });
            }

            leadDetails.id = selectedLead.id;
            leadDetails.data = leadRecords;
            tableData.push(leadDetails)
        });

        return tableData

    };

    const renderDesktopTable = () => {
        const {isShowFilterMobile} = props;
        let tableData = getFormattedTableData(leads);

        if (isEmpty(leads)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{maxHeight: 'calc(100dvh - 276px)', minHeight: 'calc(100dvh - 276px)'}}
                >
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 339px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 339px)'}
                    isRowClickable={false}
                    onRowClick={() => {
                    }}
                    cellConfig={managementCellConfig}
                    headerData={managementHeadingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderLeadsTable = () => {
        return (
            <div className={'w-100' + ((isMobileView && !isXSView) ? '  page-container' : '')}
                 style={{minHeight: isMobileView ? 'auto' : 'calc(-205px + 100dvh)'}}>
                <div>
                    {renderCount()}
                </div>
                {isXSView ?
                    renderMobileTable()
                    :
                    <div>
                        {renderDesktopTable()}
                    </div>
                }
            </div>
        )
    };


    const renderAssignCRMModal = () => {
        const {isMobileView, handleCloseAssignCRMModal, isAssignCRMModalOpen} = props;

        if (isAssignCRMModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseAssignCRMModal}>
                    <div className="modal-content " onClick={(e) => e.stopPropagation()}>
                        <AssignCRM {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <AssignCRM {...props}/>
                )
            }
        }

    };

    const renderDeleteLeadModal = () => {
        const {isMobileView, handleCloseAssignCRMModal, isDeleteLeadModalOpen} = props;

        if (isDeleteLeadModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseAssignCRMModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <DeleteLead {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <DeleteLead {...props}/>
                )
            }
        }

    };

    const renderLeadHistoryModal = () => {
        const {isMobileView, handleCloseHistoryModal, isHistoryModalOpen} = props;

        if (isHistoryModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseHistoryModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <LeadHistory {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <div>
                        <LeadHistory {...props}/>
                    </div>
                )
            }
        }

    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'} style={{
                    background: 'rgba(0, 0, 0, 0.02)'
                }}>
                    {renderHeader()}
                    {renderFilterMobile()}
                    {renderLeadsTable()}
                </div>
                {renderAssignCRMModal()}
                {renderDeleteLeadModal()}
                {renderLeadHistoryModal()}
                {renderLoader()}
            </div>
        );
    }

    return (
        <div className={'h-100 d-flex justify-content-center bg-light'}
             style={{minHeight: 'calc(100dvh - 75px)'}}>
            <div className={'w-100'}>
                {renderHeader()}
                {renderFilter()}
                <div className={'page-container mt-3'}>
                    {renderLeadsTable()}
                    {renderAssignCRMModal()}
                    {renderDeleteLeadModal()}
                    {renderLeadHistoryModal()}
                </div>
                {renderLoader()}
            </div>
        </div>
    );
};

export default ManagementLeadListView;