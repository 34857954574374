import React from 'react';
import appTheme from "../../../assets/appTheme";
import DLText from "../../../core/components/DLText/DLText";
import NotContactedLeads from "./components/leads/NotContactedLeads";
import CRMLeads from "./components/leads/CRMLeads";
import OnboardingCategory from "./components/onboardings/OnboardingCategory";
import OnboardingAccountType from "./components/onboardings/OnboardingAccountType";
import OnboardingLocation from "./components/onboardings/OnboardingLocation";
import OnboardingByCrm from "./components/onboardings/OnboardingByCRM";
import OnboardingAgeing from "./components/onboardings/OnboardingAgeing";
import OnboardingStatus from "./components/onboardings/OnboardingStatus";
import AllLeadsStatus from "./components/leads/AllLeadsStatus";
import ClientCategory from "./components/clients/ClientCategory";
import ClientAccountType from "./components/clients/ClientAccountType";
import ClientByCrm from "./components/clients/ClientByCRM";
import Transaction from "./components/transactions/Transaction";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabList from "@mui/lab/TabList";

const ManagementDashboardView = (props) => {
    const {isXSView, isSMView, isMDView, isMobileView, selectedProfileTab} = props;
    const renderClientData = () => {
        return (
            <div className={(isXSView || isSMView) ? 'w-100 h-100 mb-5 pb-3' : ' m-0 row p-0 '}>
                <div
                    className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView || isMobileView) ? ' mb-3' : ' ')}>
                    <ClientCategory
                        {...props}
                    />
                </div>
                <div
                    className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView || isMobileView) ? ' mb-3' : ' ')}>
                    <ClientByCrm
                        {...props}
                    />
                </div>
                <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? "mb-5" : isSMView ? 'px-2 mb-5' : isMDView ? 'px-2 mb-3' :  'px-2'}`}>
                    <ClientAccountType
                        {...props}
                    />
                </div>

            </div>
        )
    };

    const renderClientWidgets = () => {
        return (
            <div className={(isXSView || isSMView) ? ' mb-2 mt-3' : ' border-rounded panel p-0 mt-3'}>
                <div className="px-0 h-100">
                    <div className={(isXSView || isSMView) ? '' : "p-2 ps-3 border-bottom rounded-top"}
                         style={{backgroundColor: (isXSView || isSMView) ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Leads'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Clients'}/>

                    </div>
                    <div className={isMDView ? 'pt-3 px-2' : (isXSView || isSMView) ? 'pt-3 px-2' : 'py-3 px-2'}>
                        {renderClientData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderOnboardingData = () => {
        return (
            <div className={(isXSView || isSMView) || isMDView ? 'h-100' : 'h-100 pb-3'}>
                <div className={(isXSView || isSMView) ? 'w-100 ' : ' m-0 row p-0 '}>
                    <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView || isMobileView) ? ' mb-3' : ' ')}>
                        <OnboardingStatus
                            {...props}
                        />
                    </div>
                    <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView || isMobileView) ? ' mb-3' : ' ')}>
                        <OnboardingAgeing
                            {...props}
                        />
                    </div>
                    <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                        <OnboardingByCrm
                            {...props}
                        />
                    </div>
                    {isMobileView && <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                        <OnboardingCategory
                            {...props}
                        />
                    </div>}
                </div>
                <div className={isXSView ? 'w-100 ' : ' m-0 row p-0 ' + ((!isSMView) ? ' mt-3' : ' ')}>
                    {!isMobileView && <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                        <OnboardingCategory
                            {...props}
                        />
                    </div>}
                    <div
                        className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView) ? ' mb-3' : ' ')}>
                        <OnboardingAccountType
                            {...props}
                        />
                    </div>
                    <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'mb-5' : isSMView ? 'px-2 mb-5' : 'px-2'}`}>
                        <OnboardingLocation
                            {...props}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderOnboardingWidgets = () => {
        return (
            <div
                className={(isXSView || isSMView) ? 'border-bottom mb-2 mt-3' : 'border-rounded panel p-0 mb-0  mt-3 section-tabs-gap'}>
                <div className="px-0 h-100">
                    <div className={(isXSView || isSMView) ? '' : "p-2 ps-3 border-bottom rounded-top"}
                         style={{backgroundColor: (isXSView || isSMView) ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Onboarding-Widgets'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Onboardings'}/>

                    </div>
                    <div className={isMDView ? 'py-3 px-2' : (isXSView || isSMView) ? 'pt-3 px-2' : 'pt-3 px-2'}>
                        {renderOnboardingData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLeadData = () => {
        return (
            <div className={(isXSView || isSMView) ? 'w-100 pb-3 h-100 mb-5' : ' m-0 row p-0 '}>
                <div
                    className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView || isMobileView) ? ' mb-3' : ' ')}>
                    <AllLeadsStatus
                        {...props}
                    />
                </div>
                <div
                    className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView || isMobileView) ? ' mb-3' : ' ')}>
                    <NotContactedLeads
                        {...props}
                    />
                </div>
                <div className={`col-md-6 col-lg-4 p-0  ${isXSView ? 'p-0' : 'px-2'}` + ((isSMView || isMobileView) ? ' mb-3' : ' ')}>
                    <CRMLeads
                        {...props}
                    />
                </div>
            </div>
        )
    };

    const renderLeadWidgets = () => {
        return (
            <div
                className={(isXSView || isSMView) ? 'border-bottom mb-2 mt-3' : ' border-rounded panel p-0 section-tabs-gap mt-3'}>
                <div className="px-0 h-100">
                    <div className={(isXSView || isSMView) ? '' : "p-2 ps-3 border-bottom rounded-top"}
                         style={{backgroundColor: (isXSView || isSMView) ? '' : appTheme.appColor.lightBlue}}>
                        <DLText id={'Leads'}
                                isInline={true}
                                fontSize={'lg'}
                                fontWeight={'semi-bold'}
                                text={'Leads'}/>

                    </div>
                    <div className={isMDView ? 'pt-3 px-2' : (isXSView || isSMView) ? 'pt-3 px-2' : 'py-3 px-2'}>
                        {renderLeadData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderTransaction = () => {
        return (
            <div className={isMDView ? '' : 'mt-3 pb-4 p-0'}>
                <Transaction {...props}/>
            </div>
        )
    };


    const renderSystematicPlansTabs = () => {
        const {handleTabChange, allTabs} = props;

        return allTabs?.map((item, index) => {
            return (
                <Tab key={'tab_' + index}
                     label={item.label}
                     onClick={() => {
                         handleTabChange(item)
                     }}
                     style={{
                         textTransform: 'none',
                         color: appTheme.appColor.black,
                         fontSize: appTheme.fontSizes.md,
                         fontFamily: appTheme.default.fontFamily,
                         fontWeight: (item?.value === selectedProfileTab?.value) ? 500 : 400
                     }}
                     value={item.value}/>
            );
        })
    };

    const renderTabs = () => {
        return (
            <div
                className={((isXSView || isSMView) ? 'bg-white ' : ' border-bottom bg-light ') + 'page-container'}>
                <Box sx={{width: '100%'}}>
                    <TabContext value={selectedProfileTab?.value}>
                        <Box sx={{borderBottom: 0, borderColor: appTheme.appColor.darkBlue}}>
                            <TabList onChange={() => {
                            }} aria-label="lab API tabs-example"
                                     variant="scrollable"
                                     scrollButtons={false}>
                                {renderSystematicPlansTabs()}
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>
            </div>
        )
    };

    const getClassName = () => {
        if (selectedProfileTab?.value === "Onboardings") {
            return 'pb-4'
        } else if (selectedProfileTab?.value === "Financial Transactions") {
            return isMDView ? 'pb-4' : '';
        } else {
            return isMDView ?  "mb-5 pb-3" : "";
        }
    };

    return (
        <div className={'bg-light ' + getClassName()}>
            <div className={(isXSView || isSMView) ? 'bg-white' : ''}>
                {renderTabs()}
            </div>
            <div
                className={'page-container bg-light'}>
                {selectedProfileTab?.value === "Leads" ? renderLeadWidgets() : ''}
                {selectedProfileTab?.value === "Onboardings" ? renderOnboardingWidgets() : ''}
                {selectedProfileTab?.value === "Clients" ? renderClientWidgets() : ''}
            </div>
            <div className={isMDView ? 'bg-light ' : (isXSView || isSMView) ? 'bg-white' : 'page-container'}>
                {selectedProfileTab?.value === "Financial Transactions" ? renderTransaction() : ''}
            </div>
        </div>
    )
};

export default ManagementDashboardView;