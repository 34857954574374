import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'FTReducer',
    initialState: {
        FTFilter: {
            keyword: '',
            status: ['ALL'],
            CRMId: ['ALL'],
            skipCount: 0,
            limitCount: 50,
            page:0,
            rowsPerPage: 50
        },
        CRMDetails: []
    },
    reducers: {
        setFTSearchKeywordValue (state,action) {
            state.FTFilter.keyword = action.payload;
        },

        setFTStatusValue (state,action) {
            state.FTFilter.status = action.payload;
        },
        
        setFTCRMIdValue(state, action) {
            state.FTFilter.CRMId = action.payload;
        },

        setFTSkipCountValue(state,action){
            state.FTFilter.skipCount = action.payload;
        },

        setFTLimitCountValue(state,action){
            state.FTFilter.limitCount = action.payload;
        },

        setFTPageValue(state,action){
            state.FTFilter.page = action.payload;
        },

        setFTRowsPerPageValue(state,action){
            state.FTFilter.rowsPerPage = action.payload;
        },

        resetFTAllFilters (state) {
            state.FTFilter = {
                ...state.FTFilter,
                keyword: '',
                status: ['ALL'],
                CRMId: ['ALL'],
                skipCount: 0,
                limitCount: 50,
                page:0,
                rowsPerPage: 50
            };
        },
        setCRMDetails(state,action) {
            state.CRMDetails = action.payload;
        },
    }
});

export const {setFTSearchKeywordValue, resetFTAllFilters, setFTRowsPerPageValue, setFTPageValue,
    setFTLimitCountValue,setFTSkipCountValue, setFTStatusValue, setFTCRMIdValue} = appSlice.actions;

export default appSlice.reducer

