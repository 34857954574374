import {httpGet, httpPost, httpPut, httpDelete} from "../utils/httpHelper";
import store from '../store';
import {
    setAccountTypes,
    setAdvisoryUsers,
    setApplicableIncomes, setBankChannelTypes,
    setBanks, setBankTypes, setCashflowTypes,
    setCategoryTypes, setChequeDepositModes,
    setClientAccountTypes, setCountries,
    setCRMUsers,
    setDecisionMakingStyles,
    setFinancialProfiles, setFinancialTransactionStates,
    setGenders, setGuardianRelations, setHoldingNatures,
    setIndustryTypes, setInsuranceToIncomeRatios,
    setInvestmentStyles,
    setLeadRequests,
    setLeadSources,
    setLocations, setLostReasons,
    setMoneyManagementStyles, setNftRequestTypes, setNomineeRelations,
    setOccupations, setPaymentMechanisms,
    setPeps, setRealEstateToNetworthRatios,
    setRelationships,
    setRiskProfileTypes, setSpendingHabits,
    setStates, setTaxStatuses, setTxnRequestTypes
} from "./MetadataReducer";

export const getAdvisoryUsers = async () => {
    let {advisoryUsers, isAdvisoryUsersLoaded} = store?.getState().metadataState;
    if(isAdvisoryUsersLoaded) {
        return await advisoryUsers;
    }
    return httpGet('/service/advisory/advisoryUser/all').then(res => {
        store.dispatch(setAdvisoryUsers(res));
        return res;
    })
};

export const getCRMs = async () => {
    let {CRMUsers, isCRMUsersLoaded} = store?.getState().metadataState;
    if(isCRMUsersLoaded) {
        return await CRMUsers;
    }
    return httpGet('/service/advisory/advisoryUser/all?role=CRM').then(res => {
        store.dispatch(setCRMUsers(res));
        return res;
    })
};

export const getLeadSources = async (handleErrorInComponent) => {
    let {leadSources, isLeadSourcesLoaded} = store?.getState().metadataState;
    if(isLeadSourcesLoaded) {
        return await leadSources;
    }
    return httpGet('/service/advisory/leadSource/all',handleErrorInComponent).then(res => {
        store.dispatch(setLeadSources(res))
        return res;
    })
};

export const getCategoryOptions = async () => {
    let {categoryTypes, isCategoryTypesLoaded} = store?.getState().metadataState;
    if(isCategoryTypesLoaded) {
        return await categoryTypes;
    }
    return httpGet('/service/advisory/categoryType/all').then(res => {
        store.dispatch(setCategoryTypes(res));
        return res;
    });
};

export const getAccountTypeOptions = async () => {
    let {accountTypes, isAccountTypesLoaded} = store?.getState().metadataState;
    if(isAccountTypesLoaded) {
        return await accountTypes;
    }
    return httpGet('/service/advisory/accountType/all').then(res => {
        store.dispatch(setAccountTypes(res));
        return res;
    });
};

export const getClientAccountTypeOptions = async () => {
    let {clientAccountTypes, isClientAccountTypesLoaded} = store?.getState().metadataState;
    if(isClientAccountTypesLoaded) {
        return await clientAccountTypes;
    }
    return httpGet('/service/advisory/accountType/all?isClient=true').then(res => {
        store.dispatch(setClientAccountTypes(res));
        return res;
    });
};

export const getLocations = async () => {
    let {locations, isLocationsLoaded} = store?.getState().metadataState;
    if(isLocationsLoaded) {
        return await locations;
    }
    return httpGet('/service/advisory/location/all').then(res => {
        store.dispatch(setLocations(res));
        return res;
    })
};

export const getBanks = async () => {
    let {banks, isBanksLoaded} = store?.getState().metadataState;
    if(isBanksLoaded) {
        return await banks;
    }
    return httpGet('/service/advisory/bank/all').then(res => {
        store.dispatch(setBanks(res));
        return res;
    })
};

export const getStateList = async () => {
    let {states, isStatesLoaded} = store?.getState().metadataState;
    if(isStatesLoaded) {
        return await states;
    }
    return httpGet('/service/advisory/state/all').then(res => {
        store.dispatch(setStates(res));
        return res;
    })
};

export const getGenderList = async () => {
    let {genders, isGendersLoaded} = store?.getState().metadataState;
    if(isGendersLoaded) {
        return await genders;
    }
    return httpGet('/service/advisory/gender/all').then(res => {
        store.dispatch(setGenders(res));
        return res;
    })
};

export const getOccupationList = async () => {
    let {occupations, isOccupationsLoaded} = store?.getState().metadataState;
    if(isOccupationsLoaded) {
        return await occupations;
    }
    return httpGet('/service/advisory/occupation/all').then(res => {
        store.dispatch(setOccupations(res));
        return res;
    })
};

export const getIndustryTypeList = async () => {
    let {industryTypes, isIndustryTypesLoaded} = store?.getState().metadataState;
    if(isIndustryTypesLoaded) {
        return await industryTypes;
    }
    return httpGet('/service/advisory/industryType/all').then(res => {
        store.dispatch(setIndustryTypes(res));
        return res;
    })
};


export const getPoliticallyExposedOptions = async () => {
    let {peps, isPepsLoaded} = store?.getState().metadataState;
    if(isPepsLoaded) {
        return await peps;
    }
    return httpGet('/service/advisory/pep/all').then(res => {
        store.dispatch(setPeps(res));
        return res;
    })
};

export const getApplicableIncomeOptions = async () => {
    let {applicableIncomes, isApplicableIncomesLoaded} = store?.getState().metadataState;
    if(isApplicableIncomesLoaded) {
        return await applicableIncomes;
    }
    return httpGet('/service/advisory/applicableIncome/all').then(res => {
        store.dispatch(setApplicableIncomes(res));
        return res;
    });
};

export const getPhoneEmailBelongsToList = async () => {
    let {relationships, isRelationshipsLoaded} = store?.getState().metadataState;
    if(isRelationshipsLoaded) {
        return await relationships;
    }
    return httpGet('/service/advisory/relationship/all').then(res => {
        store.dispatch(setRelationships(res));
        return res;
    })
};

export const getRiskProfileTypeOptions = async () => {
    let {riskProfileTypes, isRiskProfileTypesLoaded} = store?.getState().metadataState;
    if(isRiskProfileTypesLoaded) {
        return await riskProfileTypes;
    }
    return httpGet('/service/advisory/riskProfileType/all').then(res => {
        store.dispatch(setRiskProfileTypes(res));
        return res;
    });
};

export const getProfileOptions = async () => {
    let {financialProfiles, isFinancialProfilesLoaded} = store?.getState().metadataState;
    if(isFinancialProfilesLoaded) {
        return await financialProfiles;
    }
    return httpGet('/service/advisory/financialProfile/all').then(res => {
        store.dispatch(setFinancialProfiles(res));
        return res;
    })
};

export const getPersonalityTraitOptions = async () => {
    let {decisionMakingStyles, isDecisionMakingStylesLoaded} = store?.getState().metadataState;
    if(isDecisionMakingStylesLoaded) {
        return await decisionMakingStyles;
    }
    return httpGet('/service/advisory/decisionMakingStyle/all').then(res => {
        store.dispatch(setDecisionMakingStyles(res));
        return res;
    })
};

export const getDecisionApproachOptions = async () => {
    let {investmentStyles, isInvestmentStylesLoaded} = store?.getState().metadataState;
    if(isInvestmentStylesLoaded) {
        return await investmentStyles;
    }
    return httpGet('/service/advisory/investmentStyle/all').then(res => {
        store.dispatch(setInvestmentStyles(res));
        return res;
    })
};

export const getFinancialApproachOptions = async () => {
    let {moneyManagementStyles, isMoneyManagementStylesLoaded} = store?.getState().metadataState;
    if(isMoneyManagementStylesLoaded) {
        return await moneyManagementStyles;
    }
    return httpGet('/service/advisory/moneyManagementStyle/all').then(res => {
        store.dispatch(setMoneyManagementStyles(res));
        return res;
    })
};

export const getSpentOnRealEstateOptions = async () => {
    let {realEstateToNetworthRatios, isRealEstateToNetworthRatiosLoaded} = store?.getState().metadataState;
    if(isRealEstateToNetworthRatiosLoaded) {
        return await realEstateToNetworthRatios;
    }
    return httpGet('/service/advisory/realEstateToNetworthRatio/all').then(res => {
        store.dispatch(setRealEstateToNetworthRatios(res));
        return res;
    })
};

export const getSpentOnInsuranceOptions = async () => {
    let {insuranceToIncomeRatios, isInsuranceToIncomeRatiosLoaded} = store?.getState().metadataState;
    if(isInsuranceToIncomeRatiosLoaded) {
        return await insuranceToIncomeRatios;
    }
    return httpGet('/service/advisory/insuranceToIncomeRatio/all').then(res => {
        store.dispatch(setInsuranceToIncomeRatios(res));
        return res;
    })
};

export const getCashFlowOptions = async () => {
    let {cashflowTypes, isCashflowTypesLoaded} = store?.getState().metadataState;
    if(isCashflowTypesLoaded) {
        return await cashflowTypes;
    }
    return httpGet('/service/advisory/cashflowType/all').then(res => {
        store.dispatch(setCashflowTypes(res));
        return res;
    })
};

export const getSpendingHabitsOptions = async () => {
    let {spendingHabits, isSpendingHabitsLoaded} = store?.getState().metadataState;
    if(isSpendingHabitsLoaded) {
        return await spendingHabits;
    }
    return httpGet('/service/advisory/spendingHabit/all').then(res => {
        store.dispatch(setSpendingHabits(res));
        return res;
    })
};

export const getRelationsList = async () => {
    let {nomineeRelations, isNomineeRelationsLoaded} = store?.getState().metadataState;
    if(isNomineeRelationsLoaded) {
        return await nomineeRelations;
    }
    return httpGet('/service/advisory/nomineeRelation/all').then(res => {
        store.dispatch(setNomineeRelations(res));
        return res;
    })
};

export const getGuardianRelationsList = async () => {
    let {guardianRelations, isGuardianRelationsLoaded} = store?.getState().metadataState;
    if(isGuardianRelationsLoaded) {
        return await guardianRelations;
    }
    return httpGet('/service/advisory/guardianRelation/all').then(res => {
        store.dispatch(setGuardianRelations(res));
        return res;
    })
};

export const getAllBankChannelType = async () => {
    let {bankChannelTypes, isBankChannelTypesLoaded} = store?.getState().metadataState;
    if(isBankChannelTypesLoaded) {
        return await bankChannelTypes;
    }
    return httpGet('/service/advisory/bank/channelType/all')
        .then(res => {
            store.dispatch(setBankChannelTypes(res));
            return res
        })
};

export const getCountryData = async (handleErrorInComponent) => {
    let {countries, isCountriesLoaded} = store?.getState().metadataState;
    if(isCountriesLoaded) {
        return await countries;
    }
    return httpGet('/service/advisory/country/all',handleErrorInComponent).then(res => {
        store.dispatch(setCountries(res));
        return res;
    })
};

export const getLostReasons = async () => {
    let {lostReasons, isLostReasonsLoaded} = store?.getState().metadataState;
    if(isLostReasonsLoaded) {
        return await lostReasons;
    }
    return httpGet('/service/advisory/lostReason/all').then(res => {
        store.dispatch(setLostReasons(res));
        return res;
    })
};

export const getHoldingNatureOptions = async () => {
    let {holdingNatures, isHoldingNaturesLoaded} = store?.getState().metadataState;
    if(isHoldingNaturesLoaded) {
        return await holdingNatures;
    }
    return httpGet('/service/advisory/holdingNature/all').then(res => {
        store.dispatch(setHoldingNatures(res));
        return res;
    })
};

export const getBankAccountType = async() => {
    let {bankTypes, isBankTypesLoaded} = store?.getState().metadataState;
    if(isBankTypesLoaded) {
        return await bankTypes;
    }
    return httpGet('/service/advisory/bank/type/all').then(res => {
        store.dispatch(setBankTypes(res));
        return res;
    })
};

export const getFTStatuses = async () => {
    let {financialTransactionStates, isFinancialTransactionStatesLoaded} = store?.getState().metadataState;
    if(isFinancialTransactionStatesLoaded) {
        return await financialTransactionStates;
    }
    return httpGet('/service/advisory/financialTransaction/status/all', true).then(res => {
        store.dispatch(setFinancialTransactionStates(res));
        return res;
    })
};

export const getTypeOfRequestFilterOptions = async () => {
    let {nftRequestTypes, isNftRequestTypesLoaded} = store?.getState().metadataState;
    if(isNftRequestTypesLoaded) {
        return await nftRequestTypes;
    }
    return httpGet('/service/advisory/nftRequestType/all').then(res => {
        store.dispatch(setNftRequestTypes(res));
        return res;
    });
};

export const getTxnRequestType = async () => {
    let {txnRequestTypes, isTxnRequestTypesLoaded} = store?.getState().metadataState;
    if(isTxnRequestTypesLoaded) {
        return await txnRequestTypes;
    }
    return httpGet('/service/advisory/txnRequestType/all', true).then(res => {
        store.dispatch(setTxnRequestTypes(res));
        return res;
    })
};

export const getPaymentModes = async () => {
    let {paymentMechanisms, isPaymentMechanismsLoaded} = store?.getState().metadataState;
    if(isPaymentMechanismsLoaded) {
        return await paymentMechanisms;
    }
    return httpGet('/service/advisory/paymentMechanism/all').then(res => {
        store.dispatch(setPaymentMechanisms(res));
        return res;
    });
};

export const getChequeDepositModes = async () => {
    let {chequeDepositModes, isChequeDepositModesLoaded} = store?.getState().metadataState;
    if(isChequeDepositModesLoaded) {
        return await chequeDepositModes;
    }
    return httpGet('/service/advisory/chequeDepositMode/all').then(res => {
        store.dispatch(setChequeDepositModes(res));
        return res;
    });
};

export const getAllTaxStatus = async () => {
    let {taxStatuses, isTaxStatusesLoaded} = store?.getState().metadataState;
    if(isTaxStatusesLoaded) {
        return await taxStatuses;
    }
    return httpGet('/service/advisory/taxStatus/all').then(res => {
        store.dispatch(setTaxStatuses(res));
        return res;
    })
};