import React from 'react';

import {APP_CONST} from "../../../AppComponents/AppConstants";
import {isEmpty} from "../../../core/components/DLComponentHelper";

import emptyLeads from '../../../assets/img/empty-leads.png';

import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLMenuOptions from "../../../core/components/DLMenuOptions/DLMenuOptions";
import DeletePortfolio from "../deletePortfolio/DeletePortfolio";
import CopyPortfolio from "../copyPortfolio/CopyPortfolio";
import appTheme from "../../../assets/appTheme";


const PortfolioListingView = (props) => {
    const {
        isXSView, listingCount, portfolioFilter, isShowLoader, onChangeFilter, UIState,
        portfolioListing, selectedOption, isMDView, isXLView, isLGView, isMobileView
    } = props;

    const {keyword} = portfolioFilter;

    const renderDeletePortfolioModal = () => {
        const {isMobileView, handleCloseDeletePortfolioModal, isDeletePortfolioModalOpen} = props;

        if (isDeletePortfolioModalOpen) {
            if (isMobileView) {
                return (
                    <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseDeletePortfolioModal}>
                        <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                            <DeletePortfolio {...props} isOverlay={true}/>
                        </div>
                    </div>)
            } else {
                return (
                    <DeletePortfolio {...props}/>
                )
            }
        }

    };

    const responsiveTableUi = () => {
        if (isXLView) {
            return "col-4"
        }
        if (isLGView) {
            return 'col-6'
        }
        if (isMDView) {
            return 'col-6'
        }
        if (isXSView) {
            return 'col-12'
        }
        return 'col-6'
    }
    const renderCopyPortfolioModal = () => {
        const {isMobileView, handleCloseCopyPortfolioModal, isCopyPortfolioModalOpen} = props;

        if (isCopyPortfolioModalOpen) {
            if (isMobileView) {
                return (
                    <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseCopyPortfolioModal}>
                        <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                            <CopyPortfolio {...props} isOverlay={true}/>
                        </div>
                    </div>)
            }
            return (
                <CopyPortfolio {...props}/>
            )
        }

    };

    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 my-2'}>
                    <DLButton
                        id={'create-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => {
                            props.history.push("/createPortfolio-Management", {requestType: 'NEW'})
                        }}
                        startIcon={<i className="fa-solid fa-plus"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center pe-2 my-2' + (isMobileView ? ' page-container' : ' mx-4')}>
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => {
                        props.history.push("/createPortfolio-Management", {requestType: 'NEW'})
                    }}
                    label={"Create New Portfolio"}/>
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div
                className={`${isXSView ? 'bg-white' : 'bg-light pt-3 '} d-flex justify-content-between bg-white pb-1`}>
                <div className='page-header d-flex pt-2 pb-2'>
                    {isMobileView && <i className='fa-solid fa-chevron-left cursor fa-xs pt-3 mt-0 pe-4'
                                        style={{width: 10}}
                                        onClick={() => {
                                            props.history.goBack()
                                        }}
                    />}
                    Portfolios
                </div>
                <div className={isMobileView ? 'd-flex align-items-center' :''}>
                    {renderHeaderCreateButton()}
                </div>
            </div>
        )
    };

    const renderFilter = () => {
        return (
            <div>
                <div className={`d-flex`}>
                    <div className={`${isXSView ? '' : 'search-input'}`} style={{flex: 1.8}}>
                        <DLInputTextField
                            id={'NFT-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={keyword}
                            placeholder={'Search by Portfolio Name or Scheme'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'}}>
            <div className='text-center'>
                <img src={emptyLeads} alt={''}/>
                <DLText id={'create-portfolio'}
                        text={'Please create your first portfolio'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => props.history.push("/createportfolio-management", {requestType: 'NEW'})}
                    label={'Create Portfolio'}/>
            </div>
        </div>
    };

    const renderCount = () => {
        const {isXSView, handleRefreshButton} = props;

        if (isXSView) {
            return (
                <div className='page-container mt-3 mb-3'>
                    {!isEmpty(listingCount) &&
                    <div className='d-flex align-items-center'>
                        <DLText id={''}
                                isInline={true}
                                fontColor={'grayDark'}
                                fontSize={'sm'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + (listingCount) + ' records'}/>
                        <div className={'d-flex align-items-center mx-2'}
                             onClick={() => {
                                 handleRefreshButton()
                             }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                        </div>
                    </div>
                    }
                </div>)
        }
        return (
            <div className='pb-3'>
                {!isEmpty(listingCount) &&
                <div className={'d-flex align-items-center '}>
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Showing ' + (listingCount) + ' records'}/>
                    <div className={'d-flex align-items-center mx-2'}
                         onClick={() => {
                             handleRefreshButton()
                         }}>
                        <i className="fa-solid fa-rotate-right cursor"/>
                    </div>
                </div>}
            </div>
        )
    };

    const renderWidget = (item) => {
        return <div className={"py-3 px-3 border-bottom d-flex justify-content-between rounded-top"}
                    style={{backgroundColor: appTheme.appColor.lightBlue}}>
            <div className='d-flex align-items-center'>
                <DLText id={'title-name'}
                        isInline={true}
                        fontSize={'md'}
                        marginBottom={'none'}
                        fontWeight={'semi-bold'}
                        text={item?.name}/>
            </div>
            <div className="d-flex justify-content-around">
                <div style={{fontSize: '16px'}}>
                    <DLMenuOptions
                        id={"menuOption"}
                        onSelect={(value) => {
                            selectedOption(value, item?.id, item?.name)
                        }}
                        options={[
                            {
                                label: "Edit",
                                value: "EDIT"
                            },
                            {
                                label: "Copy",
                                value: "COPY"
                            },
                            {
                                label: "Delete",
                                value: "DELETE"
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    };

    const renderListing = () => {
        if (isEmpty(portfolioListing)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className='row'>
                {
                    portfolioListing?.portfolio?.map((item, index) => {
                        return (
                            <div className={`${responsiveTableUi()} col-4 mb-3`} key={index}>
                                <div className={'p-0 w-100 border-rounded panel h-100'}>
                                    <div className="px-0 h-100 c">
                                        {renderWidget(item)}
                                        {renderTableUi(item)}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const renderTableUi = (item) => {
        if (isEmpty(item?.portfolioFunds)) {
            return (
                <div className='d-flex h-75 align-items-center justify-content-center'
                     style={{minHeight: 217}}>
                    <div> No funds added</div>
                </div>
            )
        } else {
            return (
                <div className={'p-3 overflow-auto overflow-x-hidden'}
                     style={{height: 210}}>
                    {isShowLoader ?
                        <div className='d-flex align-items-center justify-content-center h-100'>
                            {renderLoader()}
                        </div>
                        :
                        renderData(item?.portfolioFunds)
                    }
                </div>
            )
        }
    };

    const renderData = (funds) => {
        return funds.map((item, index) => {
            return (
                <div className={"d-flex justify-content-between py-1"}
                     key={'data' + index}>
                    <div className='data-card-label pe-4'>
                        <DLText id={''}
                                text={item?.productMaster?.description}
                                fontSize={"xs"}
                                fontWeight={"normal"}
                        />
                    </div>
                    {item?.allocationPercentage && <div className='data-card-label'>
                        <DLText id={''}
                                text={item?.allocationPercentage + '%'}
                                fontSize={"xs"}
                                fontWeight={"semi-bold"}
                        />
                    </div>}
                </div>
            )
        })
    };

    const renderUi = () => {
        return (
            <div className={'w-100 mt-3'}>
                {renderCount()}
                <div>
                    {renderListing()}
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    return (
        <div className={'d-flex justify-content-center bg-light'} style={{minHeight: '100%'}}>
            <div className={'w-100'}>
                <div className={`${isXSView ? 'bg-white pb-2' : 'bg-light'}`}>
                    {renderHeader()}
                    <div className={`page-container bg-white pb-3 ${isXSView ? 'bg-white pb-4' : 'bg-light'}`}>
                        {renderFilter()}
                    </div>
                </div>
                <div className='page-container'>
                    {renderUi()}
                </div>
                {renderDeletePortfolioModal()}
                {renderCopyPortfolioModal()}
                {renderLoader()}

            </div>
        </div>
    );
};

export default PortfolioListingView;