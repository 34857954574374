import React, {useState} from 'react';
import {connect} from "react-redux";

import {resendTxnEmail} from "../../../../FTAction";

import ResendEmailToClientView from "./ResendEmailToClientView";
import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";


const ResendEmailToClient = (props) => {

    const {txnRequestId, handleCloseResendModal, onSaveSuccess} = props;
    const [resendEmailToClientState, setResendEmailToClientState] = useState({
        isApiInProgress: false,
        handleErrorInComponent: true,
        modalErrorMessage: ''
    });

    const handleResend = () => {
        setResendEmailToClientState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));
        let payload = {
            txnRequestId : txnRequestId
        }

        resendTxnEmail(payload)
            .then((res) => {
                    if (res.success) {
                        store.dispatch(showToastMessage('success', 'Email is sent to client'))
                        setResendEmailToClientState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        handleCloseResendModal();
                        onSaveSuccess();
                    } else {
                        setResendEmailToClientState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res.__error
                        }));
                    }
                }
            );
    };

    return (
        <ResendEmailToClientView
            {...props}
            {...resendEmailToClientState}
            handleResend={handleResend}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(ResendEmailToClient);
