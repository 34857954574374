import React from 'react';
import PreLoginRedirectionView from "./PreLoginRedirectionView";
import {connect} from "react-redux";
import {userConst} from "../userConsts";
import {setUserAuthStatus} from "../userReducer";
import {logoutUser} from "../../user/userActions";

const PreLoginRedirection = (props) => {

    const logoutAndRedirect = () => {
        let uuid = props?.match?.params?.uuid;
        props.setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
        props.logoutUser();
        props.history.push('/financial-transaction/'+uuid);
    };

    return (
        <PreLoginRedirectionView
            {...props}
            logoutAndRedirect={logoutAndRedirect}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    userAuthStatus: state.userState.userAuthStatus,
});

export default connect(mapStateToProps, {
    logoutUser,
    setUserAuthStatus
})(PreLoginRedirection);