import React, {useEffect, useState} from 'react';

import {getFTAnalyticsFinancialsCRM} from "../../../../../../../../HomePageActions";

import SummaryByFinancialView from "./SummaryByFinancialCategoryView";

const SummaryByFinancial = (props) => {
    const [financialState, setFinancialState] = useState({
        data: [],
        isShowLoader: false,
    });

    useEffect(() => {
        getFinancialData();
    }, [props.isSelected])

    const getFinancialData = () => {
        const {isSelected} = props;

        setFinancialState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }))

        getFTAnalyticsFinancialsCRM(isSelected).then(res => {
                if (res?.success) {
                    setFinancialState((prevState) => ({
                        ...prevState,
                        data: res.data,
                        isShowLoader: false,
                    }));
                } else {
                    setFinancialState((prevState) => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                }
            }
        );
    }

    const getPieData = (data) => {
        return data?.map((item, index) => ({
            id: item.id || index,
            value: item.amount || 0,
            name: item.name,
            clients: item.clients || 0,
            amount: item.amount || 0,
        }));
    };

    return (
        <SummaryByFinancialView
            {...props}
            {...financialState}
            getPieData={getPieData}
        />
    );
};

export default SummaryByFinancial;