import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

import {APP_CONST} from "../../../AppComponents/AppConstants";
import {getCRMs} from "../../../AppComponents/MetadataActions";
import ManagementListView from "./ManagementListView";
import {
    getClientRequestsData,
    resetClientRequestsFilters,
    setClientRequestsRowPerPageCount,
    setCRM,
    setLimitCount,
    setPageCount,
    setSearchKeyword,
    setSkipCount,
    setStatus,
} from "../../ClientRequestsActions"

const ManagementList = (props) => {
    const{clientRequestsFilter}=props;
    const [managementListState, setManagementListState] = useState({
        clientRequests: [],
        listingCount: 0,
        isShowFilterMobile: false,

        UIState: '',
        isShowLoader: false,
        CRMs: [],
        initialStatus:clientRequestsFilter.status,
        selectedStatus:clientRequestsFilter.status
    });

    useEffect(() => {
        getCRMData()
    }, []);

    useEffect(() => {
        getClientRequests()
    }, [props.clientRequestsFilter]);

    const {page, rowsPerPage, skipCount, limitCount} = props.clientRequestsFilter;

    const handleChange = (event) => {
        // Destructuring to extract 'value' from the event target (the select element)
        const {
            target: { value },
        } = event;
        // If the value is a string (this can happen when the multiple select values are joined by commas),
        // we split it into an array. Otherwise, use the value as-is (if it's already an array).
        const newStatus = typeof value === 'string' ? value.split(',') : value;
        setManagementListState((prevStep) => ({
            ...prevStep,
            selectedStatus:newStatus
        }));
        onChangeFilter('status', newStatus);
    };

    const resetClientFilters=()=>{
        const {initialStatus}=managementListState;
        resetClientRequestsFilters();
        setManagementListState((prevStep) => ({
            ...prevStep,
            selectedStatus:initialStatus
        }));
    };
    const getCRMData = () => {
        setManagementListState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));
        getCRMs().then((CRMRes) => {
            if (CRMRes?.success) {
                setManagementListState((prevStep) => ({
                    ...prevStep,
                    CRMs: setValuesForDropdown(CRMRes.data?.advisoryUsers, "firstName", "id"),
                    isShowLoader: false
                }));

            } else {
                setManagementListState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false
                }));
            }
        })
    };

    const getCRMName = (id) => {
        const {CRMs} = managementListState;
        let CRMName = "";
        if (isEmpty(id)) {
            return "";
        }
        CRMs.forEach((item) => {
            if (item?.value === id.toString()) {
                CRMName = item?.label;
            }
        });
        return isEmpty(CRMName) ? '-' : CRMName;
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: 'ALL'}];
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken] + " " + item?.lastName, value: item?.[valueToken].toString()})
        });
        return list;
    };

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.status)) {
            if (filter.status.length === 0) {
                delete filterObject.status
            } else {
                filterObject.status = filter.status;
            }
        }
        if (!isEmpty(filter.CRMId) && filter.CRMId !== "ALL") {
            filterObject.CRMId = filter.CRMId;
        }
        return filterObject;
    };

    const getClientRequests = () => {
        const {clientRequestsFilter} = props;

        setManagementListState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            skipCount: clientRequestsFilter.skipCount,
            limitCount: clientRequestsFilter.limitCount,
            filters: generateFilter(clientRequestsFilter)
        };

        getClientRequestsData(payload)
            .then(res => {
                if (res?.success) {
                    setManagementListState(prevState => ({
                        ...prevState,
                        clientRequests: res.data?.clientRequests,
                        listingCount: res.data?.count,
                        skipCount: clientRequestsFilter.skipCount,
                        limitCount: clientRequestsFilter.limitCount,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setManagementListState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    // Function to determine whether to enable/disable the pagination control buttons
    const getPaginationNextButtonStatus = () => {
        const {listingCount} = managementListState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setSearchKeyword(value);
            return;
        }

        if (name === 'status') {
            setStatus([...value]);
        }

        if (name === 'CRMId') {
            setCRM(value);
        }
    };

    const resetPagination = () => {
        setClientRequestsRowPerPageCount(50);
        setLimitCount(50);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setManagementListState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setClientRequestsRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let tempLimitCount = limitCount;
        let tempSkipCount = skipCount;

        const isForward = newPage > page;
        if (isForward) {
            tempSkipCount = tempSkipCount + rowsPerPage;
            tempLimitCount = rowsPerPage
        } else {
            tempSkipCount = tempSkipCount - rowsPerPage;
            tempLimitCount = rowsPerPage
        }

        setSkipCount(tempSkipCount);
        setLimitCount(tempLimitCount);
        setPageCount(newPage);
    };

    const handleToggleMobileFilter = (value) => {
        setManagementListState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const resetStatusFilters = () => {
        resetClientRequestsFilters();
    };

    const handleRefreshButton = () => {
        getCRMData();
        getClientRequests();
    };

    const redirectToDetailsScreen = (clientRequestsId) => {
        props.history.push('/client-requests/' + clientRequestsId)
    };

    if (isEmpty(managementListState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <ManagementListView
            {...props}
            {...managementListState}
            {...props.clientRequestsFilter}
            handleRefreshButton={handleRefreshButton}
            handleToggleMobileFilter={handleToggleMobileFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            onChangeFilter={onChangeFilter}
            redirectToDetailsScreen={redirectToDetailsScreen}
            getCRMName={getCRMName}
            handleChange={handleChange}
            resetClientFilters={resetClientFilters}
            resetStatusFilters={resetStatusFilters}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    clientRequestsFilter: state.clientRequests.clientRequestsFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(ManagementList);
