import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {PieChart} from 'echarts/charts';
import {GridComponent, LegendComponent, TitleComponent, TooltipComponent,} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import {convertAmountInLakhAndCrore, numberWithCommas} from "../../utils/helper";

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);


function EChartDougnute(props) {
    const {data} = props;
    const option = {
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                return `<span style="font-size: 16px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif">${params.name}</span> 
                        <br/>
                        <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${numberWithCommas(convertAmountInLakhAndCrore(params.value))}</span> 
                        <span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> Amount </span>
                        <br/>
                        <span style="font-size: 14px; font-weight: 600; color: #282828 ; font-family: 'Poppins', sans-serif""> ${params.percent}% 
                        </span><span style="font-size: 10px; font-weight: 500; color: #282828 ; font-family: 'Poppins', sans-serif""> of Total </span>`;
            },
            position: function (point, params, dom, rect, size) {
                // Adjust the tooltip position to stay within the screen bounds
                const top = Math.max(10, point[1]);
                const left = Math.min(window.innerWidth - size.contentSize[0] - 10, point[0]);
                return [left, top];
            },
        },
        legend: {
            show: false
        },
        series: [
            {
                type: 'pie',
                radius: [30, 70],
                left: 'center',
                width: '100%',
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1,
                },
                data: data,
                label: {
                    show: true,
                    position: 'outside',
                    formatter: '{b}',
                    fontSize: 10,
                    color: '#282828',
                    minMargin: 5
                },
                labelLine: {
                    show: true,
                    length: 15,
                    length2: 10,
                },
            },
        ],
    };

    return (
        <div id="main" style={{width: '100%', height: '100%'}}>
            <ReactEChartsCore
                echarts={echarts}
                option={option}
                notMerge={true}
                lazyUpdate={true}
            />
        </div>
    );
}

export default EChartDougnute;

