import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DLTextView from "./DLTextView";

class DLText extends Component {

    render() {
        if (this.props.hidden) {
            return null;
        }
        return (
                <DLTextView {...this.props} />
        );
    }
}

DLText.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.any,
    fontColor: PropTypes.oneOf([
        'primary', 'secondary', 'tertiary', 'success', 'successLight', 'danger', 'darkBlue', 'lightBlue', 'grayLight', 'grayDark', 'gray', 'disable', 'black', 'white'
    ]),
    fontSize: PropTypes.oneOf([
        'xxs', 'xs', 'sm', 'md', 'lg', 'xl','xxl'
    ]),
    fontWeight: PropTypes.oneOf([
        'normal', 'bold', 'semi-bold'
    ]),
    icon: PropTypes.element,
    iconPosition: PropTypes.oneOf([
        'start', 'end'
    ]),
    marginBottom: PropTypes.oneOf([
        'none', 'xs', 'sm', 'md', 'lg', 'xl'
    ]),
    isInline: PropTypes.bool,
    isClickable: PropTypes.bool,
    onClick: PropTypes.func,
    customTextStyle: PropTypes.object,
    customIconStyle: PropTypes.object,
    customWrapperStyle: PropTypes.object,
    truncateText: PropTypes.bool,
};

DLText.defaultProps = {
    uiType: 'text',
    fontSize: 'sm',
    fontWeight: 'normal',
    marginBottom: 'xs',
    iconPosition: 'start',
    isInline: false,
    isClickable: false,
    truncateText: false,
    fontColor: 'black'
};

export default DLText;
