import React from "react";
import {connect} from "react-redux";

import OverviewAmountTypeView from "./OverviewAmountTypeView";

const OverviewAmountType = (props) => {

    return (
        <OverviewAmountTypeView{...props} />
    )
}

const mapStateToProps = (state) => ({
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(OverviewAmountType);
