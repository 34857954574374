import TxnTableSIPView from "./TxnTableSIPView";
import React from "react";

const TxnTableSIP = (props) => {
    return (
        <TxnTableSIPView
            {...props}
        />
    );
};

export default TxnTableSIP;
