import React from 'react';

import FTDetailsForManagement from "./FTDetailsForManagement/FTDetailsForManagement";
import FTDetailsForCRM from "./FTDetailsForCRM/FTDetailsForCRM";

const FTDetailsView = (props) => {
    const {isManagementUser, isSetUserType} = props;

    if (!isSetUserType) {
        return ''
    }

    if (isManagementUser) {
        return (
            <div className='w-100 h-100'>
                <FTDetailsForManagement {...props}/>
            </div>

        );
    } else {
        return (
            <div className='w-100 h-100'>
                <FTDetailsForCRM {...props} />
            </div>

        );
    }
};

export default FTDetailsView;