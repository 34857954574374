import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import FTReportListView from "./FTReportListView";

const FTReportList = (props) => {
    const {profile} = props;

    const [financialTransactionsReportState, setFinancialTransactionsReportState] = useState({
        isManagementUser: false,
        isSetUserType: false
    });

    useEffect(() => {
        setFinancialTransactionsReportState((prevState) => ({
            ...prevState,
            isManagementUser: profile?.isManagement,
            isSetUserType: true
        }))

    }, [profile?.isManagement]);


    return (
       <FTReportListView
           {...props}
           {...financialTransactionsReportState}
       />
    )
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(FTReportList);


