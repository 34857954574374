import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {getClientBankAccount} from "../../../../ClientsActions";
import {isEmpty} from "../../../../../utils/validations";
import {getBanks, getBankAccountType} from "../../../../../AppComponents/MetadataActions";
import BankAccountView from "./bankAccountView";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

const BankAccount = (props) => {
    const [bankAccountState, setBankAccountState] = useState({
        banks: [],
        banksData: [],
        accountTypeData: [],
        isBankLoaderVisible: false,
        UIState: '',
        respError: '',
        bankMandates: []
    });

    useEffect(() => {
        setBankAccountDetails()
    }, [props.accountId]);

    const setBankAccountDetails = () => {
        const {accountId} = props;
        if (isEmpty(accountId)) {
            return
        }
        setBankAccountState(prevState => ({
            ...prevState,
            isBankLoaderVisible: true
        }));
        getBankAccountType().then(typeRes => {
            const accountTypeData = typeRes?.data?.bankAccountType;
            getBanks()
                .then(banksRes => {
                    const banksData = banksRes?.data?.banks;

                    getClientBankAccount(accountId)
                        .then(bankResp => {
                            if(bankResp?.success){
                                setBankAccountState((prevState) => ({
                                    ...prevState,
                                    banks: bankResp?.data?.banks,
                                    bankMandates: getBankMandates(bankResp?.data?.banks),
                                    banksData: banksData,
                                    accountTypeData: accountTypeData,
                                    isBankLoaderVisible: false,
                                    UIState: APP_CONST.CONTENT_AVAILABLE,
                                    respError: '',
                                }));
                            }else{
                                setBankAccountState((prevState) => ({
                                    ...prevState,
                                    respError: res?.__error,
                                    isBankLoaderVisible: false,
                                    UIState: APP_CONST.CONTENT_UNAVAILABLE
                                }));
                            }

                        })
                })
        })

    };

    const getBankMandates = (banks) => {
        let allMandate = [];
        if(isEmpty(banks)){
           return allMandate;
        }
        banks.map((bankAccount) => {
            if (!isEmpty(bankAccount?.mandateReports)) {
                allMandate.push(bankAccount);
            }
        })
        return allMandate;
    }

    const getAccountType = (id) => {
        const {accountTypeData} = bankAccountState;
        if (isEmpty(accountTypeData) || isEmpty(id)) {
            return '-'
        }
        let accountObj = accountTypeData.find(item => item?.code === id);
        return isEmpty(accountObj?.description) ? '' : accountObj?.description
    };

    const getBankName = (id) => {
        const {banksData} = bankAccountState;
        if (isEmpty(banksData) || isEmpty(id)) {
            return '-'
        }
        let bankObj = banksData.find(item => item?.code === id);
        return isEmpty(bankObj?.description) ? '' : bankObj?.description
    };

    const getBankMandateData = (mandateData) => {
        let tableData = [];
        if (isEmpty(mandateData)) {
            return tableData
        }
        mandateData.forEach((obj)=>{
            let data = {};
            data.bankName = obj?.bankName
            data.accountNumber = obj?.accountNumber
            obj?.mandateReports?.forEach((item)=>{
                data.toDate = item?.toDate;
                data.fromDate = item?.fromDate;
                data.achMandateStatus = item?.achMandateStatus;
                data.amount = item?.amount;
            })
            tableData.push(data)
        })
        return tableData;
    };

    return (
        <BankAccountView
            {...props}
            {...bankAccountState}
            getAccountType={getAccountType}
            getBankName={getBankName}
            getBankMandateData={getBankMandateData}
        />
    );
};
const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(BankAccount);
