import React, {useEffect, useState} from 'react'

import FTReportStatusModalView from "./FTReportStatusModalView";

const FTReportStatusModal = (props) => {
    const {handleStatusModalClose, selectedId, FTReportListDetails} = props;

    useEffect(() => {
        setReportDetails() ;
    }, [])

    const [FTReportStatusModalState, setFTReportStatusModalState] = useState({
        FTReportListDetails: [],
        isShowLoader: false,
    })

    const setReportDetails = () => {
        setFTReportStatusModalState((prevState) => ({
            ...prevState,
            selectedId: selectedId,
            FTReportDetails: getTnxDetailsById(selectedId, FTReportListDetails)
        }))
    }

    const getTnxDetailsById = (id, txnDetails) => {
        return txnDetails?.fundReportItems.find(txn => txn?.id === id);
    }

    return (
        <FTReportStatusModalView
            {...props}
            {...FTReportStatusModalState}
            handleStatusModalClose={handleStatusModalClose}
        />
    )
}

export default FTReportStatusModal;
