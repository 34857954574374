import React from 'react';
import ManagementDashboard from "./components/managementDashboard/ManagementDashboard";
import CRMDashboard from "./components/CRMDashboard/CRMDashboard";

const HomePageView = (props) => {
    const {isManagement} = props;

    const renderCRMTable = () => {
        return (
            <CRMDashboard
                {...props}
            />
        )
    };

    const renderManagementTable = () => {
        return (
            <ManagementDashboard
                {...props}
            />
        )
    };


    return (
        <div className='pb-5 h-100 bg-light'>
            <div className='h-100'>
                {(isManagement) ? renderManagementTable() : renderCRMTable()}
            </div>
        </div>
    );
};

export default HomePageView;