import React, {useEffect} from 'react';

import DLText from "../../../core/components/DLText/DLText";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLSearchAutoComplete from "../../../core/components/DLSearchAutoComplete/DLSearchAutoComplete";

import appTheme from "../../../assets/appTheme"

import {isEmpty} from "../../../utils/validations";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import {debounce} from "../../../core/components/DLComponentHelper";
import {numberWithCommas, searchOnWordBoundary} from "../../../utils/helper";

const BankAccountView = (props) => {

    const {
        bankDetails, bankAddress, accountHolderDetails, errors, handleChange, onSearchClick, dropdownValues, onNextClick,
        isMobileView, scrollToFieldId, isTabUI,isFromOnboardingFlow, bankMandate
    } = props;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {

        handleChange('scrollToFieldId', '')
    }, 1000);

    const renderBankDetails = () => {
        return (
            <div className='mt-2'>
                <div className='section-title' id={'fathers-information'}>
                    Bank Details
                </div>
                <div className='panel'>
                    <div className={'row w-100'}>
                        <div id='first_block'
                             className={isTabUI() ? 'col-9' : 'col-6'}>
                            <div className='row align-items-center'>
                                <div id='bankDetails-IFSCCode-id' className='col-6'>
                                    <DLInputTextField
                                        id={'ifsc-code-input-field'}
                                        label={'IFSC Code'}
                                        value={bankDetails?.IFSCCode}
                                        maxCharLength={11}
                                        isClearable={false}
                                        error={errors.bankDetails?.IFSCCode !== ""}
                                        helperMessage={errors.bankDetails?.IFSCCode}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        onChangeValue={(e) => {
                                            handleChange("IFSCCode", e, "bankDetails")
                                        }}
                                        isRequired={true}
                                    />

                                </div>
                                <div className={'col-5 p-0'}>
                                    <div className={errors.bankDetails?.IFSCCode !== "" ? "mb-3" : "mt-1"}>
                                        <DLButton
                                            id={'search-button'}
                                            styleType={"outlined"}
                                            borderType={'square'}
                                            buttonSize={'sm'}
                                            fontSize={'sm'}
                                            sideMargin={'none'}
                                            onClick={() => {
                                                onSearchClick(bankDetails?.IFSCCode)
                                            }}
                                            label={"Search"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div id='bankDetails-bankName-id' className='col-6'>
                                    <DLSearchAutoComplete
                                        id={'account-type-dropDown'}
                                        label={'Bank Name'}
                                        value={bankDetails?.bankName}
                                        placeholder={""}
                                        loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.banks)}
                                        onSelect={(e) => handleChange("bankName", e, "bankDetails")}
                                        isClearable={false}
                                        isSearchable={true}
                                        error={errors.bankDetails?.bankName !== ""}
                                        helperMessage={errors.bankDetails?.bankName}
                                        itemNotFoundText={"No options found'"}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        isMulti={false}
                                        isRequired={true}
                                        defaultOptions={dropdownValues?.banks}
                                        noOptionsMessage={"No options found'"}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div id='bankDetails-branch-id' className='col-6'>
                                    <DLInputTextField
                                        id={'branch-input-field'}
                                        label={'Branch'}
                                        maxCharLength={40}
                                        value={bankDetails?.branch}
                                        isClearable={false}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'md'}
                                        error={errors.bankDetails?.branch !== ""}
                                        helperMessage={errors.bankDetails?.branch}
                                        onChangeValue={(e) => {
                                            handleChange("branch", e, "bankDetails")
                                        }}
                                        isRequired={true}
                                    />
                                </div>
                                <div className='col-6'>
                                    <DLInputTextField
                                        id={'micr-no-input-field'}
                                        label={'MICR No (Optional)'}
                                        value={bankDetails?.MICRNo}
                                        maxCharLength={9}
                                        isClearable={false}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'md'}
                                        inputType={"number"}
                                        error={errors.bankDetails?.MICRNo !== ""}
                                        helperMessage={errors.bankDetails?.MICRNo}
                                        onChangeValue={(e) => {
                                            handleChange("MICRNo", e, "bankDetails")
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderBankAddressDetails = () => {
        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'bank-address'}>
                    Bank Address
                </div>
                <div className='panel'>
                    <div className={'row w-100'}>
                        <div id='first_block'
                             className={isTabUI() ? 'col-9' : 'col-6'}>
                            <div id='bankAddress-addressLine1-id' className='row'>
                                <DLInputTextField
                                    id={'bank-address-input-field'}
                                    label={'Address Line 1'}
                                    isRequired={true}
                                    value={bankAddress?.addressLine1}
                                    maxCharLength={80}
                                    isClearable={false}
                                    error={errors.bankAddress?.addressLine1 !== ""}
                                    helperMessage={errors.bankAddress?.addressLine1}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    onChangeValue={(e) => {
                                        handleChange("addressLine1", e, "bankAddress")
                                    }}
                                />

                            </div>
                            <div id='bankAddress-addressLine2-id' className='row'>
                                <DLInputTextField
                                    id={'bank-address-input-field'}
                                    label={"Address Line 2 (Optional)"}
                                    value={bankAddress?.addressLine2}
                                    maxCharLength={50}
                                    isClearable={false}
                                    error={errors.bankAddress?.addressLine2 !== ""}
                                    helperMessage={errors.bankAddress?.addressLine2}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    onChangeValue={(e) => {
                                        handleChange("addressLine2", e, "bankAddress")
                                    }}
                                />
                            </div>
                            <div id='bankAddress-addressLine3-id' className='row'>
                                <DLInputTextField
                                    id={'bank-address-input-field'}
                                    label={'Address Line 3 (Optional)'}
                                    value={bankAddress.addressLine3}
                                    maxCharLength={50}
                                    isClearable={false}
                                    error={errors.bankAddress?.addressLine3 !== ""}
                                    helperMessage={errors.bankAddress?.addressLine3}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    onChangeValue={(e) => {
                                        handleChange("addressLine3", e, "bankAddress")
                                    }}
                                />
                            </div>
                            <div className='row'>
                                <div id='bankAddress-city-id' className='col-6'>
                                    <DLInputTextField
                                        id={'city-input-field'}
                                        label={"City"}
                                        value={bankAddress?.city}
                                        maxCharLength={30}
                                        isClearable={false}
                                        error={errors.bankAddress?.city !== ""}
                                        helperMessage={errors.bankAddress?.city}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        onChangeValue={(e) => {
                                            handleChange("city", e, "bankAddress")
                                        }}
                                        isRequired={true}
                                    />

                                </div>
                                <div id='bankAddress-zipCode-id' className='col-6'>
                                    <DLInputTextField
                                        id={'pin-code-input-field'}
                                        label={"Pin Code (Optional)"}
                                        value={bankAddress?.zipCode}
                                        maxCharLength={6}
                                        isClearable={false}
                                        inputType={"number"}
                                        error={errors.bankAddress?.zipCode !== ""}
                                        helperMessage={errors.bankAddress?.zipCode}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        onChangeValue={(e) => {
                                            handleChange("zipCode", e, "bankAddress")
                                        }}
                                        isRequired={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div id='bankAddress-stateCode-id' className='col-6'>
                                    <DLSearchAutoComplete
                                        id={'stateCode-dropDown'}
                                        label={'State'}
                                        value={bankAddress?.stateCode}
                                        placeholder={""}
                                        onSelect={(e) => handleChange("stateCode", e, "bankAddress")}
                                        isClearable={false}
                                        isSearchable={true}
                                        error={errors.bankAddress?.stateCode !== ""}
                                        helperMessage={errors.bankAddress?.stateCode}
                                        itemNotFoundText={"No options found'"}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'md'}
                                        isMulti={false}
                                        loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}
                                        defaultOptions={dropdownValues?.states}
                                        noOptionsMessage={"No options found'"}
                                        isRequired={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderAccountHolderDetails = () => {
        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'bank-account-holder'}>
                    Account Holder Details
                </div>
                <div className={'panel'}>
                    <div className={'row  pb-2 w-100'}>
                        <div className={isTabUI() ? 'col-12' : 'col-9'}>
                            <div className={"row"}>
                                <div id='accountHolderDetails-accountHolderName-id' className='col-4'>

                                    <DLInputTextField
                                        id={'account-holder-name-input-field'}
                                        label={'Name'}
                                        value={accountHolderDetails?.accountHolderName}
                                        maxCharLength={60}
                                        isClearable={false}
                                        error={errors.accountHolderDetails?.accountHolderName !== ""}
                                        helperMessage={errors.accountHolderDetails?.accountHolderName}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        onChangeValue={(e) => {
                                            handleChange("accountHolderName", e, "accountHolderDetails")
                                        }}
                                        isRequired={true}
                                    />
                                </div>
                                <div id='accountHolderDetails-accountNumber-id' className='col-4'>

                                    <DLInputTextField
                                        id={'account-number-input-field'}
                                        label={'Account Number'}
                                        value={accountHolderDetails?.accountNumber}
                                        maxCharLength={20}
                                        isClearable={false}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        inputType={"number"}
                                        error={errors.accountHolderDetails?.accountNumber !== ""}
                                        helperMessage={errors.accountHolderDetails?.accountNumber}
                                        onChangeValue={(e) => {
                                            handleChange("accountNumber", e, "accountHolderDetails")
                                        }}
                                        isRequired={true}
                                    />
                                </div>
                                <div id='accountHolderDetails-bankAccountType-id' className='col-4'>
                                    <DLSearchAutoComplete
                                        id={'account-type-dropDown'}
                                        label={'Account Type'}
                                        value={accountHolderDetails?.bankAccountType}
                                        placeholder={""}
                                        onSelect={(e) => handleChange("bankAccountType", e, "accountHolderDetails")}
                                        isClearable={false}
                                        isSearchable={false}
                                        error={errors.accountHolderDetails?.bankAccountType !== ""}
                                        helperMessage={errors.accountHolderDetails?.bankAccountType}
                                        itemNotFoundText={"No options found'"}
                                        isMulti={false}
                                        dropDownPlacement={"top"}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        defaultOptions={dropdownValues?.accountTypes}
                                        noOptionsMessage={"No options found'"}
                                        isRequired={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderBankMandateDetails = () => {
        return (
            <div className='mt-2 pb-3'>
                <div className='section-title'
                     id={'bank-mandate-amount'}>
                    Bank Mandate Amount
                </div>
                <div className={'panel'}>
                    <div className={'row  pb-2 w-100'}>
                        <div className={isTabUI() ? 'col-12' : 'col-9'}>
                            <div className={"row"}>
                                <div id='bankMandate-amount-id' className='col-4'>

                                    <DLInputTextField
                                        id={'bank-mandate-amount-input-field'}
                                        label={'Amount'}
                                        value={numberWithCommas(bankMandate?.amount)}
                                        maxCharLength={60}
                                        isClearable={false}
                                        error={errors.bankMandate?.amount !== ""}
                                        helperMessage={errors.bankMandate?.amount}
                                        disableHelperMessageHeight={true}
                                        inputType={"number"}
                                        marginBottom={'sm'}
                                        onChangeValue={(e) => {
                                            handleChange("amount", e, "bankMandate")
                                        }}
                                        isRequired={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderButtonUI = () => {
        const {isFromOnboardingFlow, onSaveAndCloseClick} = props;
        return (
            <div className='sticky-bottom-buttons'>
                {isFromOnboardingFlow ?
                    <>
                        <DLButton
                            id={'save&close-button'}
                            styleType={"outlined"}
                            borderType={'square'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => {
                                onSaveAndCloseClick()
                            }}
                            label={"Save & Close"}
                        />
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      onNextClick()
                                  }}
                                  label={"Next"}/>
                    </>
                    :
                    <DLButton id={'delete-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  onNextClick()
                              }}
                              label={"Save"}/>
                }
            </div>
        );
    };

    const renderMobileBankDetails = () => {
        return (<div className='block-padding'>
            <DLText
                id={'bank-account'}
                type={'normal'}
                text={"Bank Account"}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}
            />
            <div id='bankDetails-IFSCCode-id' className="d-flex align-items-center">
                <div className={"flex-grow-1 me-2"}>
                    <DLInputTextField id={'m-ifsc-code'}
                                      label={"IFSC Code"}
                                      value={bankDetails?.IFSCCode}
                                      maxCharLength={11}
                                      isClearable={false}
                                      error={errors.bankDetails?.IFSCCode !== ""}
                                      helperMessage={errors.bankDetails?.IFSCCode}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange("IFSCCode", e, "bankDetails")
                                      }}
                                      isRequired={true}
                    />
                </div>
                <div className={errors.bankDetails?.IFSCCode !== "" ? "mb-3 mt-1" : "mt-1"}>
                    <DLButton
                        id={'search-button'}
                        styleType={"outlined"}
                        borderType={'square'}
                        buttonSize={'sm'}
                        fontSize={'sm'}
                        sideMargin={'none'}
                        onClick={() => {
                            onSearchClick(bankDetails?.IFSCCode)
                        }}
                        label={"Search"}
                    />
                </div>
            </div>
            <div id='bankDetails-bankName-id' className={'input-text-field-wrapper'}>
                <DLText
                    id={'m-account-type'}
                    type={'normal'}
                    text={"Bank Name"}
                    fontSize={'xs'}
                    fontColor={'grayDark'}
                />
                <DLSearchAutoComplete id={'account-type-dropDown'}
                                      value={bankDetails?.bankName}
                                      placeholder={""}
                                      onSelect={(e) => handleChange("bankName", e, "bankDetails")}
                                      isClearable={false}
                                      isSearchable={true}
                                      loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.banks)}
                                      error={errors.bankDetails?.bankName !== ""}
                                      helperMessage={errors.bankDetails?.bankName}
                                      itemNotFoundText={"No options found'"}
                                      isMulti={false}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      defaultOptions={dropdownValues?.banks}
                                      noOptionsMessage={"No options found'"}
                                      isRequired={true}
                />
            </div>

            <div id='bankDetails-branch-id'>
                <DLInputTextField id={'m-branch'}
                                  label={"Branch"}
                                  value={bankDetails?.branch}
                                  maxCharLength={40}
                                  isClearable={false}
                                  error={errors.bankDetails?.branch !== ""}
                                  helperMessage={errors.bankDetails?.branch}
                                  disableHelperMessageHeight={true}
                                  marginBottom={'sm'}
                                  onChangeValue={(e) => {
                                      handleChange("branch", e, "bankDetails")
                                  }}
                                  isRequired={true}
                />
            </div>
            <div id='bankDetails-MICRNo-id'>
                <DLInputTextField id={'m-micr-no'}
                                  label={"MICR No (Optional)"}
                                  value={bankDetails?.MICRNo}
                                  maxCharLength={9}
                                  isClearable={false}
                                  inputType={"number"}
                                  error={errors.bankDetails?.MICRNo !== ""}
                                  helperMessage={errors.bankDetails?.MICRNo}
                                  disableHelperMessageHeight={true}
                                  marginBottom={'sm'}
                                  onChangeValue={(e) => {
                                      handleChange("MICRNo", e, "bankDetails")
                                  }}
                />
            </div>
        </div>)
    };

    const renderMobileBankAddressDetails = () => {
        return (<div className='block-padding'>
            <DLText
                id={'m-address'}
                type={'normal'}
                text={"Bank Address"}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}
            />
            <div id='bankAddress-addressLine1-id'>
                <DLInputTextField
                    id={'address-line-1'}
                    label={"Address Line 1"}
                    value={bankAddress?.addressLine1}
                    maxCharLength={80}
                    isClearable={false}
                    error={errors.bankAddress?.addressLine1 !== ""}
                    helperMessage={errors.bankAddress?.addressLine1}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("addressLine1", e, "bankAddress")
                    }}
                    isRequired={true}
                />
            </div>
            <div id='bankAddress-addressLine2-id'>
                <DLInputTextField
                    id={'address-line-2'}
                    label={"Address Line 2 (Optional)"}
                    value={bankAddress?.addressLine2}
                    maxCharLength={50}
                    isClearable={false}
                    error={errors.bankAddress?.addressLine2 !== ""}
                    helperMessage={errors.bankAddress?.addressLine2}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("addressLine2", e, "bankAddress")
                    }}
                />
            </div>
            <div id='bankAddress-addressLine3-id'>
                <DLInputTextField
                    id={'address-line-3'}
                    label={"Address Line 3 (Optional)"}
                    value={bankAddress?.addressLine3}
                    maxCharLength={50}
                    isClearable={false}
                    error={errors.bankAddress?.addressLine3 !== ""}
                    helperMessage={errors.bankAddress?.addressLine3}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("addressLine3", e, "bankAddress")
                    }}
                />
            </div>

            <div id='bankAddress-city-id'>
                <DLInputTextField
                    id={'m-city'}
                    label={"City"}
                    value={bankAddress?.city}
                    maxCharLength={30}
                    isClearable={false}
                    error={errors.bankAddress?.city !== ""}
                    helperMessage={errors.bankAddress?.city}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("city", e, "bankAddress")
                    }}
                    isRequired={true}
                />
            </div>
            <div id='bankAddress-zipCode-id'>
                <DLInputTextField
                    id={'m-pin-code'}
                    label={"Pin Code (Optional)"}
                    value={bankAddress?.zipCode}
                    maxCharLength={6}
                    isClearable={false}
                    error={errors.bankAddress?.zipCode !== ""}
                    helperMessage={errors.bankAddress?.zipCode}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    inputType={"number"}
                    onChangeValue={(e) => {
                        handleChange("zipCode", e, "bankAddress")
                    }}
                    isRequired={true}
                />
            </div>

            <div id='bankAddress-state-id' className={'input-text-field-wrapper'}>
                <DLSearchAutoComplete
                    id={'state-dropDown'}
                    label={'State'}
                    value={bankAddress?.stateCode}
                    placeholder={""}
                    loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}
                    onSelect={(e) => handleChange("stateCode", e, "bankAddress")}
                    isClearable={false}
                    isSearchable={true}
                    error={errors.bankAddress?.stateCode !== ""}
                    helperMessage={errors.bankAddress?.stateCode}
                    itemNotFoundText={"No options found'"}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    isMulti={false}
                    defaultOptions={dropdownValues?.states}
                    noOptionsMessage={"No options found'"}
                    isRequired={true}
                />
            </div>
        </div>)
    };

    const renderMobileAccountHolderDetails = () => {
        return (<div className='block-padding'>
            <DLText
                id={'m-bank-account-holder'}
                type={'normal'}
                text={"Account Holder Details"}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}
            />
            <div id='accountHolderDetails-accountHolderName-id'>
                <DLInputTextField
                    id={'m-account-holder-name'}
                    label={"Account Holder Name"}
                    value={accountHolderDetails?.accountHolderName}
                    maxCharLength={60}
                    isClearable={false}
                    error={errors.accountHolderDetails?.accountHolderName !== ""}
                    helperMessage={errors.accountHolderDetails?.accountHolderName}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("accountHolderName", e, "accountHolderDetails")
                    }}
                    isRequired={true}
                />
            </div>
            <div id='accountHolderDetails-accountNumber-id'>
                <DLInputTextField
                    id={'m-account-number'}
                    label={"Account Number"}
                    value={accountHolderDetails?.accountNumber}
                    maxCharLength={20}
                    isClearable={false}
                    inputType={"number"}
                    error={errors.accountHolderDetails?.accountNumber !== ""}
                    helperMessage={errors.accountHolderDetails?.accountNumber}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    onChangeValue={(e) => {
                        handleChange("accountNumber", e, "accountHolderDetails")
                    }}
                    isRequired={true}
                />
            </div>
            <div id='accountHolderDetails-bankAccountType-id' className={'input-text-field-wrapper'}>
                <DLSearchAutoComplete
                    id={'account-type-dropDown'}
                    label={'Account Type'}
                    value={accountHolderDetails?.bankAccountType}
                    placeholder={""}
                    onSelect={(e) => handleChange("bankAccountType", e, "accountHolderDetails")}
                    isClearable={false}
                    isSearchable={false}
                    error={errors.accountHolderDetails?.bankAccountType !== ""}
                    helperMessage={errors.accountHolderDetails?.bankAccountType}
                    itemNotFoundText={"No options found'"}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    isMulti={false}
                    dropDownPlacement={"top"}
                    defaultOptions={dropdownValues?.accountTypes}
                    noOptionsMessage={"No options found'"}
                    isRequired={true}
                />
            </div>
        </div>)
    };

    const renderMobileBankMandate = () => {
        return (<div className='block-padding'>
            <DLText
                id={'m-bank-mandate-amount'}
                type={'normal'}
                text={"Bank Mandate Amount"}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}
            />
            <div id='bankMandate-amount-id'>

                <DLInputTextField
                    id={'bank-mandate-amount-input-field'}
                    label={'Amount'}
                    value={numberWithCommas(bankMandate?.amount)}
                    maxCharLength={60}
                    isClearable={false}
                    error={errors.bankMandate?.amount !== ""}
                    helperMessage={errors.bankMandate?.amount}
                    disableHelperMessageHeight={true}
                    marginBottom={'sm'}
                    inputType={"number"}
                    onChangeValue={(e) => {
                        handleChange("amount", e, "bankMandate")
                    }}
                    isRequired={true}
                />
            </div>
        </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div className={'w-100  border-rounded'}>
                <div style={{
                    overflowY: "auto",
                    maxHeight: isFromOnboardingFlow ? 'calc(100dvh - 200px)':'calc(100dvh - 200px)'}}>
                    <div className={'page-container'}>
                        {renderErrorUI()}
                        {renderBankDetails()}
                        {renderBankAddressDetails()}
                        {renderAccountHolderDetails()}
                        {renderBankMandateDetails()}
                    </div>
                </div>
                {renderButtonUI()}
            </div>
        )
    };

    const renderMobileUI = () => {
        const {responseErrors} = props;
        return (
            <div style={{maxHeight: 'calc(100dvh - 230px)', overflowY: "auto"}}>
                <div className={'mb-3 bg-white'}>
                    <div className={isEmpty(responseErrors) ? '': 'block-padding' }>
                        {renderErrorUI()}
                    </div>
                    {renderMobileBankDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobileBankAddressDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobileAccountHolderDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobileBankMandate()}
                </div>
                {renderButtonUI()}
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id={'page-level-errors'}
                 className='my-3'>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='py-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };


    return (
        <>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            <DLLoader isVisible={props.isLoaderVisible} type={'screen'}/>
        </>
    )
};

export default BankAccountView;