import React, {useState} from 'react';
import AnalyticsView from "./AnalyticsView";

const AnalyticsManagement = (props) => {
    const [analytics, setAnalytics] = useState({
        isSelected: 'THIS_MONTH',
    })

    const getAnalyticsData = (interval) => {
        setAnalytics(prevState => ({
            ...prevState,
            isSelected: interval,
        }));
    };

    return (
        <AnalyticsView
            {...props}
            {...analytics}
            getAnalyticsData={getAnalyticsData}
        />
    );
};

export default AnalyticsManagement;