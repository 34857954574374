import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import moment from "moment";
import {setOverviewCRMId, setOverviewInterval, setOverviewLocation} from "./OverviewAction";
import {isEmpty} from "../../../../../../../core/components/DLComponentHelper";
import {getManagementSummary} from "../../../../../../HomePageActions";
import {getCRMs, getLocations} from "../../../../../../../AppComponents/MetadataActions";
import {intervalFilterOptions} from "./OverviewConst";

import OverviewsView from "./OverviewsView";

const Overview = (props) => {
    const [OverviewState, setOverviewState] = useState({
        chartData: [],
        xAxis: [],
        yAxis: [],
        CRMDropdownValues: [],
        CRMLocationOptions: [],
        intervalOptions: [],
        businessAmount: 0,
        purchaseAmount: 0,
        sipAmount: 0,
        redemptionAmount: 0,
        filters: {
            interval: 'THIS_MONTH',
            selectedCRM: ['ALL'],
            selectedLocation: 'ALL',
        },
        isShowFilterMobile: 'false',
        isShowLoader: false,
        isShowMasterLoader: false,
        isApiInProgress: false,
        UIState: '',
        isChartClickModalOpen: false,
        graphData: [],
        selectedDataIndex: ''
    });

    const {OverviewFilter} = props;

    useEffect(() => {
        getMasterData();
    }, []);

    useEffect(() => {
        getChartData();
    }, [OverviewFilter]);

    const getMasterData = () => {

        setOverviewState((prevState) => ({
            ...prevState,
            isShowMasterLoader: true,
        }));

        getCRMs().then((CRMRes) => {
            if (CRMRes?.success) {
                const CRMData = CRMRes?.data?.advisoryUsers;

                getLocations().then((locationsRes) => {
                    if (locationsRes?.success) {
                        const CRMLocations = locationsRes?.data?.locations;

                        setOverviewState((prevState) => ({
                            ...prevState,
                            isApiInProgress: true,
                            intervalOptions: setValuesForDropdown(intervalFilterOptions, 'label', 'value', null),
                            CRMDropdownValues: setValuesForCRMDropdown(CRMData, "firstName", "id"),
                            CRMLocationOptions: setValuesForDropdown(CRMLocations, "description", "id", "location"),
                            isShowMasterLoader: false,
                        }));
                    }
                });
            } else {
                setOverviewState((prevState) => ({
                    ...prevState,
                    isApiInProgress: false,
                    isShowMasterLoader: false,
                }))
            }
        })
    };

    const getChartData = () => {
        setOverviewState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            interval: OverviewFilter.OverviewInterval,
            filters: generateFilter(OverviewFilter),
        };

        getManagementSummary(payload, true).then((res) => {
            if (res?.success) {
                setOverviewState((prevState) => ({
                    ...prevState,
                    isApiInProgress: true,
                    businessAmount: isEmpty(res?.data?.totalBusiness) ? 0 : res?.data?.totalBusiness,
                    purchaseAmount: isEmpty(res?.data?.totalPurchase) ? 0 : res?.data?.totalPurchase,
                    sipAmount: isEmpty(res?.data?.totalSip) ? 0 : res?.data?.totalSip,
                    redemptionAmount: isEmpty(res?.data?.totalRedemption) ? 0 : res?.data?.totalRedemption,
                    isShowLoader: false,
                    graphData: res?.data
                }));
                setChartData(res?.data, OverviewFilter.OverviewInterval);
            } else {
                setOverviewState((prevState) => ({
                    ...prevState,
                    isApiInProgress: false,
                    isShowLoader: false,
                }))
            }
        })
    };

    const setChartData = (data, interval) => {
        let xAxis = [];
        let yAxis = [];
        if (isEmpty(data)) {
            setOverviewState((prevState) => ({
                ...prevState,
                businessAmount: 0,
                purchaseAmount: 0,
                sipAmount: 0,
                redemptionAmount: 0,
            }));
            return;
        }
        data?.items?.map((item) => {
            if (interval === 'THIS_WEEK') {
                xAxis.push(moment(item?.date).format("ddd"));
            }
            else if (interval === 'THIS_MONTH') {
                xAxis.push(moment(item?.date).format("DD-MMM"));
            } else {
                xAxis.push(moment(item?.date).format("MMM"));
            }

            yAxis.push(item?.totalamount);
        });

        setOverviewState(prevState => ({
            ...prevState,
            xAxis: xAxis,
            yAxis: yAxis,
        }));
    };

    const setValuesForCRMDropdown = (CRMData, labelToken, valueToken) => {

        let list = [{label: "All", value: 'ALL'}, {label: "Unassigned", value: 'UNASSIGNED'}];
        if (isEmpty(CRMData)) {
            return;
        }
        CRMData.map((item) => {
            list.push({
                label: item?.[labelToken.toString()] + " " + item?.lastName,
                value: item?.[valueToken].toString()
            })
        });

        return list;
    };

    const setValuesForDropdown = (data, labelToken, valueToken, title) => {
        if (isEmpty(data)) {
            return;
        }
        let list = [];

        if (title !== null || title === 'location') {
            list = [{label: 'All', value: 'ALL'}];
        }

        data.map((item) => {
            list.push({
                label: item?.[labelToken],
                value: item?.[valueToken]
            })
        });

        return list;
    };

    const generateFilter = (filter) => {
        let filterObject = {};

        if (!isEmpty(filter.OverviewLocation)) {

            if (filter.OverviewLocation === "ALL") {
                delete filterObject.OverviewLocation
            } else {
                filterObject.location = filter.OverviewLocation;
            }
        }

        if (!isEmpty(filter.OverviewCRMId)) {
            if (filter.OverviewCRMId[0] === "ALL") {
                delete filterObject.OverviewCRMId
            } else if (filter.OverviewCRMId[0] === "UNASSIGNED") {
                filterObject.CRMId = "UNASSIGNED";
            } else {
                filterObject.CRMId = [filter.OverviewCRMId];
            }
        }
        return filterObject;
    };

    const handleToggleMobileFilter = (value) => {
        setOverviewState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const handleOnChange = (name, event) => {

        if (name === 'selectedCRM') {
            setOverviewState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    selectedCRM: [event],
                }
            }));

            setOverviewCRMId([event]);
        }
        if (name === 'selectedLocation') {
            setOverviewState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    selectedLocation: event,
                }
            }));

            setOverviewLocation(event);
        }
        if (name === 'interval') {
            setOverviewState((prevState) => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    interval: event,
                }
            }));

            setOverviewInterval(event);
        }
    };

    const handleChartClick = (params) => {
        const{graphData} = OverviewState;
        let legendAttribute = getLegendAttribute(params?.dataIndex, graphData?.items);

        setOverviewState((prev) => ({
            ...prev,
            selectedDataIndex: params?.dataIndex,
            isChartClickModalOpen: true,
            legendInterval: OverviewFilter?.OverviewInterval,
            legendAttribute: legendAttribute
        }));
    };

    const handleCloseChartClickModal = () => {

        setOverviewState((prev) => ({
            ...prev,
            selectedDataIndex: '',
            isChartClickModalOpen: false,
            legendInterval: '',
            legendAttribute: ''
        }));
    };

    const getLegendAttribute = (dataIndex, graphData) => {
        let dataObj = [];
        if(isEmpty(dataIndex) || isEmpty(graphData)){
            return "";
        }
        dataObj = graphData.find((obj, index)=>{
            return dataIndex === index;
        });

        return isEmpty(dataObj)? "" : dataObj?.date;

    };

    return (
        <OverviewsView
            {...props}
            {...OverviewState}
            getChartData={getChartData}
            handleOnChange={handleOnChange}
            handleToggleMobileFilter={handleToggleMobileFilter}
            handleChartClick={handleChartClick}
            handleCloseChartClickModal={handleCloseChartClickModal}
        />
    )
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
    OverviewFilter: state.OverviewState.OverviewFilter
});

export default connect(mapStateToProps, {})(Overview);