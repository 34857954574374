import {createSlice} from "@reduxjs/toolkit";

const metadataSlice = createSlice({
    name: 'metadataReducer',
    initialState: {
        leadSources: [],
        isLeadSourcesLoaded: false, // Updated flag

        advisoryUsers: [],
        isAdvisoryUsersLoaded: false, // Updated flag

        CRMUsers: [],
        isCRMUsersLoaded: false, // Updated flag

        categoryTypes: [],
        isCategoryTypesLoaded: false, // Updated flag

        accountTypes: [],
        isAccountTypesLoaded: false, // Updated flag

        clientAccountTypes: [],
        isClientAccountTypesLoaded: false, // Updated flag

        locations: [],
        isLocationsLoaded: false, // Updated flag

        banks: [],
        isBanksLoaded: false, // Updated flag

        bankTypes: [],
        isBankTypesLoaded: false, // Updated flag

        bankChannelTypes: [],
        isBankChannelTypesLoaded: false, // Updated flag

        countries: [],
        isCountriesLoaded: false, // Updated flag

        states: [],
        isStatesLoaded: false, // Updated flag

        genders: [],
        isGendersLoaded: false, // Updated flag

        occupations: [],
        isOccupationsLoaded: false, // Updated flag

        industryTypes: [],
        isIndustryTypesLoaded: false, // Updated flag

        peps: [],
        isPepsLoaded: false, // Updated flag

        applicableIncomes: [],
        isApplicableIncomesLoaded: false, // Updated flag

        relationships: [],
        isRelationshipsLoaded: false, // Updated flag

        riskProfileTypes: [],
        isRiskProfileTypesLoaded: false, // Updated flag

        financialProfiles: [],
        isFinancialProfilesLoaded: false, // Updated flag

        decisionMakingStyles: [],
        isDecisionMakingStylesLoaded: false, // Updated flag

        investmentStyles: [],
        isInvestmentStylesLoaded: false, // Updated flag

        moneyManagementStyles: [],
        isMoneyManagementStylesLoaded: false, // Updated flag

        realEstateToNetworthRatios: [],
        isRealEstateToNetworthRatiosLoaded: false, // Updated flag

        insuranceToIncomeRatios: [],
        isInsuranceToIncomeRatiosLoaded: false, // Updated flag

        cashflowTypes: [],
        isCashflowTypesLoaded: false, // Updated flag

        spendingHabits: [],
        isSpendingHabitsLoaded: false, // Updated flag

        nomineeRelations: [],
        isNomineeRelationsLoaded: false, // Updated flag

        guardianRelations: [],
        isGuardianRelationsLoaded: false, // Updated flag

        lostReasons: [],
        isLostReasonsLoaded: false, // Updated flag

        holdingNatures: [],
        isHoldingNaturesLoaded: false, // Updated flag

        financialTransactionStates: [],
        isFinancialTransactionStatesLoaded: false, // Updated flag

        nftRequestTypes: [],
        isNftRequestTypesLoaded: false, // Updated flag

        txnRequestTypes: [],
        isTxnRequestTypesLoaded: false, // Updated flag

        paymentMechanisms: [],
        isPaymentMechanismsLoaded: false, // Updated flag

        chequeDepositModes: [],
        isChequeDepositModesLoaded: false, // Updated flag

        taxStatuses: [],
        isTaxStatusesLoaded: false // Updated flag
    },
    reducers: {
        setLeadSources: (state, action) => {
            state.leadSources = action.payload;
            state.isLeadSourcesLoaded = true; // Set the loaded flag to true
        },
        setAdvisoryUsers: (state, action) => {
            state.advisoryUsers = action.payload;
            state.isAdvisoryUsersLoaded = true; // Set the loaded flag to true
        },
        setCRMUsers: (state, action) => {
            state.CRMUsers = action.payload;
            state.isCRMUsersLoaded = true; // Set the loaded flag to true
        },
        setCategoryTypes: (state, action) => {
            state.categoryTypes = action.payload;
            state.isCategoryTypesLoaded = true; // Set the loaded flag to true
        },
        setAccountTypes: (state, action) => {
            state.accountTypes = action.payload;
            state.isAccountTypesLoaded = true; // Set the loaded flag to true
        },
        setClientAccountTypes: (state, action) => {
            state.clientAccountTypes = action.payload;
            state.isClientAccountTypesLoaded = true; // Set the loaded flag to true
        },
        setLocations: (state, action) => {
            state.locations = action.payload;
            state.isLocationsLoaded = true; // Set the loaded flag to true
        },
        setBanks: (state, action) => {
            state.banks = action.payload;
            state.isBanksLoaded = true; // Set the loaded flag to true
        },
        setBankTypes: (state, action) => {
            state.bankTypes = action.payload;
            state.isBankTypesLoaded = true; // Set the loaded flag to true
        },
        setBankChannelTypes: (state, action) => {
            state.bankChannelTypes = action.payload;
            state.isBankChannelTypesLoaded = true; // Set the loaded flag to true
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
            state.isCountriesLoaded = true; // Set the loaded flag to true
        },
        setStates: (state, action) => {
            state.states = action.payload;
            state.isStatesLoaded = true; // Set the loaded flag to true
        },
        setGenders: (state, action) => {
            state.genders = action.payload;
            state.isGendersLoaded = true; // Set the loaded flag to true
        },
        setOccupations: (state, action) => {
            state.occupations = action.payload;
            state.isOccupationsLoaded = true; // Set the loaded flag to true
        },
        setIndustryTypes: (state, action) => {
            state.industryTypes = action.payload;
            state.isIndustryTypesLoaded = true; // Set the loaded flag to true
        },
        setPeps: (state, action) => {
            state.peps = action.payload;
            state.isPepsLoaded = true; // Set the loaded flag to true
        },
        setApplicableIncomes: (state, action) => {
            state.applicableIncomes = action.payload;
            state.isApplicableIncomesLoaded = true; // Set the loaded flag to true
        },
        setRelationships: (state, action) => {
            state.relationships = action.payload;
            state.isRelationshipsLoaded = true; // Set the loaded flag to true
        },
        setRiskProfileTypes: (state, action) => {
            state.riskProfileTypes = action.payload;
            state.isRiskProfileTypesLoaded = true; // Set the loaded flag to true
        },
        setFinancialProfiles: (state, action) => {
            state.financialProfiles = action.payload;
            state.isFinancialProfilesLoaded = true; // Set the loaded flag to true
        },
        setDecisionMakingStyles: (state, action) => {
            state.decisionMakingStyles = action.payload;
            state.isDecisionMakingStylesLoaded = true; // Set the loaded flag to true
        },
        setInvestmentStyles: (state, action) => {
            state.investmentStyles = action.payload;
            state.isInvestmentStylesLoaded = true; // Set the loaded flag to true
        },
        setMoneyManagementStyles: (state, action) => {
            state.moneyManagementStyles = action.payload;
            state.isMoneyManagementStylesLoaded = true; // Set the loaded flag to true
        },
        setRealEstateToNetworthRatios: (state, action) => {
            state.realEstateToNetworthRatios = action.payload;
            state.isRealEstateToNetworthRatiosLoaded = true; // Set the loaded flag to true
        },
        setInsuranceToIncomeRatios: (state, action) => {
            state.insuranceToIncomeRatios = action.payload;
            state.isInsuranceToIncomeRatiosLoaded = true; // Set the loaded flag to true
        },
        setCashflowTypes: (state, action) => {
            state.cashflowTypes = action.payload;
            state.isCashflowTypesLoaded = true; // Set the loaded flag to true
        },
        setSpendingHabits: (state, action) => {
            state.spendingHabits = action.payload;
            state.isSpendingHabitsLoaded = true; // Set the loaded flag to true
        },
        setNomineeRelations: (state, action) => {
            state.nomineeRelations = action.payload;
            state.isNomineeRelationsLoaded = true; // Set the loaded flag to true
        },
        setGuardianRelations: (state, action) => {
            state.guardianRelations = action.payload;
            state.isGuardianRelationsLoaded = true; // Set the loaded flag to true
        },
        setLostReasons: (state, action) => {
            state.lostReasons = action.payload;
            state.isLostReasonsLoaded = true; // Set the loaded flag to true
        },
        setHoldingNatures: (state, action) => {
            state.holdingNatures = action.payload;
            state.isHoldingNaturesLoaded = true; // Set the loaded flag to true
        },
        setFinancialTransactionStates: (state, action) => {
            state.financialTransactionStates = action.payload;
            state.isFinancialTransactionStatesLoaded = true; // Set the loaded flag to true
        },
        setNftRequestTypes: (state, action) => {
            state.nftRequestTypes = action.payload;
            state.isNftRequestTypesLoaded = true; // Set the loaded flag to true
        },
        setTxnRequestTypes: (state, action) => {
            state.txnRequestTypes = action.payload;
            state.isTxnRequestTypesLoaded = true; // Set the loaded flag to true
        },
        setPaymentMechanisms: (state, action) => {
            state.paymentMechanisms = action.payload;
            state.isPaymentMechanismsLoaded = true; // Set the loaded flag to true
        },
        setChequeDepositModes: (state, action) => {
            state.chequeDepositModes = action.payload;
            state.isChequeDepositModesLoaded = true; // Set the loaded flag to true
        },
        setTaxStatuses: (state, action) => {
            state.taxStatuses = action.payload;
            state.isTaxStatusesLoaded = true; // Set the loaded flag to true
        }
    }
});

// Export actions to dispatch from components
export const {
    setLeadSources,
    setAdvisoryUsers,
    setCRMUsers,
    setCategoryTypes,
    setLeadRequests,
    setAccountTypes,
    setClientAccountTypes,
    setLocations,
    setBanks,
    setBankTypes,
    setBankChannelTypes,
    setCountries,
    setStates,
    setGenders,
    setOccupations,
    setIndustryTypes,
    setPeps,
    setApplicableIncomes,
    setRelationships,
    setRiskProfileTypes,
    setFinancialProfiles,
    setDecisionMakingStyles,
    setInvestmentStyles,
    setMoneyManagementStyles,
    setRealEstateToNetworthRatios,
    setInsuranceToIncomeRatios,
    setCashflowTypes,
    setSpendingHabits,
    setNomineeRelations,
    setGuardianRelations,
    setLostReasons,
    setHoldingNatures,
    setFinancialTransactionStates,
    setNftRequestTypes,
    setTxnRequestTypes,
    setPaymentMechanisms,
    setChequeDepositModes,
    setTaxStatuses
} = metadataSlice.actions;

export default metadataSlice.reducer;
