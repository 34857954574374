import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";

import {FTReportDownload, getTxnReportDetails} from "../../../FTAction";
import {
    resetFTReportFilters,
    setFTReportCRMId, setFTReportFromDate, setFTReportKeywordValue,
    setFTReportLimitCount,
    setFTReportPageCount,
    setFTReportRowsPerPage,
    setFTReportSkipCount, setFTReportStatus, setFTReportToDate, setFTReportTxnSubType, setFTReportTxnType
} from "../FTReportAction";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {
    getCRMs,
    getCategoryOptions,
    getClientAccountTypeOptions as getClientAccountTypes
} from "../../../../../AppComponents/MetadataActions";
import FTReportListForManagementView from "./FTReportListForManagementView";
import {transactionStatuses, transactionSubTypes, transactionTypes} from "../FTReportConst";
import {convertUTCToLocalTime} from "../../../../../utils/helper";

const FTReportListForManagement = (props) => {
    const {FTReportFilter} = props;

    const [FTReportListForManagementState, setFTReportListForManagementState] = useState({
        listingCount: 0,
        UIState: '',
        pageCount: 0,
        isActionClicked: false,
        isStatusModalOpen: false,
        isShowLoader: false,
        isShowLoaderMaster: false,
        isShowFilterMobile: false,
        selectedId: '',
        statusOptions: [],
        txnTypeOptions: [],
        txnSubTypeOptions: [],
        categoryOptions: [],
        accountTypeOptions: [],
        accountCategories: [],
        CRMDropdownValues: [],
        FTReportListDetails: [],
        actionBtnOptions: [{
            label: "Status",
            value: "STATUS"
        }]
    });

    useEffect(() => {
        getFTReportDetails();
    }, [FTReportFilter]);

    useEffect(() => {
        getCategoryDetails();
    }, []);

    const {page, rowsPerPage, skipCount, limitCount} = props.FTReportFilter;

    const getCategoryDetails = () => {

        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isShowLoaderMaster: true,
        }));

        getCategoryOptions().then(res => {
            if (res?.success) {

                getClientAccountTypes().then(accountTypeRes => {
                    if (accountTypeRes?.success) {

                        getCRMs().then((CRMRes) => {
                            if (CRMRes?.success) {
                                const CRMData = CRMRes?.data?.advisoryUsers;

                                setFTReportListForManagementState((prevState) => ({
                                    ...prevState,
                                    categoryOptions: setValuesForDropdown(res?.data?.categoryType, "name", "id"),
                                    accountTypeOptions: setValuesForDropdown(accountTypeRes?.data?.accountType, "name", "id"),
                                    accountCategories: res?.data?.categoryType,
                                    CRMDropdownValues: setValuesForCRMDropdown(CRMData, "firstName", "id"),
                                    statusOptions: setValuesForDropdown(transactionStatuses, "label", "value"),
                                    txnTypeOptions: setValuesForDropdown(transactionTypes, "label", "value"),
                                    txnSubTypeOptions: setValuesForDropdown(transactionSubTypes, "label", "value"),
                                    isShowLoaderMaster: false,
                                }));
                            }
                        })

                    } else {
                        setFTReportListForManagementState((prevState) => ({
                            ...prevState,
                            isShowLoaderMaster: false,
                        }))
                    }
                })
            }
        })
    };

    const getFTReportDetails = () => {

        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            "skipCount": FTReportFilter.skipCount,
            "limitCount": FTReportFilter.limitCount,
            "filters": generateFilter(FTReportFilter)
        };

        getTxnReportDetails(payload, true).then((res) => {
            if (res?.success) {

                setFTReportListForManagementState((prevState) => ({
                    ...prevState,
                    listingCount: res?.data?.count,
                    FTReportListDetails: res?.data,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    isShowLoader: false,
                }));
            } else {
                setFTReportListForManagementState((prevState) => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }))
            }
        })
    };

    const handleActionClicked = (isActionClicked, id) => {
        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            selectedId: id
        }))
    };

    const handleChangePage = (event, newPage) => {
        let tempLimitCount = limitCount;
        let tempSkipCount = skipCount;

        const isForward = newPage > page;
        if (isForward) {
            tempSkipCount = tempSkipCount + rowsPerPage;
            tempLimitCount = rowsPerPage
        } else {
            tempSkipCount = tempSkipCount - rowsPerPage;
            tempLimitCount = rowsPerPage
        }

        setFTReportSkipCount(tempSkipCount);
        setFTReportLimitCount(tempLimitCount);
        setFTReportPageCount(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setFTReportListForManagementState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setFTReportRowsPerPage(parseInt(event.target.value, 10));
        setFTReportLimitCount(parseInt(event.target.value, 10));
        setFTReportPageCount(0);
        setFTReportSkipCount(0);
    };

    const handleToggleMobileFilter = (value) => {
        setFTReportListForManagementState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const handleStatusModalOpen = () => {
        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isStatusModalOpen: true
        }))
    };

    const handleStatusModalClose = () => {
        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isStatusModalOpen: false
        }))
    };

    const handleDownloadBtnClick = () => {
        setFTReportListForManagementState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            "skipCount": FTReportFilter.skipCount,
            "limitCount": FTReportFilter.limitCount,
            "filters": generateFilter(FTReportFilter)
        };

        FTReportDownload(payload);
        setTimeout(() => {
            setFTReportListForManagementState((preState) => ({
                    ...preState,
                    isShowLoader: false,
                }
            ));
        }, 500)
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setFTReportKeywordValue(value);
        } else if (name === 'CRMId') {
            setFTReportCRMId([value]);
        }

        if (name === 'status') {
            setFTReportStatus([value]);
        }

        if (name === 'txnType') {
            setFTReportTxnType([value]);
        }

        if (name === 'txnSubType') {
            setFTReportTxnSubType([value]);
        }

        if (name === 'fromDate') {
            setFTReportFromDate(value);
        }

        if (name === 'toDate') {
            setFTReportToDate(value);
        }
    };

    const getCRM = (CRMId, CRMOptions) => {
        if (isEmpty(CRMId) || isEmpty(CRMOptions)) {
            return ''
        }
        const CRM = CRMOptions.find(crm => crm.value === CRMId.toString());
        return CRM ? CRM.label : '';
    };

    const getCategory = (selectedCategory, accountCategories) => {
        if (isEmpty(selectedCategory) || isEmpty(accountCategories)) {
            return ''
        }
        if (selectedCategory === 'CORPORATE') {
            return 'Null'
        }
        const category = accountCategories.find(category => category.id === selectedCategory);
        return category ? category.name : '';
    };

    const getAccountType = (value) => {
        const {accountTypeOptions} = FTReportListForManagementState;
        const accountType = accountTypeOptions.find(item => item?.value === value);
        return accountType?.label || '';
    };

    const generateFilter = (filter) => {

        let filterObject = {};

        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }

        if (!isEmpty(filter.CRMId)) {
            if (filter.CRMId[0] === "ALL") {
                delete filterObject.CRMId
            } else if (filter.CRMId[0] === "UNASSIGNED") {
                filterObject.CRMId = 'UNASSIGNED';
            } else {
                filterObject.CRMId = filter.CRMId[0];
            }
        }

        if (!isEmpty(filter.status)) {
            if (filter.status[0] === "ALL") {
                delete filterObject.status
            } else {
                filterObject.trxnStatus = [filter.status[0]];
            }
        }

        if (!isEmpty(filter.txnType)) {
            if (filter.txnType[0] === "ALL") {
                delete filterObject.txnType
            } else {
                filterObject.trxnType = [filter.txnType[0]];
            }
        }

        if (!isEmpty(filter.txnSubType)) {
            if (filter.txnSubType[0] === "ALL") {
                delete filterObject.txnSubType
            } else {
                filterObject.subTrxnType = [filter.txnSubType[0]];
            }
        }

        if (!isEmpty(filter.fromDate)) {
            filterObject.fromDate = convertUTCToLocalTime(filter.fromDate, 'YYYY-MM-DD');
        }

        if (!isEmpty(filter.toDate)) {
            filterObject.toDate = convertUTCToLocalTime(filter.toDate, 'YYYY-MM-DD');
        }

        return filterObject;
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const setValuesForCRMDropdown = (CRMData, labelToken, valueToken) => {

        let list = [{label: "All", value: 'ALL'}, {label: "Unassigned", value: 'UNASSIGNED'}];
        if (isEmpty(CRMData)) {
            return;
        }
        CRMData.map((item) => {
            list.push({
                label: item?.[labelToken.toString()] + " " + item?.lastName,
                value: item?.[valueToken].toString()
            })
        });

        return list;
    };

    const redirectToDetailsScreen = (accountId) => {
        props.history.push('/clients/' + accountId, {screenName: "FTReport"});
    };

    const resetPagination = () => {

        setFTReportRowsPerPage(50);
        setFTReportLimitCount(50);
        setFTReportPageCount(0);
        setFTReportSkipCount(0);
    };

    const resetFTReportListing = () => {
        resetFTReportFilters();
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = FTReportListForManagementState;

        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    return (
        <FTReportListForManagementView
            {...props}
            {...FTReportListForManagementState}
            {...props.FTReportFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleToggleMobileFilter={handleToggleMobileFilter}
            onChangeFilter={onChangeFilter}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            handleActionClicked={handleActionClicked}
            handleStatusModalOpen={handleStatusModalOpen}
            handleStatusModalClose={handleStatusModalClose}
            resetFTReportListing={resetFTReportListing}
            handleDownloadBtnClick={handleDownloadBtnClick}
            getCategory={getCategory}
            getAccountType={getAccountType}
            getCRM={getCRM}
            getFTReportDetails={getFTReportDetails}
            redirectToDetailsScreen={redirectToDetailsScreen}
        />
    )
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    profile: state.userState.profile,
    FTReportFilter: state.FTReportState.FTReportFilter,
});

export default connect(mapStateToProps, {})(FTReportListForManagement);
