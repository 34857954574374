import React from 'react';
import DLText from "../../../../../../../../../core/components/DLText/DLText";
import {isEmpty} from "../../../../../../../../../core/components/DLComponentHelper";
import EChartPieChart from "../../../../../../../EchartPieChart";
import DLLoader from "../../../../../../../../../core/components/DLLoader/DLLoader";
import {getColor} from "../../../../../../../../HomePageHelper";

const SummaryByFinancialView = (props) => {
    const {isXSView, data, getPieData, isShowLoader} = props;

    const getLegends = (data) => {
        return data.map((item, index) => {
            return (
                <div key={'legend_'+index} className='d-flex align-items-center ms-2'>
                    <div style={{paddingTop: '6px'}}>
                        <div style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: 7,
                            backgroundColor: getColor(index),
                        }}/>
                    </div>
                    <div className='ps-1' style={{minWidth: 'max-content'}}>
                        <DLText text={item.name}
                                marginBottom={"none"}
                                id={'page-not-found-oops'}
                                type={"normal"}
                                fontSize={"xxs"}
                        />
                    </div>
                </div>
            )
        })
    };


    const renderUI = () => {
        return (
            <div className={isXSView ? '' : 'panel p-0'}>
                <div className={isXSView ? 'pt-2' : 'section-title py-2'}>
                    <div className={isXSView ? 'px-3' : 'ps-2 ms-1'}>
                        <DLText
                            id={'summary'}
                            text={'Summary by Financial Category'}
                            marginBottom={'none'}
                            fontSize={isXSView ? 'sm' : 'md'}
                            fontWeight={'bold'}
                        />
                    </div>
                    <div className={"flex-grow-1"} style={{maxHeight: '300px'}}>
                        {isShowLoader ? (
                            <div className={"w-100 d-flex justify-content-center align-items-center"} style={{maxHeight: '274px', minHeight: '274px'}}>
                                <DLLoader isVisible={true} type={"inline"} />
                            </div>
                        ) : isEmpty(data) ?
                            <div className={"w-100 d-flex justify-content-center align-items-center"} style={{maxHeight: '274px', minHeight: '274px'}}>
                                <DLText
                                    id={'no-data'}
                                    text={'No data found.'}
                                />
                            </div>
                            :(
                                <div className="d-flex flex-column align-items-center pt-4 pb-3" style={{maxHeight: '300px'}}>
                                    <EChartPieChart {...props}
                                                    data={getPieData(data)}/>

                                    <div className="d-flex justify-content-center flex-wrap">
                                        {getLegends(getPieData(data))}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
        </div>
    );
};

export default SummaryByFinancialView;

