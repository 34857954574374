import React from 'react';

import appTheme from "../../../../../../../assets/appTheme";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import BarChart from '../../../../../overviewChart/OverviewBarChartUI';
import TotalBusiness from "../../../../../overviewTotalBusiness/OverviewTotalBusiness";
import TypeOfAmount from "../../../../../overviewAmountType/OverviewAmountType";

import DLText from "../../../../../../../core/components/DLText/DLText";
import ChartModal from "../../../../../ChartModal/ChartModal";
import DLDropDown from "../../../../../../../core/components/DLDropdown/DLDropDown";

const OverviewsView = (props) => {
    const {
        isXSView,
        isSMView,
        isMDView,
        isMobileView,
        purchaseAmount,
        redemptionAmount,
        sipAmount,
        handleChartClick,
        handleCloseChartClickModal
    } = props;

    const renderChart = () => {
        return (
            <BarChart {...props}
                      handleChartClick={handleChartClick}
                      handleCloseChartClickModal={handleCloseChartClickModal}
                      isChartClickable={true}
            />
        )
    };

    const renderChartModal = () => {
        const {isChartClickModalOpen} = props;
        if (isChartClickModalOpen) {
            return (
                <ChartModal {...props}/>
            )
        }

    };

    const renderTotalBusinessAmount = () => {
        if (isMDView) {
            return (
                <div
                    className={(isMDView ? 'px-3 column-gap-3 ' : 'ms-3 me-3 gap-3 ') + 'd-flex '}>
                    <TotalBusiness {...props} />
                    <div className={'w-25 border rounded '}>
                        <TypeOfAmount
                            {...props}
                            amount={purchaseAmount}
                            label={'Purchase'}
                        />
                    </div>
                    <div className={'w-25 border rounded '}>
                        <TypeOfAmount
                            {...props}
                            amount={redemptionAmount}
                            label={'Redemption'}
                        />
                    </div>
                    <div className={'w-25 border rounded '}>
                        <TypeOfAmount
                            {...props}
                            amount={sipAmount}
                            label={'SIP'}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className={((isXSView || isSMView) ? 'flex-column' : 'ms-3 gap-3') + ' d-flex me-3'}>
                <TotalBusiness {...props} />

                <div className={((isXSView || isSMView) ? 'col-12' : 'col-6') + ' h-100'}>
                    {(isXSView || isSMView) ? (
                        <div>
                            <div className='d-flex justify-content-between gap-2 py-2 my-1'>
                                <div
                                    className={(isXSView || isSMView) ? 'w-100 border-end' : 'mb-3 border rounded w-100'}>
                                    <TypeOfAmount
                                        {...props}
                                        amount={purchaseAmount}
                                        label={'Purchase'}
                                    />
                                </div>
                                <div
                                    className={(isXSView || isSMView) ? 'w-100 border-end' : 'mb-3 border rounded w-100'}>
                                    <TypeOfAmount
                                        {...props}
                                        amount={redemptionAmount}
                                        label={'Redemption'}
                                    />
                                </div>
                                <div
                                    className={(isXSView || isSMView) ? 'w-100 ' : 'mb-3 border rounded w-100'}>
                                    <TypeOfAmount
                                        {...props}
                                        amount={sipAmount}
                                        label={'SIP'}
                                    />
                                </div>
                            </div>
                        </div>

                    ) : (
                        <div className='h-100'>
                            <div className={'mb-3 border rounded '}>
                                <TypeOfAmount
                                    {...props}
                                    amount={purchaseAmount}
                                    label={'Purchase'}
                                />
                            </div>
                            <div className={'mb-3 border rounded '}>
                                <TypeOfAmount
                                    {...props}
                                    amount={redemptionAmount}
                                    label={'Redemption'}
                                />
                            </div>
                            <div className={' border rounded '}>
                                <TypeOfAmount
                                    {...props}
                                    amount={sipAmount}
                                    label={'SIP'}
                                />
                            </div>
                        </div>
                    )}
                </div>

            </div>
        )
    };

    function isFiltersApplied(filters) {
        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (filters[key].length > 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const renderFilters = () => {
        const {CRMDropdownValues, handleOnChange, filters, CRMLocationOptions} = props;
        const {selectedCRM, selectedLocation} = filters;

        return (
            <div className='d-flex gap-3'>
                <div className='w-100' style={{flex: 1, minWidth: '216px'}}>
                    <DLDropDown
                        id={'location-filter'}
                        label={''}
                        placeholder={'Select Location'}
                        value={selectedLocation}
                        options={CRMLocationOptions}
                        minWidth={'100%'}
                        onSelect={(e) => {
                            handleOnChange('selectedLocation', e);
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                    />
                </div>
                <div className='w-100' style={{flex: 1, minWidth: '216px'}}>
                    <DLDropDown
                        id={'crm-filter'}
                        label={''}
                        placeholder={'Select CRM'}
                        value={selectedCRM[0]}
                        options={CRMDropdownValues}
                        minWidth={'100%'}
                        style={{overflow: 'hidden', wordWrap: 'break-word'}}
                        onSelect={(e) => {
                            handleOnChange('selectedCRM', e);
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                    />
                </div>
            </div>
        )
    };

    const renderButtons = () => {
        const {handleOnChange, filters} = props;
        const {interval} = filters;

        const getButtonStyle = (interval, selectedValue) => ({
            textTransform: 'none',
            borderRight: '1px solid #B2B2B2',
            backgroundColor: interval === selectedValue ? appTheme.appColor.darkBlue : appTheme.appColor.white,
            color: interval === selectedValue ? appTheme.appColor.white : '',
            minWidth: '48px',
            borderColor: '#B2B2B2',
            fontSize: '14px',
            fontFamily: "'Poppins', sans-serif",
        });

        return (
            <ButtonGroup variant="outlined"
                         aria-label="outlined button group"
                         style={{height: '36px'}}>
                <Button style={getButtonStyle(interval, 'THIS_WEEK')}
                        onClick={() => {
                            handleOnChange('interval', 'THIS_WEEK')
                        }}
                >W</Button>
                <Button style={getButtonStyle(interval, 'THIS_MONTH')}
                        onClick={() => {
                            handleOnChange('interval', 'THIS_MONTH')
                        }}
                >M</Button>
                <Button
                    style={getButtonStyle(interval, 'THIS_QUARTER')}
                    onClick={() => {
                        handleOnChange('interval', 'THIS_QUARTER')
                    }}
                >Q</Button>
                <Button className={'rounded-end'}
                        style={getButtonStyle(interval, 'THIS_FINANCIAL_YEAR')}
                        onClick={() => {
                            handleOnChange('interval', 'THIS_FINANCIAL_YEAR')
                        }}
                >FY</Button>
            </ButtonGroup>
        )
    };


    const renderClassName = () => {
        if(isXSView || isSMView) {
            return "py-1 border-bottom d-flex justify-content-between align-items-center"
        }
        if(isMDView) {
            return 'py-3 border-bottom d-flex justify-content-between align-items-center'
        }

        return "py-2 d-flex justify-content-between"
    };

    const renderDesktopData = () => {

        return (
            <div
                className={isXSView || isMDView ? 'mb-2 mx-0 px-0 bg-white' : 'border-rounded panel p-0 section-tabs-gap p-0'}>
                <div className="px-0 h-100">
                    <div
                        className={"p-3  rounded-top " + renderClassName()}
                    >
                        <DLText id={'Overview'}
                                isInline={true}
                                fontSize={(isXSView || isSMView) ? 'md' : 'lg'}
                                fontWeight={'semi-bold'}
                                text={'Overview'}
                                marginBottom={'none'}
                        />
                        {!isMobileView ?
                            (
                                <div className='d-flex column-gap-3'>
                                    {renderFilters()}
                                    {renderButtons()}
                                </div>
                            ) : renderButtons()
                        }

                    </div>
                    <div
                        className={(isMDView ? 'flex-column bg-white' : (isXSView || isSMView) ? 'flex-column bg-light' : '') + ' d-flex pb-0 mb-3'}>
                        <div
                            className={isMDView ? 'my-3 ' : (isXSView || isSMView) ? 'col-12 border-bottom ps-3 bg-white ' : 'col-4 '}>
                            {renderTotalBusinessAmount()}
                        </div>
                        {(isXSView || isSMView) && <div className='bg-light' style={{height: '7px'}}/>}
                        <div
                            className={isMDView ? 'col-12 px-3 pb-3' : (isXSView || isSMView) ? 'col-12 bg-white border-top' : 'col-8 ps-3'}>
                            {renderChart()}
                        </div>
                    </div>
                </div>
            </div>)
    };

    return (
        <div className={(isXSView || isSMView) || isMDView ? 'px-0 mx-0 bg-light' : ' '}>
            {renderDesktopData()}
            {renderChartModal()}
        </div>
    )
};

export default OverviewsView;