import React from 'react';
import BusinessSummaryView from "./BusinessSummaryView";
import {connect} from "react-redux";

const BusinessSummaryManagement = (props) => {

    return (
        <div>
            <BusinessSummaryView
                {...props}/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(BusinessSummaryManagement);
