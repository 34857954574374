import React from 'react';
import {connect} from "react-redux";
import ManagementDashboardView from "./ManagementDashboardView";

const ManagementDashboard=(props)=> {
    const [managementDashboardItems, setManagementDashboardItems] = React.useState({
        allTabs: [
            {label: 'Financial Transactions', value: 'Financial Transactions'},
            {label: 'Leads', value: 'Leads'},
            {label: 'Onboardings', value: 'Onboardings'},
            {label: 'Clients', value: 'Clients'}
        ],
        selectedProfileTab: {label: 'Financial Transactions', value: 'Financial Transactions'},
    });

    const handleTabChange = (item) => {
        setManagementDashboardItems((prevState) => ({
            ...prevState,
            selectedProfileTab: {label: item.label, value: item.value},
        }));
    };

    return (
        <ManagementDashboardView
            {...props}
            {...managementDashboardItems}
            handleTabChange={handleTabChange}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
});

export default connect(mapStateToProps, {})(ManagementDashboard);