export const ftAnalyticsClientHeaderData = ['Account', 'Purchase', 'Redemption', 'Total'];

export const ftAnalyticsClientCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
        padding: '8px 0 8px 12px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
];

export const ftTransactionsPendingCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
        padding: '8px 0 8px 12px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
]

export const ftTransactionsPendingHeaderData = ['Account', 'Date', 'Type', 'Amount'];

export const ftTransactionsRejectedCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
        padding: '8px 0 8px 12px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
]

export const ftTransactionsTypeCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
        padding: '8px 0 8px 12px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
    },
]

export const ftTransactionsTypeHeaderData = ['Type', 'Purchase', 'Redemption', 'Switch', 'Total'];

export const ftMonthDropdownMobileOptions = [
    {label: "Today", value: "TODAY"},
    {label: "This Week", value: "THIS_WEEK"},
    {label: "This Month", value: "THIS_MONTH"},
    {label: "This Quarter", value: "THIS_QUARTER"},
    {label: "This FY", value: "THIS_FINANCIAL_YEAR"},
];