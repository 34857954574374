export const transactionStatuses =[
    {label:'All', value:'ALL'},
    {label:'Authorized', value:'Authorized'},
    {label:'Pending', value:'Pending'},
    {label:'Pending for Authorization', value:'Pending for Authorization'},
    {label:'Processed by RTA', value:'Processed by RTA'},
    {label:'Rejected / Reversal', value:'Rejected / Reversal'},
    {label:'Submitted to RTA', value:'Submitted to RTA'},
];

export const transactionTypes =[
    {label:'All', value:'ALL'},
    {label:'Additional Purchase', value:'Additional Purchase'},
    {label:'Fresh Purchase', value:'Fresh Purchase'},
    {label:'Redemption', value:'Redemption'},
    {label:'Switch', value:'Switch'},
];

export const transactionSubTypes =[
    {label:'All', value:'ALL'},
    {label:'Normal', value:'Normal'},
    {label:'Systematic', value:'Systematic'},
];
