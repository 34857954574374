import React from 'react';

import moment from 'moment';
import {convertAmountInLakhAndCroreForChart, getTrimmedValue, numberWithCommas} from "../../../../../utils/helper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import appTheme from "../../../../../assets/appTheme";
import {
    getTransactionStatus,
} from "../FTReportHelper";

import FTReportStatusModal from "../components/FTReportStatusModal/FTReportStatusModal";

import filterDot from "../../../../../assets/img/appIcons/filterDot.png";
import emptyFilterFilled from "../../../../../assets/img/appIcons/emptyFilterFilled.png";

import {
    FTReportCellConfigForManagement,
    headingFTReportDataForManagement,

} from "../../../FTModal";

import DLTable from "../../../../../core/components/DLTable/DLTable";
import DLMenuOptions from "../../../../../core/components/DLMenuOptions/DLMenuOptions";
import DLText from "../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import emptyFilter from "../../../../../assets/img/appIcons/emptyFilter.png";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";
import DLDropDown from "../../../../../core/components/DLDropdown/DLDropDown";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLDatePicker from "../../../../../core/components/DLDatePicker/DLDatePicker";

const FTReportListForManagementView = (props) => {
    const {
        isMobileView,
        isXSView,
        isMDView,
        isLGView,
        onChangeFilter,
        isShowLoader,
        isShowLoaderMaster,
        isStatusModalOpen,
        handleStatusModalClose,
        handleStatusModalOpen,
        FTReportListDetails,
        resetFTReportListing,
        getAccountType,
        getCRM,
        getFTReportDetails,
        txnSubTypeOptions,
        txnTypeOptions,
        statusOptions,
        handleDownloadBtnClick,
        redirectToDetailsScreen
    } = props;

    const validate = (value) => {
        return !isEmpty(value) ? value : '';
    };

    const getFormattedTableData = (data) => {
        const {accountCategories, getCategory, CRMDropdownValues} = props;

        let tableData = [];
        if (isEmpty(data)) {
            return tableData;
        }

        data.map((txn) => {

            let transactions = [];
            let transactionDetails = {};

            if (!isXSView) {

                transactions.push(renderAccountHolders(txn?.accountHolders, txn?.accountType, txn?.accountId));

                transactions.push(validate(txn?.IINNumber));

                transactions.push(getAccountType(txn?.accountType));

                transactions.push(isEmpty(txn?.trxnType) ? '' : txn?.trxnType);

                transactions.push(isEmpty(txn?.entryDate) ? '' : moment(txn?.entryDate).utc().format("DD-MM-YYYY"));
                //this format is handled using moment and utc bcoz NSE not return a date in UTC format

                transactions.push(isEmpty(txn?.schemeName) ? '' : txn?.schemeName);

                transactions.push(isEmpty(txn?.amount) ? '' : numberWithCommas(convertAmountInLakhAndCroreForChart(txn?.amount)));

                transactions.push(isEmpty(txn?.trxnStatus) ? '' : getTransactionStatus(txn?.trxnStatus, statusOptions, true, isXSView));

                transactions.push(isEmpty(txn?.subTrxnType) ? '' : txn?.subTrxnType);

                transactions.push(isEmpty(txn?.targetProduct) ? '' : txn?.targetProduct);

                transactions.push(isEmpty(txn?.registrarId) ? '' : txn?.registrarId);

                transactions.push(isEmpty(txn?.folioNo) ? '' : txn?.folioNo);

                transactions.push(isEmpty(txn?.paymentMode) ? '' : txn?.paymentMode);

                transactions.push(isEmpty(txn?.rtaStatus) ? '' : txn?.rtaStatus);

                transactions.push(isEmpty(txn?.umrnNo) ? '' : txn?.umrnNo);

                transactions.push(getCategory(txn?.category, accountCategories));

                transactions.push(getCRM(txn?.advisoryUserId, CRMDropdownValues));

                transactions.push(isEmpty(txn?.location) ? '' : txn?.location);

                transactions.push({
                    isCustomUI: true,
                    customUI: renderActionButton(txn?.id)
                })
            } else {
                transactions.push({
                    isCustomUI: true,
                    customUI: renderMobileUI(txn),
                });
            }

            transactionDetails.id = txn?.id;
            transactionDetails.data = transactions;
            tableData.push(transactionDetails);
        });
        return tableData;
    };

    const getTableHeight = (isShowFilterMobile) => {

        if (isXSView) {
            if (isShowFilterMobile) {
                return 'calc(100dvh - 534px)';
            }
            return 'calc(100dvh - 317px)';
        } else if (isMDView) {
            if (isShowFilterMobile) {
                return 'calc(100dvh - 552px)';
            }
            return 'calc(100dvh - 350px)';
        } else if (isLGView) {
            return 'calc(100dvh - 387px)';
        }
        return 'calc(100dvh - 312px)';
    };

    const getEmptyTableHeight = (isShowFilterMobile) => {

        if (isXSView) {
            if (isShowFilterMobile) {
                return 'calc(100dvh - 494px)';
            }
            return 'calc(100dvh - 270px)';
        } else if (isMDView) {
            if (isShowFilterMobile) {
                return 'calc(100dvh - 486px)';
            }
            return 'calc(100dvh - 285px)';
        } else if (isLGView) {
            return 'calc(100dvh - 323px)';
        }
        return 'calc(100dvh - 246px)';
    };

    const isFiltersApplied = (filters) => {

        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (key === 'txnType' && filters[key][0] === 'ALL') {
                        return false
                    }
                    if (key === 'txnSubType' && filters[key][0] === 'ALL') {
                        return false
                    }
                    if (key === 'status' && filters[key][0] === 'ALL') {
                        return false
                    }
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '') {
                    return true;
                }
            }
        }
        return false;
    };

    const renderDownloadIcon = () => {
        return (<i className="fa-solid fa-arrow-down px-0"></i>)
    };

    const renderHeader = () => {
        return (
            <div
                className={(!isXSView ? 'bg-light pb-2' : 'py-3') + ' d-flex justify-content-between align-items-center pt-3 '}>
                {!isXSView ?
                    (
                        <div className='page-header pt-0'>
                            Financial Transactions Report
                        </div>
                    ) :
                    (
                        <div className='ps-3 pt-0'>
                            <DLText id={'header'}
                                    text={'Financial Transactions Report'}
                                    fontWeight={'semi-bold'}
                                    fontSize={'lg'}
                                    marginBottom={'none'}
                            />
                        </div>
                    )
                }

                {!isEmpty(FTReportListDetails) && (
                    <div
                        className={(isMobileView ? 'page-header' : 'page-container') + ' d-flex align-items-center py-0'}>
                        <DLButton
                            id={'download-button'}
                            padding={'none'}
                            styleType={'outlined'}
                            type={'icon'}
                            startIcon={renderDownloadIcon()}
                            onClick={() => handleDownloadBtnClick()}
                        />
                    </div>
                )}

            </div>
        )
    };

    const renderFilterIcon = () => {
        const {FTReportFilter, isShowFilterMobile} = props;

        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(FTReportFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }
        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {
            isShowFilterMobile,
            handleToggleMobileFilter,
            CRMDropdownValues,
            FTReportFilter,
            keyword,
            CRMId,
            status,
            txnType,
            txnSubType,
            fromDate,
            toDate
        } = props;

        return (
            <div>
                <div
                    className={(isShowFilterMobile ? '' : 'mb-3 ') + 'd-flex align-items-start align-content-start justify-content-between gap-3 page-container'}>
                    <div className='search-input' style={{flex: 8}}>
                        <DLInputTextField id={'report-list-search-filter'}
                                          rows={1}
                                          styleType={"search"}
                                          value={keyword}
                                          placeholder={'Search by Name'}
                                          isSearchable={true}
                                          onChangeValue={(e) => {
                                              onChangeFilter('keyword', e)
                                          }}
                                          inputHeight={'sm'}
                                          inputType={"text"}
                                          disableHelperMessageHeight={true}
                                          marginBottom={"none"}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <DLButton
                            id={'filter-button'}
                            padding={'none'}
                            styleType={'outlined'}
                            type={'icon'}
                            startIcon={renderFilterIcon()}
                            onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        />

                        {isFiltersApplied(FTReportFilter) &&
                            <i className="fa-solid fa-xmark cursor ms-2 "
                               onClick={() => resetFTReportListing()}
                               style={{fontSize: 18}}/>
                        }
                    </div>
                </div>
                {isShowFilterMobile &&
                    <div className='bg-light py-2'>
                        <div className='page-container'>
                            <div className='my-1'>
                                <div className='d-flex justify-content-between flex-wrap gap-2 gap-wrap-3'>
                                    <div style={{flex: 1}}>
                                        <DLDatePicker
                                            id={"from-date-filter"}
                                            label={'From'}
                                            labelFontColor={'grayDark'}
                                            labelFontSize={'sm'}
                                            value={fromDate}
                                            minWidth={'100%'}
                                            onChangeValue={(e) => {
                                                onChangeFilter('fromDate', e)
                                            }}
                                            disableHelperMessageHeight={true}
                                            marginBottom={"none"}
                                            labelPlacement={'top'}
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <DLDatePicker
                                            id={"to-date-filter"}
                                            label={'To'}
                                            labelFontColor={'grayDark'}
                                            labelFontSize={'sm'}
                                            value={toDate}
                                            minWidth={"100%"}
                                            onChangeValue={(e) => {
                                                onChangeFilter('toDate', e)
                                            }}
                                            disableHelperMessageHeight={true}
                                            marginBottom={"none"}
                                            labelPlacement={'top'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='my-1'>
                                <div className='d-flex justify-content-between flex-wrap gap-2 gap-wrap-3'>
                                    <div style={{flex: 1}}>
                                        <DLDropDown
                                            id={"CRM-filter"}
                                            label={'CRM'}
                                            labelFontColor={'grayDark'}
                                            labelFontSize={'sm'}
                                            value={CRMId[0]}
                                            minWidth={"100%"}
                                            options={CRMDropdownValues}
                                            onSelect={(e) => {
                                                onChangeFilter('CRMId', e)
                                            }}
                                            disableHelperMessageHeight={true}
                                            marginBottom={"none"}
                                            labelPlacement={'top'}
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <DLDropDown
                                            id={"transaction-status-filter"}
                                            label={'Transaction Status'}
                                            labelFontColor={'grayDark'}
                                            labelFontSize={'sm'}
                                            value={status[0]}
                                            minWidth={"100%"}
                                            options={statusOptions}
                                            onSelect={(e) => {
                                                onChangeFilter('status', e)
                                            }}
                                            disableHelperMessageHeight={true}
                                            marginBottom={"none"}
                                            labelPlacement={'top'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-1'>
                                <div className='d-flex justify-content-between flex-wrap gap-2 gap-wrap-3'>
                                    <div style={{flex: 1}}>
                                        <DLDropDown
                                            id={"transaction-type-filter"}
                                            label={'Transaction Type'}
                                            labelFontColor={'grayDark'}
                                            labelFontSize={'sm'}
                                            value={txnType[0]}
                                            minWidth={"100%"}
                                            options={txnTypeOptions}
                                            onSelect={(e) => {
                                                onChangeFilter('txnType', e)
                                            }}
                                            disableHelperMessageHeight={true}
                                            marginBottom={"none"}
                                            labelPlacement={'top'}
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <DLDropDown
                                            id={"sub-transaction-type-filter"}
                                            label={'Sub Transaction Type'}
                                            labelFontColor={'grayDark'}
                                            labelFontSize={'sm'}
                                            value={txnSubType[0]}
                                            minWidth={"100%"}
                                            options={txnSubTypeOptions}
                                            onSelect={(e) => {
                                                onChangeFilter('txnSubType', e)
                                            }}
                                            disableHelperMessageHeight={true}
                                            marginBottom={"none"}
                                            labelPlacement={'top'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    };

    const renderFilter = () => {
        const {CRMDropdownValues, keyword, CRMId, status, txnType, txnSubType, fromDate, toDate} = props;

        return (
            <div className='d-flex bg-light'
                 id="flex-container"
                 style={{flexWrap: "wrap"}}>
                <div className='search-input me-3 mb-2' style={{maxWidth: '236px'}}>
                    <DLInputTextField
                        id={'report-list-search-filter'}
                        label={'Search'}
                        labelFontSize={'sm'}
                        labelFontColor={'grayDark'}
                        marginBottom={"none"}
                        rows={1}
                        styleType={"search"}
                        onChangeValue={(e) => {
                            onChangeFilter('keyword', e)
                        }}
                        value={keyword}
                        placeholder={'Search by Name'}
                        isSearchable={true}
                        size="small"
                        inputHeight={'sm'}
                        inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                        inputType={"text"}
                        disableHelperMessageHeight={true}
                    />
                </div>
                <div className='search-input me-3 mb-2' style={{maxWidth: isLGView ? '195px' : "158px"}}>
                    <DLDatePicker
                        id={"from-date-filter"}
                        label={'From'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={fromDate}
                        minWidth={'100%'}
                        onChangeValue={(e) => {
                            onChangeFilter('fromDate', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='search-input me-3 mb-2' style={{maxWidth: isLGView ? '195px' : "158px"}}>
                    <DLDatePicker
                        id={"to-date-filter"}
                        label={'To'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={toDate}
                        minWidth={"100%"}
                        onChangeValue={(e) => {
                            onChangeFilter('toDate', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='select-filter me-3 mb-2' style={{minWidth: "230px"}}>
                    <DLDropDown
                        id={"CRM-filter"}
                        label={'CRM'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={CRMId[0]}
                        minWidth={"100%"}
                        options={CRMDropdownValues}
                        onSelect={(e) => {
                            onChangeFilter('CRMId', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='select-filter mb-2 me-3' style={{minWidth: isLGView ? '236px' : "225px"}}>
                    <DLDropDown
                        id={"transaction-status-filter"}
                        label={'Transaction Status'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={status[0]}
                        minWidth={"100%"}
                        options={statusOptions}
                        onSelect={(e) => {
                            onChangeFilter('status', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='select-filter me-3 mb-2' style={{minWidth: "195px"}}>
                    <DLDropDown
                        id={"transaction-type-filter"}
                        label={'Transaction Type'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={txnType[0]}
                        minWidth={"100%"}
                        options={txnTypeOptions}
                        onSelect={(e) => {
                            onChangeFilter('txnType', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
                <div className='select-filter mb-3 me-3' style={{minWidth: isLGView ? '195px' : "160px"}}>
                    <DLDropDown
                        id={"sub-transaction-type-filter"}
                        label={'Sub Transaction Type'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={txnSubType[0]}
                        minWidth={"100%"}
                        options={txnSubTypeOptions}
                        onSelect={(e) => {
                            onChangeFilter('txnSubType', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return <DLLoader type={"screen"} isVisible={isShowLoader || isShowLoaderMaster}/>;
    };

    const renderCount = () => {
        const {listingCount} = props;

        return (
            <div
                className={!isMDView && isMobileView ? ` page-container mt-3 ${isEmpty(listingCount) ? '' : 'border-bottom'}` : 'd-flex align-items-center pb-2'}
                style={{height: '37px'}}>
                {!isEmpty(listingCount) && listingCount !== 0 &&
                    <div className={'d-flex align-items-center '}>
                        <DLText id={'count-text'}
                                isInline={true}
                                fontSize={'sm'}
                                fontColor={'grayDark'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + listingCount + ' records'}/>

                        <i className="fa-solid fa-rotate-right cursor mx-2"
                           onClick={() => {
                               getFTReportDetails();
                           }}/>
                    </div>
                }
            </div>
        )
    };

    const renderStatusModal = () => {

        if (isStatusModalOpen) {
            return <FTReportStatusModal
                {...props}
                handleStatusModalClose={handleStatusModalClose}
            />
        }
    };

    const renderActionButton = (id) => {
        const {handleActionClicked, isActionClicked, actionBtnOptions} = props;

        return (
            <div className="d-flex justify-content-around" onClick={() => handleActionClicked(!isActionClicked, id)}>
                <div className={isXSView ? '' : "px-3"} style={{fontSize: appTheme.fontSizes.md}}>
                    <DLMenuOptions
                        id={"action-btn-options"}
                        minWidth={'60px'}
                        onSelect={() =>
                            handleStatusModalOpen()
                        }
                        options={actionBtnOptions}
                    />
                </div>
            </div>
        )
    };

    const renderAccountHolders = (accountHolders, accountType, id) => {
        let name = '';

        if (isEmpty(accountHolders)) {
            return '';
        }
        accountHolders.map((item, index) => {
            if (accountType !== 'MINOR') {
                if (index <= (accountHolders?.length - 2)) {
                    name = name + item?.fullName + ', '
                } else {
                    name = name + item?.fullName
                }
            } else {
                if (item?.holder_type === 'PRIMARY_HOLDER_MINOR') {
                    name = name + item?.fullName
                }
            }
        });

        name = isXSView ? name : getTrimmedValue(name, 40);

        return (
            <DLText id={'accountHolders'}
                    text={name}
                    onClick={() => redirectToDetailsScreen(id)}
                    fontSize={'xs'}
                    marginBottom={'none'}
                    isClickable={true}
            />
        );
    };

    const renderMobileUI = (txn) => {
        const {accountCategories, getCategory} = props;

        return (
            <div className='py-2 my-1'>
                <div className='d-flex mb-3 pb-1'>
                    <div className='w-100'>
                        {renderAccountHolders(txn?.accountHolders, txn?.accountType, txn?.accountId)}
                        <DLText id={'account-type-details'}
                                text={getAccountType(txn?.accountType) + ' ' + validate(txn?.IINNumber) + ' ' + getCategory(txn?.category, accountCategories)}
                                fontSize={'xxs'}
                                fontWeight={'normal'}
                                fontColor={"grayDark"}
                                marginBottom={'none'}
                        />
                    </div>
                    {renderActionButton(txn?.id)}
                </div>
                <div className='d-flex column-gap-3'>
                    <div className='w-50'>
                        <DLText id={'date'}
                                text={'on ' + moment(txn?.entryDate).utc().format("DD-MM-YYYY")} //this format is handled using moment and utc bcoz NSE not return a date in UTC format
                                fontSize={'xxs'}
                                marginBottom={'none'}
                        />
                    </div>
                    <div className='w-50'>
                        <DLText id={'amount'}
                                text={numberWithCommas(convertAmountInLakhAndCroreForChart(txn?.amount))}
                                fontWeight={'bold'}
                                fontSize={'xxs'}
                                marginBottom={'none'}
                        />
                        <DLText id={'transaction-type'}
                                text={txn?.trxnType}
                                fontSize={'xxs'}
                                marginBottom={'none'}
                        />
                    </div>
                    <div className='w-50'>
                        {getTransactionStatus(txn?.trxnStatus, statusOptions, true, isXSView)}
                    </div>
                </div>
            </div>
        )
    };

    const renderAmountUI = () => {
        let totalAmount = FTReportListDetails?.totalAmount;

        return (
            !isEmpty(FTReportListDetails?.totalAmount) && (
                <div className="text-start">
                    <DLText
                        id={'total-amount'}
                        fontWeight={"semi-bold"}
                        fontSize={'md'}
                        text={'Total Amount: ' + numberWithCommas(convertAmountInLakhAndCroreForChart(totalAmount))}
                    />
                </div>
            )
        )
    };

    const renderTableUI = () => {
        const {
            isShowFilterMobile, handleChangePage, handleChangeRowsPerPage,
            getPaginationNextButtonStatus, skipCount, listingCount, rowsPerPage, page
        } = props;

        let tableData = getFormattedTableData(FTReportListDetails?.fundReportItems || []);

        if (isEmpty(tableData)) {
            return (
                <div
                    className={(isXSView ? '' : 'panel ') + 'w-100 d-flex align-items-center justify-content-center '}
                    style={{minHeight: getEmptyTableHeight(isShowFilterMobile)}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={'ft-report-list-for-management'}
                    tableMaxHeight={getTableHeight(isShowFilterMobile)}
                    tableMinHeight={getTableHeight(isShowFilterMobile)}
                    isRowClickable={false}
                    headerData={!isXSView ? headingFTReportDataForManagement : null}
                    tableData={tableData}
                    cellConfig={!isXSView ? FTReportCellConfigForManagement : null}
                    isShowPagination={true}
                    isShowTotalCount={isMDView || !isMobileView}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0
                        }
                    }
                />
            </div>
        )
    };

    const renderFTTable = () => {
        return (
            <div className='w-100'>
                {!isXSView ?
                    <div className='d-flex justify-content-between align-items-center '>
                        {renderCount()}
                        {renderAmountUI()}
                    </div> :
                    renderCount()
                }

                {renderTableUI()}
            </div>
        )
    };

    const renderUi = () => {
        const {UIState} = props;
        if (UIState === '') {
            return
        }

        return (
            <div className={'h-100 d-flex justify-content-center' + (!isMDView && isMobileView ? '' : ' bg-light ')}
                 style={{minHeight: !isMDView && isMobileView ? 'calc(100dvh - 58px)' : 'calc(100dvh - 52px)'}}>
                <div className={'w-100'}>
                    {renderHeader()}
                    {isMobileView ?
                        renderFilterMobile() :
                        <div className={'page-container bg-light'}>
                            {renderFilter()}
                        </div>
                    }
                    <div className={!isMDView && isMobileView ? '' : ' bg-light page-container'}>
                        {renderFTTable()}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderLoader()}
            {renderUi()}
            {renderStatusModal()}
        </div>
    );
};

export default FTReportListForManagementView;