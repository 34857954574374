import React from 'react';
import DLTableView from './DLTableView';
import {isEmpty} from "../DLComponentHelper";
import {tableConfig} from "./DLTableConfig"
import PropTypes from "prop-types";
import {connect} from "react-redux";

const DLTable = (props) => {

    const getHeaderStyle = (cellIndex) => {
        const {headerConfig, defaultHeaderCellConfig, cellConfig} = props;

        let style = {};

        if (isEmpty(cellConfig)) {
            style = {
                ...headerConfig,
                ...defaultHeaderCellConfig
            }
        }else{
            let cellStyle= cellConfig[cellIndex];
            style = {
                ...style,
                ...defaultHeaderCellConfig,
                ...cellStyle
            }
        }

        return style;

    };

    // This is for header cell as well as data cells.
    const getCellStyle = (cellIndex) => {
        const {defaultCellConfig, cellConfig} = props;
        let style = {};
        if (isEmpty(cellConfig)) {
            style = {
                ...defaultCellConfig
            }
        }else{
            let cellStyle= cellConfig[cellIndex];
            style = {
                ...defaultCellConfig,
                ...cellStyle
            }
        }
        return style;
    };

    return (
        <DLTableView
            {...props}
            getHeaderStyle={getHeaderStyle}
            getCellStyle={getCellStyle}
        />);

};

DLTable.propTypes = {
    id: PropTypes.string.isRequired,
    tableName: PropTypes.string,
    tableMinHeight: PropTypes.string,
    tableMaxHeight: PropTypes.string,
    tableWidth: PropTypes.string,
    headerConfig: PropTypes.shape({
        alignment: PropTypes.string,
        height: PropTypes.string,
        padding: PropTypes.string
    }),
    isRowClickable: PropTypes.bool,
    onRowClick: PropTypes.func,
    cellConfig: PropTypes.array,
    headerData: PropTypes.array.isRequired,
    tableData: PropTypes.any,
    pagination: PropTypes.shape({
        totalPages: PropTypes.number,
        rowsPerPage: PropTypes.number,
        currentPage: PropTypes.number,
        enableNextButton: PropTypes.bool,
        enablePrevButton: PropTypes.bool,
    }),
    onPageChange: PropTypes.func,
    onRowPerPageChange: PropTypes.func,
    isShowPagination : PropTypes.bool,
    isDynamicHeight: PropTypes.bool,
    isShowTotalCount: PropTypes.bool,
    totalCountRow: PropTypes.array,
    isShowTableBorder: PropTypes.bool,
    isShowCellSeprator: PropTypes.bool
};

DLTable.defaultProps = {
    ...tableConfig
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView
});

export default connect(mapStateToProps, {})(DLTable);
