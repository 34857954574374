import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PurchaseTransactionView from "./PurchaseTransactionView";
import {isEmpty} from "../../../../../../utils/validations";
import {
    submitFundRequest,
    serachPurchaseProducts,
    selectProduct,
    addPurchaseScheme,
    getTransactionDetails
} from "../../../../FTAction";
import {applyPortfolio} from "../../../../../../portfolio/portfolioAction";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";

import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";

import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";

const PurchaseTransaction = (props) => {

    const [purchaseTransactionState, setPurchaseTransactionState] = useState({
        funds: [],
        investmentAmount: "",
        totalAllocationAmount: "",
        totalAllocationPercentage: "",
        warningMessage: '',
        totalSelectedAmount: '',
        responseErrors: '',
        isOpenAddSchemeModal: false,
        isOpenDiscardModal: false,
        isShowLoader: false,
        isShowInlineLoader: false,
        searchFundError: '',
        searchKeyword: '',
        addedFund: '',
        productModalError: '',
        selectedProductId: '',
        isReinvested: false,
        addedProductAmount: '',
        folioNumbers: [
            {
                label: 'Create New Folio',
                value: 'null'
            }
        ],
        addedFolioNumber: '',
        productMinValue: '',
        productMaxValue: '',
        productMultipleValue: '',
        addedProductAmountError: '',
        selectedScheme: [],
        isDoneClicked: false,
        UIState: '',
        isOpenSelectFolioModal : false
    });

    useEffect(() => {
        let mode =  props?.location?.state?.mode;
        if (mode === 'EDIT') {
            getTransactionData(mode)
        }
    }, []);

    useEffect(() => {
        if(purchaseTransactionState?.isOpenAddSchemeModal){
            let limits = getProductMinAndMaxValue(purchaseTransactionState?.addedFolioNumber, purchaseTransactionState?.selectedScheme?.productLimits);
            if(isEmpty(limits)){
              limits = {
                  productMinValue: '',
                  productMaxValue: '',
                  productMultipleValue: '',
              }
            }
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                ...limits,
            }))
        }
    }, [purchaseTransactionState?.addedFolioNumber]);

    const getSelectedPortfolioData = (portfolioId) => {

        let transactionRequestId =  getTransactionRequestId();

        let payload = {
            portfolioId: isEmpty(portfolioId)? null: portfolioId,
            transactionRequestId: transactionRequestId
        };

        applyPortfolio(payload, true)
            .then(res => {
                if (res?.success) {
                    setTransactionData(res?.data, false);
                }else{
                    setPurchaseTransactionState((prevState) => ({
                        ...prevState,
                        responseErrors: res?.__error,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE
                    }))
                }
            })
    };


    const getTransactionData = (mode) => {
        const txnRequestId = getTransactionRequestId();
        const clientRequestId = getClientRequestId();
        let isAddSchemeModalOpen =  props?.location?.state?.isAddSchemeModalOpen;
        getTransactionDetails(txnRequestId).then(transactionsRes => {
            if (transactionsRes?.success) {
                let requestData = transactionsRes.data;
                if (isEmpty(requestData?.funds)) {
                    setTransactionData(requestData, isAddSchemeModalOpen);
                    if(!isAddSchemeModalOpen){
                        openSelectFolioModal()
                        // props.history.replace("/client-requests/" + clientRequestId + "/financial-transaction/" + txnRequestId + "/select-portfolio")
                    }
                } else {
                    setTransactionData(requestData);
                }
            }
        })
    };

    const getTransactionRequestId = () => {
        return parseInt(props.match?.params?.transactionId)
    };

    const getClientRequestId = () => {
        return parseInt(props.match?.params?.clientRequest)
    };

    const setTransactionData = (selectedPortfolio, isAddSchemeModalOpen) => {

        if (isEmpty(selectedPortfolio)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                isOpenAddSchemeModal: isAddSchemeModalOpen
            }));
            return
        }
        let allFunds = [];
        let selectedFunds = selectedPortfolio?.funds;

        if(selectedFunds?.length === 0 || isEmpty(selectedFunds)){
            console.log("no funds selected from portfolio")
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                funds: [],
                investmentAmount: selectedPortfolio?.investmentAmount,
                totalAllocationAmount: selectedPortfolio?.totalAllocationAmount,
                totalAllocationPercentage: selectedPortfolio?.totalAllocationPercentage,
                isOpenAddSchemeModal: isAddSchemeModalOpen,
                isOpenSelectFolioModal: false,
                UIState: APP_CONST.CONTENT_UNAVAILABLE
            }))
            return;
        }

        selectedFunds?.map((selectedFund) => {
            let allocationAmount = getAllocation(selectedFund?.amount, selectedPortfolio?.investmentAmount);
            allFunds.push({
                productId: selectedFund.productId,
                productName: selectedFund.productName,
                amount: selectedFund.amount,
                allocationAmount: selectedFund.allocation,
                allocation: isEmpty(allocationAmount) ? '-' : (allocationAmount + '%'),
                errors: getErrors(selectedFund.amount, selectedFund, selectedFund?.folioNumber),
                details: selectedFund.details,
                folioNumbers: [
                    {
                        label: 'Create New Folio',
                        value: 'null'
                    }
                ],
                isOpenSelectFolioModal: false,
                folioNumber: selectedFund.folioNumber,
                reinvestFlag: setReinvest(selectedFund)
            })
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds,
            investmentAmount: selectedPortfolio?.investmentAmount,
            totalAllocationAmount: selectedPortfolio?.totalAllocationAmount,
            totalAllocationPercentage: selectedPortfolio?.totalAllocationPercentage,
            isOpenAddSchemeModal: isAddSchemeModalOpen,
            isOpenSelectFolioModal: false,
            UIState: APP_CONST.CONTENT_AVAILABLE
        }))
    };

    const filterErrorMessages = (errors) => {
        let message = "Purchase is not allowed for this fund"
        let updatedArray = [];
        if(isEmpty(errors)){
            return [];
        }
        let array = [...errors]
        updatedArray = array.filter(value => value === message);
        return updatedArray;
    };

    const changeFolioNumber = (newFolioNumber, productId) => {
        const {funds} = purchaseTransactionState;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                return {
                    ...fund,
                    errors: getErrors(fund?.amount, fund, newFolioNumber),
                    folioNumber: newFolioNumber
                };
            }
            return fund;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds
        }))
    };

    const isMultiple = (num, multiple) => {

        if (multiple === 0) return false;

        let selectedMultiple = multiple;

        if(selectedMultiple < 100){
            //as per disscusion with both sir to solve 0.01 multiplier issue
            selectedMultiple = 100
        }

        // Count decimal places in multiple to determine the scaling factor
        const decimalPlaces = selectedMultiple?.toString().split('.')[1]?.length || 0;
        const scaleFactor = Math.pow(10, decimalPlaces);

        // Scale num and selectedMultiple to integers
        const scaledNum = Math.round(num * scaleFactor);
        const scaledMultiple = Math.round(selectedMultiple * scaleFactor);
        // Check if scaled num is a selectedMultiple of scaled selectedMultiple
        return scaledNum % scaledMultiple === 0;
    };

    const getErrors = (amount, selectedFund, folioNumber) => {
        let errors = [];
        let allLimits = selectedFund.details.limits;
        let purchaseAllowed = selectedFund.details.purchaseAllowed;
        let isFP = isEmpty(folioNumber);
        let selectedLimit = {};

        if(!purchaseAllowed){
            let emptyErrorArray = ["Purchase is not allowed for this fund"]
            return emptyErrorArray;
        }

        let uniqueErrors = errors;

        if (isEmpty(allLimits)) {
            return errors;
        }

        selectedLimit = getProductMinAndMaxValue(folioNumber, allLimits);

        if(isFP && isEmpty(selectedLimit)){
            let emptyFPErrorArray = ["Fresh Purchase is not allowed for this fund"];
            return emptyFPErrorArray;
        }else if(!isFP && isEmpty(selectedLimit)){
            let emptyAPErrorArray = ["Additional Purchase is not allowed for this fund"];
            return emptyAPErrorArray;
        }else if(isEmpty(amount)){
            let emptyErrorArray = ["Please enter amount"];
            return emptyErrorArray;
        }else if(amount <= 0){
            let zeroErrorArray = ["Amount should not be zero"];
            return zeroErrorArray;
        }else {

            //get limit details from array of all limits using txnType
            let selectedMultiplier = (selectedLimit?.productMultipleValue < 100) ? 100 : selectedLimit?.productMultipleValue;

            let minValue = selectedLimit?.productMinValue;
            let maxValue = selectedLimit?.productMaxValue;
            let multiplier = selectedMultiplier;

            let minimumAmountMessage = 'Minimum amount required is ' + minValue;
            let maximumAmountMessage = 'Maximum amount allowed is ' + maxValue;
            let multiplierMessage = 'Amount should be in multiples of ' + multiplier;

            let valuesArray = {
                minimumAmountMessage: minimumAmountMessage,
                maximumAmountMessage: maximumAmountMessage,
                multiplierMessage: multiplierMessage
            }

            errors = replaceExistingMessage(errors, valuesArray);

            if (!isEmpty(selectedLimit)) {

                if (amount > selectedLimit?.productMaxValue) {
                    errors.push(maximumAmountMessage)
                } else {
                    errors = errors.filter(value => value !== maximumAmountMessage);
                }

                if (amount < selectedLimit?.productMinValue) {
                    errors.push(minimumAmountMessage)
                } else {
                    errors = errors.filter(value => value !== minimumAmountMessage);
                }

                if (isMultiple(amount, selectedMultiplier)) {
                    errors = errors.filter(value => value !== multiplierMessage);

                } else {
                    errors.push(multiplierMessage)
                }

                uniqueErrors = [...new Set(errors)];
            }

            let filteredErrors = filterErrorMessages(uniqueErrors)

            if (!isEmpty(filteredErrors)) {
                return filteredErrors;
            }

            return uniqueErrors
        }
    };

    const replaceExistingMessage = (array, newValues) => {
        if(isEmpty(array)){
          return [];
        }
        let updatedArray = [...array]
        for(let i = 0; i < updatedArray.length; i++){
            if(updatedArray[i].includes("Minimum amount required is")){
                updatedArray[i] = newValues?.minimumAmountMessage;
            }else if(updatedArray[i].includes("Maximum amount allowed is")){
                updatedArray[i] = newValues?.maximumAmountMessage;
            }else if(updatedArray[i].includes("Amount should be in multiples of")){
                updatedArray[i] = newValues?.multiplierMessage;
            }
        }
        return updatedArray;
    };

    const onchangeValue = (name, value) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            [name]: value,
            [name + "Error"]: ''
        }))
    };

    const getAllocation = (amountSelected, investmentAmount) => {

        if (isEmpty(amountSelected) || isEmpty(investmentAmount)) {
            return ''
        }

        let totalPercentage = (amountSelected / investmentAmount) * 100;
        return isEmpty(totalPercentage) ? '' : totalPercentage.toFixed(2)
    };

    const changeAmount = (newAmount, productId) => {
        const {funds, investmentAmount} = purchaseTransactionState;
        let totalAllocationAmount = 0;
        let allFunds = funds.map(fund => {
            if (fund.productId === productId) {
                let allocationAmount = getAllocation(newAmount, investmentAmount);
                totalAllocationAmount += parseInt(newAmount);
                return {
                    ...fund,
                    amount: newAmount,
                    errors: getErrors(newAmount, fund, fund?.folioNumber),
                    allocationAmount: allocationAmount,
                    allocation: isEmpty(allocationAmount) ? '-' : allocationAmount + '%'
                };
            } else {
                totalAllocationAmount += parseInt(fund?.amount)
            }
            return fund;
        });

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            warningMessage: (totalAllocationAmount !== parseInt(investmentAmount)) ? 'The investment amount does not match the total amount of all schemes.' : '',
            funds: allFunds
        }))
    };

    const clearSelectedPortfolio = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: [],
            totalAllocationAmount: "",
            totalAllocationPercentage: "",
            warningMessage: '',
            totalSelectedAmount: ''
        }))
    };

    const deleteFund = (productId) => {
        const {funds, investmentAmount} = purchaseTransactionState;
        let allFunds = funds.filter(product => product.productId !== productId);

        const totalAllocationAmount = allFunds.reduce((sum, fund) => {
            return sum + parseInt(fund?.amount || 0);
        }, 0);

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: allFunds,
            warningMessage: (totalAllocationAmount !== parseInt(investmentAmount) && totalAllocationAmount !== 0) ? 'The investment amount does not match the total amount of all schemes.' : '',
        }))
    };

    const closeAddSchemeModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenAddSchemeModal: false,
            productModalError:'',
            searchFundError: '',
            searchProduct: '',
            searchKeyword:'',
            products:[],
            addedFund: '',
            isReinvested: false,
            addedProductAmountError: '',
            productMinValue:'',
            productMaxValue: '',
            productMultipleValue : '',
            addedProductAmount: '',
            addedFolioNumber: ''
        }))
    };

    const openAddSchemeModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenAddSchemeModal: true
        }))
    };

    const redirectScreen = (action, data) => {
        let transactionRequestId = getTransactionRequestId();
        let clientRequestId = getClientRequestId();

        if (action === 'SELECT-FOLIO') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId + '/select-portfolio',{portfolio: data});
        }
        if (action === 'DISCARD') {
            props.history.replace(
                '/client-requests/' + clientRequestId + '/financial-transaction/' + transactionRequestId);
        }
    };

    const validateTransaction = (updateFundsWithErrors) => {
        let allErrors = [];
        updateFundsWithErrors.map((selectedFund) => {
            let errors = isEmpty(selectedFund?.errors) ? [] : selectedFund?.errors;
            allErrors = [...allErrors, ...errors]
        });
        return allErrors;
    };

    const handleDoneCLick = () => {
        const {funds} = purchaseTransactionState;
        let allFunds = funds.map(fund => {
            return {
                ...fund,
                errors: getErrors(fund?.amount, fund, fund?.folioNumber)
            };
        });

        return allFunds;
    };

    let searchProductAPI = (keyword) => {
        let payload = {keyword: keyword, limitCount: 30};
        serachPurchaseProducts(payload).then(res => {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchFundError: '',
                products: res.data?.products,
                productModalError: '',
                isShowInlineLoader: false
            }))
        });
    };

    const searchProduct = (keyword) => {

        if (isEmpty(keyword)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchKeyword: '',
                products:[]
            }));
            return
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            searchKeyword: keyword,
            isShowInlineLoader: true,
        }));

        searchProductAPI(keyword)

    };

    const setReinvest = (selectedFund) => {

        if(selectedFund?.reinvestFlag === true){
            return true;
        }else if(selectedFund?.reinvestFlag === false){
            return false;
        }
        return selectedFund?.details?.reinvestTag !== 'N';

    }

    const submitTransactionDetails = () => {
        const {funds} = purchaseTransactionState;
        let transactionRequestId = getTransactionRequestId();
        let updateFundsWithErrors = handleDoneCLick();

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            funds: isEmpty(updateFundsWithErrors) ? [] : updateFundsWithErrors,
            isDoneClicked: true
        }))

        let products = [];

        updateFundsWithErrors?.map((selectedFund) => {
            products.push({
                productId: selectedFund?.productId,
                allocation: parseInt(selectedFund?.allocationAmount),
                amount: parseInt(selectedFund?.amount),
                folioNumber: selectedFund?.folioNumber,
                reinvestFlag: setReinvest(selectedFund)
            })
        });
        let transactionErrors = validateTransaction(updateFundsWithErrors);

        if (transactionErrors.length > 0) {
            store.dispatch(showToastMessage('warning', transactionErrors?.length === 1 ? 'Error exists, changes are not saved.' : 'Errors exist, changes are not saved.'))
            return;
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            transactionRequestId: transactionRequestId,
            products: products
        };

        submitFundRequest(payload, true)
            .then(res => {
                if (res.success) {
                    setPurchaseTransactionState((prevState) => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    redirectScreen('DISCARD')
                } else {
                    setPurchaseTransactionState((prevState) => ({
                        ...prevState,
                        responseErrors: typeof res?.__error === 'string'? res?.__error : '',
                        funds: typeof res?.__error === 'object'? handleFundsWithErrors(res?.__error, updateFundsWithErrors) : updateFundsWithErrors,
                        isShowLoader: false
                    }))

                }
            })

    };

    const handleFundsWithErrors = (errors, funds) => {
        if(isEmpty(errors)|| isEmpty(funds)){
            return funds;
        }
        let allFunds = funds.map(fund => {
            let productId = fund?.productId;
            let backEndError = errors.find((obj)=>{return obj?.productId === productId})
            return {
                ...fund,
                errors: isEmpty(backEndError)? [] : backEndError?.errors
            };
        });
        return allFunds;
    };


    const checkIfProductPresent = (funds, productId) => {
        if (isEmpty(funds)) {
            return false;
        }
        const scheme = funds.find((item) => {
            return item?.productId === productId;
        })
        return !isEmpty(scheme);
    }

    const addProduct = (id) => {
        const {products, funds} = purchaseTransactionState;
        const selectedProduct = products.find(item => id === item.id)
        let transactionRequestId = getTransactionRequestId();
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));
        if (isEmpty(selectedProduct)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedFund: '',
                selectedProductId: '',
                searchKeyword: '',
                products: [],
                productModalError: '',
                addedProductAmount: '',
                addedFolioNumber: '',
                productMinValue: '',
                productMaxValue: '',
                productMultipleValue: '',
                addedProductAmountError: '',
                isShowLoader: false,
                isReinvested: false
            }));
            return
        }

        let productId = selectedProduct?.id;

        if (checkIfProductPresent(funds, productId)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedFund: selectedProduct?.description,
                selectedProductId: productId,
                productModalError: "Cannot add fund as it's already added for this transaction",
                isShowLoader: false
            }))
            return;
        }

        selectProduct(transactionRequestId, productId).then(res => {
            if (res?.success) {
                let limits = getProductMinAndMaxValue("", res?.data?.productLimits)
                if(isEmpty(limits)){
                    limits = {
                        productMinValue: '',
                        productMaxValue: '',
                        productMultipleValue: '',
                    }
                }
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    ...limits,
                    selectedScheme: res.data,
                    addedFund: selectedProduct?.description,
                    selectedProductId: productId,
                    productModalError: '',
                    searchKeyword: '',
                    isShowLoader: false,
                    isReinvested: isReinvestChecked(res.data)
                }))
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    addedFund: selectedProduct?.description,
                    selectedProductId: productId,
                    productModalError: res?.__error,
                    isShowLoader: false
                }))
            }
        });
    };

    const reinvestProduct = (value) => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isReinvested: value,
        }))
    };

    const validateAmount = () => {
        const {addedProductAmount, productMinValue, productMaxValue, productMultipleValue, selectedProductId, addedFolioNumber} = purchaseTransactionState;
        if (isEmpty(selectedProductId)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                searchFundError: 'Please select Product'
            }));
            return false
        }

        if (isEmpty(addedProductAmount)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Please enter amount'
            }));
            return false
        }

        if (addedProductAmount <= 0) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Amount should not be 0'
            }));
            return false
        }

        if (isEmpty(productMaxValue)) {
            if (isEmpty(addedFolioNumber)) {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    addedProductAmountError: 'Fresh purchase is not allowed for this fund'
                }));
                return false
            }else{
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    addedProductAmountError: 'Additional purchase is not allowed for this fund'
                }));
                return false
            }
        }

        if (addedProductAmount > productMaxValue) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Maximum amount allowed is ' + productMaxValue
            }));
            return false
        }

        if (addedProductAmount < productMinValue) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Minimum amount required is ' + productMinValue
            }));
            return false
        }

        if (!isMultiple(addedProductAmount, productMultipleValue)) {
            setPurchaseTransactionState((prevState) => ({
                ...prevState,
                addedProductAmountError: 'Amount should be in multiples of ' + productMultipleValue
            }));
            return false
        }

        return true
    };

    const addScheme = () => {
        const {addedProductAmount, addedFolioNumber, selectedProductId, funds, investmentAmount, isReinvested} = purchaseTransactionState;
        let transactionRequestId = getTransactionRequestId();

        let isValidAmount = validateAmount();
        if (!isValidAmount) {
            return
        }

        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            txnRequestId: transactionRequestId,
            productId: selectedProductId,
            amount: parseInt(addedProductAmount),
            folioNumber: addedFolioNumber,
            reinvestFlag: isReinvested
        };

        addPurchaseScheme(payload).then(res => {
            if (res?.success) {
                let resp = res?.data[0];

                let allocationAmount = getAllocation(resp?.amount, investmentAmount);
                let respFunds = [{
                    productId: resp?.productId,
                    productName: resp?.productName,
                    amount: resp?.amount,
                    allocationAmount: resp?.allocation,
                    allocation: isEmpty(allocationAmount) ? '-' : (allocationAmount + '%'),
                    errors: getErrors(resp?.amount, resp, addedFolioNumber),
                    details: resp?.details,
                    folioNumbers: [
                        {
                            label: 'Create New Folio',
                            value: 'null'
                        }
                    ],
                    folioNumber: isEmpty(addedFolioNumber)? "" : addedFolioNumber,
                    reinvestFlag: resp?.reinvestFlag
                }];

                const mappedProducts = funds.concat(respFunds);
                const totalAllocationAmount = mappedProducts.reduce((sum, fund) => {
                    return sum + parseInt(fund?.amount || 0);
                }, 0);

                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    funds: mappedProducts,
                    isShowLoader: false,
                    addedFolioNumber: '',
                    addedProductAmount: '',
                    addedFund: '',
                    selectedProductId: '',
                    searchKeyword: '',
                    isOpenAddSchemeModal: false,
                    warningMessage: (parseInt(investmentAmount) !== totalAllocationAmount) ? 'The investment amount does not match the total amount of all schemes.' : '',
                }))
            } else {
                setPurchaseTransactionState((prevState) => ({
                    ...prevState,
                    productModalError: res?.__error,
                    isShowLoader: false
                }))
            }
        });
    };

    const closeAddDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: false
        }))
    };

    const isReinvestChecked = (selectedScheme) => {
        let flag = false
        if(isEmpty(selectedScheme)){
            return;
        }
        if(selectedScheme.reinvestTag === 'X' || selectedScheme.reinvestTag === 'Y' || selectedScheme.reinvestTag === 'Z'){
            flag = true
        }
        return flag
    }

    const isCheckBoxDisabled = (selectedScheme) => {
        let flag = false
        if(isEmpty(selectedScheme)){
            return;
        }
        if(selectedScheme?.reinvestTag === 'N' || selectedScheme?.reinvestTag === 'Y'  || selectedScheme?.reinvestTag === 'Z'){
            flag = true
        }
        return flag
    }

    const getProductMinAndMaxValue = (addedFolioNumber, productLimits) => {

        if(isEmpty(productLimits)){
            return null;
        }
        let purchaseTransactionObj = getPurchaseTxnLimitObj(addedFolioNumber, productLimits)
        if(!purchaseTransactionObj || purchaseTransactionObj.maxAmount <= 0){
            return null;
        }

        let limitsObj = {
            productMinValue : isEmpty(purchaseTransactionObj.minAmount)? '' : purchaseTransactionObj.minAmount,
            productMaxValue : isEmpty(purchaseTransactionObj.maxAmount)? '' : purchaseTransactionObj.maxAmount,
            productMultipleValue: isEmpty(purchaseTransactionObj.multiples)? '' : (purchaseTransactionObj.multiples < 100) ? 100 : purchaseTransactionObj.multiples,
        }
        return limitsObj;
    };

    const getPurchaseTxnLimitObj = (folioNumber, limitsArray) => {
        if(isEmpty(limitsArray)){
            return;
        }
        let txn = [];

        if(isEmpty(folioNumber)){
            txn = limitsArray.find((item)=>{
               return item?.TRXNType === "FP";
           })
        }else{
            txn = limitsArray.find((item)=>{
                return item?.TRXNType === "AP";
            })
        }
        return txn;
    };

    const openDiscardModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenDiscardModal: true
        }))
    };

    const openSelectFolioModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenSelectFolioModal: true
        }))
    };

    const closeSelectFolioModal = () => {
        setPurchaseTransactionState((prevState) => ({
            ...prevState,
            isOpenSelectFolioModal: false
        }))
    };


    if (isEmpty(purchaseTransactionState?.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <PurchaseTransactionView
            {...props}
            {...purchaseTransactionState}
            onchangeValue={onchangeValue}
            changeAmount={changeAmount}
            clearSelectedPortfolio={clearSelectedPortfolio}
            deleteFund={deleteFund}
            closeAddSchemeModal={closeAddSchemeModal}
            openAddSchemeModal={openAddSchemeModal}
            redirectScreen={redirectScreen}
            validateTransaction={validateTransaction}
            submitTransactionDetails={submitTransactionDetails}
            searchProduct={searchProduct}
            addProduct={addProduct}
            reinvestProduct={reinvestProduct}
            addScheme={addScheme}
            closeAddDiscardModal={closeAddDiscardModal}
            openDiscardModal={openDiscardModal}
            isCheckBoxDisabled={isCheckBoxDisabled}
            isReinvestChecked={isReinvestChecked}
            changeFolioNumber={changeFolioNumber}
            filterErrorMessages={filterErrorMessages}
            openSelectFolioModal={openSelectFolioModal}
            closeSelectFolioModal={closeSelectFolioModal}
            getSelectedPortfolioData={getSelectedPortfolioData}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    isMDView: state.appState.deviceInfo.isMDView,
});


export default connect(mapStateToProps, {})(PurchaseTransaction);