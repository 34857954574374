import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'OverviewReducer',
    initialState: {
        OverviewFilter: {
            OverviewLocation: '',
            OverviewCRMId: ['ALL'],
            OverviewInterval: 'THIS_MONTH'
        },
        CRMDetails: []
    },
    reducers: {

        setOverviewLocationValue (state,action) {
            state.OverviewFilter.OverviewLocation = action.payload;
        },

        setOverviewCRMIdValue(state, action) {
            state.OverviewFilter.OverviewCRMId = action.payload;
        },

        setOverviewIntervalValue(state,action){
            state.OverviewFilter.OverviewInterval = action.payload;
        },

        resetOverviewAllFilters (state) {
            state.OverviewFilter = {
                ...state.FTFilter,
                OverviewLocation: ['ALL'],
                OverviewCRMId: ['ALL'],

            };
        },
    }
});

export const {setOverviewIntervalValue, setOverviewCRMIdValue, setOverviewLocationValue, resetOverviewAllFilters} = appSlice.actions;

export default appSlice.reducer