import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment";

import {APP_CONST} from "../../../AppComponents/AppConstants";
import {isEmpty} from "../../../utils/validations";

import {getIntervalData} from "../../HomePageActions";
import {
    resetFTReportFilters,
    setFTReportFromDate, setFTReportToDate
} from "../../../transactions/FT/components/FTReportList/FTReportAction";

import ChartModalView from "./ChartModalView";

const ChartModal = (props) => {

    const{legendAttribute, legendInterval, isManagement, graphData, selectedDataIndex} = props;
    const [modalState, setModalState] = useState({
        tableData: [],
        handleErrorInComponent: true,
        modalErrorMessage: '',
        interval: '',
        subInterval: '',
        headings: ["Type", "Normal", "Systematic", "Total"],
        isShowLoader: false,
        UIState: '',
        modalHeader: '',
    });

    useEffect(()=>{
        renderGetDetails()
    },[]);

    const renderGetDetails = () =>{
        setModalState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        getIntervalData(legendInterval, legendAttribute, isManagement).then((res) => {
            if (res?.success) {
                let data = getChartData(res?.data);
                setModalState((prevState) => ({
                    ...prevState,
                    tableData: data,
                    interval: legendInterval,
                    subInterval: legendAttribute,
                    modalHeader: getModalHeader(legendInterval, selectedDataIndex, graphData?.items),
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_AVAILABLE
                }));
            } else {
                setModalState((prevState) => ({
                    ...prevState,
                    interval: legendInterval,
                    subInterval: legendAttribute,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE
                }))
            }
        })
    };

    const getChartData = (data) =>{
        let chartData = [];
        let purchaseTxn = {heading: "Purchase", Normal: 0, Systematic: 0};
        let redemptionTxn = {heading: "Redemption", Normal: 0, Systematic: 0};
        let switchTxn = {heading: "Switch", Normal: 0, Systematic: 0};
        let total = {heading: "Total", Normal: 0, Systematic: 0};

        if(isEmpty(data)){
            return []
        }
        data.forEach((obj)=>{
            purchaseTxn[obj?.type] = isEmpty(obj?.purchase) ? 0 : obj?.purchase;
            redemptionTxn[obj?.type] = isEmpty(obj?.redemption) ? 0 : obj?.redemption;
            switchTxn[obj?.type] = isEmpty(obj?.switch) ? 0 : obj?.switch;
            total[obj?.type] = isEmpty(obj?.total) ? 0 : obj?.total;
        });

        chartData.push(purchaseTxn);
        chartData.push(redemptionTxn);
        chartData.push(switchTxn);
        chartData.push(total);

        return chartData
    };

    const getModalHeader = (legendInterval, dataIndex, graphData) => {
        let label = [];
        let heading = "";
        if(isEmpty(dataIndex) || isEmpty(graphData)){
            return "";
        }
        label = graphData.find((obj, index)=>{
            return dataIndex === index;
        });

        if(legendInterval === 'THIS_WEEK') {
            heading =  moment(label.date).format("dddd")
        }
        else if(legendInterval === 'THIS_MONTH') {
            heading = moment(label.date).format("DD-MMM")
        }
        else {
            heading = moment(label.date).format("MMMM")
        }

        return isEmpty(heading)? "" : "Transaction Summary of " + heading;

    };

    const handleRedirect = () =>{
        const{subInterval, interval} = modalState;
        let startData = subInterval;
        let endDate = '';

        if(interval === "THIS_QUARTER" || interval === "THIS_FINANCIAL_YEAR"){
            let endOfMonth   = moment(subInterval).endOf('month').format('YYYY-MM-DD');
            endDate = endOfMonth;
        }else{
            endDate = subInterval;
        }
        if(isEmpty(endDate)){
            return;
        }

        resetFTReportFilters();
        setFTReportFromDate(startData);
        setFTReportToDate(endDate);
        props.history.push("/reports/financial-transactions");
    };

    return (
        <ChartModalView
            {...props}
            {...modalState}
            handleRedirect={handleRedirect}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(ChartModal);
