import React from 'react';

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import appTheme from "../../../../../assets/appTheme";

import DLText from "../../../../../core/components/DLText/DLText";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import ApproveTransaction from "../components/approveTransaction/ApproveTransaction";
import RejectTransaction from "../components/rejectTransaction/RejectTransaction";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem";
import {getTicketBadge, renderStatusUI} from "../../../FTHelper";
import {isCorporateUser, numberWithCommas} from "../../../../../utils/helper";
import moment from "moment";
import NSEResponseErrorModal from "../components/NSEResponseErrorModal/NSEResponseError";
import DLMenuOptions from "../../../../../core/components/DLMenuOptions/DLMenuOptions";
import TxnTableLumpsum from "../sharedComponents/txnTableLumpsum/TxnTableLumpsum";
import TxnTableSIP from "../sharedComponents/txnTableSIP/TxnTableSIP";

const FTDetailsForManagementView = (props) => {
    const {
        isMobileView,
        category,
        IINNumber,
        accountType,
        isShowLoader,
        UIState,
        paymentDetails,
        ticketNumber,
        txnStatus,
        txnStage,
        txnData,
        handleOpenApproveTransactionModal,
        handleOpenRejectTransactionModal,
        anchorEl,
        handleItemClose,
        handleItemOpen,
        FTStatuses,
        getDepositMode,
        getBankName,
        responseError,
        getPaymentMode,
        handleCloseNSEErrorsModal,
        handleOpenNSEErrorsModal,
        isOpenNSEErrorModal,
        txnNSEErrors,
        updatedAt,
        txnOption,
        handleViewClientRequestClick
    } = props;

    const renderNSEErrors = () => {
        return (
            <NSEResponseErrorModal
                {...props}
                isOpenNSEErrorModal={isOpenNSEErrorModal}
                txnNSEErrors={txnNSEErrors}
                updatedAt={updatedAt}
                handleCloseNSEErrorsModal={() => {
                    handleCloseNSEErrorsModal()
                }}
            />
        )
    };

    const handleAmount = (amount) => {
        if (isEmpty(amount)) {
            return "-";
        }
        return numberWithCommas(amount)
    }

    const renderApproveTransactionModal = () => {
        const {isApproveTransactionModalOpen} = props;
        if (isApproveTransactionModalOpen) {
            return (
                <ApproveTransaction {...props}/>
            )
        }

    };

    const renderRejectTransactionModal = () => {
        const {isRejectTransactionModalOpen} = props;

        if (isRejectTransactionModalOpen) {
            return (
                <RejectTransaction {...props}/>
            )
        }

    };

    const renderAccountHoldersDetails = (accountDetails) => {
        const {details, corporateAccountDetails} = props;
        let accountType = isEmpty(details?.accountDetails) ? "" : details?.accountDetails[0]?.accountType;
        if (isCorporateUser(accountType)) {
            let name = corporateAccountDetails?.name;
            let email = corporateAccountDetails?.email;
            let phoneNumber = corporateAccountDetails?.phoneNumber;
            return (
                <div className={'mb-1'}>
                    <div className='text-start'>
                        <DLText
                            id={'account-holder-name'}
                            text={'Account Holder: ' + name}
                            marginBottom={"none"}
                            fontSize={"xs"}
                        />
                        <div>
                            {isEmpty(email) ? '-' : <a href={'mailto:' + email}
                                                       style={{
                                                           fontSize: 'var(--font-size-xs)',
                                                           fontFamily: appTheme.default.fontFamily
                                                       }}>
                                {email}
                            </a>}
                        </div>
                        <div>
                            {isEmpty(phoneNumber) ? '-' : <a href={'tel:' + phoneNumber}
                                                             style={{
                                                                 fontSize: 'var(--font-size-xs)',
                                                                 fontFamily: appTheme.default.fontFamily
                                                             }}>
                                {phoneNumber}
                            </a>}
                        </div>
                    </div>
                </div>
            )
        }
        return accountDetails.map((accountHolder, index) => (
            <div key={'menuItem-' + accountHolder.id}
                 className={(accountDetails?.length === index + 1) ? ' mb-1' : ' border-bottom mb-1 pb-2'}>
                <div className='text-start'>
                    <DLText
                        id={'account-holder-name'}
                        text={'Account Holder ' + (index + 1) + ': ' + accountHolder.fullName}
                        marginBottom={"none"}
                        fontSize={"xs"}
                    />
                    <div>
                        {isEmpty(accountHolder.email) ? '-' : <a href={'mailto:' + accountHolder.email}
                                                                 style={{
                                                                     fontSize: 'var(--font-size-xs)',
                                                                     fontFamily: appTheme.default.fontFamily
                                                                 }}>
                            {accountHolder.email}
                        </a>}
                    </div>
                    <div>
                        {isEmpty(accountHolder.phoneNumber) ? '-' : <a href={'tel:' + accountHolder.phoneNumber}
                                                                       style={{
                                                                           fontSize: 'var(--font-size-xs)',
                                                                           fontFamily: appTheme.default.fontFamily
                                                                       }}>
                            {accountHolder.phoneNumber}
                        </a>}
                    </div>
                </div>
            </div>
        ));
    };

    const renderAccountDetails = () => {
        const {accountDetails, corporateAccountDetails, details, getAccountHolderDetails} = props;

        let allAccountHoldersNames = "";
        let count = "";
        let accountType = isEmpty(details?.accountDetails) ? "" : details?.accountDetails[0]?.accountType;
        let accountHolderDetails = getAccountHolderDetails(accountDetails, accountType);

        if (!isEmpty(accountHolderDetails)) {
            accountHolderDetails.map((applicant, index) => {
                if (isEmpty(allAccountHoldersNames)) {
                    allAccountHoldersNames = applicant?.fullName;
                } else {
                    count = index;
                }
            });
            if (!isEmpty(count)) {
                allAccountHoldersNames = allAccountHoldersNames + ' + ' + count;
            }
        }
        // if (isMobileView) {
        //     allAccountHoldersNames = details.accountDetails[0]?.accountHolders?.length
        // }

        if (isCorporateUser(accountType)) {
            allAccountHoldersNames = corporateAccountDetails?.name;
            if (isMobileView) {
                allAccountHoldersNames = !isEmpty(allAccountHoldersNames) ? 1 : 0;
            }
        }

        return (
            <div key={'accountDetails'}
                 className='d-flex align-items-center '>
                <div>
                    {
                        <div onClick={(event) => {
                            handleItemOpen(event)
                        }}>
                            <DLText id={'account-holder-name'}
                                    text={allAccountHoldersNames}
                                    marginBottom={"none"}
                                    isClickable={true}
                                    fontColor={"darkBlue"}
                            />
                        </div>
                    }
                    <Menu id="items-menu"
                          anchorEl={anchorEl}
                          getcontentanchorel={null}
                          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                          transformOrigin={{vertical: "top", horizontal: "left"}}
                          open={Boolean(anchorEl)}
                          onClose={() => handleItemClose()}
                          className="p-2 w-100"
                          disableAutoFocusItem
                          elevation={0}
                          PaperProps={{
                              style: {
                                  marginTop: '0px',
                                  boxShadow: '0 4px 4px rgba(147, 147, 147, 0.25)',
                                  borderRadius: '8px'
                              }
                          }}
                          MenuListProps={{
                              style: {
                                  color: appTheme.appColor.black,
                                  maxHeight: '400px',
                                  textAlign: 'center',
                                  borderRadius: '8px'
                              },
                          }}>
                        {accountHolderDetails && accountHolderDetails.length > 0 || !isEmpty(corporateAccountDetails) ? (
                            <MenuItem
                                className={'py-0 text-center'}
                                style={{minWidth: 200, height: "max-content", cursor: "auto"}}
                                disableRipple
                            >
                                <div className='text-start'>
                                    {renderAccountHoldersDetails(accountHolderDetails)}
                                </div>
                            </MenuItem>
                        ) : (
                            <MenuItem disabled>No applicants available</MenuItem>
                        )}
                    </Menu>
                </div>
            </div>
        );
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            <div className="d-flex">
                                <div className='d-flex align-items-center'>
                                    {txnOption === 'SIP' ? 'Financial Transaction: SIP' : 'Financial Transaction: Lumpsum Purchase'}
                                </div>
                                {getTicketBadge("Ticket No: ", ticketNumber)}
                            </div>
                        </div>
                    </div>
                    <div>
                        <DLText id={'client'}
                                text={'View Client Request'}
                                fontColor={"darkBlue"}
                                isClickable={true}
                                onClick={() => {
                                    handleViewClientRequestClick()
                                }}
                        />
                    </div>
                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black d-flex '>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black'>
                        <DLText id={'category'}
                                text={category}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black'>
                        {renderAccountDetails()}
                    </div>
                    <div className='px-2'>
                        {renderStatusUI(txnStage, txnStatus, FTStatuses)}
                    </div>
                    {(txnStage === 'NSE_SUBMISSION' && txnStatus === 'FAILED') && <div className='px-2'>
                        <DLText id={'nse-error-modal'}
                                text={'See more'}
                                isClickable={true}
                                onClick={() => {
                                    handleOpenNSEErrorsModal()
                                }}
                                marginBottom={"none"}
                        />
                    </div>}
                </div>
            </div>
        )
    };

    const renderChequePaymentDetails = () => {
        return (
            <div>
                <div
                    className={`h-100 bg-white p-3 ${isMobileView ? 'black-border-bottom black-border-top' : 'rounded-top'}`}>
                    <div className={`d-flex justify-content-between ${isMobileView ? '' : ''}`}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={isMobileView ? 'md' : 'lg'}
                            fontWeight={isMobileView ? 'bold' : 'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                </div>
                <div className={`pb-3 pe-3 rounded-bottom ps-3 bg-white ${isMobileView ? 'mt-3' : ''}`}>
                    <div className={`${!isMobileView ? 'row mb-3' : 'mb-3'}`}>
                        <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={getPaymentMode(paymentDetails?.paymentMechanism?.code)}/>
                        </div>
                        <div className={`${!isMobileView ? 'col-9' : ''}`}>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    <div className={`${!isMobileView ? 'row' : ''}`}>
                        <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                            <DLText id={'cheque-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Number'}/>
                            <DLText id={'cheque-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.chequeNo}/>
                        </div>
                        <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                            <DLText id={'micr-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'MICR Number'}/>
                            <DLText id={'micr-number-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.chequePayment?.micrNo}/>
                        </div>
                        <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                            <DLText id={'date'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Date on Cheque'}/>
                            <DLText id={'date-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={isEmpty(paymentDetails?.chequePayment?.chequeDate) ? "" : moment(paymentDetails?.chequePayment?.chequeDate).format("DD-MM-YYYY")}/>
                        </div>
                        <div className={`${!isMobileView ? 'col-3' : 'pb-3'}`}>
                            <DLText id={'cheque-deposite-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Cheque Deposite Mode'}/>
                            <DLText id={'cheque-deposite-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={getDepositMode(paymentDetails?.chequePayment?.chequeDepositMode)}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderPaymentDetails = () => {
        if (paymentDetails?.paymentMechanism?.code === "Q") {
            return renderChequePaymentDetails()
        }
        if (isMobileView) {
            return (
                <div>
                    <div
                        className={`h-100 bg-white p-3 ${isMobileView ? 'black-border-bottom black-border-top' : 'rounded-top'}`}>
                        <div className={`d-flex justify-content-between ${isMobileView ? '' : ''}`}>
                            <DLText
                                id={'payment-details-title'}
                                type={'normal'}
                                text={'Payment Details'}
                                fontSize={'md'}
                                fontWeight={'bold'}
                                fontColor={'black'}
                                marginBottom={"none"}
                            />
                        </div>
                    </div>
                    <div className="h-100 bg-white border-rounded">
                        <div className={'pe-3 overflow-scroll overflow-x-hidden mt-3 p-3'}
                             style={{maxHeight: 'calc(100dvh - 480px)'}}>
                            <div className='row mb-3'>
                                <div className={"mb-3"}>
                                    <DLText id={'bank-name-&-account-number'}
                                            fontSize={'xs'}
                                            fontColor={"grayDark"}
                                            fontWeight={"normal"}
                                            text={'Bank Name & Account Number'}/>
                                    {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                                </div>
                                <div className={isMobileView ? "" : "col-3"}>
                                    <DLText id={'payment-mode'}
                                            fontSize={'xs'}
                                            fontColor={"grayDark"}
                                            fontWeight={"normal"}
                                            text={'Payment Mode'}/>
                                    <DLText id={'payment-mode-value'}
                                            fontSize={'sm'}
                                            marginBottom={"none"}
                                            fontWeight={"normal"}
                                            text={getPaymentMode(paymentDetails?.paymentMechanism?.code)}/>
                                </div>
                            </div>
                            {paymentDetails?.paymentMechanism?.description !== "Bank Mandate" && <div className='row'>
                                <div>
                                    <DLText id={'RTGS/IFSC-code'}
                                            fontSize={'xs'}
                                            fontColor={"grayDark"}
                                            fontWeight={"normal"}
                                            text={'RTGS/IFSC Code'}/>
                                    <DLText id={'RTGS/IFSC-code-value'}
                                            fontSize={'sm'}
                                            marginBottom={"none"}
                                            fontWeight={"normal"}
                                            text={paymentDetails?.bankAccount?.IFSCCode}/>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 mt-3'}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={getPaymentMode(paymentDetails?.paymentMechanism?.code)}/>
                        </div>
                        <div className='col-9'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                    </div>
                    {paymentDetails?.paymentMechanism?.description !== "Bank Mandate" && <div className='row'>
                        <div className='col-3'>
                            <DLText id={'RTGS/IFSC-code'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'RTGS/IFSC Code'}/>
                            <DLText id={'RTGS/IFSC-code-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.bankAccount?.IFSCCode}/>
                        </div>
                    </div>}
                </div>
            </div>
        )
    };

    const renderBankNameAndAccountNumberUi = (bankAccountDetails) => {
        if (isEmpty(bankAccountDetails?.bankName) && isEmpty(bankAccountDetails?.accountNumber)) {
            return "-"
        }
        return (
            <div>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={getBankName(bankAccountDetails?.bankName)}/>
                <DLText id={'bank-name-&-account-number-value'}
                        fontSize={'sm'}
                        marginBottom={"none"}
                        fontWeight={"normal"}
                        isInline={true}
                        text={" - " + bankAccountDetails?.accountNumber}/>
            </div>
        )
    }

    const renderButtons = () => {
        return (
            <div
                className={isMobileView ? "sticky-bottom-buttons gap-0 bg-white mt-1" : "sticky-bottom-buttons gap-0 "}>
                {!isEmpty(txnData?.totalAllocationAmount) && !isMobileView && txnOption !== 'SIP'
                &&
                <div
                    className={isMobileView ? 'd-flex justify-content-end align-items-center gap-1  p-2' : 'd-flex justify-content-end align-items-center gap-1  me-2'}>
                    <DLText id={''}
                            text={'Current payment amount'}
                            fontSize={"sm"}
                            fontWeight={"normal"}
                            fontColor={"grayDark"}
                            marginBottom={"none"}
                    />
                    <DLText id={''}
                            text={'Rs.' + handleAmount(txnData?.totalAllocationAmount)}
                            fontSize={"lg"}
                            fontWeight={"bold"}
                            marginBottom={"none"}
                    />
                </div>}
                <DLButton
                    id={'reject-Btn'}
                    history={props.history}
                    label={'Reject'}
                    buttonSize={"sm"}
                    type={"danger"}
                    fontSize={"sm"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenRejectTransactionModal()
                    }}
                />
                <DLButton
                    id={'approve-Btn'}
                    history={history}
                    label={'Approve'}
                    buttonSize={"sm"}
                    fontSize={"sm"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => {
                        handleOpenApproveTransactionModal()
                    }}/>
            </div>
        )
    };

    const renderSIPPaymentDetails = () => {
        if (isMobileView) {
            return (
                <div className={"h-100 bg-white border-rounded "} style={{minHeight: '240px'}}>
                    <div
                        className={'d-flex justify-content-between align-items-center black-border-bottom black-border-top p-3 '}>
                        <DLText
                            id={'payment-details-title'}
                            type={'normal'}
                            text={'Payment Details'}
                            fontSize={'md'}
                            fontWeight={'semi-bold'}
                            fontColor={'black'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className={'pe-3 overflow-scroll overflow-x-hidden mt-3 px-3'}
                         style={{maxHeight: 'calc(100dvh - 480px)'}}>
                        <div className='row'>
                            <div className={"mb-3"}>
                                <DLText id={'bank-name-&-account-number'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Bank Name & Account Number'}/>
                                {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                            </div>
                            <div className={" mb-3"}>
                                <DLText id={'payment-mode'}
                                        fontSize={'xs'}
                                        fontColor={"grayDark"}
                                        fontWeight={"normal"}
                                        text={'Payment Mode'}/>
                                <DLText id={'payment-mode-value'}
                                        fontSize={'sm'}
                                        marginBottom={"none"}
                                        fontWeight={"normal"}
                                        text={paymentDetails?.paymentMechanism?.description}/>
                            </div>

                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="h-100 bg-white border-rounded p-3">
                <div className='d-flex justify-content-between'>
                    <DLText
                        id={'payment-details-title'}
                        type={'normal'}
                        text={'Payment Details'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                <div className={'pe-3 mt-3'}>
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <DLText id={'bank-name-&-account-number'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Bank Name & Account Number'}/>
                            {renderBankNameAndAccountNumberUi(paymentDetails?.bankAccount)}
                        </div>
                        <div className='col-9'>
                            <DLText id={'payment-mode'}
                                    fontSize={'xs'}
                                    fontColor={"grayDark"}
                                    fontWeight={"normal"}
                                    text={'Payment Mode'}/>
                            <DLText id={'payment-mode-value'}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    fontWeight={"normal"}
                                    text={paymentDetails?.paymentMechanism?.description}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };


    const renderDetails = () => {
        return (
            <div className='h-100'>
                <div className='d-flex flex-column h-100'>
                    <div className={`bg-light ${isMobileView ? 'pb-4' : 'flex-grow-1 pb-2'}`}>
                        {txnOption === 'SIP' ?
                            <TxnTableSIP {...props} /> : <TxnTableLumpsum {...props}/>}
                    </div>
                    <div className={isMobileView ? '' : 'mb-5'}>
                        {txnOption === 'SIP' ? renderSIPPaymentDetails() : renderPaymentDetails()}
                    </div>
                </div>
            </div>
        )
    };

    const renderModalError = () => {
        if (responseError) {
            return (
                <div className='mb-3 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            )
        }
    };

    const renderDesktopUI = () => {
        return (
            <div className='d-flex flex-column bg-light h-100'>
                {renderDesktopHeader()}
                <div className="page-container">
                    {renderModalError()}
                </div>
                <div className='page-container flex-grow-1 bg-light'>
                    {renderDetails()}
                </div>
                {txnStage === "MANAGEMENT_APPROVAL" && txnStatus === "IN_PROGRESS" && renderButtons()}
            </div>
        );
    };


    const renderMobileHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between py-3 ps-3 pe-2'>
                    <div className="d-flex align-items-top">
                        <i className='fa-solid fa-chevron-left cursor fa-xs pt-3 mt-0'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div>
                            <div className="ps-4 d-flex justify-content-between">
                                <div className='page-header-mobile d-flex align-items-top p-0'
                                     style={{lineHeight: '30px'}}>
                                    {txnOption === 'SIP' ? 'Financial Transaction: SIP' : 'Lumpsum Purchase'}
                                </div>
                            </div>
                            <div className="ps-4">
                                <div className='d-flex align-items-top fs-3' style={{lineHeight: '20px'}}>
                                    {renderAccountDetails()}
                                </div>
                            </div>
                            <div className='pe-2 ps-4 pt-2 d-flex align-items-center'>
                                {renderStatusUI(txnStage, txnStatus, FTStatuses)}
                            </div>
                            {(txnStage === 'NSE_SUBMISSION' && txnStatus === 'FAILED') &&
                            <div className='page-container ms-2'>
                                <DLText id={'nse-error-modal'}
                                        text={'See more'}
                                        isClickable={true}
                                        onClick={() => {
                                            handleOpenNSEErrorsModal()
                                        }}
                                />
                            </div>}
                        </div>
                    </div>
                    {renderMenuOptions()}
                </div>

            </div>
        )
    };

    const renderAmount = () => {
        return (
            <div className={'pb-2 mb-5'}>
                {!isEmpty(txnData?.totalAllocationAmount) &&
                <div
                    className='d-flex align-items-center justify-content-between gap-1 bg-white p-3 mb-1 black-border-bottom black-border-top'>
                    <DLText id={''}
                            text={'Payment Amount: '}
                            fontSize={"sm"}
                            fontWeight={"normal"}
                            fontColor={"grayDark"}
                            marginBottom={"none"}
                    />
                    <DLText id={''}
                            text={handleAmount(txnData?.totalAllocationAmount)}
                            fontSize={"sm"}
                            fontWeight={"bold"}
                            marginBottom={"none"}
                    />
                </div>
                }
            </div>
        )
    }

    const renderMenuOptions = () => {
        const{menuOptions}=props
        return (
            <div className="d-flex justify-content-end" style={{lineHeight: '30px'}}>
                <div style={{fontSize: '16px'}}>
                    <DLMenuOptions
                        id={"menuOption"}
                        onSelect={(value) =>
                            handleViewClientRequestClick()
                        }
                        options={menuOptions}
                    />
                </div>
            </div>
        )
    }


    const renderMobileUI = () => {
        return (
            <div className='d-flex flex-column h-100'>
                {renderMobileHeader()}
                <div className="page-container">
                    {renderModalError()}
                </div>
                <div className='flex-grow-1'>
                    {renderDetails()}
                </div>
                <div>
                    {txnStage === "MANAGEMENT_APPROVAL" && txnStatus === "IN_PROGRESS" && txnOption !== 'SIP' && renderAmount()}
                    {txnStage === "MANAGEMENT_APPROVAL" && txnStatus === "IN_PROGRESS" && renderButtons()}
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    }

    return (
        <div className='h-100'>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLoader()}
            {renderApproveTransactionModal()}
            {renderRejectTransactionModal()}
            {renderNSEErrors()}
        </div>
    );
};

export default FTDetailsForManagementView;