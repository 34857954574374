import React from 'react';
import Overview from "./components/overview/Overview";
import BusinessSummary from "./components/businessSummary/BusinessSummary";
import Analytics from "./components/analytics/Analytics"

const TransactionsView = (props) =>{
    const renderOverViewWidgets = () => {
        return (
            <div className='h-100 bg-light'>
                <Overview {...props} />
                <BusinessSummary {...props} />
                <Analytics {...props} />
            </div>
        )
    }

    return (
        <div>
            {renderOverViewWidgets()}
        </div>
    );
};

export default TransactionsView;