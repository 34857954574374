import React from 'react';

import {isEmpty} from "../../../utils/validations";
import {numberWithCommas} from "../../../utils/helper";

import DLText from "../../../../src/core/components/DLText/DLText";
import DLModal from "../../../../src/core/components/DLModal/DLModal";
import DLModalTitle from "../../../../src/core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../src/core/components/DLModal/DLModalContainer/DLModalContainer";
import DLLoader from "../../../core/components/DLLoader/DLLoader";


const ChartModalView = (props) => {

    const {
        history, isChartClickModalOpen, modalErrorMessage, handleCloseChartClickModal, handleRedirect, tableData, modalHeader, isShowLoader, UIState
    } = props;

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderHeading = () => {
        return (
            <div className='row p-0 m-0 py-2'>
                <div className='col-3'>
                    <DLText id={'type-heading'}
                            text={"Type"}
                            fontSize={"xs"}
                            fontWeight={"bold"}
                            marginBottom={"none"}
                    />
                </div>
                <div className='col-3'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <DLText id={'normal-heading'}
                                text={"Normal"}
                                fontSize={"xs"}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <DLText id={'systematic-heading'}
                                text={"Systematic"}
                                fontSize={"xs"}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <DLText id={'total-heading'}
                                text={"Total"}
                                fontSize={"xs"}
                                fontWeight={"bold"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderData = () => {
        return(
            tableData.map((obj)=>{
                let txnTotal = parseInt(obj?.Normal) + parseInt(obj?.Systematic);
                return (
                    <div className={obj.heading === "Total"? "border-top-black" : ''}>
                        <div className={'row py-2 p-0 m-0'}>
                            <div className='col-3'>
                                <DLText id={''}
                                        text={obj?.heading}
                                        fontSize={"sm"}
                                        fontWeight={"semi-bold"}
                                        marginBottom={"none"}
                                />
                            </div>
                            <div className='col-3'>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <DLText id={''}
                                            text={numberWithCommas(obj?.Normal)}
                                            fontSize={"xs"}
                                            fontColor={obj.heading === "Total" && obj?.Normal < 0? "danger" : ""}
                                            fontWeight={"normal"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <DLText id={''}
                                            text={numberWithCommas(obj?.Systematic)}
                                            fontSize={"xs"}
                                            fontColor={obj.heading === "Total" && obj?.Systematic < 0?  "danger" : ""}
                                            fontWeight={"normal"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <DLText id={''}
                                            text={numberWithCommas(txnTotal)}
                                            fontSize={"xs"}
                                            fontColor={obj.heading === "Total" && txnTotal < 0? "danger" : ""}
                                            fontWeight={"normal"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        )
    };

    const renderUiText = () => {
        return (
            <div className='border-black'>
                <div className="border-bottom-black">
                    <div className="px-2">
                        {renderHeading()}
                    </div>
                </div>
                <div>
                    <div className="">
                        {renderData()}
                    </div>
                </div>
            </div>
        )
    };

    const renderModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseChartClickModal()}
                    open={isChartClickModalOpen}
                    id={'modal'}>
                    <DLModalTitle
                        id={'modal-title'}
                        onClose={() => handleCloseChartClickModal()}
                        title={modalHeader}/>
                    <DLModalContainer
                        id={'container'}>
                        <div>
                            {renderModalError()}
                            <div className='py-2'>
                                {renderUiText()}
                            </div>
                            <div className='py-2'>
                                <DLText id={'view-detail-report'}
                                        text={"View Detailed Report"}
                                        fontSize={"sm"}
                                        isClickable={true}
                                        onClick={()=>{handleRedirect()}}
                                        fontWeight={"normal"}
                                        marginBottom={"none"}
                                />
                            </div>
                        </div>
                    </DLModalContainer>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>
        )
    };

    if(isEmpty(UIState)){
        return(
            <div>
                {renderLoader()}
            </div>
        )
    }

    return (
        <div>
            {renderModal()}
        </div>
    );
};

export default ChartModalView;