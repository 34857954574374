import React from 'react';
import {isEmpty} from "../../../../../../../../../utils/helperCommon";
import {
    convertAmountInLakhAndCrore,
    convertUTCToLocalTime,
    numberWithCommas
} from "../../../../../../../../../utils/helper";
import {ftTransactionsPendingCellConfig, ftTransactionsPendingHeaderData} from "../../../../../../../../HomePageModal";

import DLLoader from "../../../../../../../../../core/components/DLLoader/DLLoader";
import DLText from "../../../../../../../../../core/components/DLText/DLText";
import DLTable from "../../../../../../../../../core/components/DLTable/DLTable";


const SummaryTransactionsPendingAuthorizationView = (props) => {
    const {isXSView, pendingTransactionData, setFilters, isShowLoader} = props;

    const renderCustomUI = (data, type) => {
        if (type === 'account') {
            return (
                <div>
                    <DLText
                        id={'account'}
                        text={isEmpty(data.account) ? '-' : data.account}
                        fontWeight={'bold'}
                        fontSize={'xs'}
                        marginBottom={'none'}
                    />
                </div>
            );
        }
        if (type === 'date') {
            return (
                <div>
                    <DLText
                        id={'account'}
                        text={isEmpty(data.date) ? '-' : (convertUTCToLocalTime(data.date, 'DD-MM-YYYY'))}
                        fontWeight={'normal'}
                        fontSize={'xs'}
                        marginBottom={'none'}
                    />
                </div>
            );
        }
        if (type === 'trxnType') {
            return (
                <div>
                    <DLText
                        id={'account'}
                        text={isEmpty(data.trxnType || data.subTrxnType) ? "-" : data.trxnType + " - " + data.subTrxnType}
                        fontWeight={'normal'}
                        fontSize={'xs'}
                        marginBottom={'none'}
                    />
                </div>
            );
        }
        return (
            <div>
                <DLText
                    id={'account'}
                    text={isEmpty(data.amount) ? "-" : numberWithCommas(convertAmountInLakhAndCrore(data.amount))}
                    fontWeight={'bold'}
                    fontSize={'xs'}
                    marginBottom={'none'}
                />
            </div>
        );
    };

    const getFormattedTableData = (clientData) => {
        let tableData = [];
        if (isEmpty(clientData)) {
            return tableData;
        }

        let newClientData = clientData.filter(data => data.account.length > 0);

        newClientData?.slice(0, 5)?.forEach((data) => {
            let clientRecords = [];
            let clientDetails = {};

            if (!isXSView) {
                clientRecords.push({
                    isCustomUI: true,
                    customUI: renderCustomUI(data, 'account')
                });
                clientRecords.push({
                    isCustomUI: true,
                    customUI: renderCustomUI(data, 'date')
                });
                clientRecords.push({
                    isCustomUI: true,
                    customUI: renderCustomUI(data, 'trxnType')
                });
                clientRecords.push({
                    isCustomUI: true,
                    customUI: renderCustomUI(data, 'amount')
                });
            } else {
                clientRecords.push({
                    isCustomUI: true,
                    customUI: renderMobileUI(data)
                })
            }
            clientDetails.id = data.id;
            clientDetails.data = clientRecords;
            tableData.push(clientDetails);
        });
        return tableData;
    };

    const renderMobileUI = (data) => {
        return (
            <div className={'row py-2'}>
                <div>
                    <div className="col-12 pb-2">
                        <DLText
                            id={"account"}
                            text={isEmpty(data.account) ? '-' : data.account}
                            fontColor={"black"}
                            fontSize={"sm"}
                            fontWeight={'bold'}
                            marginBottom={"none"}
                        />
                    </div>
                    <div className='d-flex justify-content-between gap-3 pb-1'>
                        <div className="w-50">
                            <DLText
                                id={"date"}
                                text={"Date"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"xs"}
                            />
                            <div>
                                <DLText
                                    id={"date"}
                                    text={isEmpty(data.date) ? '-' : (convertUTCToLocalTime(data.date, 'DD-MM-YYYY'))}
                                    fontSize={"xs"}
                                    marginBottom={"none"}
                                />
                            </div>
                        </div>
                        <div className="w-100">
                            <DLText
                                id={"type"}
                                text={"Type"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"xs"}
                            />
                            <DLText
                                id={"type"}
                                text={isEmpty(data.trxnType || data.subTrxnType) ? "-" : data.trxnType + " - " + data.subTrxnType}
                                fontSize={"xs"}
                                marginBottom={"none"}
                            />
                        </div>
                        <div className="w-50">
                            <DLText
                                id={"amount"}
                                text={"Amount"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"xs"}
                            />
                            <DLText
                                id={"amount"}
                                text={isEmpty(data.amount) ? "-" : numberWithCommas(convertAmountInLakhAndCrore(data.amount))}
                                fontSize={"xs"}
                                marginBottom={"none"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderMobileSummaryTransactionType = () => {
        const tableData = getFormattedTableData(pendingTransactionData);

        if (isShowLoader) {
            return (
                <div className={'border-top d-flex align-items-center justify-content-center'}
                     style={{minHeight: '308px'}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            );
        }
        else if (isEmpty(tableData)) {
            return (
                <div className={'border-top d-flex align-items-center justify-content-center'}
                     style={{minHeight: '308px'}}>
                    No data found.
                </div>
            );
        }

        return (
            <div>
                <DLTable
                    id={'transaction-pending-authorization-table'}
                    tableMaxHeight={'auto'}
                    tableMinHeight={'auto'}
                    isRowClickable={false}
                    isShowPagination={false}
                    headerData={[]}
                    tableData={tableData}
                />
            </div>
        )
    };

    const renderSummaryTransactionType = () => {
        const tableData = getFormattedTableData(pendingTransactionData);
        if (isShowLoader) {
            return (
                <div className={'border-top d-flex align-items-center justify-content-center'}
                     style={{minHeight: '283px'}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            );
        }
        if (isEmpty(tableData)) {
            return (
                <div className={'border-top d-flex align-items-center justify-content-center'}
                     style={{minHeight: '283px'}}>
                    No data found.
                </div>
            );
        }
        return (
            <div>
                <DLTable
                    id={'transaction-pending-authorization-table'}
                    tableMaxHeight={'283px'}
                    tableMinHeight={'283px'}
                    isRowClickable={false}
                    isShowPagination={false}
                    headerData={ftTransactionsPendingHeaderData}
                    tableData={tableData}
                    isShowCellSeprator={false}
                    isShowTableBorder={false}
                    cellConfig={ftTransactionsPendingCellConfig}
                />
            </div>
        )
    };

    const renderUI = () => {
        return (
            <div className={isXSView ? 'border-top' : 'panel p-0'}>
                <div className='section-title border-bottom py-2' id='investment'>
                    <div className={(isXSView ? 'column-gap-4 lh-1' : '') + ' px-3 d-flex justify-content-between'}>
                        <DLText
                            id={'summary'}
                            text={'Summary by Transactions Pending for Authorization'}
                            marginBottom={'none'}
                            fontSize={isXSView ? 'sm' : 'md'}
                            fontWeight={'bold'}
                        />
                        {pendingTransactionData.length > 0 && (
                            <div className={isXSView ? 'w-25' : ''}>
                                <DLText id={'total-amount'}
                                        text={'See More'}
                                        fontWeight={'bold'}
                                        fontSize={'sm'}
                                        isClickable={true}
                                        marginBottom={'none'}
                                        onClick={() => setFilters()}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={`${isXSView ? 'pt-0' : ''}`}>
                    <div className='h-100'>
                        {isXSView ? renderMobileSummaryTransactionType() : renderSummaryTransactionType()}
                    </div>
                </div>

            </div>
        )
    };

    return (
        <div>
            {renderUI()}
        </div>
    );
};

export default SummaryTransactionsPendingAuthorizationView;