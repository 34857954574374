import React from "react";

import OverviewBarChartUIView from "./OverviewBarChartUIView";
import {connect} from "react-redux";
import {isEmpty} from "../../../core/components/DLComponentHelper";

export const OverviewBarChartUI = (props) => {

    const checkIfChartDataAvailable = () => {
        const {yAxis} = props;
        if (isEmpty(yAxis)) {
            return;
        }
        const maxValue = yAxis.some(value => value !== 0);
        return maxValue;
    };

    return (
        <OverviewBarChartUIView
            {...props}
            checkIfChartDataAvailable={checkIfChartDataAvailable}
        />
    )
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
});

export default connect(mapStateToProps, {})(OverviewBarChartUI);
