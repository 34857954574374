import React from "react";
import {isEmpty} from "../utils/validations";
import appTheme from "../assets/appTheme";

export const renderStatusBlock = (status, fontSize) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'OPEN':
            return (<div className="d-flex align-items-center">
                {renderStatusDot(status)}
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>Open</span>
            </div>);
        case 'IN_PROGRESS':
            return (<div className="d-flex align-items-center">
                {renderStatusDot(status)}
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>In Progress</span>
            </div>);

        case 'COMPLETED':
            return (<div className="d-flex align-items-center">
                {renderStatusDot(status)}
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>Completed</span>
            </div>);
        default: return '';
    }
};

export const renderStatusDot = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'OPEN':
            return <span className="status-dot warning"/>;

        case 'IN_PROGRESS':
            return <span className="status-dot warning"/>;

        case 'COMPLETED':
            return <span className="status-dot success "/>;
    }
};

export const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        document.activeElement?.blur();
    }
};

export const getFormattedTxnType = (type) => {
    switch (type) {
        case 'LUMPSUM':
            return 'Lumpsum';
        default:
            return type;
    }
};

