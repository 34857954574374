import React from 'react';

import DLButton from "../../../../../../core/components/DLButton/DLButton";
import DLModal from "../../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalActions from "../../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLModalContainer from "../../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLLoader from "../../../../../../core/components/DLLoader/DLLoader";
import DLInputTextField from "../../../../../../core/components/DLInputTextField/DLInputTextField";


const IgnoreKYCView = (props) => {

    const {
        history, isIgnoreKycModalOpen, isApiInProgress, modalErrorMessage, ignoreKRA, handleCloseIgnoreModal, setReason, reasonError, reason
    } = props;

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderButtons = () => {
        return (
            <div>
                <DLButton
                    id={'cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => handleCloseIgnoreModal()}
                />
                <DLButton
                    id={'Btn'}
                    history={props.history}
                    label={'Ignore'}
                    buttonSize={"sm"}
                    type={"danger"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => {
                        ignoreKRA()
                    }}
                />
            </div>
        )
    };

    const renderIgnoreModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseIgnoreModal()}
                    open={isIgnoreKycModalOpen}
                    id={'ignore-modal'}>
                    <DLModalTitle
                        id={'ignore-title'}
                        onClose={() => handleCloseIgnoreModal()}
                        title={'Ignore KYC'}/>
                    <DLModalContainer
                        id={'ignore-container'}>
                        <div>
                            {renderModalError()}
                            <DLInputTextField
                                id={''}
                                label={'Reason'}
                                value={reason}
                                marginBottom={"none"}
                                inputType={"text"}
                                isRequired={true}
                                isMulti={true}
                                rows={5}
                                maxRows={5}
                                disableHelperMessageHeight={true}
                                error={reasonError !== ""}
                                helperMessage={reasonError}
                                hideCharLength={true}
                                isClearable={false}
                                maxCharLength={500}
                                onChangeValue={(value) => {
                                    setReason("reason", value)
                                }}
                            />
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'ignore-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            {renderButtons()}
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isApiInProgress}/>
        )
    };

    return (
        <div>
            {renderLoader()}
            {renderIgnoreModal()}
        </div>
    );
};

export default IgnoreKYCView;