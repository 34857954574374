import React, {useEffect, useState} from 'react';
import {isEmpty} from "../../../../utils/validations";
import {APP_CONST} from "../../../../AppComponents/AppConstants";

import {getNFTDetails, updateMarkedAsCompleted, updateMarkedAsInProgress} from "../../NFTAction";
import {connect} from "react-redux";

import NFTDetailsView from "./NFTDetailsView";
import {getAdvisoryUsers} from "../../../../AppComponents/MetadataActions";
import {isCorporateUser} from "../../../../utils/helper";

const NFTDetails = (props) => {
    const [NFTDetailsState, setNFTDetailsState] = useState({
        id: '',
        name: "",
        phoneNumber: "",
        email: "",
        status: "",
        openDate: "",
        typeOfTransaction: "",
        remark: "",
        companyName:'',
        assignedTo: '',
        isShowLoader: false,
        accountType: '',
        IINNumber: '',
        category: '',
        advisoryUsers:[],
        advisoryUserId: '',
        sourceValues: [],
        isHistoryBlockRefresh: true,
        responseError: '',
        isContacted: false,
        UIState: '',
        isRemoveModalOpen: false,
        selectedId: '',
        selectedName: '',
        isMarkAsCompleted: false,
        isInitiateModalOpen: false,
        isManagementUser: false,
        ticketId:'',
        isOpenConfirmationModal: false,
        accountHolderDetails: [],
        isCorporateUser: false,
        corporateDetails: {}
    });

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (NFTDetailsState.isHistoryBlockRefresh) {
            getDetails();
        }
    }, [NFTDetailsState.isHistoryBlockRefresh]);


    const getId = () => {
        return props.match?.params?.id
    };

    const checkIsEmpty = (value) => {
        if (isEmpty(value)) {
            return '-'
        }
        return value;
    };

    const handleItemOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };


    const getTypeOfTransaction = (str) => {
        if (isEmpty(str)) {
            return '-'
        }
        switch (str) {
            case 'NOMINEE_CHANGE':
                return 'Change of Nominee';
            case 'FUND_TRANSFER_AFTER_DEATH':
                return 'Transfer of Fund after Death';
            case 'EMAIL_OR_PHONE_NO_CHANGE':
                return 'Change in Email/Phone Number';
            case 'ARN_CHANGE':
                return 'Change of ARN';
            case 'KYC_CHANGE':
                return 'Change in KYC';
            case 'BANK_CHANGE':
                return 'Change of Bank';
            case 'OTHERS':
                return 'Others';
            default:
                return str;
        }
    };

    const getAccountHolderDetails = (accountDetails) => {
        if (accountDetails?.accountType === "MINOR") {
            return accountDetails?.accountHolders.filter(holder => holder?.accountHolderType !== "GUARDIAN");
        }
        return accountDetails?.accountHolders;
    };


    const getDetails = () => {
        const id = getId();
        const {profile} = props;

        if (isEmpty(id)) {
            setNFTDetailsState((prevState) => ({
                ...prevState,
                isManagementUser: profile?.isManagement,
            }))
            return
        }
        setNFTDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getNFTDetails(id).then(res => {
            if (res?.success) {
                setNFTDetailsState(prevState => ({
                    ...prevState,
                    id: id,
                    name: getFormattedData('names', res.data.accountDetails),
                    isCorporateUser: isCorporateUser(res?.data?.accountDetails[0]?.accountType),
                    corporateDetails: res?.data?.accountDetails[0]?.corporate,
                    accountHolderDetails: getAccountHolderDetails(res?.data?.accountDetails[0]),
                    companyName: getFormattedData('companyName', res.data.accountDetails),
                    status: checkIsEmpty(res.data.status),
                    openDate: checkIsEmpty(res.data.createdAt),
                    typeOfTransaction: getTypeOfTransaction(res.data.nftRequest.nftRequestType),
                    remark: checkIsEmpty(res.data.nftRequest.nftRequestComment),
                    accountType: getFormattedData('accountType', res.data.accountDetails),
                    IINNumber: getFormattedData('IINNumber', res.data.accountDetails),
                    category: getFormattedData('category', res.data.accountDetails),
                    advisoryUserId: checkIsEmpty(res.data.createdBy?.id),
                    isShowLoader: false,
                    ticketId: res.data.ticketNumber,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    responseError: '',
                    isManagementUser: profile?.isManagement,
                }));

                getAdvisoryUsers()
                    .then((res) => {
                        if (res?.success) {
                            if (!isEmpty(res?.data?.advisoryUsers)) {
                                setNFTDetailsState((prevStep) => ({
                                    ...prevStep,
                                    isShowLoader: false,
                                    UIState: APP_CONST.CONTENT_AVAILABLE,
                                    advisoryUsers: res?.data?.advisoryUsers,
                                    isManagementUser: profile?.isManagement,
                                }));
                            }
                        }
                        else {
                            setNFTDetailsState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE,
                             isManagementUser: profile?.isManagement,
                            responseError: res.__error
                        }));
                        }
                    });
            } else {
                setNFTDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    isManagementUser: profile?.isManagement,
                    responseError: res.__error
                }));
            }
        })
    };

    function capitalizeFirstLetters(name) {
        if(isEmpty(name)){
            return ''
        }
        return name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const getFormattedData = (name, accountDetails) => {
        if (name === 'names') {
            return accountDetails.flatMap(account =>
                account?.accountHolders
                    ?.filter(holder => account.accountType !== "MINOR" || holder.accountHolderType !== "GUARDIAN")
                    .map(holder => holder.fullName)
            ).join(', ');
        }
        if (name === 'companyName' && (isCorporateUser(accountDetails[0]?.accountType))) {
            return accountDetails.map(account =>
                account?.corporate?.name
            );
        }

        if (name === 'accountType') {
            const accountType = accountDetails.map(account => {
                    return account.accountType;
                }
            );
            if(isEmpty(accountType[0])){
                return '-';
            }
            if(accountType[0] === 'INDIVIDUAL') {
                return 'Individual Account'
            } else if (accountType[0] === 'MINOR') {
                return 'Minor Account'
            } else if (accountType[0] === 'HUF') {
                return 'HUF Account'
            } else if (accountType[0] === 'CORPORATE') {
                return 'Corporate Account'
            } else if (accountType[0] === 'JOINT') {
                return 'Joint Account'
            } else if (accountType[0] === 'TRUST') {
                return 'Trust Account'
            } else if (accountType[0] === 'PARTNERSHIP_FIRM_LLP') {
                return 'Partnership Firm/LLP Account'
            }
            else {
                return '-'
            }
        }
        if (name === 'IINNumber') {
            return accountDetails.flatMap(account => {
                    return account.IINNumber
                }
            );
        }
        if (name === 'category') {
            return accountDetails.flatMap(account => {
                if (account.category === 'HNI') {
                    return account.category;
                }
                else if (account.category === 'UNASSIGNED') {
                    return capitalizeFirstLetters(account.category);
                }
                else if (account.category === 'RETAIL') {
                    return capitalizeFirstLetters(account.category);
                }
                else if (account.category === 'PREMIUM_RETAIL') {
                    return 'Premium Retail';
                }
                else if (account.category === 'ULTRA_HNI') {
                    return 'Ultra HNI';
                }
                else {
                    return '-';
                }
                }
            );
        }

    };

    const handleMarkedAsCompleted = () => {
        const id = getId();
        if (isEmpty(id)) {
            return
        }
        setNFTDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            id: id
        };

        updateMarkedAsCompleted(payload).then(res => {
            if (res?.success) {
                setNFTDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    isHistoryBlockRefresh: true,
                    responseError: '',
                }));
                handleConfirmationModal(false);
            } else {
                setNFTDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    responseError: res.__error
                }));
            }
        })
    };

    const handleMarkedAsInitiate = () => {
        const id = getId();
        if (isEmpty(id)) {
            return
        }
        setNFTDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            id: id
        };

        updateMarkedAsInProgress(payload).then(res => {
            if (res?.success) {
                setNFTDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    isHistoryBlockRefresh: true,
                    responseError: '',
                }));
                handleCloseInitiateModal();
            } else {
                setNFTDetailsState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    responseError: res.__error
                }));
            }
        })
    };

    const handleMarkAsCompleted = () => {
        setNFTDetailsState((prevState) => ({
            ...prevState,
            isMarkAsCompleted: true
        }))
    };

    const handleOpenAddRemarkModal = () => {
        setNFTDetailsState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: true
        }))
    };

    const handleCloseAddRemarkModal = () => {
        setNFTDetailsState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: false
        }))
    };

    const handleOpenInitiateModal = () => {
        setNFTDetailsState((prevState) => ({
            ...prevState,
            isInitiateModalOpen: true
        }))
    };

    const handleCloseInitiateModal = () => {
        setNFTDetailsState((prevState) => ({
            ...prevState,
            isInitiateModalOpen: false
        }))
    };

    const handleHistoryBlockRefresh = (value) => {
        setNFTDetailsState((prevState) => ({
            ...prevState,
            isHistoryBlockRefresh: value
        }))
    };

    const handleConfirmationModal = (value) => {
        setNFTDetailsState((prevState) => ({
            ...prevState,
            isOpenConfirmationModal: value
        }))
    };

    const handleIsContacted = () => {
        const {isContacted} = NFTDetailsState;
        if (!isContacted) {
            setNFTDetailsState((prevState) => ({
                ...prevState,
                isContacted: true
            }))
        }
    };

    const handleOpenRemoveModal = (id) => {
        const {companyName, name}=NFTDetailsState
        setNFTDetailsState((prevStep) => ({
            ...prevStep,
            isRemoveModalOpen: true,
            selectedId: id,
            selectedName: (!isEmpty(companyName)? companyName : name)
        }));
    };

    const handleCloseRemoveModal = () => {
        setNFTDetailsState((prevStep) => ({
            ...prevStep,
            isRemoveModalOpen: false,
            selectedId: "",
            selectedName: ''
        }));
    };

    const getAdvisoryUser = (id) => {
        const {advisoryUsers} = NFTDetailsState;
        let usersName = '';
        if (!isEmpty(advisoryUsers)) {
            usersName = advisoryUsers.find((item) => {
                if (item?.id === id) {
                    return item;
                }
            });
        }

        if (!isEmpty(usersName)) {
            let firstName = isEmpty(usersName?.firstName) ? '' : usersName?.firstName + ' ';
            let lastName = isEmpty(usersName?.lastName) ? '' : usersName?.lastName;
            return firstName + lastName;
        }

        return '-';
    };

    const onSaveSuccess = () => {
        handleHistoryBlockRefresh(true);
    };

    return (
        <NFTDetailsView
            {...props}
            {...NFTDetailsState}
            getId={getId}
            handleMarkAsCompleted={handleMarkAsCompleted}
            handleIsContacted={handleIsContacted}
            getAdvisoryUser={getAdvisoryUser}
            handleConfirmationModal={handleConfirmationModal}
            handleMarkedAsCompleted={handleMarkedAsCompleted}
            handleOpenInitiateModal={handleOpenInitiateModal}
            handleCloseInitiateModal={handleCloseInitiateModal}
            handleHistoryBlockRefresh={handleHistoryBlockRefresh}
            handleOpenAddRemarkModal={handleOpenAddRemarkModal}
            handleCloseAddRemarkModal={handleCloseAddRemarkModal}
            handleMarkedAsInitiate={handleMarkedAsInitiate}
            getDetails={getDetails}
            handleOpenRemoveModal={handleOpenRemoveModal}
            handleCloseRemoveModal={handleCloseRemoveModal}
            onSaveSuccess={onSaveSuccess}
            handleItemOpen={handleItemOpen}
            handleItemClose={handleItemClose}
            anchorEl={anchorEl}
        />);
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(NFTDetails);
