import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getManagementClientCRM} from "../../../../HomePageActions";
import DashboardWidget from "../../../../DashboardWidget";
import {
    resetClientListingFilters, setCRMForListing
} from "../../../../../clients/components/clientsListing/clientListingAction";
const ClientByCrm=(props)=> {
    const [clientCRMState, setClientCRMState] = useState({
        clientByCRM: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        setClientCRMState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementClientCRM().then(res => {
            if (res.success) {
                setClientCRMState(prevState => ({
                    ...prevState,
                    clientByCRM: res.data,
                    isShowLoader: false,
                }));
            } else {
                setClientCRMState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetClientListingFilters();
        setCRMForListing([item.key]);
        props.history.push('/clients');
    };


    return (
        <DashboardWidget
            {...props}
            {...clientCRMState}
            detailsObj={clientCRMState.clientByCRM}
            setFilters={setFilters}
            title={"CRM"}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(ClientByCrm);