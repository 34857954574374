import React from 'react';
import DLText from "../../../../../../../../../core/components/DLText/DLText";
import {isEmpty} from "../../../../../../../../../core/components/DLComponentHelper";
import DLTable from "../../../../../../../../../core/components/DLTable/DLTable";
import {convertAmountInLakhAndCrore, numberWithCommas} from "../../../../../../../../../utils/helper";
import {ftAnalyticsClientCellConfig, ftAnalyticsClientHeaderData} from "../../../../../../../../HomePageModal";
import DLLoader from "../../../../../../../../../core/components/DLLoader/DLLoader";

const SummaryByClientView = (props) => {
    const {isXSView, isShowLoader, clientData} = props;
    const renderMobileTableUI = (data) => {
        return (
            <div className='row py-2 '>
                <div>
                    <div className='col-12 pb-2'>
                        <DLText
                            id={'client'}
                            text={isEmpty(data.account) ? '-' : data.account}
                            fontSize={'sm'}
                            fontWeight={'bold'}
                            marginBottom={'none'}
                        />
                    </div>
                    <div className='d-flex justify-content-between gap-5 pb-1'>
                        <div className="w-100">
                            <DLText
                                id={"purchase"}
                                text={"Purchase"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"xs"}
                            />
                            <DLText
                                id={"purchase"}
                                text={isEmpty(data.purchase) ? '-' : numberWithCommas(convertAmountInLakhAndCrore(data.purchase))}
                                fontSize={"xs"}
                                marginBottom={"none"}
                            />
                        </div>
                        <div className="w-100">
                            <DLText
                                id={"redemption"}
                                text={"Redemption"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"xs"}
                            />
                            <DLText
                                id={"redemption"}
                                text={isEmpty(data.redemption) ? '-' : numberWithCommas(convertAmountInLakhAndCrore(data.redemption))}
                                fontSize={"xs"}
                                marginBottom={"none"}
                            />
                        </div>
                        <div className="w-100">
                            <DLText
                                id={"total"}
                                text={"Total"}
                                fontColor={"grayDark"}
                                fontSize={"xs"}
                                marginBottom={"xs"}
                            />
                            <DLText
                                id={"total"}
                                text={isEmpty(data.total) ? '-' : numberWithCommas(convertAmountInLakhAndCrore(data.total))}
                                fontSize={"xs"}
                                marginBottom={"none"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderCustomUI = (type, data) => {
        if (type === 'account') {
            return (
                <div>
                    <DLText
                        id={'account'}
                        text={isEmpty(data.account) ? '-' : data.account}
                        fontWeight={'bold'}
                        fontSize={'xs'}
                        marginBottom={'none'}
                    />
                </div>
            );
        }
        return (
            <div>
                <DLText
                    id={'account'}
                    text={isEmpty(data.total) ? '-' : numberWithCommas(convertAmountInLakhAndCrore(data.total))}
                    fontWeight={'bold'}
                    fontSize={'xs'}
                    marginBottom={'none'}
                />
            </div>
        )
    };

    const getFormattedTableData = () => {
        let tableData = [];
        if (isEmpty(clientData)) {
            return tableData;
        }
        clientData?.slice(0, 5).forEach((data) => {
            let clientRecords = [];
            let clientDetails = {};
            if (!isXSView) {
                clientRecords.push({
                    isCustomUI: true,
                    customUI: renderCustomUI('account', data)
                });
                clientRecords.push(isEmpty(data.purchase) ? '-' : numberWithCommas(convertAmountInLakhAndCrore(data.purchase)));
                clientRecords.push(isEmpty(data.redemption) ? '-' : numberWithCommas(convertAmountInLakhAndCrore(data.redemption)));
                clientRecords.push({
                    isCustomUI: true,
                    customUI: renderCustomUI('total', data)
                });
            } else {
                clientRecords.push({
                    isCustomUI: true,
                    customUI: renderMobileTableUI(data)
                })
            }
            clientDetails.id = data.id;
            clientDetails.data = clientRecords;
            tableData.push(clientDetails);
        });
        return tableData;

    };

    const renderTable = () => {
        let tableData = getFormattedTableData();
        if (isShowLoader) {
            return (
                <div className={'d-flex align-items-center justify-content-center pb-3'}
                     style={{minHeight: '274px', maxHeight: '274px'}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            )
        }

        return (
            <div>
                {isEmpty(tableData) ?
                    <div className={'d-flex align-items-center justify-content-center pb-3'}
                         style={{minHeight: '274px', maxHeight: '274px'}}>
                        No data found.
                    </div>
                    :
                    <div>
                        <DLTable
                            id={''}
                            tableMaxHeight={'265px'}
                            tableMinHeight={'265px'}
                            isRowClickable={false}
                            isShowPagination={false}
                            headerData={ftAnalyticsClientHeaderData}
                            tableData={tableData}
                            isShowTotalCount={false}
                            cellConfig={ftAnalyticsClientCellConfig}
                            isShowTableBorder={false}
                            isShowCellSeprator={false}
                        />
                    </div>
                }
            </div>
        );
    };

    const renderMobileTable = () => {
        let tableData = getFormattedTableData();
        if (isShowLoader) {
            return (
                <div className={'d-flex align-items-center justify-content-center'} style={{minHeight: '292px'}}>
                    <DLLoader type={"inline"} isVisible={true}/>
                </div>
            )
        }

        return (
            <div>
                {isEmpty(tableData) ?
                    <div className={'d-flex align-items-center justify-content-center border-top'}
                         style={{minHeight: '292px'}}>
                        No data found.
                    </div>
                    :
                    <div>
                        <DLTable
                            id={'client-table'}
                            tableMinHeight={'auto'}
                            tableMaxHeight={'auto'}
                            isRowClickable={false}
                            isShowPagination={false}
                            headerData={[]}
                            tableData={tableData}
                            isShowTotalCount={false}
                        />
                    </div>
                }
            </div>
        );
    };

    const renderUI = () => {
        return (
            <div className={isXSView ? '' : 'panel p-0'}>
                <div className={'section-title border-bottom py-2'} id={'investment'}>
                    <div className={isXSView ? 'px-3' : 'ps-2 ms-1'}>
                        <DLText
                            id={'top-5-clients'}
                            text={'Top 5 Client'}
                            marginBottom={'none'}
                            fontSize={isXSView ? 'sm' : 'md'}
                            fontWeight={'bold'}
                        />
                    </div>
                </div>
                {isXSView ? renderMobileTable() : renderTable()}
            </div>
        )
    };

    return (
        <div>
            {renderUI()}
        </div>
    );
};

export default SummaryByClientView;