import React from "react";

import {convertAmountInLakhAndCrore, numberWithCommas} from "../../../utils/helper";
import textFile from "../../../assets/img/appIcons/textFile.png"

import DLText from "../../../core/components/DLText/DLText";

const OverviewAmountTypeView = (props) => {
    const {isXSView, isSMView, isMDView, isLGView, amount, label} = props;

    return (
        <div className={(isMDView ? 'pt-2 pb-3 ': (isXSView || isSMView) ? '' : 'py-2 my-1') + ' d-flex align-top  '}>
            {(!isXSView || !isSMView) && (
                <div className={isMDView || (isXSView || isSMView) || isLGView ? 'mx-2 ' : 'mx-3 pt-2'}>
                    <img src={textFile} alt={''} height={28} width={28}/>
                </div>
            )}

            <div className={(isMDView ? 'ms-1 me-1' : (isXSView || isSMView) || isLGView ? 'mx-2 ' : 'me-1 ') + ' h-100'}>
                <DLText id={'amount'}
                        text={'₹ ' + numberWithCommas(convertAmountInLakhAndCrore(amount))}
                        fontSize={(isXSView || isSMView) ? 'md' : 'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={(label==='Redemption') ? 'danger' : 'black'}
                        marginBottom={'none'}
                />
                <DLText id={'total-overviewTotalBusiness-title'}
                        text={label}
                        fontSize={(isXSView || isSMView) ? 'xs' : 'sm'}
                        truncateText={true}
                />
            </div>
        </div>
    )
};

export default OverviewAmountTypeView;
