import React, {useEffect, useState} from 'react';

import {getFTAnalyticsFundsCRM} from "../../../../../../../../HomePageActions";
import SummaryByFundView from "./SummaryByFundHouseView";

const SummaryByFund = (props) => {
    const [fundState, setFundState] = useState({
        data: [],
        isShowLoader: false,
    })

    useEffect(() => {
        getFundsData();
    }, [props.isSelected])

    const getFundsData = () => {
        const {isSelected} = props;

        setFundState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }))

        getFTAnalyticsFundsCRM(isSelected).then(res => {
                if (res.success) {
                    setFundState((prevState) => ({
                        ...prevState,
                        data: res?.data?.funds,
                        isShowLoader: false,
                    }));
                } else {
                    setFundState((prevState) => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                }
            }
        );
    }

    const formattedFunds = (funds) => {
        funds?.sort((a, b) => b.amount - a.amount);
        if (funds.length <= 10) {
            return funds;
        }
        const topFunds = funds.slice(0, 9);
        const othersAmount = funds.slice(9).reduce((total, fund) => total + fund.amount, 0);
        topFunds.push({ fundName: "Others", amount: othersAmount });
        topFunds?.sort((a, b) => b.amount - a.amount);
        return topFunds;
    };

    const getDataArray = (data) => {
        if (!data || data.length === 0) {
            return [];
        }
        return formattedFunds(data)?.map(item => ({
            name: item.fundName || '',
            value: parseFloat(item.amount) || 0,
        }));
    };

    return (
        <SummaryByFundView
            {...props}
            {...fundState}
            getDataArray={getDataArray}
        />
    );
};

export default SummaryByFund;