import React, {useEffect, useState} from 'react';
import SummaryTransactionsPendingAuthorizationView from "./SummaryTransactionsPendingAuthorizationView";
import {getTransactionsPendingForAuthorizationCRM} from "../../../../../../../../HomePageActions";
import {
    resetFTReportFilters,
    setFTReportStatus
} from "../../../../../../../../../transactions/FT/components/FTReportList/FTReportAction";

const SummaryTransactionsPendingAuthorization = (props) => {
    const [summaryPendingAuthorization, setSummaryPendingAuthorization] = useState({
        pendingTransactionData: [],
        isShowLoader: false,
    });
    useEffect(() => {
        setPendingTransactionData();
    }, []);

    const setPendingTransactionData = () => {
        setSummaryPendingAuthorization((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }))
        getTransactionsPendingForAuthorizationCRM().then((pendingTransactionData) => {
            if (pendingTransactionData.success) {
                setSummaryPendingAuthorization((prevState) => ({
                    ...prevState,
                    pendingTransactionData: pendingTransactionData?.data,
                    isShowLoader: false
                }))
            } else {
                setSummaryPendingAuthorization((prevState) => ({
                    ...prevState,
                    isShowLoader: false
                }))
            }
        })
    }

    const setFilters = () => {
        resetFTReportFilters();
        setFTReportStatus(["Pending for Authorization"]);
        props.history.push('/reports/financial-transactions');
    };

    return (
        <div>
            <SummaryTransactionsPendingAuthorizationView
                {...props}
                {...summaryPendingAuthorization}
                setFilters={setFilters}
            />
        </div>
    );
};

export default SummaryTransactionsPendingAuthorization;
