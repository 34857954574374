import React from 'react';

import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext/TabContext";
import appTheme from "../../../../../../assets/appTheme";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

import AppTheme from "../../../../../../assets/appTheme";
import {
    activeSystematicPlansSIPTableHeading,
    activeSystematicPlansSTPTableHeading,
    activeSystematicPlansSWPTableHeading,
    investmentCellSIPConfig, investmentCellSTPConfig, investmentCellSWPConfig
} from "../../../../../ClientsModel";

import {isEmpty} from "../../../../../../core/components/DLComponentHelper";
import {
    convertUTCToLocalTime,
    numberWithCommas
} from "../../../../../../utils/helper";

import DLText from "../../../../../../core/components/DLText/DLText";
import DLTable from "../../../../../../core/components/DLTable/DLTable";

const SystematicInvestmentsView = (props) => {

    const {
        isMobileView,
        isXSView,
        selectedInvestmentTab,
        investments,
        getInvestmentData
    } = props;

    const getDuration = (fromDate, toDate) => {
        if (isEmpty(fromDate || toDate)) {
            return "-";
        }
        if (isXSView) {
            return (
                <div>
                    <DLText id={'form-date-mobile'} fontSize={'xs'}
                            text={convertUTCToLocalTime(fromDate, 'DD-MM-YYYY') + ' to '}
                    />
                    <DLText id={''} fontSize={'xs'} text={convertUTCToLocalTime(toDate, 'DD-MM-YYYY')}/>
                </div>
            )
        } else {
            return (
                <div>
                    <DLText id={'form-date'} fontSize={'xs'}
                            text={convertUTCToLocalTime(fromDate, 'DD-MM-YYYY')+' to '}
                    />
                    <DLText id={'form-date'} fontSize={'xs'}
                            text={convertUTCToLocalTime(toDate, 'DD-MM-YYYY')}
                    />
                </div>
            )
        }
    };

    const getInvestmentHeading = (selectedInvestmentTab) =>{
        let currentTab = selectedInvestmentTab.value;
        if(currentTab === "SIP"){
            return activeSystematicPlansSIPTableHeading;
        }
        if(currentTab === "STP"){
            return activeSystematicPlansSTPTableHeading;
        }
        if(currentTab === "SWP"){
            return activeSystematicPlansSWPTableHeading;
        }
    };

    const getInvestmentConfig = (selectedInvestmentTab) =>{
        let currentTab = selectedInvestmentTab.value;
        if(currentTab === "SIP"){
            return investmentCellSIPConfig;
        }
        if(currentTab === "STP"){
            return investmentCellSTPConfig;
        }
        if(currentTab === "SWP"){
            return investmentCellSWPConfig;
        }
    };

    const renderTransactionsMobileUI = (transaction) => {
        return (
            <div className={'py-3'}>
                <div className="row">
                    <div className="col-12">
                        {renderSchemeNameUi(transaction)}
                    </div>
                </div>
                {renderMobileTxnUi(transaction)}
            </div>
        );
    };

    const renderMobileTxnUi = (transaction) =>{
        let currentTab = selectedInvestmentTab.value;

        if(currentTab === "SIP"){
            return(
                <div className="row mt-3">
                    <div className={'col-4 pe-0'}>
                        <DLText
                            id={"sip-date"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Date"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"dateValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={isEmpty(transaction?.instDate) ? '-' : transaction?.instDate}
                            marginBottom={'none'}
                        />
                    </div>
                    <div className={'col-4'}>
                        <DLText
                            id={"duration"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Duration"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"amountValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={getDuration(transaction?.fromDate, transaction?.toDate)}
                            fontColor={"black"}
                            fontWeight={"semi-bold"}
                        />
                    </div>
                    <div className={'col-4'}>
                        <DLText
                            id={"sip-amount"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Amount"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"amountValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={isEmpty(transaction?.amount) ? '-' :numberWithCommas(transaction.amount)}
                            fontColor={"black"}
                            fontWeight={"semi-bold"}
                        />
                    </div>
                </div>
            )
        }
        if(currentTab === "STP"){
            return(
                <div className="row mt-3">
                    <div className={'col-4 pe-0'}>
                        <DLText
                            id={"stp-date"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Date"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"dateValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={isEmpty(transaction?.instDate) ? '-' : transaction?.instDate}
                            marginBottom={'none'}
                        />
                    </div>
                    <div className={'col-4'}>
                        <DLText
                            id={"duration"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Duration"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"amountValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={getDuration(transaction?.fromDate, transaction?.toDate)}
                            fontColor={"black"}
                            fontWeight={"semi-bold"}
                        />
                    </div>
                    <div className={'col-4'}>
                        <DLText
                            id={"stp-amount"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Unit & Amount"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"amountValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={(isEmpty(transaction?.unit) ? '-' : transaction?.unit)+ ' Units & Rs. '+ (isEmpty(transaction?.amount) ? '-' :numberWithCommas(transaction?.amount))}
                            fontColor={"black"}
                            fontWeight={"semi-bold"}
                        />
                    </div>
                </div>
            )
        }
        if(currentTab === "SWP"){
            return(
                <div className="row mt-3">
                    <div className={'col-4 pe-0'}>
                        <DLText
                            id={"swp-date"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Date"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"dateValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={isEmpty(transaction?.instDate) ? '-' : transaction?.instDate}
                            marginBottom={'none'}
                        />
                    </div>
                    <div className={'col-4'}>
                        <DLText
                            id={"duration"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Duration"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"amountValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={getDuration(transaction?.fromDate, transaction?.toDate)}
                            fontColor={"black"}
                            fontWeight={"semi-bold"}
                        />
                    </div>
                    <div className={'col-4'}>
                        <DLText
                            id={"sip-amount"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={"Unit & Amount"}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                        <DLText
                            id={"amountValue"}
                            type={"normal"}
                            fontSize={"xs"}
                            text={(isEmpty(transaction?.unit) ? '-' : transaction?.unit)+ ' Units & Rs. '+ (isEmpty(transaction?.amount) ? '-' :numberWithCommas(transaction?.amount))}
                            fontColor={"black"}
                            fontWeight={"semi-bold"}
                        />
                    </div>
                </div>
            )
        }

    };

    const getFormattedActiveSystematicPlansSIPTableData = (data) => {
        let monthTableData = [];
        let weekTableData = [];
        let QuarterlyTableData = [];
        let BusinessTableData = [];

        if (isEmpty(data)) {
            return {monthTableData, weekTableData, QuarterlyTableData, BusinessTableData};
        }

        data?.map((transaction) => {
            let bankAccountDetails = [];
            let formattedData = {};

            const frequency = transaction?.frequency;

            if (isMobileView) {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderTransactionsMobileUI(transaction)
                });
            } else {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderSchemeNameUi(transaction)
                });
                bankAccountDetails.push(isEmpty(transaction?.instDate) ? '-' : transaction?.instDate);
                bankAccountDetails.push(getDuration(transaction?.fromDate, transaction?.toDate));
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderStatus(transaction, "SIP")
                });
                bankAccountDetails.push(isEmpty(transaction?.amount) ? '-' :numberWithCommas(transaction.amount));
            }

            formattedData.id = '';  // Set the ID as required
            formattedData.data = bankAccountDetails;

            // Categorize by frequency
            if (frequency === 'Once a Month') {
                monthTableData.push(formattedData);
            } else if (frequency === 'Once a Week') {
                weekTableData.push(formattedData);
            } else if (frequency === 'Business Day') {
                BusinessTableData.push(formattedData);
            } else if (frequency === 'Quarterly') {
                QuarterlyTableData.push(formattedData);
            }
        });

        return {monthTableData, weekTableData, BusinessTableData, QuarterlyTableData};
    };

    const getFormattedActiveSystematicPlansSTPTableData = (data) => {
        let monthTableData = [];
        let weekTableData = [];
        let QuarterlyTableData = [];
        let BusinessTableData = [];

        if (isEmpty(data)) {
            return {monthTableData, weekTableData, QuarterlyTableData, BusinessTableData};
        }

        data?.map((transaction) => {
            let bankAccountDetails = [];
            let formattedData = {};

            const frequency = transaction?.frequency;

            if (isMobileView) {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderTransactionsMobileUI(transaction)
                });
            } else {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderSchemeNameUi(transaction)
                });
                bankAccountDetails.push(isEmpty(transaction?.instDate) ? '-' : transaction?.instDate);
                bankAccountDetails.push(getDuration(transaction?.fromDate, transaction?.toDate));
                bankAccountDetails.push(isEmpty(transaction?.unit) ? '-' :numberWithCommas(transaction.unit));
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderStatus(transaction, "STP")
                });
                bankAccountDetails.push(isEmpty(transaction?.amount) ? '-' :numberWithCommas(transaction.amount));
            }

            formattedData.id = '';  // Set the ID as required
            formattedData.data = bankAccountDetails;

            // Categorize by frequency
            if (frequency === 'Once a Month') {
                monthTableData.push(formattedData);
            } else if (frequency === 'Once a Week') {
                weekTableData.push(formattedData);
            } else if (frequency === 'Business Day') {
                BusinessTableData.push(formattedData);
            } else if (frequency === 'Quarterly') {
                QuarterlyTableData.push(formattedData);
            }
        });

        return {monthTableData, weekTableData, BusinessTableData, QuarterlyTableData};
    };

    const getFormattedActiveSystematicPlansSWPTableData = (data) => {
        let monthTableData = [];
        let weekTableData = [];
        let QuarterlyTableData = [];
        let BusinessTableData = [];

        if (isEmpty(data)) {
            return {monthTableData, weekTableData, QuarterlyTableData, BusinessTableData};
        }

        data?.map((transaction) => {
            let bankAccountDetails = [];
            let formattedData = {};

            const frequency = transaction?.frequency;

            if (isMobileView) {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderTransactionsMobileUI(transaction)
                });
            } else {
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderSchemeNameUi(transaction)
                });
                bankAccountDetails.push(isEmpty(transaction?.instDate) ? '-' : transaction?.instDate);
                bankAccountDetails.push(getDuration(transaction?.fromDate, transaction?.toDate));
                bankAccountDetails.push(isEmpty(transaction?.unit) ? '-' :numberWithCommas(transaction.unit));
                bankAccountDetails.push({
                    isCustomUI: true,
                    customUI: renderStatus(transaction, "SWP")
                });
                bankAccountDetails.push(isEmpty(transaction?.amount) ? '-' :numberWithCommas(transaction.amount));
            }

            formattedData.id = '';  // Set the ID as required
            formattedData.data = bankAccountDetails;

            // Categorize by frequency
            if (frequency === 'Once a Month') {
                monthTableData.push(formattedData);
            } else if (frequency === 'Once a Week') {
                weekTableData.push(formattedData);
            } else if (frequency === 'Business Day') {
                BusinessTableData.push(formattedData);
            } else if (frequency === 'Quarterly') {
                QuarterlyTableData.push(formattedData);
            }
        });

        return {monthTableData, weekTableData, BusinessTableData, QuarterlyTableData};
    };

    const renderSystematicPlansTabs = () => {
        const {handleTabChange, allInvestmentTabs} = props;

        return(
            <Box sx={{width: '100%'}}>
                <TabContext value={selectedInvestmentTab?.value}>
                    <Box sx={{borderBottom: 2, borderColor: appTheme.appColor.grayLight}}>
                        <TabList onChange={() => {
                        }} aria-label="lab API tabs-example"
                                 variant="scrollable"
                                 scrollButtons={false}>
                            {
                                allInvestmentTabs?.map((item, index) => {
                                    let investmentData = getInvestmentData(item);
                                    return (
                                        <Tab key={'tab_' + index}
                                             label={item.label+' ('+investmentData?.length+')'}
                                             onClick={() => {
                                                 handleTabChange(item)
                                             }}
                                             style={{
                                                 textTransform: 'none',
                                                 color: appTheme.appColor.black,
                                                 fontSize: appTheme.fontSizes.md,
                                                 fontFamily: appTheme.default.fontFamily,
                                                 minWidth: '70px',
                                                 fontWeight: (item?.value === selectedInvestmentTab?.value) ? 500 : 400
                                             }}
                                             value={item.value}/>

                                    )
                                })
                            }
                        </TabList>
                    </Box>
                </TabContext>
            </Box>
        )
    };

    const renderStatusDot = (status, txnType) => {
        if (isEmpty(status)) {
            return '-'
        }
        switch (status) {
            case txnType+'-Pending for Authorisation' :
                return <span className="status-dot warning"/>;

            case txnType+'-Processed':
                return <span className="status-dot info"/>;

            default:
                return <span className="status-dot " style={{backgroundColor: AppTheme.appColor.gray}}/>;
        }
    };

    const renderStatus = (transaction, txnType) => {
        return (
            <div className={`d-flex ${isXSView ? ' justify-content-between' : 'align-items-top'} `}>
                <div>{isEmpty(transaction?.trxnStatus) ? '' : renderStatusDot(transaction?.trxnStatus, txnType)}</div>
                <DLText
                    id={"statusValue"}
                    type={"normal"}
                    fontSize={"xs"}
                    text={isEmpty(transaction?.trxnStatus) ? '-' : transaction?.trxnStatus}
                    marginBottom={'none'}
                />
            </div>
        )
    };

    const renderSchemeNameUi = (transaction) => {
        let fromScheme = transaction?.fromScheme;
        let toScheme = transaction?.toScheme;
        let currentTab = selectedInvestmentTab.value;
        if(currentTab !== 'STP'){
            return (
                <div>
                    {isMobileView &&
                        <DLText
                            id={""}
                            type={"normal"}
                            fontSize={"xs"}
                            text={'Scheme'}
                            fontColor={"grayDark"}
                            marginBottom={'none'}
                        />
                    }
                    <DLText
                        id={""}
                        type={"normal"}
                        fontSize={"xs"}
                        text={fromScheme}
                        marginBottom={'none'}
                    />
                </div>
            )
        }
        return (
            <div>
                {isMobileView &&
                <DLText
                    id={""}
                    type={"normal"}
                    fontSize={"xs"}
                    text={'Source Scheme'}
                    fontColor={"grayDark"}
                    marginBottom={'none'}
                />
                }
                <div className='d-flex mb-1'>
                    {!isMobileView && <div>
                        <div className='source-scheme-icon mx-1 mt-1'/>
                        <div className='d-flex justify-content-center h-100'>
                            <div className={'step-connector'} style={{color: appTheme.appColor.grayDark}}/>
                        </div>
                    </div>}

                    <div className={isMobileView? '' : 'ms-1'}>
                        <DLText
                            id={""}
                            type={"normal"}
                            fontSize={"xs"}
                            marginBottom={"sm"}
                            text={fromScheme}
                        />

                    </div>
                </div>
                {isMobileView &&
                <DLText
                    id={""}
                    type={"normal"}
                    fontSize={"xs"}
                    text={'Target Scheme'}
                    fontColor={"grayDark"}
                    marginBottom={'none'}
                />
                }
                <div className='d-flex mb-1'>
                    {!isMobileView && <div>
                        <div className='target-scheme-icon mx-1 mt-1'/>
                    </div>}

                    <div className={isMobileView? '' : 'ms-1'}>
                        <DLText
                            id={""}
                            type={"normal"}
                            fontSize={"xs"}
                            marginBottom={"sm"}
                            text={toScheme}
                        />

                    </div>
                </div>
            </div>
        )
    };

    const renderActiveSystematicPlansTable = () => {
        let investmentData = getInvestmentData(selectedInvestmentTab);
        let investmentHeading = getInvestmentHeading(selectedInvestmentTab);
        let investmentConfig = getInvestmentConfig(selectedInvestmentTab);
        let requiredFunction = '';
        if (isEmpty(investmentData)) {
            return (
                <div className={'d-flex align-items-center justify-content-center border-top mb-3'}
                     style={{minHeight: '256px'}}>
                    No Active {selectedInvestmentTab?.label}.
                </div>
            );
        }

        if(selectedInvestmentTab.value === "SIP"){
            requiredFunction = getFormattedActiveSystematicPlansSIPTableData;
        }
        if(selectedInvestmentTab.value === "STP"){
            requiredFunction = getFormattedActiveSystematicPlansSTPTableData;
        }
        if(selectedInvestmentTab.value === "SWP"){
            requiredFunction = getFormattedActiveSystematicPlansSWPTableData;
        }

        const {
            monthTableData,
            weekTableData,
            BusinessTableData,
            QuarterlyTableData
        } = requiredFunction(investmentData);

        const tableSections = [];

        if (monthTableData.length > 0) {
            tableSections.push({
                id: 'monthly',
                title: 'Monthly',
                tableData: monthTableData
            });
        }

        if (weekTableData.length > 0) {
            tableSections.push({
                id: 'weekly',
                title: 'Weekly',
                tableData: weekTableData
            });
        }

        if (BusinessTableData.length > 0) {
            tableSections.push({
                id: 'business day',
                title: 'Business Day',
                tableData: BusinessTableData
            });
        }

        if (QuarterlyTableData.length > 0) {
            tableSections.push({
                id: 'quarterly',
                title: 'Quarterly',
                tableData: QuarterlyTableData
            });
        }

        if (tableSections.length === 0) {
            return (
                <div className={'d-flex align-items-center justify-content-center border-top mb-3'}
                     style={{minHeight: '256px'}}>
                    No Active {selectedInvestmentTab?.label}.
                </div>
            );
        }

        return (
            <div>
                {/* Loop through the tableSections array and render each table dynamically */}
                {tableSections.map((sipData) => (
                    <div key={sipData.id} className='py-3'>
                        <div className={'d-flex align-items-center ps-3 mb-2'}>
                            <DLText
                                id={sipData.id}
                                isInline={true}
                                fontColor={'black'}
                                fontSize={'sm'}
                                marginBottom={'none'}
                                fontWeight={'bold'}
                                text={sipData.title}
                            />
                        </div>
                        <DLTable
                            id={sipData.id}
                            tableMaxHeight={'auto'}
                            tableMinHeight={'auto'}
                            isRowClickable={false}
                            isShowTableBorder={false}

                            isShowPagination={false}
                            headerData={isMobileView? [] : investmentHeading}
                            tableData={sipData.tableData}
                            cellConfig={isMobileView? [] : investmentConfig}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const renderHeading = () => {
        return(
            <div className={isMobileView? 'block-padding' : 'py-2 ps-3'} id={'investment'}>
                <DLText id={'iin-form-id'}
                        text={"Systematic Plans"}
                        fontSize={isMobileView? "md" : "lg"}
                        marginBottom={'none'}
                        fontWeight={"semi-bold"}
                />
            </div>
        )
    };

    const activeSystematicPlans = () => {

        if (isEmpty(investments)) {
            return (
                <div>
                    <div className='bg-white rounded-bottom'>
                        <div
                            className={'d-flex align-items-center justify-content-center empty-records px-3' + (isXSView ? ' text-center' : '')}
                            style={{maxHeight: '200px', minHeight: '266px'}}>
                            No Active {selectedInvestmentTab?.label}.
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                {renderActiveSystematicPlansTable()}
            </div>);
    };

    const activeSystematicPlansMobileView = () => {

        if (isEmpty(investments)) {
            return (
                <div className='border'>
                    <div
                        className={'d-flex align-items-center justify-content-center empty-records px-3' + (isXSView ? ' text-center' : '')}
                        style={{maxHeight: '200px', minHeight: '200px'}}>
                        No Active {selectedInvestmentTab?.label}.
                    </div>
                </div>
            )
        }
        return (
            <div>
                {renderActiveSystematicPlansTable()}
            </div>
        );
    };

    return (
        <div>
            <div className={isMobileView? 'bg-white' : 'panel p-0 table-border'}>
            <div>
                {renderHeading()}
                <div>
                    {renderSystematicPlansTabs()}
                </div>
            </div>
                {isMobileView ? activeSystematicPlansMobileView() : activeSystematicPlans()}
        </div>
        </div>
    );
};

export default SystematicInvestmentsView;