export const headingAccountData = ['Bank Name','Branch','IFSC','Account Holder Name','Account Number', 'Account Type', 'Status'];

export const headingMandateData = ['Bank Name','Account Number' , 'Date Range',  'Status', 'Bank Mandate Amount',];

export const clientMandateCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px'
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '167px',
        maxWidth: '200px',
        paddingRight: '20px'
    },

];

export const clientBankAccountCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '70px',
        paddingRight: '20px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },

];

export const clientMandateSmallScreenCellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '10px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '80px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '70px'
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '147px',
        maxWidth: '147px',
    },

];

export const lastMonthsTransactionsCellConfig=[
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
        padding: '8px 0 8px 12px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '240px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '120px',
    },
];

export const investmentCellSIPConfig=[
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
        padding: '8px 0 8px 12px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '120px',
    }
];
export const investmentCellSTPConfig=[
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
        padding: '8px 0 8px 12px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '100px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '100px',
        paddingRight: '40px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '120px',
    }
];
export const investmentCellSWPConfig=[
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
        padding: '8px 0 8px 12px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '120px',
        paddingRight: '40px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '120px',
    },
    {
        textAlign: 'right',
        width: 'auto',
        minWidth: '120px',
    }
];

export const lastMonthsTransactionsCellConfigMobile=[
    {
        padding: '16px'
    }
];

export const clientNomineeHeadingData = ['Name', 'Date of Birth', 'PAN', 'Relation', '% Allocation'];

export const clientNomineeheadingMobileData = ['Name', 'Date of Birth', '% Alloc.'];

export const lastMonthsTransactionsHeading = ['Scheme', 'Type of Transactions', 'Date', 'Status','Amount'];

export const activeSystematicPlansSIPTableHeading = ['Scheme', 'Date', 'Duration', 'Status', 'Amount'];
export const activeSystematicPlansSTPTableHeading = ['Source Scheme & Target Scheme ', 'Date', 'Duration', 'Units', 'Status', 'Amount'];
export const activeSystematicPlansSWPTableHeading = ['Scheme', 'Date', 'Duration', 'Units', 'Status', 'Amount'];