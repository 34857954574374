import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";

import {
    setSearchKeyword,
    resetClientListingFilters,
    setSkipCount,
    setLimitCount,
    setPageCount,
    setClientsListingRowPerPageCount,
    getClientListing,
    setCategory,
    setCRMForListing,
    setAccountType,
    setLocationForListing
} from "../../clientListingAction";
import {setLeadsRowPerPageCount} from "../../../../../leads/LeadsActions";
import {getCategoryOptions, getClientAccountTypeOptions as getClientAccountTypes} from "../../../../../AppComponents/MetadataActions";

import ListingForManagementView from "./ListingForManagementView";
import {getCRMs, getLocations} from "../../../../../AppComponents/MetadataActions";

const ListingForManagement = (props) => {
    const [clientsListingState, setClientsListingState] = useState({
        clientList: [],
        listingCount: 0,
        isShowFilterMobile: false,
        isApiInProgress: false,
        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        accountCategories: [],
        accountTypesDropdownValues: [],
        accountCategoriesDropdownValues: [],
        CRMDropdownValues: [],
        locationDropdownValues: [],
        badgeData : {
            title : '',
            data : ''
        }
    });
    const {clientsFilter}=props;

    useEffect(() => {
        getMasterData()
    }, []);

    useEffect(() => {
        getClientList();
    }, [props.clientsFilter]);

    const {page, rowsPerPage, skipCount, limitCount} = props.clientsFilter;

    const getMasterData = () => {
        setClientsListingState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));
        getCategoryOptions()
            .then(categoryRes => {
                if (categoryRes.success) {
                    const categoryData = categoryRes?.data?.categoryType;
                    getClientAccountTypes()
                        .then(accountTypeRes => {
                            if (accountTypeRes.success) {
                                const accountTypeData = accountTypeRes?.data?.accountType;
                                getCRMs().then((CRMRes) => {
                                    if (CRMRes?.success) {
                                        const CRMData = CRMRes?.data?.advisoryUsers;

                                        getLocations().then((locationsRes) => {
                                            if (locationsRes?.success) {
                                                const locationData = locationsRes?.data?.locations;

                                                setClientsListingState(prevState => ({
                                                    ...prevState,

                                                    accountCategories: categoryData,
                                                    accountTypesDropdownValues: setValuesForDropdown(accountTypeData, "name", "id"),
                                                    accountCategoriesDropdownValues: setValuesForDropdown(categoryData, "name", "id"),
                                                    CRMDropdownValues: setValuesForCRMDropdown(CRMData, "firstName", "id"),
                                                    locationDropdownValues: setValuesForDropdown(locationData, "description", "id"),
                                                    badgeData : applyFilterBadge(clientsFilter, categoryData),
                                                    UIState: APP_CONST.CONTENT_AVAILABLE,
                                                    isShowLoader: false,
                                                }))
                                            } else {
                                                setClientsListingState(prevState => ({
                                                    ...prevState,
                                                    isShowLoader: false,
                                                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                                                }));
                                            }
                                        })
                                    }})
                            }
                        })
                }
            })
    };

    const applyFilterBadge = (clientsFilter,accountCategories) => {

        let badgeObj = {
            title : '',
            data : '',
        };

        if (!isEmpty(clientsFilter?.category)) {
            badgeObj.title= 'Category'+ ': ';
            if(clientsFilter?.category[0] === 'UNASSIGNED'){
                badgeObj.data = 'Unassigned'
            }else{
                badgeObj.data = getCategoryValue(clientsFilter?.category[0], accountCategories);
            }
        }
        return badgeObj;
    };

    const getCategoryValue = (selectedStatus, accountCategories) => {
        if (isEmpty(selectedStatus) || isEmpty(accountCategories)) {
            return '-'
        }
        const status = accountCategories.find(status => status.id === selectedStatus);
        return status ? status.name : '-';

    };

    const getCategory = (accountCategory) => {
        if (isEmpty(accountCategory)) {
            return '-'
        }
        const category = clientsListingState.accountCategories.find(status => status.id === accountCategory);
        return category ? category.name : '-';
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: "ALL"}];
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken], value: item?.[valueToken]})
        });
        return list;
    };

    const setValuesForCRMDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: 'ALL'},{label: "Unassigned", value: 'UNASSIGNED'}];
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken] + " " + item?.lastName, value: item?.[valueToken]})
        });
        return list;
    };

    const resetClientListing = () => {
        setClientsListingState((prevStep) => ({
            ...prevStep,
            badgeData: {
                title : '',
                data : '',
                filterKey: '',
            }
        }));
        resetClientListingFilters();
    };

    const handleToggleMobileFilter = (value) => {
        setClientsListingState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount} = clientsListingState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const resetPagination = () => {
        setLeadsRowPerPageCount(50);
        setLimitCount(50);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setClientsListingState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));
        setClientsListingRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let tempLimitCount = limitCount;
        let tempSkipCount = skipCount;

        const isForward = newPage > page;
        if (isForward) {
            tempSkipCount = tempSkipCount + rowsPerPage;
            tempLimitCount = rowsPerPage
        } else {
            tempSkipCount = tempSkipCount - rowsPerPage;
            tempLimitCount = rowsPerPage
        }

        setSkipCount(tempSkipCount);
        setLimitCount(tempLimitCount);
        setPageCount(newPage);
    };

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.CRMId) && filter.CRMId[0] !== "ALL") {
            filterObject.CRMId = filter.CRMId;
        }
        if (!isEmpty(filter.category) && filter.category[0] !== "ALL") {
            filterObject.category = filter.category;
        }
        if (!isEmpty(filter.type) && filter.type[0] !== "ALL") {
            filterObject.type = filter.type;
        }
        if (!isEmpty(filter.location) && filter.location[0] !== "ALL") {
            filterObject.location = filter.location;
        }
        return filterObject;
    };

    const getClientList = () => {
        const {clientsFilter} = props;

        setClientsListingState(prevState => ({
            ...prevState,
            isApiInProgress: true,
        }));

        let payload = {
            "skipCount": clientsFilter.skipCount,
            "limitCount": clientsFilter.limitCount,
            "filters": generateFilter(clientsFilter)
        };

        getClientListing(payload)
            .then(res => {
                if (res?.success) {
                    setClientsListingState(prevState => ({
                        ...prevState,
                        clientList: res.data?.accounts,
                        listingCount: res.data?.count,
                        skipCount: clientsFilter.skipCount,
                        limitCount: clientsFilter.limitCount,
                        isApiInProgress: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));

                } else {
                    setClientsListingState(prevState => ({
                        ...prevState,
                        isApiInProgress: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setSearchKeyword(value);
            return;
        }

        if (name === 'CRMId') {
            setCRMForListing([value]);
        }
        if (name === 'category') {
            setCategory([value]);
        }
        if (name === 'type') {
            setAccountType([value]);
        }
        if (name === 'location') {
            setLocationForListing([value]);
        }
        if(name === 'title'){
            setCategory('');
            setClientsListingState((prevStep) => ({
                ...prevStep,
                badgeData: {
                    title : '',
                    data : '',
                    filterKey: '',
                }
            }));
        }
    };

    const getCRMNameById = (id) => {
        const {CRMDropdownValues} = clientsListingState;
        let name = "";
        if (isEmpty(id)) {
            return "-";
        }
        CRMDropdownValues.forEach((item) => {
            if (id === item?.value) {
                name = item?.label
            }
        });
        return name;
    };

    const getAccountTypeById = (id) => {
        const {accountTypesDropdownValues} = clientsListingState;
        let accountType = "";
        if (isEmpty(id)) {
            return "-";
        }
        accountTypesDropdownValues.forEach((item) => {
            if (id === item?.value) {
                accountType = item?.label
            }
        });
        return accountType;
    };

    const handleRefreshButton=()=>{
        getMasterData();
        getClientList();
    };
    const redirectToDetailsScreen = (accontId) =>{
        props.history.push('/clients/' + accontId)
    };

    return (
        <ListingForManagementView
            {...props}
            {...clientsListingState}
            {...props.clientsFilter}
            onChangeFilter={onChangeFilter}
            handleChangePage={handleChangePage}
            resetClientListing={resetClientListing}
            handleRefreshButton={handleRefreshButton}
            handleToggleMobileFilter={handleToggleMobileFilter}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getCategoryValue={getCategoryValue}
            getCRMNameById={getCRMNameById}
            getAccountTypeById={getAccountTypeById}
            redirectToDetailsScreen={redirectToDetailsScreen}
            getCategory={getCategory}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    clientsFilter: state.clientsState.clientsFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(ListingForManagement);
