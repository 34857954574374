import {httpPost, httpGet, httpPut} from "../utils/httpHelper";
import store from "../store";
import {
    setSearchKeywordValue, setFromDateValue, setToDateValue,
    setStatusValue, resetAllFilters,
    setLimitCountValue,
    setPageValue,
    setRowsPerPageValue,
    setSkipCountValue,
    setCRMValue, resetLeadFilter
} from "./leadsReducer"

export const saveLeadData = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/lead/request', data, handleErrorInComponent).then(res => {
        return res;
    })
};

export const getLeadDetails = (leadId) => {
    return httpGet('/service/advisory/lead/request?id='+leadId).then(res => {
        return res;
    })
};

export const getLeadsListing = async (data) => {
    return httpPost('/service/advisory/lead/request/all', data)
        .then(res => {
            return res;
        });
};

export const updateLeadStatus = (data) => {
    return httpPost('/service/advisory/lead/request/updateStatus', data, true).then(res => {
        return res;
    })
};

export const updateOnHoldLeadStatus = (data) => {
    return httpPut('/service/advisory/lead/request/updateOnHoldDetails', data, true).then(res => {
        return res;
    })
};
export const updateMarkedAsContacted = (data) => {
    return httpPut('/service/advisory/lead/request/contacted', data, true).then(res => {
        return res;
    })
};

export const leadAddRemark = (data) => {
    return httpPost('/service/advisory/lead/request/remark', data, true).then(res => {
        return res;
    })
};

export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordValue(data))
};

export const setFromDate = (data) => {
    store.dispatch(setFromDateValue(data))
};

export const setToDate = (data) => {
    store.dispatch(setToDateValue(data))
};

export const setStatus = (data) => {
    store.dispatch(setStatusValue(data))
};

export const setCRM = (data) => {
    store.dispatch(setCRMValue(data))
};

export const resetLeadFilters = (data) => {
    store.dispatch(resetAllFilters(data))
};

export const setSkipCount = (data) => {
    store.dispatch(setSkipCountValue(data))
};

export const setLimitCount = (data) => {
    store.dispatch(setLimitCountValue(data))
};

export const setPageCount = (data) => {
    store.dispatch(setPageValue(data))
};

export const setLeadsRowPerPageCount = (data) => {
    store.dispatch(setRowsPerPageValue(data))
};
export const resetSelectedLeadFilter = (data) => {
    store.dispatch(resetLeadFilter(data))
};




