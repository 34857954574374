import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'FTReportReducer',
    initialState: {
        FTReportFilter: {
            keyword: '',
            CRMId: ['ALL'],
            skipCount: 0,
            limitCount: 50,
            page:0,
            rowsPerPage: 50,
            fromDate: '',
            toDate: '',
            txnType: ['ALL'],
            txnSubType: ['ALL'],
            status: ['ALL']
        },
        CRMDetails: []
    },
    reducers: {
        setFTReportSearchKeywordValue(state,action) {
            state.FTReportFilter.keyword = action.payload;
        },

        setFTReportCRMIdValue(state, action) {
            state.FTReportFilter.CRMId = action.payload;
        },

        setFTReportSkipCountValue(state,action){
            state.FTReportFilter.skipCount = action.payload;
        },

        setFTReportLimitCountValue(state,action){
            state.FTReportFilter.limitCount = action.payload;
        },

        setFTReportPageValue(state,action){
            state.FTReportFilter.page = action.payload;
        },

        setFTReportRowsPerPageValue(state,action){
            state.FTReportFilter.rowsPerPage = action.payload;
        },

        setFTReportTxnTypeValue(state,action){
            state.FTReportFilter.txnType = action.payload;
        },

        setFTReportTxnSubTypeValue(state,action){
            state.FTReportFilter.txnSubType = action.payload;
        },

        setFTReportStatusValue(state,action){
            state.FTReportFilter.status = action.payload;
        },

        setFTReportFromDateValue(state,action){
            state.FTReportFilter.fromDate = action.payload;
        },

        setFTReportToDateValue(state,action){
            state.FTReportFilter.toDate = action.payload;
        },

        resetFTReportAllFilters(state) {
            state.FTReportFilter = {
                ...state.FTReportFilter,
                keyword: '',
                CRMId: ['ALL'],
                skipCount: 0,
                limitCount: 50,
                page:0,
                rowsPerPage: 50,
                fromDate: '',
                toDate: '',
                txnType: ['ALL'],
                txnSubType: ['ALL'],
                status: ['ALL']
            };
        },
    }
});

export const {setFTReportSearchKeywordValue, resetFTReportAllFilters, setFTReportRowsPerPageValue, setFTReportPageValue,
    setFTReportLimitCountValue,setFTReportSkipCountValue, setFTReportCRMIdValue, setFTReportTxnTypeValue, setFTReportTxnSubTypeValue, setFTReportStatusValue, setFTReportFromDateValue, setFTReportToDateValue} = appSlice.actions;

export default appSlice.reducer