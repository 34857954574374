import React from 'react';
import DLText from "../../../../../../../../../core/components/DLText/DLText";
import {isEmpty} from "../../../../../../../../../utils/validations";
import EChartDougnute from "../../../../../../../EchartDougnute";
import DLLoader from "../../../../../../../../../core/components/DLLoader/DLLoader";

const SummaryByFundView = (props) => {

    const {data, isXSView, getDataArray, isShowLoader} = props;

    const renderUI = () => {
        return (
            <div className={isXSView ? '' : 'panel p-0'}>
                <div className={isXSView ? 'pt-2' : 'section-title py-2'}>
                    <div className={isXSView ? 'px-3' : 'ps-2 ms-1'}>
                        <DLText
                            id={'summary'}
                            text={'Summary by Fund House'}
                            marginBottom={'none'}
                            fontSize={isXSView ? 'sm' : 'md'}
                            fontWeight={'bold'}
                        />
                    </div>
                    <div className={"w-100 d-flex justify-content-center align-items-center"}
                         style={{maxHeight: '274px', minHeight: '274px'}}>
                        {isShowLoader ? (
                            <DLLoader isVisible={true} type={"inline"} />
                        ) : isEmpty(data) ? (
                            <DLText
                                id={'no-data'}
                                text={'No data found.'}
                            />
                        ) : (
                            <EChartDougnute
                                {...props}
                                data={getDataArray(data)}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className={''}>
            {renderUI()}
        </div>
    );
};

export default SummaryByFundView;