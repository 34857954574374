import {httpGet, httpPost} from "../utils/httpHelper";
import {
    resetAllFilters as resetAllLeadsFilters,
    setCRMValue as setLeadsCRMValue,
    setFromDateValue as setLeadsFromDateValue,
    setStatusValue as setLeadsStatusValue,
    setToDateValue as setLeadsToDateValue,
    setLeadDateKeyValue
} from "../leads/leadsReducer";
import store from "../store";
import {
    resetAllFilters as resetAllOnboardingFilters,
    setAccountTypeValue as setOnboardingAccountTypeValue, setAgingKey, setApplicationStatusValue,
    setCategoryValue as setOnboardingCategoryValue,
    setCRMValue as setOnboardingCRMValue,
    setFromDateValue as setOnboardingFromDateValue, setLocationValue, setStatusTitle,
    setStatusValue as setOnboardingStatusValue,
    setToDateValue as setOnboardingToDateValue,
} from "../onboarding/listing/onboardingReducer";

export const getMyLeads = () => {
    return httpGet('/service/advisory/summary/crm/myLeads').then(res => {
        return res;
    })
};

export const getLeadsAgeing = () => {
    return httpGet('/service/advisory/summary/crm/leadsByAgeing').then(res => {
        return res;
    })
};

export const getUnassignedLeads = () => {
    return httpGet('/service/advisory/summary/crm/unassignedLeads').then(res => {
        return res;
    })
};

export const getOnboardingCategory = () => {
    return httpGet('/service/advisory/summary/crm/onboardingByCategory').then(res => {
        return res;
    })
};

export const getOnboardingAccountType = () => {
    return httpGet('/service/advisory/summary/crm/onboardingByType').then(res => {
        return res;
    })
};

export const getOnboardingAgeing = () => {
    return httpGet('/service/advisory/summary/crm/onboardingByAgeing').then(res => {
        return res;
    })
};

export const getOnboardingStatus = () => {
    return httpGet('/service/advisory/summary/crm/onboardingByApplicationStatus').then(res => {
        return res;
    })
};

export const getClientCategory = () => {
    return httpGet('/service/advisory/summary/crm/clientByCategory').then(res => {
        return res;
    })
};

export const getClientAccountType = () => {
    return httpGet('/service/advisory/summary/crm/clientByAccountType').then(res => {
        return res;
    })
};

// Management Dashboard
export const getManagementMyLeads = () => {
    return httpGet('/service/advisory/summary/management/leadStatus').then(res => {
        return res;
    })
};

export const getManagementLeadsAgeing = () => {
    return httpGet('/service/advisory/summary/management/notContactedLeads').then(res => {
        return res;
    })
};

export const getManagementCRMLeads = () => {
    return httpGet('/service/advisory/summary/management/crmLeads').then(res => {
        return res;
    })
};

export const getManagementOnboardingCategory = () => {
    return httpGet('/service/advisory/summary/management/onboardingByCategory').then(res => {
        return res;
    })
};

export const getManagementOnboardingAccountType = () => {
    return httpGet('/service/advisory/summary/management/onboardingByAccountType').then(res => {
        return res;
    })
};

export const getManagementOnboardingLocation = () => {
    return httpGet('/service/advisory/summary/management/onboardingByLocation').then(res => {
        return res;
    })
};

export const getManagementOnboardingCRM = () => {
    return httpGet('/service/advisory/summary/management/onboardingBycrm').then(res => {
        return res;
    })
};

export const getManagementOnboardingAgeing = () => {
    return httpGet('/service/advisory/summary/management/onboardingByAgeing').then(res => {
        return res;
    })
};

export const getManagementOnboardingStatus = () => {
    return httpGet('/service/advisory/summary/management/onboardingByApplicationStatus').then(res => {
        return res;
    })
};

export const getManagementClientCategory = () => {
    return httpGet('/service/advisory/summary/management/clientByCategory').then(res => {
        return res;
    })
};

export const getManagementClientAccountType = () => {
    return httpGet('/service/advisory/summary/management/clientByAccountType').then(res => {
        return res;
    })
};

export const getManagementClientByLocation = () => {
    return httpGet('/service/advisory/summary/management/clientByLocation').then(res => {
        return res;
    })
};

export const getManagementClientCRM = () => {
    return httpGet('/service/advisory/summary/management/clientBycrm').then(res => {
        return res;
    })
};

export const getManagementSummary = (data, handleErrorInComponent) => {
    return httpPost('/service/advisory/fundReport/management/summary/requests', data, handleErrorInComponent).then(res => {
        return res;
    })
};

export const getIntervalData = (legendInterval, legendAttribute, isManagement) => {
    let url = '';
    if(isManagement){
      url = '/service/advisory/fundReport/management/summary/transaction?interval='+legendInterval+'&subInterval='+legendAttribute;
    }else{
        url = '/service/advisory/fundReport/crm/summary/transaction?interval='+legendInterval+'&subInterval='+legendAttribute;
    }
    return httpGet(url).then(res => {
        return res;
    })
};

export const getCRMSummary = (data) => {
    return httpGet('/service/advisory/fundReport/crm/summary/requests?interval=' + data).then(res => {
        return res;
    })
};

export const getFTAnalyticsClientManagement = (interval) => {
    return httpGet('/service/advisory/fundReport/management/summary/analytics/client?interval=' + interval).then(res => {
        return res;
    })
};

export const getFTAnalyticsFundsManagement = (interval) => {
    return httpGet('/service/advisory/fundReport/management/summary/analytics/fundHouse?interval=' + interval).then(res => {
        return res;
    })
};

export const getFTAnalyticsFinancialsManagement = (interval) => {
    return httpGet('/service/advisory/fundReport/management/summary/analytics/category?interval=' + interval).then(res => {
        return res;
    });
};

export const getFTAnalyticsClientCRM = (interval) => {
    return httpGet('/service/advisory/fundReport/CRM/summary/analytics/client?interval=' + interval).then(res => {
        return res;
    })
};

export const getSummaryTransactionTypeManagement = (interval) => {
    return httpGet('/service/advisory/fundReport/management/summary/transaction?interval=' + interval).then(res => {
        return res;
    })
};

export const getSummaryTransactionTypeCRM = (interval) => {
    return httpGet('/service/advisory/fundReport/crm/summary/transaction?interval=' + interval).then(res => {
        return res;
    })
};

export const getTransactionsPendingForAuthorizationManagement = () => {
    return httpGet('/service/advisory/fundReport/management/summary/trxnPending').then(res => {
        return res;
    });
};

export const getTransactionsPendingForAuthorizationCRM = () => {
    return httpGet('/service/advisory/fundReport/crm/summary/trxnPending').then(res => {
        return res;
    })
};

export const getTransactionRejectedReversalManagement = () => {
    return httpGet('/service/advisory/fundReport/management/summary/trxnReject').then(res => {
        return res;
    })
};

export const getTransactionRejectedReversalCRM = () => {
    return httpGet('/service/advisory/fundReport/crm/summary/trxnReject').then(res => {
        return res;
    })
};

export const getFTAnalyticsFundsCRM = (interval) => {
    return httpGet('/service/advisory/fundReport/CRM/summary/analytics/fundHouse?interval=' + interval).then(res => {
        return res;
    })
};

export const getFTAnalyticsFinancialsCRM = (interval) => {
    return httpGet('/service/advisory/fundReport/CRM/summary/analytics/category?interval=' + interval).then(res => {
        return res;
    });
};

export const setCRM = (data) => {
    store.dispatch(setLeadsCRMValue(data))
};

export const setStatus = (data) => {
    store.dispatch(setLeadsStatusValue(data))
};

export const setFromDate = (data) => {
    store.dispatch(setLeadsFromDateValue(data))
};

export const setToDate = (data) => {
    store.dispatch(setLeadsToDateValue(data))
};
export const setLeadDateKey = (data) => {
    store.dispatch(setLeadDateKeyValue(data))
};

export const resetLeadFilters = (data) => {
    store.dispatch(resetAllLeadsFilters(data))
};

export const setOnboardingFromDate = (data) => {
    store.dispatch(setOnboardingFromDateValue(data))
};

export const setOnboardingToDate = (data) => {
    store.dispatch(setOnboardingToDateValue(data))
};

export const setOnboardingAPPStatus = (data) => {
    store.dispatch(setApplicationStatusValue(data))
};

export const setOnboardingCategory = (data) => {
    store.dispatch(setOnboardingCategoryValue(data))
};

export const setOnboardingAccountType = (data) => {
    store.dispatch(setOnboardingAccountTypeValue(data))
};

export const resetOnboardingListingFilters = (data) => {
    store.dispatch(resetAllOnboardingFilters(data))
};

export const setLocationForListing = (data) => {
    store.dispatch(setLocationValue(data))
};

export const setCRMForListing = (data) => {
    store.dispatch(setOnboardingCRMValue(data))
};

export const setOnboardingStatusTitle = (data) => {
    store.dispatch(setStatusTitle(data))
};
export const setAgingDateKey = (data) => {
    store.dispatch(setAgingKey(data))
};

