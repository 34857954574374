import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {userConst} from "../../user/userConsts";
import {isEmpty} from "../../utils/validations";

const PreLoginRoute = ({component: Component, userAuthStatus, prevLocation, ...rest}) => (

    <Route

        {...rest}
        render={props => {
            let authStatus = (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED);
            let uiContent;
            if (authStatus) {
                let uuid = props?.match?.params?.id;
                if(isEmpty(uuid)){
                    uiContent = (<Redirect to={{pathname: '/'}}/>);
                }else{
                    uiContent = (<Redirect to={{pathname: '/postlogin/financial-transaction/'+uuid}}/>);
                }

            } else {
                uiContent = (<Component {...props} prevLocation={prevLocation}/>);
            }
            return uiContent;
        }
        }
    />
);

PreLoginRoute.propTypes = {};

const mapStateToProps = state => ({
    userAuthStatus: state.userState.userAuthStatus,
    prevLocation: state.appState.prevLocation
});

export default connect(mapStateToProps)(PreLoginRoute);
