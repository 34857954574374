import React, {useEffect, useState} from 'react';
import NomineeCreateOrUpdateView from "./NomineeCreateOrUpdateView";
import {connect} from "react-redux";
import {
    addNominee,
    removeNominee
} from '../../../../../onboarding/OnboardingActions';
import {isEmpty} from "../../../../../utils/validations";
import {getClientNomineeDetails, getClientRequiredDocuments} from "../../../../ClientsActions";
import {getStateList, getRelationsList, getGuardianRelationsList} from "../../../../../AppComponents/MetadataActions";

const NomineeCreateOrUpdate = (props) => {

    const {nomineeType, profile, accountId, selectedNomineeId} = props;

    const [addNomineeState, setAddNomineeState] = useState({
        isMajor: true,
        nomineeId: isEmpty(selectedNomineeId) ? '' : selectedNomineeId,
        dropdownValues: {
            relations: [],
            states: [],
            guardianRelations: []
        },
        nomineeData: [],
        isSaveClicked: false,
        isShowLoader: false,
        requiredDocuments: ''
    });

    useEffect(() => {
        getMasterData();
        if (nomineeType === "NEW") {
            // createNominee();
        } else {
            getRequiredDocuments(selectedNomineeId)
        }
    }, []);

    const getMasterData = () => {
        getRelationsList().then(relationsRes => {
            if (relationsRes?.success) {
                const relationsData = relationsRes?.data?.relationship;
                getStateList()
                    .then(stateRes => {
                        if (stateRes?.success) {
                            const stateData = stateRes?.data?.state;

                            getGuardianRelationsList()
                                .then(guardianRelationRes => {
                                    if (guardianRelationRes?.success) {

                                        const guardianRelationData = guardianRelationRes?.data?.relationship;

                                        const dropdownVals = {
                                            states: setValuesForDropdown(stateData, "description", "code"),
                                            relations: setValuesForDropdown(relationsData, "description", "code"),
                                            guardianRelations: setValuesForDropdown(guardianRelationData, "description", "code"),
                                        };
                                        setAddNomineeState(prevState => ({
                                            ...prevState,
                                            dropdownValues: {
                                                ...prevState.dropdownValues,
                                                ...dropdownVals
                                            }
                                        }));

                                    }
                                })
                        }
                    })
            }
        })
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const createNominee = (value) => {
        const {onboardingApplicationId} = props;
        let isMajorNominee = !isEmpty(value) ? value : addNomineeState.isMajor;

        let nominee = {
            type: "N",  // N is major Y is minor
            name: "",
            fatherName: "",
            dateOfBirth: null,
            PANNumber: "",
            relation: "",
            percentageAllocation: 1,
            nomineeAddress: {
                country: "IND",
                city: "",
                stateCode: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                zipCode: ""
            }
        };
        if (!isMajorNominee) {
            nominee = {
                type: "Y", // N is major Y is minor
                name: "",
                fatherName: "",
                dateOfBirth: null,
                relation: "",
                percentageAllocation: 1
            }
        }
        nominee.accountId = accountId;
        nominee.onboardingApplicationId = onboardingApplicationId;

        addNominee(nominee, onboardingApplicationId)
            .then((res) => {
                if (res.success) {
                    getRequiredDocuments(res.data?.id, res.data?.guardian?.id)
                }

            });
    };

    const getRequiredDocuments = (nomineeId, id) => {
        setAddNomineeState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));
        getClientRequiredDocuments(accountId).then(res => {
            if (res?.success) {
                const documents = res.data?.nomineeDocuments;

                let requiredDocs = documents.find((item) => {
                    return item.nomineeId === nomineeId;
                });
                getClientNomineeDetails(accountId, nomineeId,).then(nomineeRes => {
                    if (nomineeRes?.success) {
                        setAddNomineeState(prevState => ({
                            ...prevState,
                            isMajor: nomineeRes?.data?.type === "N",
                            requiredDocuments: requiredDocs?.documentsRequired,
                            nomineeId: nomineeId,
                            nomineeData: nomineeRes.data,
                            guardianId: isEmpty(id) ? nomineeRes.data?.guardian?.id : id,
                            isShowLoader: false
                        }));
                    }else{
                        setAddNomineeState(prevState => ({
                            ...prevState,
                            isShowLoader: false
                        }));
                    }
                })
            }
        })

    };

    // const handleSaveFlag = () =>{
    //     setAddNomineeState((prevStep) => ({
    //         ...prevStep,
    //         isSaveClicked: true
    //     }));
    // }

    // const removeNomineeDetails = (value) => {
    //     const {nomineeId} = addNomineeState;
    //     const {accountId} = props;
    //     removeNominee(accountId, nomineeId)
    //         .then((res) => {
    //             if (res.success) {
    //                 setAddNomineeState((prevStep) => ({
    //                     ...prevStep,
    //                     nomineeId: ''
    //                 }));
    //             }
    //
    //         });
    // }

    const removeAndCreateNomineeDetails = (value) => {
        const {nomineeId} = addNomineeState;
        const {accountId, onboardingApplicationId} = props;
        removeNominee(accountId, nomineeId, onboardingApplicationId)
            .then((res) => {
                if (res.success) {
                    setAddNomineeState((prevStep) => ({
                        ...prevStep,
                        nomineeId: ''
                    }));
                    createNominee(value)
                }

            });
    }

    const setTab = (value) => {
        setAddNomineeState((prevState) => ({
            ...prevState,
            isMajor: value,
        }))
        removeAndCreateNomineeDetails(value);
    };

    const onCancelClick = () => {
        const {handleCloseCreateModal} = props;
        handleCloseCreateModal()

    }

    const setData = (value) => {
        setAddNomineeState((prevState) => ({
            ...prevState,
            nomineeData: value
        }))
    };

    return (
            <NomineeCreateOrUpdateView {...props}
                                       {...addNomineeState}
                                       setTab={setTab}
                                       setData={setData}
                                       onCancelClick={onCancelClick}
            />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId,
});

export default connect(mapStateToProps, {})(NomineeCreateOrUpdate);