import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import InvestmentView from "./InvestmentView";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {getInvestmentsSip, getLastTransactions} from "../../../../ClientsActions";
import {
    resetFTReportFilters,
    setFTReportKeywordValue
} from "../../../../../transactions/FT/components/FTReportList/FTReportAction";
import {isEmpty} from "../../../../../utils/validations";

const Investment = (props) => {

    const {accountId,applicantDetails} = props;
    const [investmentState, setInvestmentState] = useState({
        lastTransactions: [],
        investments: [],
        sipData : [],
        stpData : [],
        swpData : [],
        isShowLoader: false,
        allInvestmentTabs: [{label: 'SIP', value: 'SIP'}, {label: 'STP', value: 'STP'}, {label: 'SWP', value: 'SWP'}],
        selectedInvestmentTab: {label: 'SIP', value: 'SIP'},
    });


    useEffect(() => {
        lastTransactions();
        getInvestments()
    },[]);

    const handleTabChange=(item)=>{
        setInvestmentState((prevState) => ({
            ...prevState,
            selectedInvestmentTab: {label: item.label, value: item.value}
        }));
    };

    const lastTransactions = () => {
        setInvestmentState(prevState => ({
            ...prevState,
            isShowLoader: true,
            UIState: ''
        }));

        getLastTransactions(accountId).then(res => {
            if(res.success){
                setInvestmentState((prevState) => ({
                    ...prevState,
                    lastTransactions: res.data,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                }));
            } else {
                setInvestmentState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }));
            }
        });
    };

    const getInvestments=()=>{
        let investmentData = {};
        setInvestmentState(prevState => ({
            ...prevState,
            isShowLoader: true,
            UIState: ''
        }));
        getInvestmentsSip(accountId).then(res=>{
            if(res.success){
                investmentData = setInvestmentData(res?.data);
                setInvestmentState((prevState) => ({
                    ...prevState,
                    ...investmentData,
                    investments: res.data,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                }));
            } else {
                setInvestmentState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }));
            }
        })
    };

    const setInvestmentData = (data) =>{
        let sipArr = [];
        let stpArr = [];
        let swpArr = [];

        if(isEmpty(data)){
            return;
        }
        data.forEach((item)=>{
            if(item.autoTrxnType === 'SIP'){
                sipArr.push(item)
            }else if(item.autoTrxnType === 'STP'){
                stpArr.push(item)
            }else{
                swpArr.push(item)
            }
        });

        return {
            sipData : sipArr,
            stpData : stpArr,
            swpData : swpArr
        }

    };

    const getInvestmentData = (selectedInvestmentTab) =>{
        const{sipData, stpData, swpData} = investmentState;
        let currentTab = selectedInvestmentTab.value;
        if(currentTab === "SIP"){
            return sipData;
        }
        if(currentTab === "STP"){
            return stpData;
        }
        if(currentTab === "SWP"){
            return swpData;
        }

    };

    const setSeeMoreFilters = () => {
        let combinedNames='';
        applicantDetails.map((applicant)=>{
            combinedNames += applicant.name
        });
        resetFTReportFilters();
        setFTReportKeywordValue(combinedNames);

        props.history.push('/reports/financial-transactions')
    };

    return (
        <InvestmentView
            {...props}
            {...investmentState}
            setSeeMoreFilters={setSeeMoreFilters}
            handleTabChange={handleTabChange}
            getInvestmentData={getInvestmentData}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isMDView: state.appState.deviceInfo.isMDView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(Investment);