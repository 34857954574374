import React, {useEffect, useState} from 'react';
import SummaryTransactionsRejectedReversalView from "./SummaryTransactionsRejectedReversalView";
import {getTransactionRejectedReversalCRM,} from "../../../../../../../../HomePageActions";
import {
    resetFTReportFilters,
    setFTReportStatus
} from "../../../../../../../../../transactions/FT/components/FTReportList/FTReportAction";

const SummaryTransactionsRejectedReversal = (props) => {
    const [summaryRejectedReversal,setSummaryRejectedReversal] = useState({
        rejectedTransactionData:[],
        isShowLoader: false,
    });

    useEffect(() => {
        setTransactionTypeData();
    }, []);

    const setTransactionTypeData = (isSelected) => {
        setSummaryRejectedReversal((prevState) => ({
            ...prevState,
            isShowLoader: true,
        }));
        getTransactionRejectedReversalCRM(isSelected).then((rejectedTransactionData) => {
            if(rejectedTransactionData.success){
                setSummaryRejectedReversal((prevState) => ({
                    ...prevState,
                    rejectedTransactionData: rejectedTransactionData?.data,
                    isShowLoader: false,
                }))
            } else {
                setSummaryRejectedReversal((prevState) => ({
                    ...prevState,
                    isShowLoader: false,
                }))
            }

        })
    };

    const setFilters = () => {
        resetFTReportFilters();
        setFTReportStatus(["Rejected / Reversal"]);
        props.history.push('/reports/financial-transactions');
    };

    return (
        <div>
            <SummaryTransactionsRejectedReversalView
                {...props}
                {...summaryRejectedReversal}
                setFilters={setFilters}
            />
        </div>
    );
};

export default SummaryTransactionsRejectedReversal;

