import React from 'react';

import SummaryTransactionsPendingAuthorization
    from "./BusinessOperationSummary/summaryTransactionsAuthorization/SummaryTransactionsPendingAuthorization";
import SummaryTransactionsRejectedReversal
    from "./BusinessOperationSummary/summaryTransactionsRejectedReversal/SummaryTransactionsRejectedReversal";

import DLText from "../../../../../../../core/components/DLText/DLText";

const BusinessSummaryView = (props) => {
    const {isXSView, isMobileView} = props;
    return (
        <div className='pb-4'>
            <div className=' bg-light'>
                <div>
                    <div className={isMobileView ? 'bg-white' : 'p-0 w-100 border-rounded panel h-100'}>
                        {!isXSView ? <div className={'ps-3 py-2'}>
                                <DLText
                                    id={'summary'}
                                    text={'Business Operations Summary'}
                                    marginBottom={'none'}
                                    fontSize={'lg'}
                                    fontWeight={'semi-bold'}
                                />
                            </div> :
                            <div className={`ps-3 py-2 ${isXSView ? ' border-top' : ''}`}>
                                <DLText
                                    id={'summary'}
                                    text={'Business Operations Summary'}
                                    marginBottom={'none'}
                                    fontSize={'md'}
                                    fontWeight={'semi-bold'}
                                />
                            </div>
                        }
                        <div className={`${isMobileView ? '' : 'd-flex'}`}>
                            <div className={`${isMobileView ? `col-12 ${isXSView ? '' : 'px-3 py-3'}` : 'col-6 pb-3 ps-3 pe-2'}`}>
                                <SummaryTransactionsPendingAuthorization
                                    {...props}
                                />
                            </div>
                            {isXSView && <div className='bg-light' style={{height: '7px'}}/>}
                            <div
                                className={`${isMobileView ? `col-12 ${isXSView ? '' : 'px-3 pb-3'}` : 'col-6 pb-3 ps-2 pe-3'}`}>
                                <SummaryTransactionsRejectedReversal
                                    {...props}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessSummaryView;