import React, {useEffect, useState} from 'react';

import {getFTAnalyticsClientCRM} from "../../../../../../../../HomePageActions";
import SummaryByClientView from "./SummaryByClientView";

const SummaryByClient = (props) => {
    const [summaryByClient, setSummaryByClient] = useState({
        clientData: [],
        total: {},
        isShowLoader: false,
    });



    useEffect(() => {
        getClientData();
    }, [props.isSelected])

    const getClientData = () => {
        const {isSelected} = props;

        setSummaryByClient(prevState => ({
            ...prevState,
            isShowLoader: true,
        }))

        getFTAnalyticsClientCRM(isSelected).then(res => {
                if (res.success) {
                    setSummaryByClient((prevState) => ({
                        ...prevState,
                        clientData: res?.data?.summary?.data,
                        total: res?.data?.summary?.total,
                        isShowLoader: false,
                    }));
                } else {
                    setSummaryByClient((prevState) => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                }
            }
        );
    };

    return (
        <SummaryByClientView
            {...props}
            {...summaryByClient}
        />
    );
};


export default SummaryByClient;