import React from 'react';
import {connect} from "react-redux";
import NSEResponseErrorView from "./NSEResponseErrorView";


const NSEResponseError = (props) => {

    return (
        <NSEResponseErrorView
            {...props}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(NSEResponseError);
