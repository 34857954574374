import React, {useState} from 'react';
import {connect} from "react-redux";

import IgnoreKYCView from "./IgnoreKYCView";
import {ignoreKYC} from "../../../FormsAndReviewActions";
import {isEmpty} from "../../../../../../utils/validations";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";

const IgnoreKYC = (props) => {

    const {selectedPANNumber, accountId, handleCloseIgnoreModal} = props;

    const [ignoreKycState, setIgnoreKycState] = useState({
        isApiInProgress: false,
        handleErrorInComponent: true,
        modalErrorMessage: '',
        reason: '',
        reasonError: ''
    });

    const validateChanges = (name, currentState) => {
        let {reason} = currentState;
        let reasonError = '';

        if (name === "reason" || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(reason)) {
                reasonError = "Please enter reason";
            } else if (reason.length > 500) {
                reasonError = "Maximum 500 characters allowed"
            } else {
                reasonError = "";
            }

            if (name === 'reason') {
                return reasonError;
            }
        }

        return reasonError;
    };


    const setReason = (name, value) => {
        let updatedState = {...ignoreKycState};
        updatedState[name] = value;

        const validateObject = validateChanges(name, updatedState);
        setIgnoreKycState(prevStep => ({
            ...prevStep,
            ...updatedState,
            reasonError: validateObject
        }));
    };

    const ignoreKRA = () => {
        const {reason} = ignoreKycState;
        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, ignoreKycState);

        if (!isEmpty(validateObj)) {
            setIgnoreKycState(prevStep => ({
                ...prevStep,
                reasonError: validateObj
            }));
            return;
        }
        setIgnoreKycState(prevStep => ({
            ...prevStep,
            isApiInProgress: true,
        }));

        let payload = {
            PANNumber: selectedPANNumber,
            accountId: accountId,
            reason: reason
        };
        ignoreKYC(payload)
            .then((res) => {
                    if (res.success) {
                        setIgnoreKycState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: ""
                        }));
                        handleCloseIgnoreModal(true);
                    } else {
                        setIgnoreKycState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res.__error
                        }));
                    }
                }
            );
    };

    return (
        <IgnoreKYCView
            {...props}
            {...ignoreKycState}
            ignoreKRA={ignoreKRA}
            setReason={setReason}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    accountId: state.onboardingFlowState.accountId,
});

export default connect(mapStateToProps, {})(IgnoreKYC);
