import {httpGet, httpPost, httpPut} from "../utils/httpHelper";

export const getAccountDetails = (accountId) => {
    return httpGet('/service/advisory/client/account?accountId='+accountId, true).then(res => {
        return res;
    })
};

export const getPersonDetails = (accountId, accountHolderId) => {
    return httpGet('/service/advisory/client/profile?accountId='+accountId+'&accountHolderId='+accountHolderId).then(res => {
        return res;
    })
};

export const getClientFinancialProfile = (accountId) => {
    return httpGet('/service/advisory/client/financialProfile?accountId='+accountId).then(res => {
        return res;
    })
};

export const getClientBankAccount = (accountId) => {
    return httpGet('/service/advisory/client/bank?accountId='+accountId).then(res => {
        return res;
    })
};

export const getClientNominee = (accountId) => {
    return httpGet('/service/advisory/client/nominee/all?accountId='+accountId).then(res => {
        return res;
    })
};

export const getClientNomineeDetails = (accountId, nomineeId) => {
    return httpGet('/service/advisory/client/nominee?accountId='+accountId+'&nomineeId='+nomineeId).then(res => {
        return res;
    })
};

export const getClientPersonDetails = (accountHolderId, accountId) => {
    return httpGet('/service/advisory/client/personForm?accountId='+accountId+'&accountHolderId='+accountHolderId).then(res => {
        return res;
    })
};

export const getClientRequiredDocuments = (accountId) => {
    return httpGet('/service/advisory/client/documentRequirement?accountId='+accountId).then(res => {
        return res;
    })
};

export const getClientGenerateForms = (data) => {
    return httpPost('/service/advisory/client/generateForms', data, true)
        .then(res => {
            return res;
        })
};

export const updateClientFinancialProfileData = (data) => {
    return httpPut('/service/advisory/client/financialProfile', data, true)
        .then((res) => {
            return res;
        });
};

export const assignClientToCRM = (data) => {
    return httpPut('/service/advisory/client/updateCRM',data, true)
        .then(res => {
            return res;
        })
};

export const getLastTransactions = (accountId) => {
    return httpGet('/service/advisory/client/investmentDetails/txnReport?accountId='+accountId).then(res => {
        return res;
    })
}
export const getInvestmentsSip = (accountId) => {
    return httpGet('/service/advisory/client/investmentDetails/systematicTxnReport?accountId='+accountId).then(res => {
        return res;
    })
}
