export const getColor = (index) => {
    switch (index) {
        case 0: return '#1C64F2';
        case 1: return '#16BDCA';
        case 2: return '#fac858';
        case 3: return '#ee6666';
        case 4: return '#73c0de';
        case 5: return '#3ba272';
        case 6: return '#fc8452';
        case 7: return '#9a60b4';
        case 8: return '#ea7ccc';
        case 9: return '#CC7575';
        default:
            return '#1C64F2';
    }
};