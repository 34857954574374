import React, {useState} from 'react';
import {withRouter} from "react-router-dom";
import {setUserAuthStatus} from "../../../src/user/userReducer";
import {searchAllData} from "../AppActions";
import {connect} from "react-redux";
import AppResAppBarView from "./AppResAppBarView";
import {isEmpty} from "../../utils/validations";
import {debounce} from "../../utils/helper";
import {logoutUser} from "../../user/userActions";

function AppResAppBar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorClients, setAnchorClients] = useState(false);
    const [anchorSetting, setAnchorSetting] = useState(false);

    const [resAppBarState, setResAppBarState] = useState({
        searchOptions: [],
        searchOptionsFound: false,
        isSearchInProgress: false,
        anchorEl: null,
    });

    const searchData = (keyword) =>{

        if (isEmpty(keyword)){
            setResAppBarState((prevState)=>({
                ...prevState,
                searchOptions: [],
                searchOptionsFound: false,
            }));
            return
        }

        setResAppBarState((prevState)=>({
            ...prevState,
            isSearchInProgress: true
        }));

        searchAllData(keyword).then((res)=>{
            if (res.success){
                setResAppBarState((prevState)=>({
                    ...prevState,
                    searchOptions: res.data.data,
                    searchOptionsFound: true,
                    isSearchInProgress:false
                }))
            }else {
                setResAppBarState((prevState)=>({
                    ...prevState,
                    searchOptions: [],
                    isSearchInProgress:false,
                    searchOptionsFound: true,

                }))
            }
        })
    };

    const onChangeValue = (value) =>{
        onChange(value);
    };

    const onChange = debounce((value) => {
        searchData(value);
    }, 500);

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemsOpen = event => {
        setAnchorClients(event.currentTarget);
    };

    const handleMenuItemsClose = () => {
        setAnchorClients(null)
    };

    const handleSettingIconOpen = event => {
        setAnchorSetting(event.currentTarget);
    };

    const handleSettingIconClose = () => {
        setAnchorSetting(null)
    };
    return (
        <AppResAppBarView
            {...props}
            {...resAppBarState}
            anchorEl={anchorEl}
            anchorClients={anchorClients}
            anchorSetting={anchorSetting}
            isDeleteDialogOpen={false}
            searchData={searchData}
            onChangeValue={onChangeValue}
            handleItemOpen={handleItemOpen}
            handleItemClose={handleItemClose}
            handleMenuItemsClose={handleMenuItemsClose}
            handleMenuItemsOpen={handleMenuItemsOpen}
            handleSettingIconOpen={handleSettingIconOpen}
            handleSettingIconClose={handleSettingIconClose}
        />
    );
}

AppResAppBar.propTypes = {

};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile:state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isDrawerOpen: state.appState.isDrawerOpen,
    currentPathname: state.appState.currentPathname,
    prevLocation: state.appState.prevLocation
});


export default connect(mapStateToProps, {
    setUserAuthStatus, logoutUser
}) (withRouter(AppResAppBar))
