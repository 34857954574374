import React, {useState} from 'react';
import {connect} from "react-redux";

import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";

import {approveTransaction} from "../../../../FTAction";

import ApproveTransactionView from "./ApproveTransactionView";


const ApproveTransaction = (props) => {

    const {txnRequestId, handleCloseApproveTransactionModal, onSaveSuccess, ticketNumber} = props;
    const [approveTransactionState, setApproveTransactionState] = useState({
        isApiInProgress: false,
        handleErrorInComponent: true,
        modalErrorMessage: ''
    });

    const handleApprove = () => {
        let payload = {
            txnRequestId: txnRequestId
        }
        setApproveTransactionState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        approveTransaction(payload)
            .then((res) => {
                    if (res.success) {
                        store.dispatch(showToastMessage('success', 'Financial Transaction: '+ ticketNumber +' is approved and sent to client for approval.'))
                        setApproveTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false
                        }));
                        onSaveSuccess( 'isApproveTransactionModalOpen',false);
                    } else {
                        setApproveTransactionState((prevStep) => ({
                            ...prevStep,
                            isApiInProgress: false,
                            modalErrorMessage: res.__error
                        }));
                    }
                }
            );
    };

    return (
        <ApproveTransactionView
            {...props}
            {...approveTransactionState}
            handleApprove={handleApprove}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(ApproveTransaction);
