import React, {useEffect, useState} from 'react';

import {isEmpty} from "../core/components/DLComponentHelper";
import {APP_CONST} from "../AppComponents/AppConstants";

import {verifyOTPUrl, verifyTxnDetails} from "./clientsApprovalAction";
import {connect} from "react-redux";

import ClientsTransactionView from "./ClientsTransactionView";
import DLLoader from "../core/components/DLLoader/DLLoader";

const ClientsTransaction = (props) => {
    const [clientsTransactionState, setClientsTransactionState] = useState({
        isUserVerified: false,
        approvalData: [],
        isShowLoader: false,
        responseError: '',
        otp: "",
        txnRequestType : '',
        isOtpApiFired: false
    });

    useEffect(()=>{
        validateDetails()
    }, []);

    const getUUID = () => {
        return props?.match?.params?.id
    };

    const validateDetails = () => {
        let UUID = getUUID();

        if (isEmpty(UUID)) {
            return;
        }

        setClientsTransactionState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        verifyTxnDetails(UUID)
            .then(res => {
                if (res.success) {
                    let userData = {
                        accountDetails: res?.data?.accountDetails,
                        clientData: res?.data?.clientData
                    };
                    setClientsTransactionState((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        uuid: UUID,
                        isUserVerified: true,
                        approvalData: userData,
                        txnRequestType: userData?.clientData?.txnRequestType,
                        UIState: APP_CONST.CONTENT_AVAILABLE
                    }));

                } else {
                    setClientsTransactionState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseError: res.__error,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE
                    }));
                }
            })
    };

    const verifyURL = () => {
        let UUID = getUUID();
        if (isEmpty(UUID) || clientsTransactionState?.isUserVerified) {
            return ''
        }
        setClientsTransactionState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            uuid: UUID,
            version:'1.1'
        };

        verifyOTPUrl(payload, true).then(res => {
            if (res?.success) {
                setClientsTransactionState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                    uuid: UUID,
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                    isOtpApiFired:true,
                }));
            } else {
                setClientsTransactionState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                    uuid: UUID,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    responseError: res.__error,
                    isOtpApiFired: false,
                }));
            }
        })
    };


    const otpVerified = (userData, otp) => {
        setClientsTransactionState((prevStep) => ({
            ...prevStep,
            isUserVerified: true,
            otp: otp,
            approvalData: userData,
            txnRequestType: userData?.clientData?.txnRequestType
        }));
    };

    const {UIState} = clientsTransactionState;

    if(isEmpty(UIState)){
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <div>
            <ClientsTransactionView {...props}
                                    {...clientsTransactionState}
                                    otpVerified={otpVerified}
                                    verifyURL={verifyURL}
            />
        </div>
    );
};


const mapStateToProps = (state) => ({
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(ClientsTransaction);
